import React from 'react';
import AnimatedNumberText from '../../../components/AnimatedNumber/AnimatedNumber';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import classNames from 'classnames';

export type ChartLegendItem = {
    label: string;
    value: number;
    color: string;
    testId: string;
    durationInSec: number;
    durationInSecString: string;
};

export type WidgetLegendItemProps = {
    isLoadingChart: boolean,
    itm: ChartLegendItem,
    darkMode: boolean,
};

const useStyles = makeStyles(() => ({
    labelItemContainer: {
        height: 21,
        marginTop: 8
    },
    labelItemTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        verticalAlign: 'middle',
        height: 21,
        width: 'fit-content',
        marginTop: 8
    },
    dot: {
        width: 6,
        height: 6,
        borderRadius: 3,
        marginRight: 4,
        marginTop: 9
    },
    labelTopTextContainer: {
        lineHeight: '16px',
        fontWeight: 400,
        fontSize: 14,
        height: 'fit-content',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginTop: 5.5,
        marginLeft: 4,
    },
    labelText: {
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
        lineHeight: '16px'
    },
    labelTextDarkMode: {
        color: '#D4D8DE'
    },
    valueSpanDarkMode: {
        color: Colors.White
    },
    dashDarkMode: {
        color: '#DDE1E7',
    },
}));

const WidgetLegendItem: React.VFC<WidgetLegendItemProps> = ({
    isLoadingChart,
    itm,
    darkMode
}) => {

    const classes = useStyles();

    return (
        <div key={itm.label} className={classes.labelItemContainer}>
            <div className={classes.labelItemTextContainer}>
                <div
                    className={classes.dot}
                    style={{ backgroundColor: itm.color }}
                />
                {!isLoadingChart ? (
                    <AnimatedNumberText
                        value={itm.value}
                        dataTestId="doughnut-chart-legend-value"
                        dataQa="doughnut-chart-legend-value"
                        className={classNames(darkMode && classes.valueSpanDarkMode)}
                    />
                ) : (
                    <span className={classNames(darkMode && classes.dashDarkMode)}>—</span>
                )}
                <div className={classes.labelTopTextContainer} data-testid={itm.testId}>
                    <span className={classNames(classes.labelText, darkMode && classes.labelTextDarkMode)}>{itm.label}</span>
                </div>
            </div>
        </div>);
};

export default WidgetLegendItem;