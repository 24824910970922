import React from "react";
import {ReactComponent as GreenIcon} from '../../assets/call_control_icons/green.svg';
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import CustomizedTooltip from "../Tooltip/Tooltip";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {SipCallState} from "../../store/actions/ringgroups/payloads";
import {CallControlsProps} from "./callControls.utils";
import classNames from "classnames";
import {actions} from "../../store";


const useStyles = makeStyles(() => ({
    root: {
        '& path, & rect': {transition: 'fill .4s ease'},
        cursor: 'pointer',
    },

    icon: {
        '& rect:first-child': {
            fill: Colors.White,
        },

        '& path': {
            fill: Colors.Support,
        },

        '&:hover > rect:first-child': {
            fill: Colors.Support
        },
        '&:hover path': {
            fill: Colors.White
        },
    },

    disabled: {
        '& > rect': {
            fill: 'transparent',
            stroke: 'transparent'
        },

        '& path': {
            fill: Colors.Gray6
        },
    }

}))


const Resume: React.VFC<CallControlsProps> = ({activeCallEntity, callbackAfterOnClick, isReadOnly}) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const enableState = [SipCallState.Holding];
    const enabledStateCondition = enableState.find((item) => item == activeCallEntity.state) == undefined;
    const disabled = isReadOnly || enabledStateCondition;

    const handleOnClick = () => {
        dispatch(actions.unHoldCall.request({
            id: activeCallEntity.call.id,
            tag: activeCallEntity.call.tag,
            callback: callbackAfterOnClick ? ()=>{setTimeout(()=>{callbackAfterOnClick()},500)} : undefined
        }))
    }

    return (
        activeCallEntity.state == SipCallState.Holding ?
            <CustomizedTooltip
                copy={false}
                title={isReadOnly
                    ? t('common:noPermissionToResource')
                    : t('screens:calls.resumeCall')
                }
                disableHoverListener={!isReadOnly && enabledStateCondition}
            >
                <GreenIcon
                    className={classNames(classes.root, disabled ? classes.disabled : classes.icon)}
                    onClick={!disabled ? handleOnClick : undefined}
                />

            </CustomizedTooltip> : <></>
    )
}

export default Resume;