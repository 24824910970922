import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Box, Grid} from '@material-ui/core';
import TextField from '../../TextField/TextField';
import classNames from 'classnames';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import Checkbox from '../../Checkbox/Checkbox';
import SwitchWithLabel from '../../SwitchWithLabel/SwitchWithLabel';

import {
    CallQueueFormProps,
    CallQueueFormType,
    CallQueuePromptActionType,
    addDialogClosedListener,
    callQueueValidationReqFieldsName,
    useStyles,
} from './callQueueFormUtils';
import Separator from '../../Separator/Separator';
import {actions} from "../../../store";
import {useDispatch} from "react-redux";
import {isAnyKEyFromSearchExistInSource} from "../../../utils/isAnyKEyFromSearchExistInSource";
import { DropDownDictionaryItem } from '../../../views/CallHistory/ActiveCalls/ActiveCalls.utils';
import SelectField from '../../SelectField/SelectField';
import CallQueuePromptAction from './CallQueuePromptAction';

const defaultActionPrompt: CallQueuePromptActionType = {
    prompt: 1,
    promptAction: null,
    promptName: '',
    promptFile: null,
    playPromptFile: false,
    redirectToEntity: 0,
    waitConfirmation: false,
    action: 1
}; 

export const callQueueFormDefaultValues: CallQueueFormType = {
    callQueueStatus: false,
    announceNumberOfCallersInQueue: false,
    maximumNumberOfQueuedCallers: 10,
    announceEstimatedWaitTime: false,
    averageHandleTime: 1,
    intervalBetweenAnnouncements: '5',
    playOnHoldMusicStatus: false,
    onHoldMusicFileName: '',
    onHoldMusicFile: null,
    dtmfToConfirmQueueExit: '0',
    timeOutForCallerInput: 9,
    dispatchCallsToBusyExtensions: false,
    onIncomingCallLimit: defaultActionPrompt,
    onMaxRingingTime: defaultActionPrompt,
    onMaxWaitingTime: defaultActionPrompt,
    maximumWaitingTime: null,
    maximumRingingTime: null,
    queueId: 0,
};

const CallQueueForm: React.FC<CallQueueFormProps & {
    statusChangeCallback?: (flag:boolean) => void

} > = ({tabName, tabIndex, statusChangeCallback}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isFileSelectOpen, setIsFileSelectOpen] = useState(false);
    const [updatedValues, setUpdatedValues] = useState<CallQueueFormType|undefined>(undefined)

    const fileRef = useRef<HTMLInputElement>(null);
    
    const {
        values,
        errors,
        handleChange,
        setFieldValue,
        setFieldError,
        setErrors,
        initialValues,
        setValues,
        dirty
    } = useFormikContext<CallQueueFormType>();

    const markTabAsHaveError = (setTabError: boolean) => {
        if (tabName && tabIndex !== undefined) {
            dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
        }
    }

    useEffect(() => {
        const errorsKeys = Object.keys(errors);
        if (isAnyKEyFromSearchExistInSource(errorsKeys, callQueueValidationReqFieldsName)) {
            markTabAsHaveError(true)
        } else {
            markTabAsHaveError(false)
        }
    }, [errors]);

    useEffect(() => {

        if(statusChangeCallback)
        {
            if(values.callQueueStatus)
            {
                statusChangeCallback(true);
            }else{
                statusChangeCallback(false)
            }
        }

        if(!values.callQueueStatus && Object.keys(errors).length)
        {
            setErrors({});
            setUpdatedValues(values);
            setValues({...initialValues,callQueueStatus:values.callQueueStatus});
        }else if(values.callQueueStatus && updatedValues && Object.keys(updatedValues).length)
        {
            setValues({...updatedValues,callQueueStatus:true})
        }

    }, [values.callQueueStatus]);

    useEffect(() => {
        if(!dirty)
        {
            markTabAsHaveError(false)
        }
    }, [dirty]);

    const musicOnHoldSelectItems = useMemo(() => {
        const retArray: DropDownDictionaryItem<number>[] = [];
        if(values.onHoldMusicFileName) {
            retArray.push({name: values.onHoldMusicFileName, value: 2});
        }
        retArray.push({name: t('common:uploadNewFile'), value: 1});
        retArray.push({name: t('common:default'), value: 0});
        return retArray;
    }, [values]);

    return (
        <div className={classes.inputs}>
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.extraPadding,
                )}
                style={{
                    height: 80
                }}
            >
                <Box
                    className={classNames(classes.rowBox, classes.switchMargin)}
                >
                    <SwitchWithLabel
                        label={t('screens:ringGroups.callQueue')}
                        setValue={setFieldValue}
                        value={values.callQueueStatus}
                        field={'callQueueStatus'}
                        id={'callQueueStatus'}
                        icon={
                            <IconWithTooltip
                                dataQa="callQueue-tooltip"
                                tooltipText={t('tooltips:ringGroups.callQueue')}
                            />
                        }
                    />
                </Box>
            </Grid>

            {values.callQueueStatus && (
                <>
                    <Grid item className={classNames(classes.itemsContainer2)}>
                            <TextField
                                id="intervalBetweenAnnouncements"
                                label={t(
                                    'screens:ringGroups.intervalBetweenAnnouncements',
                                )}
                                onChange={handleChange}
                                value={values.intervalBetweenAnnouncements?.toString()}
                                type="number"
                                icon={
                                    <IconWithTooltip
                                        dataQa="ring-group-name-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.intervalBetweenAnnouncements',
                                        )}
                                        className={
                                            classes.numberInputTooltip
                                        }
                                    />
                                }
                                inputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 999,
                                    },
                                    pattern: '[0-9]*',
                                    startAdornment:
                                        values.intervalBetweenAnnouncements !==
                                        '' ? (
                                            <span
                                                className={classes.minText}
                                            >
                                                {t('common:minutes').toLowerCase()}
                                            </span>
                                        ) : undefined,
                                }}
                                iconPosition="end"
                                className={classNames(
                                    classes.numberInput,
                                    classes.noMarginBottom,
                                )}
                                dataQa="interval-between-announcements-input"
                                required
                                helperText={
                                    errors.intervalBetweenAnnouncements
                                }
                                disableCounter
                            />
                            
                            <TextField
                                id="dtmfToConfirmQueueExit"
                                label={t(
                                    'screens:ringGroups.dtmfToConfirmQueueExit',
                                )}
                                onChange={(e) => {
                                    handleChange(e);
                                    if(errors.dtmfToConfirmQueueExit) {
                                        setFieldError('dtmfToConfirmQueueExit', undefined);
                                    }
                                }}
                                value={values.dtmfToConfirmQueueExit?.toString()}
                                icon={
                                    <IconWithTooltip
                                        dataQa="ring-group-name-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.dtmfToConfirmQueueExit',
                                        )}
                                        className={
                                            classes.numberInputTooltip
                                        }
                                    />
                                }
                                inputProps={{
                                    pattern: '[0-9*#]',
                                }}
                                iconPosition="end"
                                className={classNames(
                                    classes.numberInput,
                                    classes.noMarginBottom,
                                )}
                                dataQa="dtmf-to-confirm-queue-exit"
                                required
                                helperText={
                                    errors.dtmfToConfirmQueueExit
                                }
                                maxLength={1}
                            />

                            <TextField
                                id="timeOutForCallerInput"
                                label={t(
                                    'screens:ringGroups.timeOutForCallerInput',
                                )}
                                onChange={handleChange}
                                value={values.timeOutForCallerInput?.toString()}
                                type="number"
                                icon={
                                    <IconWithTooltip
                                        dataQa="ring-group-name-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.timeOutForCallerInput',
                                        )}
                                        className={
                                            classes.numberInputTooltip
                                        }
                                    />
                                }
                                inputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 99,
                                    },
                                    pattern: '[0-9]*',
                                    startAdornment:
                                        values.timeOutForCallerInput !== null ? (
                                            <span
                                                className={classes.minText}
                                            >
                                                {t('common:seconds').toLowerCase()}
                                            </span>
                                        ) : undefined,
                                }}
                                iconPosition="end"
                                className={classNames(
                                    classes.numberInput,
                                    classes.noMarginBottom,
                                )}
                                dataQa="dtmf-input-timeout"
                                required
                                helperText={
                                    errors.timeOutForCallerInput
                                }
                                disableCounter
                            />
                    </Grid>
                    
                    <Grid className={classNames(classes.itemsContainer, classes.switchMargin)} style={{height: 42}}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classNames(classes.rowBox)}
                        >
                            <Checkbox
                                dataQa="dispatch-calls-to-busy-extensions"
                                checked={values.dispatchCallsToBusyExtensions}
                                onChange={(e) =>
                                    setFieldValue(
                                        'dispatchCallsToBusyExtensions',
                                        e.target.checked,
                                        false,
                                    )
                                }
                                label={t(
                                    'screens:ringGroups.dispatchCallsToBusyExtensions',
                                )}
                            />
                            <IconWithTooltip
                                dataQa="dispatch-calls-to-busy-extensions-tooltip"
                                tooltipText={t(
                                    'tooltips:ringGroups.dispatchCallsToBusyExtensions',
                                )}
                            />
                        </Box>
                    </Grid>

                    <Separator/>
                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classNames(classes.rowBox)}
                        >
                            <span className={classes.sectionHeader}>{t(
                                    'screens:ringGroups.placingIntoQueue',
                                )}</span>
                        </Box>
                    </Grid>
                    
                    <Grid className={classes.itemsContainer} style={{marginBottom: 15}}>
                        <TextField
                            id="maximumNumberOfQueuedCallers"
                            label={t(
                                'screens:ringGroups.maximumNumberInQueuedCallers',
                            )}
                            onChange={handleChange}
                            value={values.maximumNumberOfQueuedCallers?.toString()}
                            type="number"
                            icon={
                                <IconWithTooltip
                                    dataQa="ring-group-name-tooltip"
                                    tooltipText={t(
                                        'tooltips:ringGroups.maximumNumberInQueuedCallers',
                                    )}
                                    className={classes.numberInputTooltip}
                                />
                            }
                            inputProps={{
                                inputProps: {
                                    min: 1,
                                    max: 999,
                                },
                                pattern: '[0-9]*',
                                startAdornment:
                                    values.maximumNumberOfQueuedCallers !==
                                    0 ? (
                                        <span className={classes.minText}>
                                            {t('common:callers')}
                                        </span>
                                    ) : undefined,
                            }}
                            iconPosition="end"
                            className={classNames(
                                classes.numberInput,
                                classes.noMarginBottom,
                            )}
                            dataQa="maximum-number-of-queued-callers-input"
                            required
                            helperText={errors && errors.maximumNumberOfQueuedCallers}
                            disableCounter
                        />
                    </Grid>
                    
                    <CallQueuePromptAction
                        header={t('screens:ringGroups.uponReachingMaximumCallsOnHold')}
                        noActionTitle={t('screens:ringGroups.disconnectAction')} 
                        data={values.onIncomingCallLimit}
                        setFieldValue={(name, value, extra) => {
                            setFieldValue('onIncomingCallLimit.' + name, value, extra);
                            if(name === 'redirectToEntity' && value) {
                                setFieldError('onIncomingCallLimit.redirectToEntity', undefined);
                            }
                        }}
                        takeActionToolTip={t('tooltips:ringGroups.uponReachingMaximumCallsOnHoldAction')}
                        errors={errors?.onIncomingCallLimit}
                        queueId={values.queueId}
                    />

                    <Separator/>
                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classNames(classes.rowBox)}
                        >
                            <span className={classes.sectionHeader}>{t(
                                'screens:ringGroups.waitingInQueue',
                            )}</span>
                        </Box>
                    </Grid>
                    
                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classNames(classes.rowBox)}
                        >
                            <Checkbox
                                dataQa="announce-number-of-callers-in-queue-status"
                                checked={values.announceNumberOfCallersInQueue}
                                onChange={(e) =>
                                    setFieldValue(
                                        'announceNumberOfCallersInQueue',
                                        e.target.checked,
                                        false,
                                    )
                                }
                                label={t(
                                    'screens:ringGroups.announceNumberOfCallersInQueue',
                                )}
                            />
                            <IconWithTooltip
                                dataQa="announceNumberOfCallers-tooltip"
                                tooltipText={t(
                                    'tooltips:ringGroups.announceNumberOfCallers',
                                )}
                            />
                        </Box>
                    </Grid>

                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.rowBox}
                        >
                            <Checkbox
                                dataQa="announce-estimated-wait-time-status"
                                checked={values.announceEstimatedWaitTime}
                                onChange={(e) =>
                                    setFieldValue(
                                        'announceEstimatedWaitTime',
                                        e.target.checked,
                                        false,
                                    )
                                }
                                label={t(
                                    'screens:ringGroups.announceEstimatedWaitTime',
                                )}
                            />
                            <IconWithTooltip
                                dataQa="ring-group-ringback-tone-tooltip"
                                tooltipText={t(
                                    'tooltips:ringGroups.announceEstimated',
                                )}
                            />
                        </Box>
                    </Grid>
                    
                    {values.announceEstimatedWaitTime && (
                        <Grid className={classes.itemsContainer} style={{marginBottom: 11}}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                className={classes.rowBox}
                            >
                                <TextField
                                    id="averageHandleTime"
                                    label={t(
                                        'screens:ringGroups.averageHandleTime',
                                    )}
                                    onChange={handleChange}
                                    value={values.averageHandleTime?.toString()}
                                    type="number"
                                    icon={
                                        <IconWithTooltip
                                            dataQa="ring-group-name-tooltip"
                                            tooltipText={t(
                                                'tooltips:ringGroups.averageHandleTime',
                                            )}
                                            className={
                                                classes.numberInputTooltip
                                            }
                                        />
                                    }
                                    inputProps={{
                                        inputProps: {
                                            min: 1,
                                            max: 300,
                                        },
                                        pattern: '[0-9]*',
                                        startAdornment:
                                            values.averageHandleTime !== null ? (
                                                <span
                                                    className={classes.minText}
                                                >
                                                    {t('common:minutes').toLowerCase()}
                                                </span>
                                            ) : undefined,
                                    }}
                                    iconPosition="end"
                                    className={classNames(classes.numberInput)}
                                    dataQa="average-handle-time-input"
                                    required
                                    helperText={errors.averageHandleTime}
                                    maxLength={3}
                                    disableCounter
                                />
                            </Box>
                        </Grid>
                    )}
                    
                    <Grid className={classes.itemsContainer} style={{marginBottom: 24, maxWidth: 522}}>
                        <SelectField
                            label={t('screens:ringGroups.playMusicOnHold')}
                            items={musicOnHoldSelectItems}
                            value={
                                (isFileSelectOpen ? musicOnHoldSelectItems.find((v) => v.value === 1) : null)
                                ?? musicOnHoldSelectItems.find((v) => v.name === values.onHoldMusicFileName)
                                ?? musicOnHoldSelectItems.find((v) => v.value === 0)
                            }
                            dataQa="on-play-music-input"
                            onChange={(_, value: DropDownDictionaryItem<number>) => {
                                if (value?.value === 1) {
                                    addDialogClosedListener(fileRef?.current, function() {
                                        setTimeout(() => {
                                            setIsFileSelectOpen(false);
                                        }, 200);
                                    });
                                    setIsFileSelectOpen(true);
                                    fileRef.current?.click();
                                } else if ((value?.value ?? 0) >= 2) {
                                    setFieldValue('onHoldMusicFileName', value.name);
                                    setFieldValue('playOnHoldMusicStatus', true);
                                } else if((value?.value ?? 0) === 0) {
                                    setFieldValue('onHoldMusicFileName', '');
                                    setFieldValue('onHoldMusicFile', '');
                                    setFieldValue('playOnHoldMusicStatus', false);
                                }
                            }}
                            getOptionLabel={(v: DropDownDictionaryItem<number>) => v.name}
                            getOptionSelected={(v: DropDownDictionaryItem<number>) =>
                                v.value === values.onHoldMusicFileName
                            }
                            disableClearable
                            icon={
                                <IconWithTooltip
                                    dataQa="on-play-music-tooltip"
                                    tooltipText={t('tooltips:ringGroups.ringbackTone')}
                                />
                            }
                            helperText={errors && errors.onHoldMusicFileName}
                        />
                    </Grid>

                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={classes.rowBox}
                        >
                            <TextField
                                id="maximumWaitingTime"
                                label={t(
                                    'screens:ringGroups.maximumWaitingTime',
                                )}
                                onChange={handleChange}
                                value={values.maximumWaitingTime?.toString()}
                                type="number"
                                icon={
                                    <IconWithTooltip
                                        dataQa="maximum-waiting-time-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.maximumWaitingTime',
                                        )}
                                        className={
                                            classes.numberInputTooltip
                                        }
                                    />
                                }
                                inputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 999,
                                    },
                                    pattern: '[0-9]*',
                                    startAdornment:
                                        values.maximumWaitingTime !== null ? (
                                            <span
                                                className={classes.minText}
                                            >
                                                {t('common:minutes').toLowerCase()}
                                            </span>
                                        ) : undefined,
                                }}
                                iconPosition="end"
                                className={classNames(classes.numberInput)}
                                dataQa="maximum-waiting-time-input"
                                helperText={errors.maximumWaitingTime}
                                maxLength={3}
                                disableCounter
                            />
                        </Box>
                    </Grid>

                    <CallQueuePromptAction header={t('screens:ringGroups.whenWaitingTimePasses')}
                        noActionTitle={t('common:none')} 
                        data={values.onMaxWaitingTime}
                        setFieldValue={(name, value, extra) => {
                            setFieldValue('onMaxWaitingTime.' + name, value, extra);
                            if(name === 'redirectToEntity' && value) {
                                setFieldError('onMaxWaitingTime.redirectToEntity', undefined);
                            }
                        }}
                        takeActionToolTip={t('tooltips:ringGroups.whenWaitingTimePassesAction')}
                        errors={errors?.onMaxWaitingTime}
                        queueId={values.queueId}
                    />

                    <Separator/>
                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classNames(classes.rowBox)}
                        >
                            <span className={classes.sectionHeader}>{t(
                                'screens:ringGroups.ringingOperators',
                            )}</span>
                        </Box>
                    </Grid>

                    <Grid className={classes.itemsContainer}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={classes.rowBox}
                        >
                            <TextField
                                id="maximumRingingTime"
                                label={t(
                                    'screens:ringGroups.maximumRingingTime',
                                )}
                                onChange={handleChange}
                                value={values.maximumRingingTime?.toString()}
                                type="number"
                                icon={
                                    <IconWithTooltip
                                        dataQa="maximum-ringing-time-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.maximumRingingTime',
                                        )}
                                        className={
                                            classes.numberInputTooltip
                                        }
                                    />
                                }
                                inputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 55555,
                                    },
                                    pattern: '[0-9]*',
                                    startAdornment:
                                        values.maximumRingingTime !== null ? (
                                            <span
                                                className={classes.minText}
                                            >
                                                {t('common:seconds').toLowerCase()}
                                            </span>
                                        ) : undefined,
                                }}
                                iconPosition="end"
                                className={classNames(classes.numberInput)}
                                dataQa="maximum-ringing-time-input"
                                helperText={errors.maximumRingingTime}
                                maxLength={5}
                                disableCounter
                            />
                        </Box>
                    </Grid>
                    
                    <CallQueuePromptAction header={t('screens:ringGroups.whenRingingTimePasses')}
                        noActionTitle={t('common:none')} 
                        data={values.onMaxRingingTime}
                        setFieldValue={(name, value, extra) => {
                            setFieldValue('onMaxRingingTime.' + name, value, extra);
                            if(name === 'redirectToEntity' && value) {
                                setFieldError('onMaxRingingTime.redirectToEntity', undefined);
                            }
                        }}
                        takeActionToolTip={t('tooltips:ringGroups.whenRingingTimePassesAction')}
                        errors={errors?.onMaxRingingTime}
                        queueId={values.queueId}
                    />
                    <input
                        type="file"
                        id="onHoldMusicFileName"
                        accept={'audio/*'}
                        ref={fileRef}
                        data-testid="moh-file-upload"
                        style={{ display: 'none' }}
                        onChange={(event) => {
                            event?.stopPropagation();
                            event?.preventDefault();
                            const file = event?.target?.files?.[0];
                            if (file) {
                                setFieldValue(
                                    'onHoldMusicFileName',
                                    file.name,
                                );
                                setFieldValue(
                                    'onHoldMusicFile',
                                    file,
                                );
                                setFieldValue('playOnHoldMusicStatus', true);
                            }
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default CallQueueForm;
