import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";
import React from "react";
import {string} from "yup";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    tooltipContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.TooltipTextColor,
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 400,
        justifyContent: 'space-between',
        width: 204,
        padding: '15px 24px 20px 24px',
        borderRadius: '4px'
    },
    tooltipHeader: {
        fontWeight: 700,
        paddingBottom: 6,
        borderBottom: '1px solid #F5F7F8',
        marginBottom: 9
    },

    tooltipContent: {
        display: 'flex',
        flexDirection: 'column',
    },

    tooltipContentRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    tooltipFooter: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: '1px solid #F5F7F8',
        opacity: .6,
        marginTop:10
    }
}))

interface CallStatisticTooltipProps {
    title: string,
    items: { value: string | number, label: string }[]
    summary: number
}

const CallStatisticTooltip: React.VFC<CallStatisticTooltipProps> = (
    {
        title, items, summary
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const rows = items.map((item, index) => (
        <div key={index} className={classes.tooltipContent}>
            <div className={classes.tooltipContentRow}>
                <span>{item.label}</span> <span>{item.value}</span>
            </div>
        </div>
    ))

    return (
        <div className={classes.tooltipContainer}>
            <div className={classes.tooltipHeader}>{title}</div>
            <div className={classes.tooltipContent}>
                {rows}
            </div>
            <div className={classes.tooltipFooter}>
                <div className={classes.tooltipContentRow}>
                    <span style={{textTransform:'capitalize'}}>{t('common:total')}</span> <span>{summary}</span>
                </div>
            </div>
        </div>
    )
}

export default CallStatisticTooltip