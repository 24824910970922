import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { MenuActionFormType } from '../Forms/AutoAttendantsMenus/ActionsForm.utils';
import { MenuAction, UserInput } from '../../store/types/AutoAttendant';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { ServiceFeatureName } from '../../store/types/ServiceFeature';

const useStyles = makeStyles(() => ({
    label: {
        color: Colors.Gray5,
    },
}));

type MenuActionAdditionalInfoProps = {
    action: MenuActionFormType;
};

const MenuActionAdditionalInfo: React.VFC<MenuActionAdditionalInfoProps> = ({
    action,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const email = useSelector<ReduxState, string>(
        (state) =>
            state.autoAttendants?.incomingTab?.emailAddressIncoming || '',
    );

    const serviceFeatures = useSelector(
        (state: ReduxState) => state.autoAttendants.autoAttendantDetails?.extension?.serviceFeatures
            || state.autoAttendants?.autoAttendantInfo?.account_info?.service_features
            || state.autoAttendants?.autoAttendantInfo?.serviceFeatures
            || state.extensions?.serviceFeatures
    );

    const unifiedMessagingVoicemailEnabled = useMemo(() => serviceFeatures
            ?.find((v) => v.name === ServiceFeatureName.UnifiedMessaging)
            ?.attributes.find((v) => v.name === 'fax_only_mode')?.effective_values[0]
            === "N",
        [serviceFeatures]);

    let value = '';

    switch (action.action) {
        case MenuAction.Transfer:
            value = t('screens:autoAttendants.actionDestination', {
                value: action.transferCallerToPhoneNumberExtension,
            });
            break;
        case MenuAction.TransferToE164Number:
            value = t('screens:autoAttendants.actionDestination', {
                value: `+${action.transferCallerToPhoneNumberExtension}`,
            });
            break;
        case MenuAction.TransferToFaxMailbox:
            value = !unifiedMessagingVoicemailEnabled ? (email || '—') : '';
            break;
        case MenuAction.DISA:
            value = '—';
            break;
        case MenuAction.DialByNameDirectory:
            value = t('screens:autoAttendants.directoryIntroPrompt');
            break;
        case MenuAction.DialExtensionDirectly:
            value = t('screens:autoAttendants.actionMaxDigits', {
                value: action.maxDigits,
            });
            break;
        case MenuAction.DisconnectCall:
            value = '—';
            break;
        case MenuAction.DoNothing:
            value = '—';
            break;
        case MenuAction.Menu:
            value = t('screens:autoAttendants.menuAction', {
                value: action.menu?.name,
            });
            break;
        case MenuAction.Queue:
            value = t('screens:autoAttendants.queueAction', {
                value: action.queue?.group_name,
            });
            break;
        case MenuAction.PromptForExtension:
            value = '—';
            break;
    }

    if (action.userInput === UserInput.NoInput) {
        value = `${t('screens:autoAttendants.actionTimeout', {
            value: action.timeout,
        })}${
            action.menu && action.action === MenuAction.Menu
                ? `. ${t('screens:autoAttendants.menuAction', {
                      value: action.menu?.name,
                  })}`
                : action.queue && action.action === MenuAction.Queue
                ? `. ${t('screens:autoAttendants.queueAction', {
                      value: action.queue.group_name,
                  })}`
                : ''
        }`;
    }

    return (
        <OverflowTooltip
            tooltip={value}
            text={value}
            classes={{ text: classes.label }}
        />
    );
};

export default MenuActionAdditionalInfo;
