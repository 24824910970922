import React from 'react';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';

type SwitchProps = {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    dataQa?: string;
    dataTestId?: string;
    name?: string;
    className?: string;
    skipPermission?: boolean;
};

const useStyles = makeStyles(() => ({
    switchBase: {
        '&.Mui-disabled': {
            color: Colors.White,
        },
        '&.Mui-checked': {
            color: Colors.Secondary1,
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: Colors.Secondary1,
            opacity: 0.5,
        },
    },
    disabled: {
        opacity: 0.5,
    },
}));

const CustomizedSwitch: React.FC<SwitchProps> = ({
    onChange,
    checked,
    disabled,
    dataQa,
    dataTestId,
    name,
    className,
    skipPermission,
}) => {
    const classes = useStyles();
    let permission = usePermissionContext();
    permission = skipPermission ? PermissionType.Visible : permission;

    disabled = disabled ? true : permission !== PermissionType.Visible;

    return (
        <Switch
            className={classNames(disabled && classes.disabled, className)}
            classes={{
                switchBase: classes.switchBase,
            }}
            onChange={onChange}
            checked={checked}
            value={checked}
            disabled={disabled}
            data-qa={dataQa}
            data-testid={dataTestId}
            name={name}
        />
    );
};

export default CustomizedSwitch;
