import {ChangeDirtyStateDetailsTab, EditExtensionForm} from "../../../../views/Extensions/ExtensionDetails/utils";
import {makeStyles} from "@material-ui/core/styles";
import {ExtensionCallSettingsFormType} from "../CallSettings/CallSettingsForm.utils";
import {TransportProtocol} from "../../../../store/types/AccountFollowMeSettings";
import {Colors} from "../../../../styles/Colors";
import * as Yup from "yup";
import i18n from "../../../../services/i18n";

export interface SipContactFormTypeProps {
    handleSubmitForm?: (formData: Partial<EditExtensionForm>) => void;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    customClass?: { container?: string };
    dynamicList?: DynamicListEntity[],
    i_customer: string|number,
}

export interface DynamicListEntity {
    status?: string,
    name: string,
    iAccount: number,
}

export interface SipContactFormType {
    sipContactStatus: boolean,
    sipContactLocked: boolean,
    staticAddress?: boolean,
    dynamicAddress?: boolean,
    sipCldNumber?: number | string,
    host?: string,
    port?: number,
    transportProtocol?: TransportProtocol|string;
    dynamicAddressEntity?: DynamicListEntity

}

const requiredFieldError = i18n.t('errors:common.emptyInput');
const InvalidHostError = i18n.t('errors:sipTrunks.invalidHostError');

function isValidIp(value: string) {
    const regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

    if (regex.test(value || '')) {

        const split = value?.split('.');
        let isValid = true;
        split?.forEach((splitVal, index) => {
            if ((index == 0 || index == 3) && Number(splitVal) < 1 || Number(splitVal) > 255) {
                isValid = false;
            } else if (Number(splitVal) < 0 || Number(splitVal) > 255) {
                isValid = false;
            }
        });

        return isValid;
    }

    return false;
}

function isValidDomain(domain: string) {
    const regex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
    if (regex.test(domain || '')) {

        const lastDot = (domain?.lastIndexOf('.') || 0) + 1;
        const testString = domain?.substring(domain?.length - (domain?.length - (lastDot || 1)));

        if (testString?.match(/^[a-zA-Z]+$/)) {
            return true
        }
    }

    return false
}

export const sipContactFormValidationSchema = Yup.object().shape({
    sipCldNumber: Yup.string().nullable().notRequired().max(64),
    host: Yup.string().max(255).when('staticAddress', {
        is: true,
        // @ts-ignore
        then: Yup.string().required(requiredFieldError).test("invalidChar", InvalidHostError, val => {

            const isValidIpAddress = isValidIp(val || '');
            const isValidDomainAddress = isValidDomain(val || '');

            if (isValidIpAddress) {
                return true
            } else return isValidDomainAddress;
        }),
        otherwise: Yup.string().notRequired(),
    }),
    port: Yup.number().nullable().notRequired()
        .min(1,i18n.t<string>('errors:sipTrunks.sipPortInvalid'))
        .max(65535,i18n.t<string>('errors:sipTrunks.sipPortInvalid')),
    transportProtocol: Yup.string().notRequired(),
    dynamicAddressEntity: Yup.object().when('dynamicAddress', {
        is: true,
        then: Yup.object().required(requiredFieldError),
        otherwise: Yup.object().notRequired()
    })
});

export const initSipContactFormData: SipContactFormType = {
    sipContactStatus: false,
    sipContactLocked: false,
    staticAddress: true,
    dynamicAddress: false,
    sipCldNumber: undefined,
    host: undefined,
    port: undefined,
    transportProtocol: undefined,
    dynamicAddressEntity: undefined
}

export type SipTrunkExtensionCallSettingsFormType = ExtensionCallSettingsFormType & SipContactFormType;


export const useStyles = makeStyles(() => ({

    register: {
        color: Colors.Support
    },
    unregister: {
        color: Colors.Primary
    },

    optionContainer: {
        display: "flex",
        flexDirection: "column"
    },
    optionText: {
        color: Colors.Black
    },

    selectFiled: {
        maxWidth: 356,
        width: 356
    },
    transportProtocolSelect: {
        maxWidth: 156,
        width: 156,
        minWidth: 'unset',
    },
    controlMargin: {
        marginRight: 24,
    },
    phoneNumberInput: {
        width: 264,
    },
    hostInput: {
        width: 264,
    },
    portInput: {
        width: 156,
    },
    itemsContainer: {
        padding: '10px 38px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: '3px 0',
    },
    radioContainer: {
        marginRight: 32,
    },
    inputs: {
        display: 'flex',
        padding: '0px 16px 0px 16px',
        flexDirection: 'column',
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
        justifyContent: "center"
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        alignItems: 'center',
    },
    switchContainer: {
        marginBottom: 0,
        flexGrow: "unset",
        justifyContent: "center",
        marginTop: '-10px'
    },
}));