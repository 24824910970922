import { createAction, createAsyncAction } from 'typesafe-actions';
import { APIErrorInterface } from '../../types';
import * as payloads from './payloads';

export const changePassword = createAsyncAction(
    'CHANGE_PASSWORD',
    'CHANGE_PASSWORD_SUCCESS',
    'CHANGE_PASSWORD_FAILED',
)<
    payloads.ChangePasswordRequestPayload,
    undefined,
    { [key in string]: string }
>();

export const getCompanyInfoData = createAsyncAction(
    'GET_COMPANY_INFO_DATA',
    'GET_COMPANY_INFO_DATA_SUCCESS',
    'GET_COMPANY_INFO_DATA_FAILED',
)<undefined, payloads.GetCompanyDetailsResponsePayload, undefined>();

export const getCompanyData = createAsyncAction(
    'GET_COMPANY_DATA',
    'GET_COMPANY_DATA_SUCCESS',
    'GET_COMPANY_DATA_FAILED',
)<undefined, undefined, undefined>();

export const updateCompanyInfoForm = createAsyncAction(
    'UPDATE_COMPANY_INFO_FORM',
    'UPDATE_COMPANY_INFO_FORMSUCCESS',
    'UPDATE_COMPANY_INFO_FORM_FAILED',
)<payloads.SetCompanyInfo, undefined, undefined>();

export const getPortalDetails = createAsyncAction(
    'GET_PORTAL_DETAILS',
    'GET_PORTAL_DETAILS_SUCCESS',
    'GET_PORTAL_DETAILS_FAILED',
)<payloads.GetPortalUsersListRequestPayload, undefined, undefined>();

export const getAddPortalUserData = createAsyncAction(
    'GET_ADD_PORTAL_USER_DATA',
    'GET_ADD_PORTAL_USER_DATA_SUCCESS',
    'GET_ADD_PORTAL_USER_DATA_FAILED',
)<undefined, undefined, undefined>();

export const getPortalUsersList = createAsyncAction(
    'GET_PORTAL_USER_LIST',
    'GET_PORTAL_USER_LIST_SUCCESS',
    'GET_PORTAL_USER_LIST_FAILED',
)<
    payloads.GetPortalUsersListRequestPayload,
    payloads.PortalUsersResponsePayload,
    undefined
>();

export const getRoles = createAsyncAction(
    'GET_ROLES',
    'GET_ROLES_SUCCESS',
    'GET_ROLES_FAILED',
)<undefined, payloads.RolesPortal, undefined>();

export const getCustomerInfoPortalUser = createAsyncAction(
    'GET_CUSTOMER_INFO_PORTAL_USER',
    'GET_CUSTOMER_INFO_PORTAL_USER_SUCCESS',
    'GET_CUSTOMER_INFO_PORTAL_USER_FAILED',
)<undefined, payloads.GetRolesRequestPayload, undefined>();

export const saveNewPortalUser = createAsyncAction(
    'SAVE_NEW_PORTAL_USER',
    'SAVE_NEW_PORTAL_USER_SUCCESS',
    'SAVE_NEW_PORTAL_USER_FAILED',
)<payloads.SaveUserRequestPayload, undefined, APIErrorInterface>();

export const getPortalUserInfo = createAsyncAction(
    'GET_PORTAL_USER_INFO',
    'GET_PORTAL_USER_INFO_SUCCESS',
    'GET_PORTAL_USER_INFO_FAILED',
)<payloads.GetPortalUserDetailsRequest, undefined, undefined>();

export const getPortalUserDetails = createAsyncAction(
    'GET_PORTAL_USER_DETAILS',
    'GET_PORTAL_USER_DETAILS_SUCCESS',
    'GET_PORTAL_USER_DETAILS_FAILED',
)<
    payloads.GetPortalUserDetailsRequest,
    payloads.GetPortalUserDetailsResponse,
    APIErrorInterface | undefined
>();

export const editPortalUser = createAsyncAction(
    'EDIT_PORTAL_USER',
    'EDIT_PORTAL_USER_SUCCESS',
    'EDIT_PORTAL_USER_FAILED',
)<payloads.EditPortalUserRequestPayload, undefined, APIErrorInterface>();

export const deletePortalUser = createAsyncAction(
    'DELETE_PORTAL_USER',
    'DELETE_PORTAL_USER_SUCCESS',
    'DELETE_PORTAL_USER_FAILED',
)<payloads.DeletePortalUserDetailsRequest, undefined, undefined>();

export const updateCompanyInfoEventRaised = createAction('UPDATE_COMPANY_INFO_EVENT')();