import {createAsyncAction} from 'typesafe-actions';
import {ExtensionsListItem} from '../../reducers/extensions/extensions/reducer';
import {AutoAttendantMenu} from '../../types/AutoAttendant';
import {APIErrorInterface} from '../../types';
import {CustomerDIDNumberType} from '../../types/CustomerDIDNumber';
import {CustomerInfoDetails} from '../../types/CustomerInfo';
import * as payloads from './payloads';
import {UpdateExtensionStatusPayload} from '../payloads';
import {DeleteCustomerExtension} from '../../types/Extension';

export const getAutoAttendantDetails = createAsyncAction(
    'GET_AUTO_ATTENDANT_DETAILS',
    'GET_AUTO_ATTENDANT_DETAILS_SUCCESS',
    'GET_AUTO_ATTENDANT_DETAILS_FAILED',
)<payloads.GetAutoAttendantDetailsRequestPayload, undefined, undefined>();

export const getAutoAttendantBasicDetailsData = createAsyncAction(
    'GET_AUTO_ATTENDANT_BASIC_DETAILS_DATA',
    'GET_AUTO_ATTENDANT_DETAILS_DATA_SUCCESS',
    'GET_AUTO_ATTENDANT_DETAILS_DATA_FAILED',
)<
    payloads.GetAutoAttendantDetailsRequestPayload,
    ExtensionsListItem,
    undefined
>();

export const getCallFlowTabData = createAsyncAction(
    'GET_CALL_FLOW_TAB_DATA',
    'GET_CALL_FLOW_TAB_DATA_SUCCESS',
    'GET_CALL_FLOW_TAB_DATA_FAILED',
)<{ id: string; um_domain: string }, undefined, undefined>();

export const getAutoAttendantExtensionTabData = createAsyncAction(
    'GET_AA_EXTENSION_TAB_DETAILS_DATA',
    'GET_AA_EXTENSION_TAB_DETAILS_DATA_SUCCESS',
    'GET_AA_EXTENSION_TAB_DETAILS_DATA_FAILED',
)<undefined, undefined, undefined>();

export const getAutoAttendantCallBaringDetailsData = createAsyncAction(
    'GET_AA_CALL_BARING_TAB_DETAILS_DATA',
    'GET_AA_CALL_BARING_TAB_DETAILS_DATA_SUCCESS',
    'GET_AA_CALL_BARING_TAB_DETAILS_DATA_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const getAutoAttendantIncomingTabData = createAsyncAction(
    'GET_AA_INCOMING_TAB_DETAILS_DATA',
    'GET_AA_INCOMING_TAB_DETAILS_DATA_SUCCESS',
    'GET_AA_INCOMING_TAB_DETAILS_DATA_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const getAutoAttendantCallSettingsTabData = createAsyncAction(
    'GET_AA_CALL_SETTINGS_TAB_DETAILS_DATA',
    'GET_AA_CALL_SETTINGS_TAB_DETAILS_DATA_SUCCESS',
    'GET_AA_CALL_SETTINGS_TAB_DETAILS_DATA_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const getAutoAttendantCallRecordingTabData = createAsyncAction(
    'GET_AA_CALL_RECORDING_TAB_DETAILS_DATA',
    'GET_AA_CALL_RECORDING_TAB_DETAILS_DATA_SUCCESS',
    'GET_AA_CALL_RECORDING_TAB_DETAILS_DATA_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const getAutoAttendantPlanTabData = createAsyncAction(
    'GET_AA_PLAN_TAB_DETAILS_DATA',
    'GET_AA_PLAN_TAB_DETAILS_DATA_SUCCESS',
    'GET_AA_PLAN_TAB_DETAILS_DATA_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const getAutoAttendantInfo = createAsyncAction(
    'GET_AUTO_ATTENDANT_INFO',
    'GET_AUTO_ATTENDANT_INFO_SUCCESS',
    'GET_AUTO_ATTENDANT_INFO_FAILED',
)<
    payloads.GetAutoAttendantDetailsRequestPayload,
    ExtensionsListItem & {
    delete_enable?: boolean;
    customerInfo?: CustomerInfoDetails;
    product_visible_name?: string;
},
    APIErrorInterface | undefined
>();

export const getAutoAttendantMenus = createAsyncAction(
    'GET_AUTO_ATTENDANT_MENUS',
    'GET_AUTO_ATTENDANT_MENUS_SUCCESS',
    'GET_AUTO_ATTENDANT_MENUS_FAILED',
)<
    payloads.GetAutoAttendantMenusRequestPayload,
    AutoAttendantMenu[],
    undefined
>();

export const getCustomerDidNumbers = createAsyncAction(
    'GET_CUSTOMER_DID_NUMBERS',
    'GET_CUSTOMER_DID_NUMBERS_SUCCESS',
    'GET_CUSTOMER_DID_NUMBERS_FAILED',
)<undefined, CustomerDIDNumberType[], undefined>();

export const getCustomerInfo = createAsyncAction(
    'GET_CUSTOMER_INFO',
    'GET_CUSTOMER_INFO_SUCCESS',
    'GET_CUSTOMER_INFO_FAILED',
)<
    payloads.GetCustomerInfoForAuttoAttendantRequestPayload,
    CustomerInfoDetails,
    undefined
>();

export const editAutoAttendant = createAsyncAction(
    'EDIT_AUTO_ATTENDANT',
    'EDIT_AUTO_ATTENDANT_SUCCESS',
    'EDIT_AUTO_ATTENDANT_FAILED',
)<payloads.EditAutoAttendantRequestPayload, undefined, undefined>();

export const getAutoAttendantsList = createAsyncAction(
    'GET_AUTO_ATTENDANTS_LIST',
    'GET_AUTO_ATTENDANTS_LIST_SUCCESS',
    'GET_AUTO_ATTENDANTS_LIST_FAILED',
)<{
    limit: number;
    offset: number
}, payloads.FetchAutoAttendantPayload, undefined>();

export const getAutoAttendantMenuList = createAsyncAction(
    'GET_AUTO_ATTENDANTS_MENU_LIST',
    'GET_AUTO_ATTENDANTS_MENU_LIST_SUCCESS',
    'GET_AUTO_ATTENDANTS_MENU_LIST_FAILED',
)<payloads.FetchAutoAttendantMenuListPayload, payloads.FetchAutoAttendantMenuListPayload,
    payloads.FetchAutoAttendantMenuListPayload>();

export const createAutoAttendantDetailsData = createAsyncAction(
    'CREATE_AUTO_ATTENDANT_DETAILS_DATA',
    'CREATE_AUTO_ATTENDANT_DETAILS_DATA_SUCCESS',
    'CREATE_AUTO_ATTENDANT_DETAILS_DATA_FAILED',
)<undefined, undefined, undefined>();

export const createNewAutoAttendant = createAsyncAction(
    'CREATE_NEW_AUTO_ATTENDANT',
    'CREATE_NEW_AUTO_ATTENDANT_SUCCESS',
    'CREATE_NEW_AUTO_ATTENDANT_FAILED',
)<
    payloads.CreateNewAutoAttendantRequestPayload,
    undefined,
    APIErrorInterface
>();

export const getDISAState = createAsyncAction(
    'GET_DISA_STATE_INFO',
    'GET_DISA_STATE_INFO_SUCCESS',
    'GET_DISA_STATE_INFO_FAILED',
)<undefined, payloads.GetDISAStatePayload, APIErrorInterface>();

export const deleteCustomerAutoAttendant = createAsyncAction(
    'DELETE_CUSTOMER_AUTO_ATTENDANT',
    'DELETE_CUSTOMER_AUTO_ATTENDANT_SUCCESS',
    'DELETE_CUSTOMER_AUTO_ATTENDANT_FAILED',
)<DeleteCustomerExtension, { id: string }, undefined>();

export const setAutoAttendantStatus = createAsyncAction(
    'SET_AUTO_ATTENDANT_STATUS',
    'SET_AUTO_ATTENDANT_STATUS_SUCCESS',
    'SET_AUTO_ATTENDANT_STATUS_FAILED',
)<UpdateExtensionStatusPayload, UpdateExtensionStatusPayload, undefined>();
