import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import CallerInfo from './CallerInfo';
import { ReactComponent as OutgoingCall } from '../../assets/outgoing-call.svg';
import { ReactComponent as IncomingCall } from '../../assets/incoming-call.svg';
import { ReactComponent as ForwardedCall } from '../../assets/forwarded-call.svg';
import { ReactComponent as ForwardLine } from '../../assets/line.svg';

interface CallRecordDotsProps {
    leftText?: string;
    rightText?: string;
    type?: number;
    forwardLine?:boolean
}

const useStyles = makeStyles(() => ({
    headercontainer: {
        margin: '0 30px 26px 30px',
    },
    spaceContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 45,
    },
    dotsContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '14px 0 11px 0',
    },
    dotInfoText: {
        fontSize: 12,
        color: Colors.Gray5,
        width: 200,
        textAlign: 'center',
        '&:last-of-type': {
            textAlign: 'right',
        },
        '&:first-of-type': {
            textAlign: 'left',
        },
    },
    insideDot: {
        background: Colors.Secondary7,
        height: 8,
        width: 8,
        borderRadius: '50%',
    },
    outsideDot: {
        backgroundColor: Colors.Secondary8,
        padding: 3,
        borderRadius: '50%',
    },
    insideDotPrimary: {
        background: Colors.Primary,
    },
    outsideDotPrimary: {
        backgroundColor: Colors.Primary1,
    },
    line: {
        backgroundColor: Colors.Border,
        height: 2,
        flex: 1,
        margin: '0 2px 0 2px',
    },
    infoText: {
        width: 400,

        '&:last-of-type': {
            textAlign: 'right',
        },
    },
    heightSmall: {
        height: 12,
    },
}));

const CallRecordDots: React.VFC<CallRecordDotsProps> = ({
    leftText,
    rightText,
    type = 0,
    forwardLine
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    let iconColor = '';
    let icon: JSX.Element | undefined = undefined;

    switch (type) {
        case 0:
            iconColor = Colors.Support;
            icon = <OutgoingCall />;
            break;
        case 4:
            iconColor = Colors.Support;
            icon = <OutgoingCall />;
            break;
        case 8:
            iconColor = Colors.Primary;
            icon = <IncomingCall />;
            break;
        case 12:
            iconColor = Colors.Secondary1;
            icon = <ForwardedCall />;
            break;
    }

    return (
        <Grid className={classes.headercontainer}>
            <Grid className={classes.spaceContainer}>
                <CallerInfo
                    className={classes.infoText}
                    caller={leftText || ''}
                />
                {icon}
                <CallerInfo
                    className={classes.infoText}
                    caller={rightText || ''}
                />
            </Grid>

            <Grid className={classes.dotsContainer}>
                <div className={classes.outsideDot}>
                    <div className={classes.insideDot} />
                </div>
                <div className={classes.line} />

                {forwardLine ? <ForwardLine data-testid={'forward-line-svg'}/> : (
                    <div
                        className={classes.insideDot}
                        style={{ backgroundColor: iconColor }}
                    />
                ) }

                <div className={classes.line} />
                <div
                    className={classNames(
                        classes.outsideDot,
                        classes.outsideDotPrimary,
                    )}
                >
                    <div
                        className={classNames(
                            classes.insideDot,
                            classes.insideDotPrimary,
                        )}
                    />
                </div>
            </Grid>

            <Grid
                className={classNames(
                    classes.spaceContainer,
                    classes.heightSmall,
                )}
            >
                <span className={classes.dotInfoText}>
                    {t('screens:calls.caller')}
                </span>
                <span className={classes.dotInfoText}>
                    {t(`enums:callRecordType.${type}`)}
                </span>
                <span className={classes.dotInfoText}>
                    {t('screens:calls.destination')}
                </span>
            </Grid>
        </Grid>
    );
};

export default CallRecordDots;
