import dayjs from '../../services/customDayJs'
import i18n from '../../services/i18n';
import {prepareDefaultRangeDate} from '../../components/TimeRangePicker/TimeRangePicker.utils';
import {isNaN} from 'lodash';
import {IntervalFormProps} from "../../components/IntervalSelect/IntervalSelect.utils";

export type WeekDayDescriptor = {
    name: string;
    value: number;
    apiValue: string;
};

export const getPeriods = (
    items: { name: string; value: number }[],
    outputStrings: string[],
) => {
    const numberToRange = items.map((v) => v.value);
    const ranges = reduceNumberList(numberToRange);

    let result = '';

    ranges.forEach((range, index) => {
        if (index > 0) {
            result += ', ';
        }

        if (range.length === 1) {
            result += outputStrings[range[0]];
        } else {
            result +=
                outputStrings[range[0]] +
                '-' +
                outputStrings[range[range.length - 1]];
        }
    });

    return result;
};

export const getYearsPeriods = (items: { name: string; value: number }[]) => {
    const numberToRange = items.map((v) => v.value);
    const ranges = reduceNumberList(numberToRange);

    let result = '';

    ranges.forEach((range, index) => {
        if (index > 0) {
            result += ', ';
        }

        if (range.length === 1) {
            result += items.find(item => item.value == range[0])?.name
        } else {
            result +=
                items.find(item => item.value == range[0])?.name +
                '-' +
                items.find(item => item.value == range[range.length - 1])?.name;
        }
    });

    return result;
};

export function reduceNumberList(numberToRange: number[]) {
    return numberToRange.reduce((r: number[][], n) => {
        const lastSubArray = r[r.length - 1];

        if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
            r.push([]);
        }

        r[r.length - 1].push(n);

        return r;
    }, []);
}

const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRingScheduleSummary = (
    form: IntervalFormProps,
    ampm?: boolean
) => {
    let result = '';

    if (!form.intervals?.length) {
        return i18n.t<string>('screens:ringSchedule.always');
    }

    for (const interval of form.intervals) {
        let nestedResult = '';

        const preparedDate = prepareDefaultRangeDate(
            interval.startTime,
            interval.endTime,
            ampm,
        ).split(' - ');

        nestedResult += i18n.t<string>('tooltips:extensions.fromTill', {
            startTime: preparedDate[0],
            endTime: preparedDate[1],
        });

        if (interval.days?.length) {
            const dayValue = i18n.t<string>('tooltips:extensions.onDay', {
                value: getPeriods(interval.days, dayjs.weekdaysShort()),
            });
            if(nestedResult.trim().length > 0) {
                nestedResult += dayValue;
            } else if(dayValue.length > 0) {
                nestedResult += capitalizeFirstLetter(dayValue);
            }
        }

        if (interval.daysOfMonth) {
            nestedResult += `${interval.daysOfMonth} `;
        }

        if (!interval.months?.length || interval.months.length === 12) {
            nestedResult += i18n.t<string>('tooltips:extensions.ofEveryMonth');
        } else {
            nestedResult += i18n.t<string>('tooltips:extensions.ofMonth', {
                value: getPeriods(interval.months, dayjs.months()),
            });
        }

        if (interval.years && interval.years.length > 0) {
            nestedResult += getYearsPeriods(interval.years) + ', '
        }

        if (nestedResult) {
            result += 
                (
                    nestedResult.endsWith(', ')
                    ? nestedResult.slice(0, nestedResult.length - 2) 
                    : nestedResult
                )
            + ' or ';
        }
    }

    return result ? result.slice(0, result.length - 4)?.trimEnd() : '';
};

export const monthApi = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
];

export const daysApi = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];

export const daysSelectItems = dayjs.weekdaysShort().map((v, index) => ({
    name: v,
    value: index,
    apiValue: daysApi[index],
}));

export const daysSelectLongItems = dayjs.weekdays().map((v, index) => ({
    name: v,
    value: index,
    apiValue: daysApi[index],
} as WeekDayDescriptor));

export const monthsSelectItems = dayjs.monthsShort().map((v, index) => ({
    name: v,
    value: index,
    apiValue: monthApi[index],
} as WeekDayDescriptor));

export const monthsFullSelectItems = dayjs.months().map((v, index) => ({
    name: v,
    value: index,
    apiValue: monthApi[index],
} as WeekDayDescriptor));

export const years = () => {
    const result = [];
    for (let i = new Date().getFullYear(); i <= 2100; i++) {
        result.push(i.toString());
    }

    return result;
};

export const yearsSelectItems = years().map((v, index) => ({
    name: v,
    value: index,
    apiValue: v,
} as WeekDayDescriptor));

export const convertArrayOfMonthsStringToMonthSelectItems = (
    value?: string[],
) => {
    if (!value) {
        return [];
    }

    return monthsSelectItems.filter((v) => value.includes(v.apiValue));
};

export const convertArrayOfDaysStringToDaysSelectItems = (value?: string[]) => {
    if (!value) {
        return [];
    }

    return daysSelectItems.filter((v) => value.includes(v.apiValue));
};

export const convertArrayOfYearsStringToYearsSelectItems = (
    value?: string[],
) => {
    if (!value) {
        return [];
    }
    return yearsSelectItems.filter((v) =>
        value.map((v) => v.toString()).includes(v.apiValue),
    );
};

export const convertArrayOfNumbersToRangesString = (
    values?: number[],
    names?: string[],
) => {

    if (!values || (values.length === 1 && isNaN(values[0]))) {
        return '';
    }

    const ranges = reduceNumberList(values);

    let result = '';

    ranges.forEach((range, index) => {
        if (index > 0) {
            result += ', ';
        }

        const range1 = names ? names[range[0]] : range[0];
        const range2 = names
            ? names[range[range.length - 1]]
            : range[range.length - 1];

        if (range.length === 1) {
            result += range1;
        } else {
            result += range1 + '-' + range2;
        }
    });

    return result;
};

export const convertRangesStringToNumbersArray = (value: string) => {
    const arr: number[] = [];

    value
        .replace(/\s/g, '')
        .split(',')
        .forEach((v) => {
            const parts = v.split('-');

            if (parts.length > 1) {
                arr.push(
                    ...Array(parseInt(parts[1]) - parseInt(parts[0]) + 1)
                        .fill(0)
                        .map((_, idx) => parseInt(parts[0]) + idx),
                );
            } else if (parts.length === 1 && parts[0].length > 0) {
                arr.push(parseInt(parts[0]));
            }
        });

    return arr;
};

export const validateDayOfMonth = (value?: string) => {
    if (!value) {
        return true;
    }

    value = value.replace(/\s/g, '');

    if (!/^(\d+(-\d+)?)(,(\d+(-\d+)?))*$/.test(value)) {
        return false;
    }

    const ranges = value.split(',');

    for (const range of ranges) {
        const values = range.split('-');

        if (
            values.filter((v) => parseInt(v) > 0 && parseInt(v) <= 31)
                .length !== values.length
        ) {
            return false;
        }
    }

    return true;
};

export const validateDayOfMonthAllowEmpty = (value?: string) => {
    if (!value) {
        return true;
    }

    value = value.replace(/\s/g, '');

    if (!/(\d+(-\d+)?)(,(\d+(-\d+)?))?/.test(value)) {
        return false;
    }

    const ranges = value.split(',');

    for (const range of ranges) {
        const values = range.split('-');

        if (
            values.filter((v) => parseInt(v) > 0 && parseInt(v) <= 31)
                .length !== values.length
        ) {
            return false;
        }
    }

    return true;
};
