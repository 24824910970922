import React from 'react';
import PermissionProvider from '../components/PermissionProvider/PermissionProvider';
import {PermissionType, PortalPermission} from '../store/types/Permission';
import {getPermission} from './permissions/getPermission';

export const generateTabs = (
    items: {
        tab: React.ReactNode;
        title: string;
        permission: string[];
        skipReadOnly?: boolean;
        customizePermission?: (value: PermissionType) => PermissionType;
    }[],
    permissions?: PortalPermission,
    hiddeRestricted = true
) => {

    const perm: PermissionType[] = [];

    if (hiddeRestricted) {

        items = items.filter(
            (v) => {
                let permission = getPermission(permissions, ...v.permission);
                if (v.customizePermission) permission = v.customizePermission(permission);
                perm.push(permission);
                return permission !== PermissionType.Hidden;
            }
        );
    }

    return {
        tabNames: items.map((v) => v.title),
        tabs: items.map((v, index) => (
            <PermissionProvider
                key={`tab-${index}`}
                permission={v.permission}
                skipReadOnly={v.skipReadOnly || false}
                customizePermission={v.customizePermission}>
                {v.tab}
            </PermissionProvider>
        )),
        perm:perm
    };
};
