import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import { RingGroupType } from '../../../store/types/RingGroup';
import RingGroupItem from './RingGroupItem';
import AddToGroupButton from './AddToGroupButton';
import AddToRingGroupDialog from './AddToRingGroupDialog';
import { AddtoRingGroupFormProps } from './AddToRingGroupDialog.utils';
import { prepareAssignedExtensionToChange } from '../../../utils/extensions/AddExtensionToRingGroup';
import {Permission, PermissionType} from "../../../store/types/Permission";
import {CustomerOfficeType} from "../../../store/types/CustomerInfo";
import PermissionProvider from "../../PermissionProvider/PermissionProvider";
import {useSelector} from "react-redux";
import {ReduxState} from "../../../store/types";

type RingGroupsGridProps = {
    ringGroups: RingGroupType[];
    allRingGroups?: RingGroupType[];
    accountId?: string;
    extensionId?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (w: string, v: any) => void;
    extensionName?: string;
};

const useStyles = makeStyles(() => ({
    root: {
        margin: -8,

        display: 'flex',
        flexWrap: 'wrap',
    },
    nameHeader: {
        fontWeight: 'bold',
        fontSize: 14,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 140,
    },
    strategyText: {
        fontSize: 11,
        color: Colors.Gray5,
    },
    strategyName: {
        fontWeight: 'bold',
    },
    icon: {
        height: 12,
        width: 12,
        marginLeft: 10,
        cursor: 'pointer',
    },
}));

const RingGroupsGrid: React.FC<RingGroupsGridProps> = ({
    ringGroups,
    allRingGroups = [],
    accountId,
    extensionId,
    setFieldValue,
    extensionName,
}) => {
    const classes = useStyles();

    const [dialogVisible, setDialogVisible] = useState(false);
    const [ringGroup, setRingGroup] = useState<RingGroupType | undefined>();
    const i_office_type: CustomerOfficeType = useSelector<ReduxState, CustomerOfficeType>(
        (state) => state.generic?.customerOfficeType || CustomerOfficeType.Hq
    );

    const allDialogRingGroups = useMemo(
        () =>
            allRingGroups.filter(
                (v) =>
                    !ringGroups.map((w) => w.id).includes(v.id) ||
                    v.id === ringGroup?.id,
            ),
        [ringGroups, allRingGroups, ringGroup],
    );

    const onAddNewRingGroup = useCallback(
        (form: AddtoRingGroupFormProps) => {
            const group = allRingGroups.find((v) => v.id === form.groupId);

            if (group) {
                setFieldValue('extensionRingGroups', [
                    ...ringGroups,
                    {
                        ...group,
                        assigned_extensions: [
                            {
                                i_c_ext: extensionId,
                                account_id: accountId,
                                ...prepareAssignedExtensionToChange(form),
                            },
                        ],
                    },
                ]);
            }
            setDialogVisible(false);
        },
        [ringGroups, allRingGroups],
    );

    const onEditRingGroup = useCallback(
        (form: AddtoRingGroupFormProps) => {
            const groupIndex = ringGroups.findIndex(
                (v) => v.id === form.groupId,
            );

            if (groupIndex !== -1) {
                const groups: RingGroupType[] = JSON.parse(
                    JSON.stringify(ringGroups),
                );

                const assignedExtension = groups[
                    groupIndex
                ]?.assigned_extensions?.find((v) => v.account_id === accountId);

                if (assignedExtension) {
                    groups[groupIndex].assigned_extensions = [
                        {
                            ...assignedExtension,
                            account_id: accountId,
                            ...prepareAssignedExtensionToChange(form),
                        },
                    ];

                    setFieldValue('extensionRingGroups', groups);
                }
            }

            setDialogVisible(false);

            setTimeout(() => {
                setRingGroup(undefined);
            }, 100);
        },
        [ringGroups, allRingGroups],
    );

    const onDeleteRingGroup = useCallback(
        (id: string) => {
            setFieldValue(
                'extensionRingGroups',
                ringGroups.filter((v) => v.id !== id),
            );
        },
        [ringGroups],
    );

    return (
        <>
            <Grid item className={classes.root}>
                {ringGroups?.map((v, index) => (
                    <RingGroupItem
                        key={v.i_c_group}
                        name={v.name}
                        strategy={v.hunt_sequence}
                        callQueue={!!v.assigned_callqueue}
                        onEdit={() => {
                            setRingGroup(v);
                            setDialogVisible(true);
                        }}
                        dataQa={`extension-ring-group-item-${index}`}
                    />
                ))}
                <PermissionProvider
                    permission={
                        Permission.CloudPBX.Extensions.ExtensionDetails.Extension.value
                    }
                    customizePermission={(value) => {
                        if(value === PermissionType.Visible && i_office_type === CustomerOfficeType.Branch) {
                            return PermissionType.ReadOnly;
                        }
                        return value;
                    }}
                >
                    <AddToGroupButton onClick={() => setDialogVisible(true)} />
                </PermissionProvider>

            </Grid>

            <AddToRingGroupDialog
                isOpen={dialogVisible}
                ringGroups={allDialogRingGroups}
                extensionRingGroups={ringGroups}
                ringGroupToEdit={ringGroup}
                onAddNewRingGroup={
                    ringGroup ? onEditRingGroup : onAddNewRingGroup
                }
                toggleVisibility={() => {
                    setDialogVisible(false);
                    setTimeout(() => {
                        setRingGroup(undefined);
                    }, 100);
                }}
                onExclude={onDeleteRingGroup}
                accountId={accountId}
                extensionName={extensionName}
            />
        </>
    );
};

export default RingGroupsGrid;
