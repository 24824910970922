import {makeStyles} from '@material-ui/core/styles';
import * as Yup from 'yup';
import {Colors} from '../../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '0 38px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 25,
        paddingLeft:30
    },

    fitContent: {
        width: 'fit-content',
    },
    rowContainer: {
        flex: 1,
        display: 'flex',
        paddingBottom: 24,
        alignItems: 'flex-start',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        margin: '3px 0',
    },
    separator: {
        height: 1,
        width: 440,
        backgroundColor: Colors.Border,
        margin: '10px 0',
    },
    inputs: {
        display: 'flex',
        paddingLeft: 16,
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },
    switchContainer: {
        marginBottom: 0,
        marginLeft:0
    },
    disabledEmail: {
        width: 300,
    },
}));

export type CallRecordingFormType = {
    callRecordingStatus: boolean;
    callRecordingBlocked: boolean;
    outgoingStatus: boolean;
    incomingStatus: boolean;
    redirectedStatus: boolean;
    playCallRecordingAnnouncementToAllStatus: boolean;
    sendTheCallRecordingViaEmailStatus: boolean;
    allowToStartStopRecordingManually: boolean;
};

export const callRecordingFormValidationSchema = Yup.object().shape({});

export const callRecordingFormDefaultValues: CallRecordingFormType = {
    callRecordingStatus: false,
    callRecordingBlocked: false,
    outgoingStatus: false,
    incomingStatus: false,
    redirectedStatus: false,
    playCallRecordingAnnouncementToAllStatus: false,
    sendTheCallRecordingViaEmailStatus: false,
    allowToStartStopRecordingManually: false,
};
