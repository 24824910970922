/* eslint-disable react/display-name */
import React, {useCallback, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import {Colors} from '../../styles/Colors';
import {usePermissionContext} from '../../hooks/usePermissions';
import {PermissionType} from '../../store/types/Permission';
import Tooltip from "../Tooltip/Tooltip";

export type CustomTextFieldProps = {
    id?: string;
    value?: string | number;
    label?: string;
    readOnlyWithPosibilityToEdit?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    icon?: React.ReactNode;
    iconPosition?: 'end' | 'start';
    helperText?: string;
    multiline?: boolean;
    type?: string;
    dataQa?: string;
    className?: string;
    required?: boolean;
    warningIcon?: boolean;
    shrink?: boolean;
    placeholder?: string;
    keyboardIcon?: React.ReactNode;
    inputProps?: any;
    inputRef?: any;
    withSelectionPrevention?: boolean;
    maxLength?: number;
    disableCounter?: boolean;
    autoComplete?: string;
    fixedLengthCounter?: boolean;
    inputFieldProps?: Object;
    skipPermission?: boolean;
    convertToLink?: boolean;
    autoFocus?: boolean;
    onClick?: () => void;
    onFocus?: (ev: React.FocusEvent<any>) => void;
    onBlur?: () => void;
    setFieldError?: (field: string, value: string | undefined) => void;
    onMouseEnter?: (ev: React.MouseEvent<any, any>) => void;
    onMouseLeave?: () => void;
    handleSubmit?: (e: React.ChangeEvent<any>) => void;
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    widthFromLiableText?: boolean;
    customCounterLength?: (value: string | undefined) => number;
    customCounterMaxLength?: (value: string | undefined) => number;
    handleKeyPress?: (e: React.ChangeEvent<any>) => void;
};

function customWidth(
    labelSize?: number,
): { maxWidth: number; minWidth: number } {
    const minWidth = 130;
    const actualLabelLength = (labelSize || minWidth) * 10;
    const newWidth =
        actualLabelLength > minWidth ? actualLabelLength : minWidth;
    return {
        maxWidth: newWidth,
        minWidth: newWidth,
    };
}

const useStyles = makeStyles(() => {
    return {
        textField: {

            '& .MuiInputBase-root': {
                height: 56,
                backgroundColor: Colors.White,
                borderRadius: '4px 4px 0px 0px',

                '&:before': {
                    borderBottom: `1px solid ${Colors.Border}`,
                },
            },

            '& label + .MuiInput-formControl': {
                marginTop: 0,
            },
            '& .MuiInputBase-input': {
                padding: '16px 16px 0 16px',
                height: 42,
                color: Colors.Text,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '100%',
                whiteSpace: 'nowrap',
            },
            '& .Mui-focused': {
                color: Colors.Primary,
                marginLeft: 0,

                '& .character-counter': {
                    visibility: 'visible',
                },
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: Colors.Primary,
                },
            },
            '& .Mui-disabled:hover:before': {
                borderBottomColor: Colors.Border,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: Colors.Primary,
            },
            '& .MuiInputBase-input.Mui-disabled': {
                opacity: 1,
                color: Colors.LightGraySecondary2
            },
            '& .MuiInputLabel-root': {
                marginLeft: '16px !important',
                top: '-3px !important',
                zIndex: 4,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
            '& .MuiInputLabel-asterisk': {
                color: Colors.Error,
            },
            '& .MuiInputLabel-shrink': {
                transform: 'translate(0, 13px) scale(0.75)',
            },

            '& .MuiFormHelperText-root': {
                marginTop: 'unset',
            },

            '& .MuiInputAdornment-root': {
                '& .MuiSvgIcon-root': {
                    zIndex: 1,
                },
            },
        },
        counter: {
            position: 'absolute',
            top: 59,
            right: 10,
            visibility: 'hidden',
            color: Colors.Gray9,
        },
        fixedCounter: {
            top: 55,
        },
        counterReached: {
            color: `${Colors.Error} !important`,
            visibility: 'visible',
        },
        icon: {
            '& .MuiInputLabel-root': {
                marginLeft: 32,
            },
            '& .MuiFormLabel-filled': {
                marginLeft: 0,
            },
        },
        error: {
            '& .MuiInputLabel-root': {
                color: Colors.PlaceHolder,
            },
            '& .MuiFormLabel-filled, .MuiFormHelperText-root': {
                color: Colors.Error,
            },
            '& .MuiFormHelperText-root': {
                width: '100%',
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: Colors.Error,
                },
            },
            '& fieldset': {
                borderColor: `${Colors.Error}!important`,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: Colors.Error,
            },
        },
        helperText: {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            width: 'unset',
            margin: '0 15px',
            marginTop: 3,
            marginBottom: -25,
            lineHeight: '12px'
        },
        helperTextWithCounter: {
            marginRight: 45,
        },
        readOnlyContainer: {
            backgroundColor: 'transparent !important',

            '& .MuiInputBase-root': {
                backgroundColor: 'transparent !important',
            },

            '& .MuiInputBase-input.Mui-disabled': {
                opacity: 1,
                color: Colors.Text
            },
            '& .MuiFormLabel-root.Mui-disabled': {
                color: Colors.PlaceHolder,
            },
            '& .Mui-disabled': {
                '& .MuiInputAdornment-root': {
                    '& .MuiSvgIcon-root': {
                        fill: Colors.Gray9,
                    },
                },
                '& .MuiIconButton-label': {
                    '& .MuiSvgIcon-root': {
                        fill: Colors.Gray9,
                    },
                },
            }
        },
        readOnlyWithPosibilityToEdit: {
            '& .Mui-disabled:before': {
                borderBottom: `1px solid ${Colors.Gray}`,
            },
            '& .MuiInputBase-input.Mui-disabled': {
                opacity: 1,
            },
            '& .MuiFormLabel-root.Mui-disabled': {
                color: Colors.PlaceHolder,
            },
        },
        disabledContainer: {
            '& .Mui-disabled': {
                '&:before': {
                    borderBottom: `1px dashed ${Colors.LightGraySecondary} `,
                },
                '& .MuiInputAdornment-root': {
                    '& .MuiSvgIcon-root': {
                        opacity: 0.8,
                    },
                },
            },
        },
        hyperLinkText: {
            '& .MuiInputBase-input': {
                cursor: 'pointer',
                color: `${Colors.Link}!important`,
                textDecoration: 'underline'
            }
        }
    };
});

const CustomizedTextField: React.FC<CustomTextFieldProps> = React.memo(
    ({
         id,
         value,
         label,
         disabled,
         readOnly,
         readOnlyWithPosibilityToEdit,
         icon = null,
         iconPosition = 'start',
         helperText,
         multiline,
         type,
         dataQa,
         className,
         required,
         placeholder,
         autoComplete,
         maxLength,
         disableCounter,
         inputProps,
         inputRef: customInputRef,
         withSelectionPrevention,
         fixedLengthCounter,
         inputFieldProps,
         skipPermission,
         convertToLink = false,
         autoFocus = false,
         onClick,
         onFocus,
         onBlur,
         onChange,
         setFieldError,
         onMouseEnter,
         onMouseLeave,
         handleSubmit,
         widthFromLiableText,
         customCounterLength,
         customCounterMaxLength,
         handleKeyPress
     }) => {
        const classes = useStyles(label?.length);
        const inputRef = React.useRef<any>(null);
        const [showTooltip, setShowTooltip] = useState(false)

        let permission = usePermissionContext();

        permission = skipPermission ? PermissionType.Visible : permission;
        readOnly = !!(permission !== PermissionType.Visible || readOnly);


        React.useEffect(() => {
            if (customInputRef) {
                customInputRef.current = inputRef.current;
            }
        }, [inputRef.current]);

        const onChangeText = useCallback(
            (event) => {
                const {selectionStart, selectionEnd} = event.target;

                if (type === 'number') {
                    if (event.target.value === '' && required) {
                        event.target.value = '0';
                    }

                    if (event.target.validity.valid) onChange?.(event);
                } else {
                    onChange?.(event);
                }
                !!id && setFieldError?.(id, undefined);

                if (
                    withSelectionPrevention &&
                    inputRef?.current &&
                    selectionStart &&
                    selectionEnd
                ) {
                    setTimeout(() => {
                        inputRef.current?.setSelectionRange(
                            selectionStart,
                            selectionEnd,
                        );
                    }, 0);
                }
            },
            [withSelectionPrevention, id, setFieldError, type, onChange, required],
        );

        const onKeyPress = useCallback(
            (ev: React.KeyboardEvent<any>) => {
                if (ev?.key === 'Enter') {
                    if (handleSubmit) {
                        handleSubmit(ev);
                        ev.preventDefault();
                    }
                } else {
                    handleKeyPress?.(ev);
                }
            },
            [handleSubmit, handleKeyPress],
        );

        const myStyle = customWidth(label?.length);

        const measureTextWidth = (text: string, font = '16px Arial') => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (context) {
                context.font = font;
                // @ts-ignore
                return context?.measureText(text).width;
            }

            return false
        };


        useEffect(() => {

            if (value && value.toString().length && readOnly) {
                const inputWidth = inputRef.current.offsetWidth;
                const textWidth = measureTextWidth(value.toString());

                if(textWidth)
                {
                    const isTextLonger = textWidth > inputWidth;
                    setShowTooltip((isTextLonger && readOnly) || false)
                }
            }


        }, [value, readOnly]);

        return (
            <>

                <Tooltip
                    title={value}
                    disableHoverListener={!showTooltip}
                    interactive={false}
                    copy={false}
                >
                    <TextField
                        autoComplete={autoComplete || 'off'}
                        inputRef={inputRef}
                        id={id}
                        value={value}
                        className={classNames(
                            classes.textField,
                            {
                                [classes.icon]: icon,
                                [classes.error]: helperText,
                            },
                            className,
                            readOnly && classes.readOnlyContainer,
                            readOnlyWithPosibilityToEdit &&
                            classes.readOnlyWithPosibilityToEdit,
                            disabled && classes.disabledContainer,
                            ((readOnly || disabled) && convertToLink) && classes.hyperLinkText
                        )}
                        autoFocus={autoFocus}
                        style={widthFromLiableText ? {...myStyle} : {}}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChangeText}
                        onKeyPress={onKeyPress}
                        onClick={() => {
                            if (onClick && (readOnly || disabled) && convertToLink) {
                                onClick();
                            }
                        }}
                        disabled={
                            disabled || readOnly || readOnlyWithPosibilityToEdit
                        }
                        label={label}
                        error={!!helperText}
                        required={required}
                        multiline={multiline}
                        type={type}
                        data-qa={dataQa}
                        placeholder={placeholder}
                        InputProps={{
                            [iconPosition === 'start'
                                ? 'startAdornment'
                                : 'endAdornment']: !!icon && (
                                <InputAdornment position="start">
                                    {icon}
                                </InputAdornment>
                            ),
                            ...inputProps,
                        }}
                        inputProps={{
                            maxLength,
                            max: maxLength,
                            ...inputFieldProps,
                        }}
                        helperText={
                            <>
                                {helperText && (
                                    <span
                                        className={classNames(
                                            classes.helperText,
                                            maxLength &&
                                            !disableCounter &&
                                            classes.helperTextWithCounter,
                                        )}
                                        data-qa={`helper-${dataQa}`}
                                        data-testid="textfield-helper"
                                    >
                                    {helperText}
                                </span>
                                )}

                                {maxLength && !disableCounter && (
                                    <span
                                        className={classNames(
                                            'character-counter',
                                            classes.counter,
                                            fixedLengthCounter &&
                                            !helperText &&
                                            classes.fixedCounter,
                                            (value?.toString().length || 0) >
                                            maxLength && classes.counterReached,
                                        )}
                                    >
                                    {customCounterLength ? customCounterLength(value?.toString()) : value?.toString().length || 0}
                                        /
                                        {customCounterMaxLength ? customCounterMaxLength(value?.toString()) : maxLength}
                                </span>
                                )}
                            </>
                        }
                    />
                </Tooltip>

            </>
        );
    },
);

export default CustomizedTextField;


// <div class="ellipsis-container" id="ellipsisContainer">
//     Lorem ipsum dolor sit amet, consectetur adipiscing elit.
// </div>
//
// <script>
//     window.addEventListener('DOMContentLoaded', function() {
//     var container = document.getElementById('ellipsisContainer');
//     var isTextOverflowed = container.scrollWidth > container.clientWidth;
//
//     if (isTextOverflowed) {
//     console.log('Tekst jest ucięty.');
//     // Tutaj możesz dodać odpowiednie działania, jeśli tekst jest ucięty
// } else {
//     console.log('Tekst nie jest ucięty.');
// }
// });
// </script>