import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Colors} from '../../../styles/Colors';
import {TFunction} from 'react-i18next';
import {CloudDownload, Assignment} from '@material-ui/icons';
import {CallHistory} from '../../../store/types/CallHistory';
import {Column} from 'react-table';
import {prepareCallRecordName} from '../../../utils/calls/prepareCallRecordName';
import DateTimeCell from '../../../components/Calls/DateTimeCell';
import CallerInfo from '../../../components/Calls/CallerInfo';
import CustomizedIconButton from '../../../components/IconButton/IconButton';
import PermissionProvider from "../../../components/PermissionProvider/PermissionProvider";
import {Permission, PermissionType} from "../../../store/types/Permission";
import {roundUp} from "../../../utils/roundUp";
import CallQuality from '../../../components/Calls/CallQuality';
import PlayFileButton from '../../../components/PlayFileButton/PlayFileButton';
import {PlayingFile} from '../../../store/reducers/calls/reducer';
import {usePermissions} from '../../../hooks/usePermissions';
import classNames from 'classnames';

export type CallHistoryFormProps = {
    from: string;
    till: string;
    cli: string;
    cld: string;
    unsuccessfulCalls: boolean;
};
export const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export const useStyles = makeStyles(() => ({
    qualityContainer: {
        justifyContent: 'center',
        display: 'flex',
        width: '100%'
    },
    durationContainer: {
        textAlign: "right",
        width:'100%',
        display:'block'
    },
    formsContainer: {
        minWidth: 1124.910
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 11,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
        },
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    itemsContainer: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'flex-start',
    },
    tableContainer: {
        maxWidth: 1040,

        '& thead th:nth-child(6)': {
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: 0,
            paddingLeft: 32
        },

        '& thead th:nth-child(7)': {
            justifyContent: 'center',
            display: 'flex',
            paddingLeft: 75,
        },

        '& tbody tr td:nth-child(6)': {
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: '0px !important',
            paddingLeft: '32px !important',
        },
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'flex-start',

        '& .MuiFormControl-root': {
            marginRight: 18,
        },

        '& .date-time-picker': {
            '& .MuiInputBase-input': {
                width: 208,
            },
        },
    },
    inputs: {
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },
    },
    button: {
        '& .MuiButton-label': {
            color: Colors.White,
        },
        width: 96,
        marginTop: 8,
    },
    table: {
        marginTop: 30,
    },
    pagination: {
        color: Colors.Gray,
        marginTop: 30,
    },
    centeredChargeValue: {
        paddingLeft: '0!important',
        marginLeft: 30,
    },
    centeredRecordingValue: {
        paddingLeft: '0!important',
        marginLeft: 20,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 700,
        color: Colors.Gray7,
    },
    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
            background: `${Colors.White} !important`,
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    checkbox: {
        padding: '5px 0 23px 0',
        marginTop: 15,
        display: 'flex',
        alignItems: 'center',
    },
    recordingColumn: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        flex: 1
    },
    cloud: {
        display: 'flex',
        alignItems: 'center',
        color: Colors.LightGraySecondary3,
        marginLeft: 20,
        minWidth: 30,
    },
    tooltipContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 8px',
    },
    tooltip: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    tooltipTitle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        fontSize: '16px',
        lineHeight: '28px',
        marginRight: '8px',
        whiteSpace: 'pre-wrap',
    },
    download: {
        marginLeft: 20,
        color: `${Colors.PlaceHolder} !important`,
    },
    playPauseButtonLoading: {
        height: '24px !important',
        width: '24px !important',
        padding: '8px',
    },
    audioBox: {
        maxWidth: 1042,
        width: 'calc(100% - 196px - 32px) !important',
        left: '96px !important'
    },

    '@media (max-width: 1276px)': {
        audioBox: {
            maxWidth: 1042,
            width: 'calc(100% - 48px - 32px) !important',
            left: '96px !important'
        },
    },

    '@media (min-width:1277px) and (max-width: 1440px)': {
        audioBox: {
            maxWidth: 1042,
            width: 'calc(100% - 196px - 32px) !important',
            left: '96px !important'
        },
    },

    '@media (min-width: 1441px)': {
        audioBox: {
            maxWidth: 1042,
            width: 'calc(100% - 196px - 32px) !important',
            left: '96px !important'
        },
    },

    playingMusicRow: {
        background: '#FFF5DD !important',
        borderLeft: '4px solid ' + Colors.Primary2,

        '& [custom-attrib-file-counter="custom-attrib-file-counter"]': {
            background: Colors.Primary2,
            color: Colors.White,
        }
    },

    filesCounterCellContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        paddingRight: 36,

        '& span': {
            marginTop: 12
        }
    },
    filesCounter: {
        width: 'fit-content',
        height: 16,
        background: Colors.Border,
        color: Colors.Secondary,
        borderRadius: 16,
        padding: '4px 12px 5px 12px',
        marginTop: 11,
        fontSize: 13,
        fontWeight: 400,

        '& span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            marginLeft: '0px !important'
        }
    },
    margin1button: {
        marginRight: 45
    },
    margin2button: {
        marginRight: 90
    },
    margin3button: {
        marginRight: 135
    }
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    customerCurrency: string,
    decimalDigits: number,
    downloadFile: (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => void,
    setCallRecordInformation: React.Dispatch<
        React.SetStateAction<CallHistory | undefined>
    >,
    userDateTimeFormat?: string,
    isDownloadingGoingOn?: boolean[],
    filesQueue?: PlayingFile[][]
): Column<CallHistory>[] => {
    return [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.connectTime'),
            width: 1.3,
            Cell: function Cell(params) {
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        time={params.row.original.connect_time as string}
                        customDateFormat={userDateTimeFormat}
                    />
                );
            },
        },
        {
            accessor: 'CLI',
            Header: t<string>('screens:calls.caller'),
            width: 2,
            Cell: function Cell(params) {
                return <CallerInfo caller={params.row.original.CLI!}/>;
            },
        },
        {
            accessor: 'CLD',
            Header: t<string>('screens:calls.destination'),
            width: 2,
            Cell: function Cell(params) {
                return <CallerInfo caller={params.row.original.CLD!}/>;
            },
        },
        {
            accessor: 'aux_xdrs',
            Header: t<string>('screens:calls.quality'),
            width: 0.6,
            Cell: function Cell(params) {
                return (<div className={classes.qualityContainer}>
                        <CallQuality auxXdrs={params.row.original.aux_xdrs}/>
                    </div>
                );
            },
        },
        {
            accessor: 'charged_quantity',
            Header: <span className={classes.durationContainer}>{t<string>('screens:calls.duration')}</span>,
            width: 1,
            Cell: function Cell(params) {
                return (
                    <span className={classes.durationContainer}>
                        {new Date(params.row.original.charged_quantity! * 1000)
                            .toISOString()
                            .substr(11, 8)}
                    </span>
                );
            },
        },
        {
            accessor: 'charged_amount',
            Header:
                t('screens:calls.charge') +
                (customerCurrency ? `, ${customerCurrency}` : ''),
            width: 0.6,
            Cell: function Cell(params) {
                return (
                    <span>
                        {' '}
                        {roundUp(
                            params.row.original.charged_amount || 0,
                            2,
                        ).toFixed(decimalDigits)}
                    </span>
                );
            },
        },
        {
            accessor: 'cr_download_ids',
            Header: t<string>('common:actions'),
            maxWidth: 222,
            minWidth: 222,
            width: 1.5,
            Cell: function Cell(params) {
                const number = params.row.original.i_xdr as number;
                const downloadId = params.row.original.cr_download_ids?.[0];
                const downloadIds = params.row.original.cr_download_ids || [];
                const index = params.row.index;
                const row = params.row.original;
                let fileSet: PlayingFile[] = [];
                const i_xdr = params.row.original.i_xdr;

                if (downloadIds.length && filesQueue) {
                    for (const fq of filesQueue) {
                        if (fq.length && fq[0].call_recording_id === downloadIds[0]
                            && fq[0].i_xdr === i_xdr) {
                            fileSet = fq;
                        }
                    }
                }

                const downloadPermission = usePermissions(...Permission.Calls.Activity.RecentCalls.DownloadCallRecording.value);
                const downloadButtonShown = downloadId && downloadPermission !== PermissionType.Hidden;
                const detailsButtonShown = usePermissions(...Permission.Calls.Activity.RecentCalls.CallDetailRecord.value) !== PermissionType.Hidden;
                const playButtonShown = (downloadIds.length > 0) && usePermissions(...Permission.Calls.Activity.RecentCalls.PlayCallRecording.value) !== PermissionType.Hidden;
                const emptyPlace = <span style={{minWidth:'44px', maxWidth:'44px', display:"block"}}/>

                return (
                    <div className={classes.recordingColumn}>

                        {downloadIds.length > 1 && playButtonShown ? (
                            <div
                                className={classNames(classes.filesCounter
                            )}
                                custom-attrib-file-counter="custom-attrib-file-counter"
                                data-testid={'multi-files-badge'}>
                                <span>{t<string>('screens:callSettings.filesCount', {
                                    count: downloadIds.length
                                })}</span>
                            </div>
                        ): <span style={{minWidth:'60.0833px', maxWidth:'60.0833px', display:"block"}}/>}

                        {(downloadIds.length > 0) ? (
                            <PermissionProvider
                                permission={Permission.Calls.Activity.RecentCalls.PlayCallRecording.value} skipReadOnly>
                                <PlayFileButton
                                    files={fileSet}
                                    customClasses={{
                                        beforePlaying: classes.playPauseButtonLoading
                                    }}
                                    dataQa={"play-file-icon"}
                                    dataTestId={"play-file-icon-id"}
                                />
                            </PermissionProvider>
                        ) : emptyPlace}

                        <PermissionProvider permission={Permission.Calls.Activity.RecentCalls.CallDetailRecord.value}
                                            skipReadOnly>
                            <CustomizedIconButton
                                tooltipText={t(
                                    'tooltips:callSettings.viewCallDetailRecord',
                                )}
                                dataQa="details-icon-button"
                                dataTestId="details-icon-button"
                                onClick={() =>
                                    setCallRecordInformation(params.row.original)
                                }
                            >
                                <Assignment/>
                            </CustomizedIconButton>
                        </PermissionProvider>


                        {downloadId ? (
                            <PermissionProvider
                                permission={Permission.Calls.Activity.RecentCalls.DownloadCallRecording.value}
                                skipReadOnly>
                                <CustomizedIconButton
                                    tooltipText={t(
                                        'tooltips:ringGroups.downloadRecording',
                                    )}
                                    dataQa="detail-download-icon"
                                    dataTestId="download-icon"
                                    onClick={() =>
                                        downloadFile(
                                            number,
                                            downloadId,
                                            prepareCallRecordName(
                                                row,
                                                timezoneOffset,
                                            ),
                                            index,
                                        )
                                    }
                                    disabled={
                                        !(
                                            downloadId &&
                                            !isDownloadingGoingOn?.[
                                                params.row.index
                                                ]
                                        )
                                    }
                                >
                                    <CloudDownload/>
                                </CustomizedIconButton>
                            </PermissionProvider>

                        ): emptyPlace}
                    </div>
                );
            },
        },
    ];
};
