import React, {useEffect} from 'react';
import {TabInterface} from '../../../components/Tabs/Tabs.utils';
import {useTabStyles} from '../../Extensions/ExtensionDetails/utils';
import Loader from '../../../components/Loader/Loader';
import {Formik} from 'formik';
import CallSettingsForm from '../../../components/Forms/AutoAttendants/CallSettings/CallSettingsForm';
import {
    CallSettingsFormType,
    callSettingsValidationSchema,
} from '../../../components/Forms/AutoAttendants/CallSettings/CallSettingsForm.utils';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {useAutoAttendantCallSettingsFormData} from '../../../hooks/useAutoAttendantCallSettingsFormData';
import RestrictedMessageProvider, {ProviderTabName} from "./RestrictedMessageProvider";

interface CallSettingsTabInterface extends TabInterface {
    accountId?: number;
}

const CallSettingsTab: React.VFC<CallSettingsTabInterface> = (
    {
        accountId,
        handleSubmitForm,
        handleInitDataChange,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,

    }) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.autoAttendants.autoAttendantDetails?.callSettingsTab
                ?.isLoading || false,
    );

    const {initialValues, data} = useAutoAttendantCallSettingsFormData();

    useEffect(() => {
        if (!isDataLoading && initialValues) {
            handleInitDataChange?.(initialValues);
            handleSubmitForm?.(initialValues);
        }
    }, [isDataLoading, initialValues]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="call-settings-details-loader"/>
        </div>
    ) : (
        <RestrictedMessageProvider tabName={ProviderTabName.CallSettings}>
            <Formik<CallSettingsFormType>
                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={false}
                onSubmit={handleSubmitForm!}
                validationSchema={callSettingsValidationSchema}
            >
                {() => {

                    return (
                        <>
                            <CallSettingsForm
                                accountId={accountId}
                                handleSetSubmitFunc={handleSetSubmitFunc}
                                handleDirtyChange={handleDirtyChange}
                                handleSetIsValidFunc={handleSetIsValidFunc}
                                callRecordingEmail={data.email}
                                mohItems={data.mohItems}
                                i_account={accountId}
                            />
                        </>
                    )
                }
                }

            </Formik>
        </RestrictedMessageProvider>
    );
};

export default CallSettingsTab;
