import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import {Formik} from 'formik';
import {TabInterface, useTabStyles} from '../../../components/Tabs/Tabs.utils';
import RestrictedMessageProvider, {ProviderTabName} from "../../AutoAttendants/Details/RestrictedMessageProvider";
import {useEditSipTrunkCallSettingsFormData} from "../../../hooks/useEditSipTrunkCallSettingsFormData";
import CallSettingsForm from "../../../components/Forms/SipTrunks/CallSettings/CallSettingsForm";
import {
    SipTrunkExtensionCallSettingsFormType
} from "../../../components/Forms/SipTrunks/SipContact/SipContactForm.utils";
import {sipCallSettingsValidationSchema} from "../../../components/Forms/SipTrunks/CallSettings/CallSettingsForm.utils";

const CallSettingsTab: React.VFC<TabInterface> = (
    {
        handleSubmitForm,
        handleInitDataChange,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc

    }) => {
    const classes = useTabStyles();

    const {initFormData, data} = useEditSipTrunkCallSettingsFormData();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.sipTrunks.sipTrunkDetails?.callSettingsTab?.isLoading ||
            false,
    );

    useEffect(() => {
        if (!isDataLoading && initFormData) {
            handleInitDataChange?.(initFormData);
            handleSubmitForm?.(initFormData);
        }
    }, [isDataLoading, initFormData]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="call-settings-form-loader"/>
        </div>
    ) : (
        <RestrictedMessageProvider tabName={ProviderTabName.CallSettings}>
            <Formik<SipTrunkExtensionCallSettingsFormType>
                initialValues={initFormData}
                validationSchema={sipCallSettingsValidationSchema}
                onSubmit={handleSubmitForm!}
                enableReinitialize
                validateOnChange={false}
            >
                {() => {
                    return (
                        <>
                            <CallSettingsForm
                                handleDirtyChange={handleDirtyChange}
                                handleSetSubmitFunc={handleSetSubmitFunc}
                                handleSetIsValidFunc={handleSetIsValidFunc}
                                accountId={data?.extension?.account_id}
                                i_account={data?.extension?.i_customer}
                                dynamicList={data.dynamicList}
                                callRecordingEmail={data.email}
                            />
                        </>
                    )
                }
                }

            </Formik>
        </RestrictedMessageProvider>

    );
};

export default CallSettingsTab;
