import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { RingGroupsSectionData, RingGroupsSectionProps, generateColumns, useStyles } from "./RingGroupsSection.utils";
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { actions } from '../../store';
import { PaginationMode } from '../DataGrid/types';
import DataGrid from '../DataGrid/DataGrid';
import { SipCall, SipCallState, SipCallType } from '../../store/actions/ringgroups/payloads';
import { YesNo } from '../../store/types/CallScreening';
import { RingGroupType } from '../../store/types/RingGroup';
import { ExtensionType } from '../../store/types/Extension';
import Loader from '../Loader/Loader';
import { useRawPermissions } from '../../hooks/usePermissions';
import { getPermission } from '../../utils/permissions/getPermission';
import { Permission, PermissionType } from '../../store/types/Permission';

const INITIAL_PAGE_SIZE = 10;

const RingGroupsSection: React.VFC<RingGroupsSectionProps> = ({
}) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { ringGroupsList, sipCallsList, sipCallsApiError, sipRingGroupsApiError } = useSelector(
        (state: ReduxState) => state.ringgroups,
    );
    
    const isLoadingRingGroups = useSelector(
        (state: ReduxState) => state.ringgroups?.isLoading ?? false,
    );
    
    const isLoadingCalls = useSelector(
        (state: ReduxState) => state.ringgroups?.isLoadingSipCalls ?? false,
    );
    
    const { callQueues } = useSelector(
        (state: ReduxState) => state.callQueues,
    );
    
    const isLoadingCallQueues: boolean = useSelector(
        (state: ReduxState) => state.callQueues?.isLoading ?? false,
    );
    
    const { extensionsList, loadingExtensionApiError } = useSelector(
        (state: ReduxState) => state.wallboard,
    );
    
    const isLoadingExtensions: boolean = useSelector(
        (state: ReduxState) => state.wallboard?.isLoadingExtensions ?? false,
    );

    const [, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);

    const fetchData = (params?: { pageSize: number; page: number }) => {
        dispatch(
            actions.getExtensionsListForWidgets.request({})
        );
        dispatch(
            actions.ringGroupsList.request({
                limit: params?.pageSize || pageSize || INITIAL_PAGE_SIZE,
                offset: params ? params?.page * params?.pageSize : 0,
            }),
        );
        dispatch(
            actions.getSipCallsList.request({
            }),
        );
        dispatch(
            actions.getCallQueues.request()
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filteredExtensions = useCallback((ringGroup: RingGroupType) => {
        return extensionsList?.filter(e => ringGroup.assigned_extensions?.find(c => e.extension_id === c.id)) ?? [];
    }, [extensionsList]);
        
    const callsByExtensionIds = useCallback((filtExts: ExtensionType[]) =>  {
        return sipCallsList?.filter(e => 
            (e.callee?.extension_id && filtExts.findIndex(c => c.extension_id === e.callee.extension_id) !== -1) || 
            (e.caller?.extension_id && filtExts.findIndex(c => c.extension_id === e.caller.extension_id) !== -1)
        ) || [];
    }, [sipCallsList]);

    const onCallExtensions = useCallback((ringGroup: RingGroupType) => {
        const filtExts = filteredExtensions(ringGroup);
        const calls = callsByExtensionIds(filtExts)
            ?.filter(e => (e.state === SipCallState.Connected)
                || (e.state === SipCallState.Trying && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Ringing && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Queued && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Dequeued)
            ) ?? [];

        const extIds: string[] = [];
        for(const e of calls) {
            if(e.callee?.extension_id) {
                extIds.push(e.callee.extension_id);
            }
            if(e.caller?.extension_id) {
                extIds.push(e.caller.extension_id);
            }
        }
        return filtExts?.filter(e => !!extIds.find(d => d === e.extension_id)) ?? [];
    }, [callsByExtensionIds, filteredExtensions, extensionsList, sipCallsList]);

    const idleAgents = useCallback((ringGroup: RingGroupType) => {
        const filtExts = filteredExtensions(ringGroup);
        const extInCallIds: string[] = [];
        for(const e of sipCallsList ?? []) {
            if(e.callee?.extension_id && filtExts.findIndex(c => c.extension_id === e.callee.extension_id) !== -1) {
                extInCallIds.push(e.callee.extension_id);
            }
            if((e.caller?.extension_id && filtExts.findIndex(c => c.extension_id === e.caller.extension_id) !== -1)) {
                extInCallIds.push(e.caller.extension_id);
            }
        }
        const inCallExts =  filtExts?.filter(e => !!extInCallIds?.find(k => k === e.extension_id)) ?? [];
        const filteredExtensionsNotInCall = filtExts
            ?.filter(e => !inCallExts?.find(c => e.extension_id === c.extension_id));
        const idleExtensions = 
            //AND NOT On call
            filteredExtensionsNotInCall
            //sip_status:1 (Registered)
            ?.filter(e => e.sip_status === 1)
            //AND Logged in
            ?.filter(e => ringGroup.assigned_extensions?.find(c => e.extension_id === c.id)?.hunt_active === YesNo.Yes)
            //AND NOT On hold
            ?.filter(e => e.state !== SipCallState.Holding && e.state !== SipCallState.Held)
            ?? [];
        return idleExtensions.length;
    }, [onCallExtensions, filteredExtensions, sipCallsList]);

    const loggedIn = useCallback((ringGroup: RingGroupType) => {
        return extensionsList
            ?.filter(e => e.sip_status === 1 && ringGroup.assigned_extensions
            ?.find(c => e.extension_id === c.id && c.hunt_active === YesNo.Yes))
            ?.length ?? 0;
    }, [extensionsList]);

    const queued = useCallback((ringGroup: RingGroupType) => {
        const assignedCallQueueId = ringGroup.assigned_callqueue?.i_c_queue || -1;
        const exts = sipCallsList
            ?.filter((v: SipCall) =>
            v.type === SipCallType.Incoming
            && v.state === SipCallState.Queued
            && (v.callee?.huntgroup_id === ringGroup.id || v.queue_info?.i_c_queue === assignedCallQueueId)
        )?.map(e => e.tracking_id);
        return [...new Set(exts)].length;
    }, [sipCallsList]);

    const onHold = useCallback((ringGroup: RingGroupType) => {
        const filtExts = filteredExtensions(ringGroup);
        const calls = callsByExtensionIds(filtExts);
        const tracks = calls
            ?.filter(e => e.state === SipCallState.Holding || e.state === SipCallState.Held)
            ?.map(e => e.tracking_id);
        return [...new Set(tracks)].length;
    }, [callsByExtensionIds]);

    const isLoading = useMemo(() => {
        return (isLoadingRingGroups || isLoadingCallQueues || isLoadingExtensions || isLoadingCalls) ?? false;
    }, [isLoadingRingGroups, isLoadingCallQueues, isLoadingExtensions, isLoadingCalls]);

    const dataForTable = useMemo(() => {
        const retObjects: RingGroupsSectionData[] = [];
        for(const rg of ringGroupsList?.items ?? []) {
            retObjects.push({
                i_c_ext: rg.i_c_ext ?? 0,
                idle: idleAgents(rg),
                loggedIn: loggedIn(rg),
                onHold: onHold(rg),
                onCall: onCallExtensions(rg)?.length ?? 0,
                queued: queued(rg),
                total: filteredExtensions(rg)?.length ?? 0
            });
        }
        return retObjects
    }, [ringGroupsList, sipCallsList, extensionsList])
    
    const columns = useMemo(
        () =>
            generateColumns(
                t,
                classes,
                callQueues,
                dataForTable,
                isLoading
            ),
        [callQueues, dataForTable, isLoading],
    );
    
    const permissions = useRawPermissions();
    const ringGroupsForbidden = getPermission(permissions, ...Permission.CloudPBX.RingGroups.value) === PermissionType.Hidden;

    const isError = useMemo(() => {
        const error = sipCallsApiError ?? sipRingGroupsApiError ?? loadingExtensionApiError;
        return error !== undefined && error !== null;
    }, [sipCallsApiError, sipRingGroupsApiError,loadingExtensionApiError]);

    if(ringGroupsForbidden || isError) {
        return (<></>);
    }

    return (
        <div className={classes.mainContainer}>
            <div className={classes.labelContainer}>
                <span className={classes.label}>
                    {t('screens:dashboard.ringGroups')}
                </span>
            </div>
            <div className={classes.ringGroupsContainer} style={{
                position: 'relative'
            }}>
                <DataGrid
                    columns={columns}
                    data={ringGroupsList.items}
                    rowCount={ringGroupsList.total}
                    onPageChange={(params) => {
                        fetchData({
                            pageSize: params.pageSize,
                            page: params.page,
                        });
                        setPage(params.page);
                    }}
                    onPageSizeChange={(params) => {
                        setPageSize(params.pageSize);
                        setPage(0);
                        fetchData({ pageSize: params.pageSize, page: 0 });
                    }}
                    initialPageSize={10}
                    paginationMode={PaginationMode.Server}
                    centeredRows
                    narrowRows
                    classes={{
                        tableContainer: classes.tableContainer
                    }}
                />
                {isLoading && (<div className={classes.loaderBackground}>
                    <></>
                </div>)}
            </div>
        </div>
    );
}

export default RingGroupsSection;
