import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import { AndroidAppUrl, Enable2FADialogProps, IOsAppUrl, mfaValidationSchema, useStyles } from './Enable2FADialog.utils';
import classNames from 'classnames';
import { ConfirmMfaConfig } from '../../store/actions/payloads';
import DialogContainer, { DialogButton } from '../../components/AlertDialog/DialogContainer';
import CustomizedTextField from '../../components/TextField/TextField';
import Logo from '../../components/Logo/Logo';
import CustomizedButton from '../../components/Button/Button';
import { ReactComponent as Copy } from '../../assets/copy-content.svg';
import CustomizedTooltip from '../../components/Tooltip/Tooltip';
import QRCode from 'react-qr-code';
import { actions } from '../../store';
import { ReduxState } from '../../store/types';
import {showErrorToast} from "../../utils/showErrorToast";

const Enable2FADialog: React.FC<Enable2FADialogProps> = ({
        isOpen,
        mfaConfig,
        showActionButtons = false,
        onClose
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { mfaVerifyError } = useSelector(
        (state: ReduxState) => state.auth,
    );
    
    const toastString = t('common:textIsCopied');
    const handleOnClick = useCallback(() => {
        navigator.clipboard.writeText(mfaConfig?.secret || '');
        showErrorToast(toastString || '');
    }, [mfaConfig]);

    const initialValues: ConfirmMfaConfig = {
        one_time_password: ''
    };

    const {
        values,
        handleSubmit,
        setFieldError,
        handleChange,
        errors,
        setFieldValue,
    } = useFormik<ConfirmMfaConfig>({
        initialValues,
        onSubmit: (form) => {
            dispatch(actions.confirmMfaConfig.request({
                ...form,
                //@ts-ignore
                sessionId: mfaConfig?.sessionId,
                onSuccess: () => {
                    const message: string = t('screens:twoFa.successActivatedNotification');
                    showErrorToast(message);
                    onClose?.(true);
                }
            }));
        },
        validationSchema: mfaValidationSchema,
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: true
    });
    
    useMemo(() => {
        if(mfaVerifyError?.faultstring) {
            setFieldValue('one_time_password', '');
            setFieldError('one_time_password', mfaVerifyError.faultstring);
        } else {
            setFieldError('one_time_password', undefined);
        }
    }, [mfaVerifyError, isOpen]);
    
    const actionButtons = useMemo(() => [
        <DialogButton
            key="cancel"
            label={t('common:cancel')}
            onClick={() => {
                dispatch(actions.cleanupMfaConfig());
                onClose?.(false);
                setFieldError('one_time_password', undefined);
            }}
        />,
        <DialogButton
            key="save"
            label={t('screens:twoFa.activateButton')}
            onClick={handleSubmit}
            disabled={values.one_time_password.length !== 6}
            className={classNames(classes.primaryButton, (values.one_time_password.length !== 6) ? classes.disabledButton : null)}
        />,
    ], [values, onClose, handleSubmit]);

    return (
            <DialogContainer
                className={classNames(classes.modalContainer, showActionButtons && classes.smallerDialog)}
                isOpen={isOpen}
                dataQa={'login-enable-2fa'}
                hideHeader={!showActionButtons}
                header={t('screens:twoFa.twoFactorAuth2fa')}
                dialogActionsButtons={showActionButtons ? actionButtons : null}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="login-enable-2fa-form"
                >
                    <Grid className={classes.itemsContainer}>
                        {!showActionButtons && (
                            <>
                                <Box className={classNames(classes.boxRow, classes.logoContainer)}>
                                    <Logo />
                                </Box>

                                <Box className={classes.boxRow} style={{marginTop: 17}}>
                                    <div className={classes.header1}>{t('screens:twoFa.twoFactorAuth')}</div>
                                </Box>
                            </>
                        )}

                        <Box className={classes.boxRow} style={{marginTop: showActionButtons ? 0 : 24}}>
                            <div className={classes.header2}>{t('screens:twoFa.twoFaRequires')}
                                <a href={AndroidAppUrl} target="_blank" rel="noreferrer">Android</a>,  
                                <a href={IOsAppUrl} target="_blank" rel="noreferrer" style={{marginLeft: 6}}>iOS</a>
                                ).
                            </div>
                        </Box>
                        
                        <Box className={classes.boxRow} style={{marginTop: 24}}>
                            <div className={classes.header2}>{t('screens:twoFa.scanQr')}</div>
                        </Box>
                        
                        <Box className={classes.boxRow} style={{marginTop: 10}}>
                            <div className={classes.mainContainer}>
                                <div className={classes.qrContainer}>
                                    <QRCode
                                        size={125}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={mfaConfig?.qr_text || ''}
                                        viewBox={`0 0 125 125`}
                                    />
                                </div>
                                <div className={classes.rightContainer}>
                                    <div className={classes.header3} style={{marginTop: 18}}>
                                        {t('screens:twoFa.ifYourApp')}
                                    </div>
                                    <CustomizedTextField
                                        id="tf_code"
                                        label=""
                                        value={mfaConfig?.secret}
                                        dataQa="tf_code-field"
                                        className={classes.tfCode}
                                        readOnly
                                        skipPermission
                                        icon={
                                            <CustomizedTooltip title={t('screens:twoFa.copyToClipboard')} copy={false}>
                                                <div className={classes.iconContainer}>
                                                    <Copy
                                                        data-testid="copy-button"
                                                        onClick={handleOnClick}
                                                    />
                                                </div>
                                            </CustomizedTooltip>
                                        }
                                        iconPosition='end'
                                    />
                                </div>
                            </div>
                        </Box>

                        <Box className={classes.boxRow} style={{marginTop: 16}}>
                            <CustomizedTextField
                                id="one_time_password"
                                label={t('screens:twoFa.enterCode')}
                                value={values.one_time_password}
                                required
                                setFieldError={setFieldError}
                                helperText={
                                    errors.one_time_password && errors.one_time_password.length > 0
                                        ? errors.one_time_password
                                        : ''
                                }
                                onChange={(e) => {
                                    handleChange(e);
                                    if(e.target.value.length >= 6) {
                                        setTimeout(() => {
                                            handleSubmit();
                                        }, 100);
                                    }
                                }}
                                maxLength={6}
                                dataQa="one_time_password-field"
                                className={classes.oneTimePassword}
                                skipPermission
                            />
                        </Box>
                        
                        {!showActionButtons && (
                            <Box className={classNames(classes.boxRow, classes.buttonContainer)}>
                                <CustomizedButton
                                    dataQa="submit-button"
                                    skipPermission
                                    onClick={() => {handleSubmit();}}
                                    primary
                                    className={classes.submitButton}
                                    disabled={values.one_time_password.length !== 6}
                                >
                                    {t('screens:twoFa.submit')}
                                </CustomizedButton>
                            </Box>
                        )}

                    </Grid>
                </form>
            </DialogContainer>
    );
};

export default Enable2FADialog;