import React, {useEffect, useMemo, useState} from "react";
import PermissionProvider from "../../../components/PermissionProvider/PermissionProvider";
import {Permission} from "../../../store/types/Permission";
import Header from "../../../components/ListViewHeader/Header";
import {generateColumns, useStyles} from "./utils";
import usePageTitle from "../../../hooks/usePageTitle";
import {useTranslation} from "react-i18next";
import CreateNewSipTrunkDialog from "./CreateNewSipTrunkDialog";
import FiltersComponent from "./FiltersComponent";
import { actions } from '../../../store';
import {SipTrunksListFilters} from "./FiltersComponent.utils";
import { useDispatch, useSelector } from 'react-redux';
import {ReduxState} from "../../../store/types";
import {PaginationMode} from "../../../components/DataGrid/types";
import DataGrid from "../../../components/DataGrid/DataGrid";
import {SipTrunksListItem} from "../../../store/reducers/extensions/extensions/reducer";
import CallHistoryDialog from "../../../components/Extensions/CallHistoryDialog";
import PermissionPlaceholder from "../../../components/PermissionProvider/PermissionPlaceholder";

const SipTrunksList = () => {
    usePageTitle();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const classes = useStyles();

    const initPage = 0;
    const initPageSize = 10;
    const [forceFirstPage, setForceFirstPage] = useState(false);
    const [forceSearch, setForceSearch] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SipTrunksListItem | null>(null);

    const [
        createNewSipTrunkModalVisible,
        setCreateNewSipTrunkModalVisible,
    ] = useState(false);

    const handleForceFirstPage = () => {
        setForceFirstPage(true);
    };

    const showCreateNewSipTrunkModal = () =>
        setCreateNewSipTrunkModalVisible(true);

    const handleChangeVisibilityCreateNewModal = () =>
        setCreateNewSipTrunkModalVisible(!createNewSipTrunkModalVisible);

    const handleHideShowHistory = () => setCallHistoryModalConfig(undefined);


    const [callHistoryModalConfig, setCallHistoryModalConfig] = useState<
        { accountId: number } | undefined
        >();

    const { sipTrunksList, isLoading } = useSelector(
        (state: ReduxState) => state.sipTrunks,
    );

    const fetchData = (
        params?: { pageSize: number; page: number },
        filters?: SipTrunksListFilters,
        withReset?: boolean,
    ) => {
        dispatch(
            actions.getSipTrunksList.request({
                trunkIdFilter: filters?.trunkIdFilter,
                didNumberFilter: filters?.didNumberFilter,
                statusFilter: filters?.statusFilter,
                limit: params?.pageSize || initPageSize,
                offset: params ? params?.page * params?.pageSize : 0,
                withReset
            })
        );

        setForceSearch(false);
    };

    const refreshStatus = (i_account: number) => {
        dispatch(
            actions.refreshSipTrunkStatus.request({
                i_account,
            }),
        );
    };

    const showCallHistory = (item: SipTrunksListItem) => {
        setCallHistoryModalConfig({
            accountId: Number(item?.account_id) || item.i_account,
        });
        setSelectedItem(item);
    };

    useEffect(() => {
        fetchData(undefined, undefined, true)
    }, []);

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                refreshStatus,
                showCallHistory,
                classes,
            ),
        [],
    );

    return (<PermissionPlaceholder permission={Permission.SipTrunks.value}>
        <div className={classes.mainContainer}>
            <div className={classes.scrollable}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <PermissionProvider
                            permission={
                                Permission.SipTrunks.AddNewSipTrunk.value
                            }
                        >
                            <Header
                                title={t('screens:sipTrunks.listHeader')}
                                buttonText={t('common:addNewButton')}
                                totalNumber={sipTrunksList.allTotal || 0}
                                ofText={t('common:registered')}
                                ofNumber={sipTrunksList.registeredTotal || 0}
                                dataQa={'sip-trunks-view-header'}
                                buttonOnClick={showCreateNewSipTrunkModal}
                                plusIcon={true}
                            />
                        </PermissionProvider>
                    </div>

                    <FiltersComponent
                        page={initPage}
                        pageSize={initPageSize}
                        handleSearch={fetchData}
                        handleForceFirstPage={handleForceFirstPage}
                        triggerSearch={forceSearch}
                    />

                    <DataGrid
                        columns={columns}
                        data={sipTrunksList?.items}
                        rowCount={sipTrunksList.total}
                        loading={isLoading}
                        paginationMode={PaginationMode.Client}
                        centeredRows
                        initialPageSize={initPageSize}
                        forceFirstPage={forceFirstPage}
                    />
                </div>

                <CallHistoryDialog
                    isOpen={!!callHistoryModalConfig}
                    toggleVisibility={handleHideShowHistory}
                    accountId={callHistoryModalConfig?.accountId}
                    config={selectedItem}
                    customHeader={t('screens:sipTrunks.callActivity', {
                        extensionNumber: selectedItem?.id,
                    })}
                    downloadRecordingPermission={
                        Permission.SipTrunks.ViewCallHistory
                            .DownloadCallRecording.value
                    }
                    hiddeName={true}
                />

                <CreateNewSipTrunkDialog
                    isOpen={createNewSipTrunkModalVisible}
                    toggleVisibility={handleChangeVisibilityCreateNewModal}
                />
            </div>
        </div>
    </PermissionPlaceholder>)
}

export default SipTrunksList;

