import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Colors } from '../../styles/Colors';
import { FormControlLabel } from '@material-ui/core';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';

export interface CheckboxProps {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    dataQa: string;
    label?: string | React.ReactNode;
    className?: string;
    indeterminate?: boolean;
    value?: unknown;
    dataTestId?: string;
    skipPermission?: boolean;
}

const useStyles = makeStyles(() => ({
    checked: {
        '&$checked': {
            color: Colors.Secondary1,
        },
    },
    checkbox: {
        '&.Mui-checked': {
            color: Colors.Secondary1,
        },
        '&.MuiCheckbox-indeterminate': {
            color: Colors.Secondary1,
        },
    },
    disabled: {
        '&$checked': {
            color: Colors.Secondary14,
        },
    },
}));

const CustomizedCheckbox: React.FC<CheckboxProps> = ({
    onChange,
    checked,
    disabled,
    dataQa,
    label = '',
    className,
    indeterminate,
    value,
    dataTestId,
    skipPermission,
}) => {
    const classes = useStyles();

    let permission = usePermissionContext();

    permission = skipPermission ? PermissionType.Visible : permission;
    disabled = disabled || (permission !== PermissionType.Visible);

    return (
        <FormControlLabel
            className={className}
            control={
                <Checkbox
                    className={classNames(classes.checkbox, {
                        [classes.checked]: checked,
                        [classes.disabled]: disabled,
                    })}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                    color="primary"
                    data-qa={dataQa}
                    data-testid={dataTestId}
                    indeterminate={indeterminate}
                    value={value}
                />
            }
            label={label}
        />
    );
};

export default CustomizedCheckbox;
