import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import TextField from '../../TextField/TextField';
import SelectField from '../../SelectField/SelectField';
import classNames from 'classnames';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import { CustomerDIDNumberType } from '../../../store/types/CustomerDIDNumber';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../store';
import { ReduxState } from '../../../store/types';
import { TimeZone } from '../../../store/types/TimeZone';
import { Country } from '../../../store/types/Country';
import { Subdivision } from '../../../store/types/Subdivision';
import CustomizedChip from '../../Chip/Chip';
import { ExtensionsListItem } from '../../../store/reducers/extensions/extensions/reducer';
import { EditAutoAttendantForm } from '../../../views/AutoAttendants/Details/AutoAttendantsDetails';
import { ExtensionFormType, useStyles } from './ExtensionForm.utils';
import Separator from '../../Separator/Separator';
import { DID_NUMBERS_FETCH_API_ERROR } from '../../../store/sagas/extensions/extensions/saga';
import AccessDenied from '../../PermissionProvider/AccessDenied';
import {callback} from "chart.js/helpers";

export type ExtensionFormProps = {
    accountId?: number;
    handleSubmitForm?: (formData: Partial<EditAutoAttendantForm>) => void;
    handleDirtyChange?: (funcName: string, isDirty: boolean) => void;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    callback?: () => void
};

const ExtensionForm: React.FC<ExtensionFormProps> = ({
    accountId,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
    callback
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const didNumbers = useSelector<ReduxState, CustomerDIDNumberType[]>(
        (v) => v.autoAttendants.customerDidNumbers || [],
    );

    const countriesList = useSelector<ReduxState, Country[]>(
        (state) => state.generic.countriesList,
    );

    const timeZonesList = useSelector<ReduxState, TimeZone[]>(
        (state) => state.generic.timeZonesList,
    );
    
    const isLoading = useSelector<ReduxState, Boolean>(
        (state) => state.autoAttendants?.autoAttendantDetails?.extensionTab?.isLoading || false,
    );

    const extensionItem = useSelector<
        ReduxState,
        ExtensionsListItem | undefined
    >((state) => state.autoAttendants.autoAttendantInfo);

    const countriesOptionsList = [
        {
            iso_3166_1_a2: '',
            name: '',
        },
        ...(countriesList || []),
    ];

    const subdivisionsList = useSelector<ReduxState, Subdivision[]>((state) => [
        {
            i_country_subdivision: 0,
            iso_3166_1_a2: '',
            iso_3166_2: '',
            name: '',
        },
        ...state.generic?.subdivisionsList,
    ]);

    const getSubdivisions = (iso_3166_1_a2?: string) => {
        if (iso_3166_1_a2) {
            dispatch(
                actions.getSubdivisionData.request({
                    iso_3166_1_a2,
                }),
            );
        }
    };

    const {
        values,
        handleChange,
        errors,
        setFieldValue,
        setFieldError,
        dirty,
        handleSubmit,
        isValid,
    } = useFormikContext<ExtensionFormType>();

    useEffect(() => {
        handleDirtyChange?.('extension', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('extension', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('extension', () => isValid);
    }, [isValid]);

    useEffect(()=>{
        if(errors.extensionNumber == t('errors:extensions.numberInUse') )
        {
            callback && callback();
        }
    },[errors])

    const renderDidTags = useCallback(
        (selected: string[]) => {
            return selected.map((v) => (
                <CustomizedChip
                    key={v}
                    label={v}
                    handleDelete={
                        v !== extensionItem?.account_info?.did_number
                            ? () => {
                                    setFieldValue(
                                        'didNumber',
                                        values.didNumber.filter((w) => w !== v),
                                    );
                                }
                            : undefined
                    }
                />
            ));
        },
        [extensionItem, values.didNumber],
    );

    if(extensionItem?.apiError?.faultcode === DID_NUMBERS_FETCH_API_ERROR) {
        return <AccessDenied subPageMode />;
    }

    return (
        <div className={classes.inputs}>
            <Grid item className={classes.itemsContainer}>
                <TextField
                    id="extensionName"
                    label={t('screens:extensions.extensionName')}
                    onChange={handleChange}
                    value={values.extensionName}
                    icon={
                        <IconWithTooltip
                            dataQa="extension-name-tooltip"
                            tooltipText={t(
                                'tooltips:autoAttendants.extensionName',
                            )}
                        />
                    }
                    iconPosition="end"
                    dataQa="extension-name-input"
                    helperText={errors.extensionName}
                    setFieldError={setFieldError}
                    required
                    maxLength={32}
                />
                <TextField
                    id="extensionNumber"
                    label={t('screens:extensions.extensionNumber')}
                    onChange={handleChange}
                    value={values.extensionNumber}
                    icon={
                        <IconWithTooltip
                            dataQa="extension-number-tooltip"
                            tooltipText={t(
                                'tooltips:autoAttendants.extensionNumber',
                            )}
                        />
                    }
                    iconPosition="end"
                    dataQa="extension-number-input"
                    helperText={errors.extensionNumber}
                    setFieldError={setFieldError}
                    required
                    maxLength={5}
                />
            </Grid>

            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.singleItem,
                )}
            >
                <SelectField
                    label={t('screens:extensions.didNumber')}
                    onChange={(e, value) => setFieldValue('didNumber', value)}
                    items={
                        didNumbers
                            ?.filter(
                                (v) =>
                                    !v.i_account ||
                                    v.i_account === accountId ||
                                    v.i_master_account === accountId,
                            )
                            .map((v) => v.did_number) || []
                    }
                    value={values.didNumber}
                    multiple
                    icon={
                        <IconWithTooltip
                            dataQa="did-numbers-tooltip"
                            tooltipText={t('tooltips:autoAttendants.didNumber')}
                        />
                    }
                    dataQa="extension-didnumbers-input"
                    getOptionDisabled={(v) =>
                        v === extensionItem?.account_info?.did_number
                    }
                    renderTags={renderDidTags}
                    disableClearable
                    disableAutoSettingValue
                />
            </Grid>
            <Separator />
            <Grid item className={classes.itemsContainer}>
                <TextField
                    id="emailAddress"
                    label={t('screens:extensions.emailAddress')}
                    onChange={handleChange}
                    value={values.emailAddress}
                    icon={
                        <IconWithTooltip
                            dataQa="extension-email-tooltip"
                            tooltipText={t(
                                'tooltips:autoAttendants.emailAddress',
                            )}
                        />
                    }
                    iconPosition="end"
                    dataQa="extension-email-input"
                    helperText={
                        !!errors.emailAddress
                            ? t(`errors:extensions.emailAddress`)
                            : ''
                    }
                    setFieldError={setFieldError}
                    maxLength={128}
                />

                <SelectField
                    label={t('screens:extensions.timezone')}
                    items={timeZonesList?.map((v) => v.time_zone_name) || []}
                    onChange={(_, value) => setFieldValue('timezone', value)}
                    value={values.timezone}
                    icon={
                        <IconWithTooltip
                            dataQa="extension-timezone-tooltip"
                            tooltipText={t('tooltips:autoAttendants.timezone')}
                        />
                    }
                    dataQa="extension-timezone-input"
                    helperText={errors.timezone}
                    setFieldError={setFieldError}
                    disableClearable
                />
            </Grid>

            {values.office && (
                <Grid
                    item
                    className={classNames(
                        classes.itemsContainer,
                        classes.singleItem,
                    )}
                >
                    <TextField
                        label={t('screens:extensions.office')}
                        value={values.office}
                        readOnly
                        iconPosition="end"
                        icon={
                            <IconWithTooltip
                                dataQa="extension-office-tooltip"
                                tooltipText={t(
                                    'tooltips:autoAttendants.office',
                                )}
                            />
                        }
                        dataQa="extension-timezone-input"
                    />

                    {!values.office && <div style={{ flex: 1 }} />}
                </Grid>
            )}

            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.columnItemsContainer,
                )}
            >
                <Box className={classes.rowBox}>
                    <Box className={classes.rowBox} width={350}>
                        <span
                            className={classNames(
                                classes.rowBoxHeader,
                                classes.boldHeader,
                            )}
                        >
                            {t('screens:extensions.emergencyLocation')}
                        </span>

                        <IconWithTooltip
                            dataQa="extension-emergency-location-tooltip"
                            tooltipText={t(
                                'tooltips:autoAttendants.emergencyLocation',
                            )}
                        />
                    </Box>
                </Box>

                <Grid
                    item
                    className={classNames(
                        classes.insideItemsContainer,
                        classes.subSectionSpace,
                    )}
                >
                    <SelectField
                        label={t('screens:extensions.country')}
                        items={countriesOptionsList?.map((v) => v.name) || []}
                        value={
                            countriesOptionsList?.find(
                                (v) => v.iso_3166_1_a2 === values.country,
                            )?.name || ''
                        }
                        onChange={(_, value) => {
                            const country = countriesOptionsList?.find(
                                (v) => v.name === value,
                            )?.iso_3166_1_a2;

                            setFieldValue('country', country || '', false);
                            setFieldValue('state', '');
                            getSubdivisions(country);
                        }}
                        dataQa="extension-country-input"
                    />
                    <TextField
                        id="city"
                        label={t('screens:extensions.city')}
                        onChange={handleChange}
                        value={values.city}
                        dataQa="extension-city-input"
                        maxLength={31}
                    />
                </Grid>
                <Grid
                    item
                    className={classNames(
                        classes.insideItemsContainer,
                        classes.subSectionSpace,
                    )}
                >
                    <TextField
                        id="address"
                        label={t('screens:extensions.address')}
                        onChange={handleChange}
                        value={values.address}
                        dataQa="extension-address-input"
                        maxLength={41}
                    />
                    <TextField
                        id="postalCode"
                        label={t('screens:extensions.postalCode')}
                        onChange={handleChange}
                        value={values.postalCode}
                        dataQa="extension-postal-code-input"
                        maxLength={10}
                        setFieldError={setFieldError}
                        helperText={errors.postalCode}
                    />
                </Grid>
                <Grid
                    item
                    className={classNames(
                        classes.insideItemsContainer,
                        classes.subSectionSpace,
                    )}
                >
                    <SelectField
                        label={t('screens:extensions.provincyState')}
                        dataQa="extension-state-input"
                        items={subdivisionsList?.map((v) => v.name) || []}
                        value={values.state}
                        onChange={(_, value) => {
                            if(!isLoading) {
                                setFieldValue('state', value || null);
                            }
                        }}
                        disabled={!values.country}
                    />

                    <div style={{ flex: 1 }} />
                </Grid>
            </Grid>
        </div>
    );
};

export default ExtensionForm;
