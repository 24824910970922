import React, { useCallback, useMemo } from 'react';
import CustomizedIconButton from '../IconButton/IconButton';
import { useTranslation } from 'react-i18next';
import { PlayCircleFilledRounded, PauseCircleFilledRounded } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { PlayingFile, RecordingsPlayingState, RecordingsPlayingStatus, initialState } from '../../store/reducers/calls/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { actions } from '../../store';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';

export type PlayFileButtonProps = {
    files: PlayingFile[];
    customClasses?: {
        initial?: string,
        beforePlaying?: string,
        playing?: string,
        container?: string
    };
    dataQa?: string;
    dataTestId?: string;
    skipPermission?: boolean;
};

export const useStyles = makeStyles(() => ({
    playPauseButtonBase: {
        '& svg': {
            color: `${Colors.Link}`,
        },
        '&:hover': {
            background: `${Colors.Gray2}!important`,
            borderRadius: 24
        },
    },

    playPauseButtonLoading: {
        color: Colors.Primary2,
        '& .MuiCircularProgress-svg': {
            color: Colors.Primary2
        },
    },

    playClicked: {
        '& svg': {
            color: `${Colors.Primary2} !important`,
        },
    }
}));

const PlayFileButton: React.FC<PlayFileButtonProps> = ({
    files,
    customClasses,
    dataQa,
    dataTestId = '',
    skipPermission = false
}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const recordingsPlayer: RecordingsPlayingState = useSelector<ReduxState, RecordingsPlayingState>(
        (state) => state.calls.recordingsPlayer,
    );
    
    const permission = skipPermission ? PermissionType.Visible : usePermissionContext();

    if(permission === PermissionType.Hidden) {
        return (<></>);
    }

    const isTheCurrentFile = useMemo(() => {
        if(!(recordingsPlayer?.currentFile)) return false;
        for(const f of files) {
            if(f && f.call_recording_id === recordingsPlayer.currentFile.call_recording_id
                && f.i_xdr === recordingsPlayer.currentFile.i_xdr)
                return true;
        }
        return false;
    }, [files, recordingsPlayer]);

    const downloading = useMemo(() => {
        return isTheCurrentFile && 
            (recordingsPlayer.status == RecordingsPlayingStatus.downloading
            || recordingsPlayer.status == RecordingsPlayingStatus.downloadedNotStartedPlaying);
    }, [isTheCurrentFile, recordingsPlayer]);
    
    const playClicked = useMemo(() => {
        return isTheCurrentFile && 
            (recordingsPlayer.status == RecordingsPlayingStatus.playing);
    }, [isTheCurrentFile, recordingsPlayer]);
    
    const onClickPlay = useCallback(() => {
        if(recordingsPlayer.status === RecordingsPlayingStatus.notDefined || 
            recordingsPlayer.status === RecordingsPlayingStatus.playing ||
            (recordingsPlayer.status === RecordingsPlayingStatus.finished && !isTheCurrentFile) ||
            (recordingsPlayer.status === RecordingsPlayingStatus.paused && !isTheCurrentFile)) {

            if(!files || !files.length) return;
            const file = files[0]; 
            
            dispatch(actions.updateRecordingsPlayingState({
                ...recordingsPlayer,
                status: RecordingsPlayingStatus.downloading,
                currentFile: file,
                currentBlob: null
            }));

            dispatch(
                actions.getCallHistoryFileAsBlob.request({
                    i_xdr: file.i_xdr,
                    call_recording_id: file.call_recording_id,
                    callback: (url) => {
                        setTimeout(() => {
                            dispatch(actions.updateRecordingsPlayingState({
                                ...recordingsPlayer,
                                currentFile: file,
                                status: RecordingsPlayingStatus.downloadedNotStartedPlaying,
                                currentBlob: url
                            }));
                        }, 1);
                    },
                    restrictedCallback: () => {
                        setTimeout(() => {
                            dispatch(actions.updateRecordingsPlayingState({
                                ...initialState.recordingsPlayer,
                                status: RecordingsPlayingStatus.dowloadingRestricted,
                            }));
                        }, 1);
                    }
                }),
            );
        } else if ((recordingsPlayer.status === RecordingsPlayingStatus.paused && isTheCurrentFile)
                || (recordingsPlayer.status === RecordingsPlayingStatus.finished && isTheCurrentFile)) {
            dispatch(actions.updateRecordingsPlayingState({
                ...recordingsPlayer,
                status: RecordingsPlayingStatus.playing
            }));
        }
    }, [isTheCurrentFile, recordingsPlayer, files]);

    const onClickPause = useCallback(() => {
        dispatch(actions.updateRecordingsPlayingState({
            ...recordingsPlayer,
            status: RecordingsPlayingStatus.paused
        }));
    }, [isTheCurrentFile, recordingsPlayer]);

    if(downloading) {
        return (
            <CircularProgress className={classNames(classes.playPauseButtonBase, classes.playPauseButtonLoading, customClasses?.beforePlaying)} />
        );
    }
    
    return (<CustomizedIconButton
        tooltipText={playClicked ? t('tooltips:callSettings.pauseAudio') : t('tooltips:callSettings.playAudio')}
        dataQa={dataQa}
        dataTestId={dataTestId}
        onClick={() => {
            if(!playClicked) {
                onClickPlay();
            } else {
                onClickPause();
            }
        }}
        className={classNames(classes.playPauseButtonBase,
            customClasses?.container,
            playClicked && classes.playClicked, 
            playClicked && customClasses?.playing,
            !playClicked && customClasses?.initial)}
        disabled={recordingsPlayer.status === RecordingsPlayingStatus.dowloadingRestricted
            || permission !== PermissionType.Visible}
        above
        skipPermission
    >
        {playClicked ? (
            <PauseCircleFilledRounded/>
        ) : (
            <PlayCircleFilledRounded/>
        )}
    </CustomizedIconButton>);
};

export default PlayFileButton;