import {ChangeDirtyStateDetailsTab} from '../../../../views/Extensions/ExtensionDetails/utils';
import {EditAutoAttendantForm} from '../../../../views/AutoAttendants/Details/AutoAttendantsDetails';
import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../../../styles/Colors';
import {
    callBarringFormDefaultValues,
    CallBarringFormType,
    callBarringFormValidationSchema,
    preferedIvrLanguageFormDefaultValues,
    PreferedIvrLanguageFormType,
    preferedIvrLanguageFormValidationSchema,
} from '../../CallSettings/CallBarring/utils';
import {
    callRecordingFormDefaultValues,
    CallRecordingFormType,
    callRecordingFormValidationSchema,
} from '../../Extensions/CallRecording/CallRecordingForm.utils';
import {
    incomingFormDefaultValues,
    IncomingFormType,
    incomingFormValidationSchema,
} from '../Incoming/utils';
import {
    disaFormDefaultValues,
    DisaFormType,
    disaFormValidationSchema,
} from '../DisaForm.utils';
import {ApiFile} from "../../../../store/types/ApiFile";

export type CallSettingsFormProps = {
    accountId?: number;
    handleSubmitForm?: (formData: Partial<EditAutoAttendantForm>) => void;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    callRecordingEmail?: string,
    mohItems: ApiFile[],
    i_account?: number;
};

export type CallSettingsFormType = IncomingFormType &
    CallBarringFormType &
    CallRecordingFormType &
    DisaFormType &
    { onHoldMusicStatus: boolean, onHoldMusicName?: string } & 
    PreferedIvrLanguageFormType;

export const callSettingsDefaultValues = {
    ...incomingFormDefaultValues,
    ...callBarringFormDefaultValues,
    ...callRecordingFormDefaultValues,
    ...disaFormDefaultValues,
    ...preferedIvrLanguageFormDefaultValues
};

export const callSettingsValidationSchema = incomingFormValidationSchema
    .concat(callBarringFormValidationSchema)
    .concat(callRecordingFormValidationSchema)
    .concat(disaFormValidationSchema)
    .concat(preferedIvrLanguageFormValidationSchema);

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '22px 16px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
    },
    rowContainer: {
        flex: 1,
        display: 'flex',
        paddingBottom: 24,
        alignItems: 'flex-start',
        '& > :first-child': {
            marginRight: 24,
        },
        '&:last-of-type': {
            paddingBottom: 0,
        },
    },
    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
    },
    inputs: {
        display: 'flex',

        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },
    numberInput: {
        maxWidth: 125,
        width: 125,
        marginRight: 20,

        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
        },
        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
        },
    },

    defaultAnswringModeInput: {
        maxWidth: 320,
    },
    minText: {
        position: 'absolute',
        marginTop: 17,
        marginLeft: 58,
        top: 10.5
    },
    mohContainer: {
        marginTop: 0,
        marginLeft: 10,
    },
    selectedMohContainer: {
        marginBottom: 10,
        marginTop: 15,
    },
    switchContainer: {
        marginBottom: 0,
        marginTop: 20,
    },
}));
