import {ReactComponent as Person} from '../../assets/person-2.svg';
import {ReactComponent as Dashboard} from '../../assets/dashboard.svg';
import {ReactComponent as CloudPhone} from '../../assets/cloud-phone.svg';
import {ReactComponent as AllInbox} from '../../assets/all_inbox.svg';
import {ReactComponent as CallHistory} from '../../assets/call_history.svg';
import {ReactComponent as SipTrunks} from '../../assets/sip_trunks.svg';
import React from 'react';
import {Routes} from '../../routes/routes';
import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';
import Config from '../../config.json';
import {Permission} from '../../store/types/Permission';

export const sections: {
    [key in string]: {
        sections: { route: string; permission: string[] }[];
        permission: string[];
    };
} = {
    dashboard: {
        sections: [
            { route: Routes.Dashboard, permission: Permission.Dashboard.value },
        ],
        permission: Permission.Dashboard.value,
    },
    cloudPbx: {
        sections: [
            {
                route: Routes.Extensions,
                permission: Permission.CloudPBX.Extensions.value,
            },
            {
                route: Routes.RingGroups,
                permission: Permission.CloudPBX.RingGroups.value,
            },
            {
                route: Routes.AutoAttendants,
                permission: Permission.CloudPBX.AutoAttendants.value,
            },
            {
                route: Routes.FaxMailboxes,
                permission: Permission.CloudPBX.FaxMailboxes.value,
            },
            {
                route: Routes.PhoneBook,
                permission: Permission.CloudPBX.PhoneBook.value,
            },
            {
                route: Routes.Wallboard,
                permission: Permission.CloudPBX.Wallboard.value
            }
        ],
        permission: Permission.CloudPBX.value,
    },
    calls: {
        sections: [
            {
                route: Routes.CallSettings,
                permission: Permission.Calls.Settings.value,
            },
            {
                route: Routes.CallRecordings,
                permission: Permission.Calls.Recordings.value,
            },
            {
                route: Routes.CallActivity,
                permission: Permission.Calls.Activity.value,
            },
        ],
        permission: Permission.Calls.value,
    },
    sipTrunks: {
        sections:[
            {
                route: Routes.SipTrunks,
                permission: Permission.SipTrunks.value,
            }
        ],
        permission: Permission.SipTrunks.value,
    },
    inventory: {
        sections: [
            {
                route: Routes.Devices,
                permission: Permission.Inventory.Devices.value,
            },
            {
                route: Routes.DidNumbers,
                permission: Permission.Inventory.DIDNumbers.value,
            },
        ],
        permission: Permission.Inventory.value,
    },
    customer: {
        sections: [
            {
                route: Routes.MyProfile,
                permission: Permission.MyCompany.MyProfile.value,
            },
            {
                route: Routes.PortalUsers,
                permission: Permission.MyCompany.PortalUsers.value,
            },
            {
                route: Routes.MyCompany,
                permission: Permission.MyCompany.CompanyInfo.value,
            },
            {
                route: Routes.Billing,
                permission: Permission.MyCompany.Billing.value,
            },
        ],
        permission: Permission.MyCompany.value,
    },
};

export const sectionsIcons: { [key in string]: JSX.Element } = {
    customer: <Person />,
    dashboard: <Dashboard />,
    cloudPbx: <CloudPhone />,
    inventory: <AllInbox />,
    calls: <CallHistory />,
    sipTrunks: <SipTrunks />
};

export const useStyles = makeStyles(() => ({
    sidebar: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid rgba(0,0,0, 0.12)`,
        width: 'auto',
        minWidth: 220,
        overflow: 'hidden',
        height: '100vh',
        backgroundColor: Config.NAVIGATION_PANEL_COLOR || Colors.Secondary1,
        '& .MuiList-padding': {
            paddingTop: 18,
            flex: 1,
        },
        '& .MuiTypography-body1': {
            fontSize: 16,
            fontWeight: 500,
            paddingTop: 8,
            paddingBottom: 8,
            color: Colors.White,
            lineHeight: '24px',
        },
        '& .MuiListItem-gutters': {
            color: Colors.White,
            fontSize: 14,
            fontWeight: 500,
            paddingTop: 2,
            paddingBottom: 2,
            minWidth: 215
        },
        '& a': {
            textDecoration: 'none',
        },
    },
    hiddenSidebar: {
        width: '54px',

        '& .MuiListItemText-primary': {
            height: 50,
        },
    },
    logoContainer: {
        color: Colors.White,
        paddingTop: 20,
        paddingBottom: 0,
        height: 94,
        overflow: 'hidden'
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: 100,
        height: 80,
        width: 'auto',
        padding: '8px',

        '& img': {
            maxHeight: '80px',
        },
    },
    hqIndicatorContainer: {
        display: 'flex',
        height: 0,
        width: '100%',
        position: 'relative',
        top: '-100%'
    },
    hqIndicator: {
        alignItems: 'center',
        padding: '4px 8px',
        gap: '10px',
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '0px 0px 0px 4px',
        opacity: 0.7,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
        textAlign: 'right',
        position: 'absolute',
        right: '0px',
        textTransform:'uppercase'
    },
    hq: {
        background: 'rgba(255, 255, 255, 0.8)',
        color: Colors.Secondary1,
        fontSize: '12px'
    },
    branch: {
        background: 'rgba(0, 29, 105, 0.4)',
        color: Colors.White
    },
    hidden: {
        display: 'none'
    },
    tooltipText: {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '150%',
        color: Colors.SmokeBackground
    }
}));

const I_CUSTOMER_PATTERN = '{{i_customer}}';

export function cleanStringsForLabelAndLink(label: string, link: string, iCustomer?: string): { label: string, link: string } {
    const regex = /(\r\n|\n|\r)/gm;

    label = label.replace(regex, "").trim();
    link = link.replace(I_CUSTOMER_PATTERN, iCustomer ? iCustomer : I_CUSTOMER_PATTERN)
        .replace(regex, "").trim();

    return {link, label}
}