import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import { UAInfo } from '../../store/types/Devices';
import DeviceModeCell from './DeviceModeCell';
import AssignedExtensionsCell from './AssignedExtensionsCell';
import FreeLines, {getFreeLines} from './FreeLines';
import history from '../../history';
import { Delete, Edit } from '@material-ui/icons';
import React from 'react';
import DeviceStatusTableCell from '../../components/Devices/DeviceStatusTableCell';
import { getRoute, Routes } from '../../routes/routes';
import { Statuses } from '../../components/Extensions/StatusIcon';
import CustomizedIconButton from '../../components/IconButton/IconButton';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import { Permission } from '../../store/types/Permission';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: 'white',
        },
        flex: 1,
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
    },

    content: {
        maxWidth: 1040,
        padding: '0px 94px 20px 94px',
    },
    header: {
        paddingTop: 48,
        paddingBottom: 16,
        '& p': {
            marginBottom: 0,
        },
        '& div:not(:first-child)': {
            marginTop: 14
        }
    },
    table: {
        '& .MuiDataGrid-cell': {
            alignItems: 'center',
            height: 64,
            color: Colors.Gray5,
        },

        '& .MuiTableHead-root': {
            height: 64,
            display: 'flex',
        },

        '& td.MuiTableCell-root.MuiTableCell-body': {
            padding: 8,
        },
        '& th.MuiTableCell-root.MuiTableCell-head': {
            padding: 8,
        },

        '& .MuiTableCell-body': {
            color: Colors.Gray5,
        },
        
        '& tr th.MuiTableCell-root.MuiTableCell-head:nth-child(7)': {
            textAlign: 'center'
        },
    },
    moreOptionsButton: {
        '& svg.MuiSvgIcon-root > path': {
            fill: Colors.Gray5,
            fillOpacity: 1,
        },
        '&:hover': {
            backgroundColor: Colors.Gray11,
        },
        padding: 8,
        marginLeft: 14
    },
    dropdownMenuItem: {
        fontSize: 16,
        color: Colors.Text
    },
    hidden: {
        display: 'none'
    },
    deleteDialog: {
        '& .MuiPaper-root': {
            maxWidth: '365px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset',
        },
    },
    contentDialog: {
        backgroundColor: Colors.White + ' !important',
        wordBreak: 'break-all'
    },
}));

export const addId = (o: UAInfo) => ({ ...o, id: o.i_ua });

export const getStatusNumber = (o: UAInfo): number => {
    const { used, ports_config } = o;
    if (used == '0') {
        return Statuses.Hold;
    }

    for (let i = 0; i < ports_config.length; i++) {
        if (!ports_config[i].account_id) {
            return Statuses.On;
        }
    }

    return Statuses.Off;
};

export const generateColumns = (t: TFunction<string>,
    onDelete: (obj: UAInfo) => void
    ): Column<UAInfo>[] => {
    return [
        {
            accessor: 'used',
            Header: (
                <div style={{ textAlign: 'center' }}>
                    {t<string>('common:status')}
                </div>
            ),
            width: 1,
            minWidth: 80,
            maxWidth: 80,
            Cell: function Cell(params) {
                const status = getStatusNumber(params.row.original);
                return <DeviceStatusTableCell status={status} />;
            },
        },
        {
            accessor: 'name',
            width: 2,
            Header: t<string>('screens:devices.deviceAndModal'),
            Cell: function Cell(params) {
                return (
                    <PermissionProvider
                        permission={
                            Permission.Inventory.Devices.DeviceDetails.value
                        }
                        skipReadOnly
                    >
                        <DeviceModeCell
                            name={params.row.original.name}
                            type={params.row.original.type}
                            moveTo={'/devices/' + params.row.original.i_ua}
                        />
                    </PermissionProvider>
                );
            },
        },
        {
            accessor: 'ports_config',
            width: 1.1,
            Header: t<string>('screens:devices.assignedExtension'),
            Cell: function Cell(params) {
                return (
                    <AssignedExtensionsCell
                        portConfig={params.row.original.ports_config}
                    />
                );
            },
        },
        {
            accessor: 'mac',
            width: 1,
            Header: t<string>('screens:devices.macAddress'),
            Cell: function Cell(params) {
                return params.row.original.mac;
            },
        },
        {
            accessor: 'available_ports',
            width: 1,
            Header: (
                <div style={{ textAlign: 'center' }}>
                    {t<string>('screens:devices.freeLinesHeader')}
                </div>
            ),
            Cell: function Cell(params) {
                return (
                    <div
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                            flex: 1,
                        }}
                    >
                        <FreeLines info={params.row.original} />
                    </div>
                );
            },
        },
        {
            accessor: 'inventory_id',
            width: 1,
            Header: t<string>('screens:devices.inventoryId'),
            Cell: function Cell(params) {
                const text = params.row.original.inventory_id || '';
                return (<OverflowTooltip
                    tooltip={text}
                    text={text}
                    dataQa={'inventory_id-text'}
                    copy={false}
                />);
            },
        },
        {
            accessor: 'i_ua',
            minWidth: 104,
            maxWidth: 104,
            Header: t<string>('common:actions'),
            Cell: function Cell(params) {
                const freeLines = getFreeLines(params.row.original);
                const allLinesFree = freeLines.left == freeLines.right;

                return (<>
                    <PermissionProvider
                        permission={
                            Permission.Inventory.Devices.DeviceDetails.value
                        }
                        skipReadOnly
                    >
                        <CustomizedIconButton
                            onClick={() =>
                                history.push(
                                    getRoute(Routes.DevicesDetails, {
                                        id: params.row.original.i_ua,
                                    }),
                                )
                            }
                            tooltipText={t<string>('common:edit')}
                            dataTestId="edit-list-item-button"
                            dataQa="edit-list-item-button"
                        >
                            <Edit htmlColor={Colors.ListIconColor} />
                        </CustomizedIconButton>
                    </PermissionProvider>
                    <PermissionProvider
                        permission={
                            Permission.Inventory.Devices.DeleteDevice.value
                        }
                    >
                        {params.row.original.ported === 'Y' && allLinesFree  ? (
                            <CustomizedIconButton
                                onClick={() =>
                                    onDelete?.(params.row.original)
                                }
                                tooltipText={t<string>('common:delete')}
                                dataTestId="remove-list-item-button"
                                dataQa="remove-list-item-button"
                            >
                                <Delete htmlColor={Colors.ListIconColor}/>
                            </CustomizedIconButton>
                        ) : (<></>)}
                    </PermissionProvider>
                    </>
                );
            },
        },
    ];
};
