import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../../../styles/Colors';
import {CustomerDIDNumberType} from '../../../../store/types/CustomerDIDNumber';
import * as Yup from 'yup';
import {ChangeDirtyStateDetailsTab, EditExtensionForm,} from '../../../../views/Extensions/ExtensionDetails/utils';
import i18n from '../../../../services/i18n';
import {TimeZone} from '../../../../store/types/TimeZone';
import {Country} from '../../../../store/types/Country';
import {PasswordRules} from '../../../../store/types/ConfigData';
import {Subdivision} from '../../../../store/types/Subdivision';
import {APIErrorInterface} from '../../../../store/types';
import {
    maxTrunkIdLength,
    nameSipTrunkMaxLengthError
} from "../../../../views/SipTrunks/List/CreateNewSipTrunkDialog.utils";
import {nameMaxLengthError, requiredFieldError} from "../../../PortalUsers/utils";

export const useStyles = makeStyles(() => ({
    visibilityIcon: {
        marginRight: 6,
    },
    itemsContainer: {
        padding: '32px 0px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
    },
    rowContainer: {
        flex: 1,
        display: 'flex',
        paddingBottom: 24,
        alignItems: 'flex-start',
        padding: '0px 16px 24px 16px',

        '& > :first-child': {
            marginRight: 32,
        },
        '&:last-of-type': {
            paddingBottom: 0,
        },
    },
    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
    },
    groupsContainer: {
        padding: '24px 16px',
        maxWidth: 944,
    },

    columnItemsContainer: {
        flexDirection: 'column',
        '& .MuiFormControl-root:first-of-type': {
            marginRight: '15px !important',
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
    },
    boldHeader: {
        fontWeight: 700,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 11,

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
    },
    sectionSpace: {
        marginTop: 48,
    },
    emergencyHeaderContainer: {
        padding: '0px 24px 0px 24px !important',
        alignItems: 'center',
        display: 'flex',
        marginBottom: 16,
        marginTop: -28,
    },
    emergencyHeader: {
        color: Colors.Text,
        fontWeight: 700,
        marginRight: 12,
    },
    includeSwitcher: {
        maxWidth: '944px !important',
    },

    outsideContainer: {
        paddingRight: 0,
    },
    includeContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: -5,
    },
    includeRowContainer: {
        '& > :first-child': {
            flex: 1,
            paddingLeft: 0,
        },
    },
    topContainer: {
        paddingTop: 22,
    },
}));

export type SipTrunkFormType = {
    sipTrunk: string;
    didNumber: string[];
    displayNumber: string;
    emailAddress: string;
    timezone: string;
    office: string;
    country: string;
    address: string;
    city: string;
    postalCode: string;
    state: string;
    servicePassword: string;
};

export const emptyField = i18n.t('errors:common.emptyInput');
export const portalLoginError = i18n.t('errors:extensions.portalLogin');
export const portalLoginLengthError = i18n.t(
    'errors:extensions.portalLoginLength',
);

const postalCodeMinError = i18n.t('errors:extensions.postalCode');
export const invalidCharError = i18n.t('errors:sipTrunks.withoutSpace');

export const generateSipTrunkFormValidationSchema = (
    portalPasswordLength: number,
) =>
    Yup.object().shape(
        {
            sipTrunk: Yup.string()
                .max(maxTrunkIdLength, nameSipTrunkMaxLengthError)
                .matches(/^[a-zA-Z0-9_.-]*$/, invalidCharError)
                .required(emptyField),
            didNumber: Yup.array().notRequired(),
            displayNumber: Yup.string().notRequired().nullable(),
            emailAddress: Yup.string().email().notRequired(),
            timezone: Yup.string().required(emptyField),
            office: Yup.string().notRequired(),
            country: Yup.string().nullable().notRequired(),
            address: Yup.string().notRequired(),
            city: Yup.string().notRequired(),
            postalCode: Yup.string().min(2, postalCodeMinError).notRequired(),
            state: Yup.string().nullable().notRequired(),
            // servicePassword: Yup.string().min(portalPasswordLength, portalLoginLengthError)
            servicePassword: Yup.string()
                .test(
                    'passLength',
                    i18n.t(`screens:portalUsers.portalPassword`, {
                        value:portalPasswordLength,
                    }),
                    function (value) {
                        if (
                            value !== undefined &&
                            portalPasswordLength
                        ) {
                            return (
                                value.length >=
                                Number(
                                    portalPasswordLength
                                )
                            );
                        }

                        return true;
                    },
                ).test(
                    'passChars',
                    i18n.t(`errors:sipTrunks.whiteSpaceAdded`),
                    function (value) {
                        const test = value?.indexOf(' ')
                        return test == undefined || test == -1;
                    }
                )
                .max(32, nameMaxLengthError)
                .required(requiredFieldError),
        }
    );

export const sipTrunkFormDefaultValues: SipTrunkFormType = {
    sipTrunk: '',
    didNumber: [],
    displayNumber: '',
    emailAddress: '',
    timezone: '',
    country: '',
    address: '',
    city: '',
    postalCode: '',
    state: '',
    office: '',
    servicePassword: ''
};

export type SipTrunkFormProps = {
    didNumbers?: CustomerDIDNumberType[];
    accountId?: number;
    initFormData?: SipTrunkFormType;
    timeZones?: TimeZone[];
    countries?: Country[];
    webConfig?: PasswordRules;
    subdivisionsList?: Subdivision[];
    apiErrors?: APIErrorInterface;
    handleSubmitForm?: (formData: Partial<EditExtensionForm>) => void;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    prefixEnabled?: boolean;
    callback?: () => void

};

export function generateDisplayNumberList(
    value: string,
    realValue: string,
    prefixEnabled: boolean,
    prefixMark: string,
    simple = true,
): string | { value: string; label: string } {
    let displayValue = value;
    if (prefixEnabled && value != realValue) {
        const indexOfPrefix = value.indexOf(prefixMark);
        displayValue =
            prefixEnabled && indexOfPrefix !== 0
                ? `${prefixMark}${value}`
                : value;
    } else if (`+${value}` == realValue) {
        displayValue = realValue;
    }

    return simple ? displayValue : {label: displayValue, value: displayValue};
}

export const sipDetails = {
    "id": "333444777",
    "numbers": [
        "12120000006"
    ],
    "i_account": 102535,
    "i_customer": 7031,
    "account_info": {
        "i_account_role": 1,
        "um_url": "https://demo.portaone.com:8943",
        "billing_model": 1,
        "inactivity_expire_time": null,
        "i_subscriber": 25817,
        "bill_status": "O",
        "i_account": 102535,
        "out_date_format": "YYYY-MM-DD",
        "iso_4217": "ZAR",
        "i_customer": 7031,
        "issue_date": "2023-08-31",
        "customer_name": "Pepkor Group",
        "product_visible_name": "Essentials 30 channels",
        "time_zone_name": "Europe/Prague",
        "um_domain": "demosip-12.portaone.com",
        "assigned_addons": [],
        "i_account_balance_control_type": 3,
        "i_time_zone": 113,
        "in_date_format": "YYYY-MM-DD",
        "city": "some city",
        "h323_password": "JFx5c3",
        "i_lang": "en",
        "service_features": [
            {
                "locked": 0,
                "attributes": [
                    {
                        "values": [
                            "30"
                        ],
                        "name": "max_calls",
                        "effective_values": [
                            "30"
                        ]
                    },
                    {
                        "values": [
                            "10"
                        ],
                        "name": "max_calls_fwd",
                        "effective_values": [
                            "10"
                        ]
                    },
                    {
                        "values": [
                            "10"
                        ],
                        "name": "max_calls_out",
                        "effective_values": [
                            "10"
                        ]
                    },
                    {
                        "effective_values": [
                            "10"
                        ],
                        "values": [
                            "10"
                        ],
                        "name": "max_calls_in"
                    }
                ],
                "locks": [],
                "effective_flag_value": "Y",
                "name": "voice_fup",
                "flag_value": "Y"
            }
        ],
        "um_url_valid": 1,
        "life_time": null,
        "id": "333444777",
        "account_expired": 0,
        "sip_status": 0,
        "i_role": 6,
        "alias_did_number_list": [
            {
                "i_did_number": 985378,
                "did_number": "12120000006"
            }
        ],
        "service_flags": "                               Y",
        "balance": 825.17,
        "customer_blocked": "N",
        "i_acl": 155,
        "cust_bill_suspension_delayed": 0,
        "is_active": 1,
        "credit_limit": null,
        "out_date_time_format": "YYYY-MM-DD HH24:MI:SS",
        "included_services": [],
        "out_time_format": "HH24:MI:SS",
        "blocked": "N",
        "customer_bill_status": "O",
        "has_custom_fields": 1,
        "account_inactive": 0,
        "customer_bill_suspended": 0,
        "i_product": 3326,
        "expiration_date": null,
        "activation_date": "2023-08-31",
        "login_to_um_is_available": 0,
        "ecommerce_enabled": "N",
        "in_time_format": "HH24:MI:SS",
        "opening_balance": 0,
        "product_name": "Essentials 30 channels"
    },
    "serviceFeatures": [
        {
            "locked": 0,
            "attributes": [
                {
                    "values": [
                        "30"
                    ],
                    "name": "max_calls",
                    "effective_values": [
                        "30"
                    ]
                },
                {
                    "values": [
                        "10"
                    ],
                    "name": "max_calls_fwd",
                    "effective_values": [
                        "10"
                    ]
                },
                {
                    "values": [
                        "10"
                    ],
                    "name": "max_calls_out",
                    "effective_values": [
                        "10"
                    ]
                },
                {
                    "effective_values": [
                        "10"
                    ],
                    "values": [
                        "10"
                    ],
                    "name": "max_calls_in"
                }
            ],
            "locks": [],
            "effective_flag_value": "Y",
            "name": "voice_fup",
            "flag_value": "Y"
        }
    ],
    "fetchDIDNumbers": 0,
    "fetchSipStatus": 0,
    "calls": [
        {
            "name": "maxCalls",
            "value": "30",
            "translation": "simultaneous"
        },
        {
            "name": "maxCallsFwd",
            "value": "10",
            "translation": "forwarded"
        },
        {
            "name": "maxCallsOut",
            "value": "10",
            "translation": "outgoing"
        },
        {
            "name": "maxCallsIn",
            "value": "10",
            "translation": "incoming"
        }
    ],
    "activeCalls": [
        {
            "name": "forwarded",
            "count": 0
        },
        {
            "name": "incoming",
            "count": 0
        },
        {
            "name": "outgoing",
            "count": 0
        },
        {
            "name": "simultaneous",
            "count": 0
        }
    ]
}
export const init = {
    "sipTrunk": "333444777",
    "didNumber": [
        "12120000006"
    ],
    "displayNumber": "",
    "emailAddress": "",
    "timezone": "Europe/Prague",
    "country": "",
    "address": "",
    "city": "some city",
    "postalCode": "",
    "state": "",
    "office": "Pepkor Group",
    "servicePassword": "JFx5c3"
}
export const data = {
    "didNumbers": [
        {
            "area_name": "New York",
            "is_provisioned": 0,
            "description": "",
            "pricing": {
                "customer_costs": {
                    "activation": 14.3740117866897,
                    "periodic": 14.3740117866897,
                    "iso_4217": "ZAR",
                    "free_of_charge": "N"
                }
            },
            "i_master_account": 102535,
            "did_number": "12120000006",
            "country_name": "United States of America",
            "i_account": 102783,
            "master_account_id": "333444777",
            "i_did_number": 985378,
            "i_customer": 7031
        },
    ],
    "accountId": 102535,
    "subdivisionsList": [
        {
            "i_country_subdivision": 0,
            "iso_3166_1_a2": "",
            "iso_3166_2": "",
            "name": ""
        }
    ],
    "passwordConfig": {
        "OnlineHelpURL": null,
        "PasswordMinLength": 6,
        "SelectBoxMaxItems": "50",
        "ForceTooManyCustomersMode": 0,
        "PasswordComplexityRules": [
            "cr",
            "na"
        ],
        "AllowExternalBilling": 0
    },
    "passwordLength": 6,
    "countriesList": [
        {
            "name": "Afghanistan",
            "iso_3166_1_a2": "AF"
        }
    ],
    "timeZonesList": [
        {
            "time_zone_name": "Africa/Abidjan",
            "i_time_zone": 97
        },
    ]
}