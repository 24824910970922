import { makeStyles } from '@material-ui/styles';
import * as Yup from 'yup';
import i18n from '../../../services/i18n';

export const useStyles = makeStyles(() => ({
    DISAPassword: {
        '& .MuiFormControl-root': {
            width: 365,
        },
    },
    switch: {
      marginLeft:0
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 16px 0px 16px',
        maxWidth: 876,
        minHeight: 94,

        '& > :first-child': {
            marginRight: 23,
            marginBottom: 0,
            '& .MuiInputBase-adornedStart': {
                flexDirection: 'row-reverse',
            },
        },
        '& .MuiInputBase-adornedStart': {
            flexDirection: 'row-reverse',
        },
    },

    collapsedContainer: {
        minHeight: 56,
        height: 56,
    },

    row: {
        marginBottom: '25px',
        marginLeft:30
    },
}));

export type DisaFormType = {
    DISAPassword: string;
    DISASwitcher: boolean;
};

export const disaFormDefaultValues = {
    DISAPassword: '',
    DISASwitcher: false,
};

const requiredFieldError = i18n.t('errors:common.emptyInput');

export const disaFormValidationSchema = Yup.object().shape({
    DISAPassword: Yup.number().when('DISASwitcher', {
        is: true,
        then: Yup.number().required(requiredFieldError),
    }),
});
