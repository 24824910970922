import {useSelector} from 'react-redux';
import {ReduxState} from '../store/types';
import {useMemo} from 'react';
import {DefaultAnsweringActionFlag, ServiceFeatureName,} from '../store/types/ServiceFeature';
import {EmailOption, VoicemailFileFormat, VoicemailGreeting,} from '../store/types/Voicemail';
import {getServiceFeatureLockedStatus, getServiceFeatureValue,} from '../utils/extensions/getServiceValue';
import {
    SipTrunkExtensionCallSettingsFormType
} from "../components/Forms/SipTrunks/SipContact/SipContactForm.utils";
import {TransportProtocol} from "../store/types/AccountFollowMeSettings";

export const useEditSipTrunkCallSettingsFormData = () => {
    const permittedSipProxies = useSelector<ReduxState, string[] | undefined>(
        (state) => state.extensions.permittedSipProxies,
    );

    const extension = useSelector(
        (state: ReduxState) => state.sipTrunks.sipTrunkDetails?.sipTrunk,
    );


    const dynamicList = useSelector(
        (state: ReduxState) => state.sipTrunks.dynamicList,
    );

    const {
        voicemailSettings,
        serviceFeatures,
        mohDetails,
        callBarringRules,
        greetingsFilesNames,
    } = useSelector((state: ReduxState) => state.extensions);

    const initFormData: SipTrunkExtensionCallSettingsFormType = useMemo(() => {
        const sipContactStatus = getServiceFeatureValue(ServiceFeatureName.SipStaticContact, serviceFeatures) == "Y";

        const sipHostValue = getServiceFeatureValue(
            ServiceFeatureName.SipStaticContact,
            serviceFeatures,
            'host',
        );
        const sipUserValue = getServiceFeatureValue(
            ServiceFeatureName.SipStaticContact,
            serviceFeatures,
            'user',
        );

        const staticAddressSelected = sipContactStatus && sipHostValue !== null;
        const dynamicAddressSelected = sipContactStatus && sipHostValue == null;
        const dynamicAddressEntity = dynamicAddressSelected && sipUserValue && dynamicList?.length ? dynamicList.find(item => item.name === sipUserValue) : undefined;

        return {
            defaultAnsweringMode:
                getServiceFeatureValue(
                    ServiceFeatureName.DefaultAction,
                    serviceFeatures,
                ) || DefaultAnsweringActionFlag.RingForwardVoicemail,
            defaultAnsweringTimeout:
                getServiceFeatureValue(
                    ServiceFeatureName.DefaultAction,
                    serviceFeatures,
                    'timeout',
                ) || 0,
            onHoldMusicStatus: mohDetails?.isEnabled || false,
            onHoldMusicName:
                mohDetails?.items?.find(
                    (v) => v.i_moh === mohDetails?.selectedValueId,
                )?.name ?? '',
            extToExtCallDisctintiveRingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.DistinctiveRingVPN,
                    serviceFeatures,
                ) === 'Y',
            extToExtCallDisctintiveRingBlocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.DistinctiveRingVPN,
                serviceFeatures,
            ),
            callWaitingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallWaiting,
                    serviceFeatures,
                ) === 'Y',
            callWaitingBlocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.CallWaiting,
                serviceFeatures,
            ),
            pinProtectionStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.VoiceAuthentication,
                    serviceFeatures,
                ) === 'Y',
            pinProtectionBlocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.VoiceAuthentication,
                serviceFeatures,
            ),
            serviceUnblockCode: getServiceFeatureValue(
                ServiceFeatureName.VoiceAuthentication,
                serviceFeatures,
                'pin'
            ) || '',

            //Unified messaging
            unifiedMessagingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.UnifiedMessaging,
                    serviceFeatures,
                ) === 'Y',
            unifiedMessagingBlocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.UnifiedMessaging,
                serviceFeatures,
            ),
            autoPlayStatus: voicemailSettings?.auto_play === 'yes',
            announceDateStatus: voicemailSettings?.announce_dt === 'yes',
            requirePinStatus: voicemailSettings?.password_ask === 'yes',
            pin: voicemailSettings?.password || '',
            unifiedMessagingEmailAddress: voicemailSettings?.ext_email || '',
            emailOption:
                voicemailSettings?.ext_email_action || EmailOption.None,
            fileFormat:
                voicemailSettings?.ext_email_vm_fmt || VoicemailFileFormat.WAV,
            greetingType:
                voicemailSettings?.greetings || VoicemailGreeting.Standard,
            greetingFileName:
                greetingsFilesNames?.[
                voicemailSettings?.greetings || VoicemailGreeting.Standard
                    ] || '',
            greetingFile: null,

            //Call barrings
            callBarringStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallBarring,
                    serviceFeatures,
                ) === 'Y',
            callBarringLocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.CallBarring,
                serviceFeatures,
            ),
            callBarringItems: callBarringRules || [],
            individualRules: false,

            //Call recordings
            callRecordingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                ) === 'Y',
            callRecordingBlocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.CallRecording,
                serviceFeatures,
            ),
            outgoingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'call_recording_out',
                ) === '1',
            incomingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'call_recording_in',
                ) === '2',
            redirectedStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'call_recording_redirect',
                ) === '4',
            playCallRecordingAnnouncementToAllStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'audible_notification',
                ) === 'Y',
            sendTheCallRecordingViaEmailStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'mail_recording',
                ) === 'Y',
            allowToStartStopRecordingManually:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'on_demand',
                ) === 'Y',
            sipContactStatus: sipContactStatus,
            sipContactLocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.SipStaticContact,
                serviceFeatures,
            ),
            staticAddress: staticAddressSelected,
            dynamicAddress: dynamicAddressSelected,
            host: staticAddressSelected ? getServiceFeatureValue(
                ServiceFeatureName.SipStaticContact,
                serviceFeatures,
                'host',
            ) : '',
            port: staticAddressSelected ? getServiceFeatureValue(
                ServiceFeatureName.SipStaticContact,
                serviceFeatures,
                'port',
            ) : undefined,
            sipCldNumber: staticAddressSelected ? getServiceFeatureValue(
                ServiceFeatureName.SipStaticContact,
                serviceFeatures,
                'user',
            ) : '',
            transportProtocol: staticAddressSelected ? getServiceFeatureValue(
                ServiceFeatureName.SipStaticContact,
                serviceFeatures,
                'use_tcp',
            ) == "Y" ? TransportProtocol.TCP : TransportProtocol.UDP : '',
            dynamicAddressEntity: dynamicAddressEntity,
            
            //Prefered IVR Language
            preferedIvrLanguageStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.PreferedIvrLanguage,
                    serviceFeatures,
                ) === 'Y',
            preferedIvrLanguageBlocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.PreferedIvrLanguage,
                serviceFeatures,
            ),
            preferedIvrLanguageCode:
                getServiceFeatureValue(
                    ServiceFeatureName.PreferedIvrLanguage,
                    serviceFeatures,
                    'iso_639_1',
                ),
        }
            ;
    }, [
        permittedSipProxies,
        serviceFeatures,
        mohDetails,
        greetingsFilesNames,
        voicemailSettings,
        dynamicList
    ]);

    return {
        initFormData,
        data: {
            email: extension?.account_info?.email,
            dynamicList,
            extension,
            mohItems:
                mohDetails?.items.map((v) => ({
                    status: '',
                    id: v.i_moh || 0,
                    name: v.name,
                })) || [],
        },
    };
};
