import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../../store';
import {ReduxState} from '../../../store/types';
import {useTranslation} from 'react-i18next';
import CallHistoryDialog from '../../../components/Extensions/CallHistoryDialog';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../../components/AlertDialog/DialogContainer';
import CreateNewExtensionDialog from '../../../components/Extensions/CreateNewExtensionDialog';
import {
    PageChangeEvent,
    PaginationMode,
} from '../../../components/DataGrid/types';
import DataGrid from '../../../components/DataGrid/DataGrid';

import {generateColumns, useStyles} from './ExtensionList.utils';
import Header from '../../../components/ListViewHeader/Header';
import usePageTitle from '../../../hooks/usePageTitle';
import SwitchMode from '../../../components/SwitchMode/SwitchMode';
import FiltersComponent from './FiltersComponent';
import {ExtensionListFilters} from './FiltersComponent.utils';
import {ExtensionsListItem} from '../../../store/reducers/extensions/extensions/reducer';
import PermissionProvider from '../../../components/PermissionProvider/PermissionProvider';
import {Permission, PermissionType} from '../../../store/types/Permission';
import PermissionPlaceholder from "../../../components/PermissionProvider/PermissionPlaceholder";
import {MenuItem} from "@material-ui/core";
import classNames from "classnames";
import {usePermissions} from "../../../hooks/usePermissions";
import MoreMenu from "../../../components/MoreMenu/MoreMenu";
import UploadExtensionFileDialog from "./UploadExtensionFileDialog";
import {ListFileFormat} from "../../../store/types/Devices";
import {showErrorToast} from "../../../utils/showErrorToast";

export const ExtensionsList = () => {
    usePageTitle();

    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const addPermission = usePermissions(...Permission.CloudPBX.Extensions.AddNewExtension.value);
    const downloadPermission = usePermissions(...Permission.CloudPBX.Extensions.DownloadExtensions.value);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [forceFirstPage, setForceFirstPage] = useState(false);
    const [forceSearch, setForceSearch] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ExtensionsListItem | null>(
        null,
    );
    const [callHistoryModalConfig, setCallHistoryModalConfig] = useState<
        { accountId: number } | undefined
    >();

    const [isUploadingModal, setIsUploadingModal] = useState<boolean>(false);
    const [isExtensionListDownloading, setIsExtensionListDownloading] = useState<boolean>(false);

    const [
        createNewExtensionModalVisible,
        setCreateNewExtensionModalVisible,
    ] = useState(false);

    const {extensionList, isLoading} = useSelector(
        (state: ReduxState) => state.extensions,
    );

    const [removeModal, setRemoveModal] = useState<{
        isOpen: boolean;
        name?: string;
        id?: string;
        i_customer?: number;
    }>();

    const hideMoreOptionsButton = (addPermission === PermissionType.Hidden
        && downloadPermission === PermissionType.Hidden);

    const fetchData = (
        params?: { pageSize: number; page: number },
        filters?: ExtensionListFilters,
        withReset?: boolean,
    ) => {
        dispatch(
            actions.getExtensionsList.request({
                nameFilter: filters?.nameFilter,
                extensionFilter: filters?.extensionFilter,
                didNumberFilter: filters?.didNumberFilter,
                emailFilter: filters?.emailFilter,
                statusFilter: filters?.statusFilter,
                limit: params?.pageSize || 10,
                offset: params ? params?.page * params?.pageSize : 0,
                withReset,
            }),
        );

        setForceSearch(false);
    };

    const handleForceFirstPage = () => {
        setForceFirstPage(true);
    };

    const refreshStatus = (i_account: number) => {
        dispatch(
            actions.refreshExtensionStatus.request({
                i_account,
            }),
        );
    };

    const showCallHistory = (item: ExtensionsListItem) => {
        setCallHistoryModalConfig({
            accountId: Number(item?.account_id) || item.i_account,
        });
        setSelectedItem(item);
    };

    const showRemoveExtensionModal = (id: string, i_customer: number) => {
        setRemoveModal({
            isOpen: true,
            id,
            name: id,
            i_customer: i_customer,
        });
    };

    const deleteExtension = () => {
        if (removeModal?.id && removeModal?.i_customer) {
            dispatch(
                actions.deleteCustomerExtension.request({
                    id: removeModal.id,
                    i_customer: removeModal.i_customer,
                }),
            );
            setRemoveModal({...removeModal, isOpen: false});
            setTimeout(() => {
                setForceSearch(true);
            }, 1000);
        }
    };

    useEffect(() => {
        fetchData(undefined, undefined, true);
    }, []);

    const showCreateNewExtensionModal = () =>
        setCreateNewExtensionModalVisible(true);

    const handlePageChange = (param: PageChangeEvent) => {
        setForceFirstPage(false);
        setPage(param.page);
    };

    const handlePageSizeChange = (param: PageChangeEvent) => {
        setForceFirstPage(false);
        setPageSize(param.pageSize);
        setPage(0);
    };

    const handleHideDeleteModal = () =>
        setRemoveModal({
            ...removeModal,
            isOpen: false,
        });

    const handleChangeVisibilityCreateNewModal = () =>
        setCreateNewExtensionModalVisible(!createNewExtensionModalVisible);

    const handleHideShowHistory = () => setCallHistoryModalConfig(undefined);

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                refreshStatus,
                showCallHistory,
                showRemoveExtensionModal,
                classes,
            ),
        [],
    );

    const switchMode = useMemo(
        () => <SwitchMode actionAfterSave={() => setForceSearch(true)}/>,
        [],
    );


    useEffect(() => {
        if (isExtensionListDownloading) {
            showErrorToast(t<string>('screens:devices.downloadWillStartShortly'));
            dispatch(
                actions.getExtensionListCsvFile.request({
                    file_format: ListFileFormat.CSV,
                    fileName: 'cust_ext_list',
                    callback: () => setIsExtensionListDownloading(false)
                }),
            );
        }
    }, [isExtensionListDownloading]);


    const handleDownloadCsv = () => {
        setIsExtensionListDownloading(true);
    };



    const handleImportFromFile = () => {
        setIsUploadingModal(true);
    };

    return (
        <PermissionPlaceholder permission={Permission.CloudPBX.Extensions.value}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <div className={classes.content}>
                        <div className={classes.header}>
                            <PermissionProvider
                                permission={
                                    Permission.CloudPBX.Extensions.AddNewExtension
                                        .value
                                }
                            >
                                <Header
                                    title={t('screens:extensions.extensionList')}
                                    buttonText={t('common:addNewButton')}
                                    totalNumber={extensionList.total || 0}
                                    ofText={t('common:registered')}
                                    ofNumber={extensionList.registeredTotal || 0}
                                    dataQa={'extension-list-view-header'}
                                    buttonOnClick={showCreateNewExtensionModal}
                                    plusIcon={true}
                                >

                                    <MoreMenu hideMoreOptionsButton={hideMoreOptionsButton}>

                                        <MenuItem key="import-from-file" onClick={handleImportFromFile}
                                                  className={classNames(classes.dropdownMenuItem,
                                                      addPermission === PermissionType.Hidden && classes.hidden
                                                  )}
                                                  disabled={addPermission === PermissionType.ReadOnly}
                                        >
                                            {t('screens:extensions.importFromFile')}
                                        </MenuItem>
                                        <MenuItem key="upload-csv" onClick={handleDownloadCsv}
                                                  className={classNames(classes.dropdownMenuItem,
                                                      downloadPermission === PermissionType.Hidden && classes.hidden
                                                  )}
                                                  disabled={downloadPermission === PermissionType.ReadOnly}
                                        >
                                            {t('screens:extensions.downloadExtensionListCsv')}
                                        </MenuItem>

                                    </MoreMenu>

                                </Header>
                            </PermissionProvider>

                            <PermissionProvider
                                permission={
                                    Permission.CloudPBX.Extensions.SwitchNode.value
                                }
                            >
                                <div className={classes.switchMode}>
                                    {switchMode}
                                </div>
                            </PermissionProvider>
                        </div>

                        <FiltersComponent
                            page={page}
                            pageSize={pageSize}
                            handleSearch={fetchData}
                            handleForceFirstPage={handleForceFirstPage}
                            triggerSearch={forceSearch}
                        />

                        <DataGrid
                            columns={columns}
                            data={extensionList?.items}
                            rowCount={extensionList.total}
                            loading={isLoading}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            paginationMode={PaginationMode.Server}
                            centeredRows
                            initialPageSize={10}
                            forceFirstPage={forceFirstPage}
                        />
                    </div>

                    <CallHistoryDialog
                        isOpen={!!callHistoryModalConfig}
                        toggleVisibility={handleHideShowHistory}
                        accountId={callHistoryModalConfig?.accountId}
                        config={selectedItem}
                        downloadRecordingPermission={
                            Permission.CloudPBX.Extensions.ViewCallHistory
                                .DownloadCallRecording.value
                        }
                    />

                    <AlertDialog
                        isOpen={!!removeModal?.isOpen}
                        dataQa={'delete-extension-modal'}
                        header={t('screens:extensions.deleteModalExtension')}
                        contentClass="medium-width-modal-delete"
                        hideHeader={true}
                        description={t(
                            'screens:extensions.deleteExtensionDescription',
                            {name: removeModal?.name},
                        )}
                        dialogActionsButtons={[
                            <DialogButton
                                key="cancel"
                                label={t('common:cancel')}
                                onClick={handleHideDeleteModal}
                            />,
                            <DialogButton
                                key="delete"
                                label={t('common:delete')}
                                className={classes.primaryModalButton}
                                onClick={deleteExtension}
                            />,
                        ]}
                    />

                    <CreateNewExtensionDialog
                        isOpen={createNewExtensionModalVisible}
                        toggleVisibility={handleChangeVisibilityCreateNewModal}
                    />

                    <UploadExtensionFileDialog
                        isModalOpen={isUploadingModal}
                        setIsModalOpen={(v) => {
                            setIsUploadingModal(v);
                        }}
                        afterUpload={fetchData}
                    />

                </div>
            </div>
        </PermissionPlaceholder>

    );
};
