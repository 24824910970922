import React from 'react';
import Radio from '@material-ui/core/Radio';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import {Colors} from '../../styles/Colors';
import {FormControlLabel} from '@material-ui/core';
import {usePermissionContext} from '../../hooks/usePermissions';
import {PermissionType} from '../../store/types/Permission';

type RadioProps = {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    value?: unknown;
    disabled?: boolean;
    dataQa: string;
    label?: string;
    name?: string;
    className?: string;
};

const useStyles = makeStyles(() => ({
    checked: {
        '&$checked': {
            color: Colors.Secondary1,
        },
    },
    disabled: {
        '&$checked': {
            color: Colors.Gray,
        },

        '&.Mui-checked': {
            color: `${Colors.Secondary14}!important`,
        },
    },
    radio: {
        '&.Mui-checked': {
            color: Colors.Secondary1,
        },
    },
}));

const CustomizedRadio: React.FC<RadioProps> = ({
    onChange,
    checked,
    value,
    disabled,
    dataQa,
    label = '',
    name,
    className,
}) => {
    const classes = useStyles();
    const permission = usePermissionContext();

    disabled = disabled == true || permission !== PermissionType.Visible;

    return (
        <FormControlLabel
            control={
                <Radio
                    className={classNames(classes.radio, {
                        [classes.checked]: checked,
                        [classes.disabled]: disabled,
                    })}
                    onChange={onChange}
                    checked={checked}
                    value={value}
                    disabled={disabled}
                    color="primary"
                    data-qa={dataQa}
                    name={name}
                />
            }
            label={label}
            className={className}
        />
    );
};

export default CustomizedRadio;
