import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';
import * as Yup from 'yup';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        marginBottom: 10,
        maxWidth: 964,
        display: 'flex',
        flexDirection: 'column',
        '& > :first-child': {
            marginRight: 90,
            '& .makeStyles-inputs-40': {
                marginBottom: 0,
            },

            '& div': {
                '& div': {
                    height: 'unset',
                    marginBottom: 0,
                },
            },
        },
        '& .MuiGrid-root': {
            maxWidth: '63% !important',
        },
    },

    selectedMohContainer: {
        marginBottom: 10,
        marginTop: 15,
        marginLeft: 38,
    },

    switcherContainer: {
        maxWidth:'fit-content !important',
        marginLeft:-10
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        padding: '0px 16px 0px 10px',
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        alignItems: 'center',

        '& .makeStyles-inputs-41': {
            marginBottom: 0,
        },

        '& .MuiTypography-body1': {
            color: `${Colors.Gray7} !important`,
        },
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Gray7,
    },
    boldHeader: {
        fontWeight: 700,
    },
    checkbox: {
        marginLeft: 48,

        '& .MuiTypography-body1': {
            fontWeight: 'bold',
            color: Colors.Text,
            fontSize: 14,
        },
    },
    individualRules: {
        '& .MuiTypography-root': {
            fontWeight: 'normal',
            fontSize: '16px !important',
        },
    },
    itemContainer: {
        padding: '0 !important',
        display: 'flex',
    },
    itemsElementsContainer: {
        paddingTop: 28,
        paddingLeft: 9,
    },
    noData: {
        color: Colors.LightGraySecondary2,
        display: 'flex',
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 15,
        paddingLeft: 12,
    },
    headerClass: {
        display: 'flex',
        maxWidth: 986,
        paddingLeft: '0 !important',
        marginTop: 24,
        color: Colors.Text,
        fontSize: 16,

        '& span': {
            paddingLeft: 12,
            fontWeight: 500,
        },

        '& .MuiFormControlLabel-root': {
            '& span': {
                fontSize: 16,
            },
        },
    },
    switcher: {
        marginBottom: '0 !important',
        height: 'unset !important',
        maxWidth: 'fit-content',
        marginLeft: -3,
    },
    firstContainer: {
        paddingTop: 0,
    },
}));

export type MusicRingingFormType = {
    onHoldMusicSwitcher: boolean;
    extToExtSwitcher: boolean;
    selectedMohFile: string;
};

export const MusicRingingFormValidationSchema = Yup.object().shape({
    callBarringStatus: Yup.boolean().required(),
    callBarringItems: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required(),
                status: Yup.boolean().notRequired(),
            }),
        )
        .notRequired(),
});

export const musicRingingFormDefaultValues: MusicRingingFormType = {
    onHoldMusicSwitcher: false,
    extToExtSwitcher: false,
    selectedMohFile: '',
};

export type MusicRingingFormProps = {};
