import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        background: Colors.SmokeBackground,

        '& .MuiButton-label': {
            color: Colors.White,
        },
    },
    scrollable: {
        overflowY: 'auto',
        padding: '50px 90px 120px 90px',
    },

    outsideContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100vh',
        width: 824,
        overflowY: 'hidden',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 14,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
        },
    },
    header: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        alignItems: 'center',
        maxWidth: 'calc(100vw - 420px)',
        lineHeight: '35px',
        minWidth: 200,
        '& label': {
            marginRight: 16,
            fontWeight: 300,
            fontSize: 32,
        },
        '& p': {
            fontWeight: 500,
            fontSize: 32,
            color: Colors.Text,
        },
    },
    formContainer: {
        '& > :first-child': {
            flex: 'unset',
            height: '100vh'
        }
    }
}));
