import AuthWrapper from '../../components/AuthWraper/AuthWrapper';
import React, {useEffect, useState} from 'react';
import {ApiChangePasswordPayload} from '../../store/services/auth-api';
import * as actions from '../../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {APIErrorInterface, ReduxState} from '../../store/types';
import {useTranslation} from 'react-i18next';
import usePageTitle from '../../hooks/usePageTitle';
import ChangePasswordExpirationForm from "../../components/Forms/LoginForm/ChangePasswordExpirationForm";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";
import history from "../../history";
import {Routes} from "../../routes/routes";
import {formatMsg} from "../../utils/formatMsg";
import ExtendedMessage from "./ExtendenMessage";
import { AxiosError } from 'axios';
import { PASSWORD_CHANGE_MISSING_OTP_ERROR } from '../../store/sagas/auth/saga';
import Validate2FADialog from '../SignIn/Validate2FADialog';
import { Validate2FADialogProps } from '../SignIn/Validate2FADialog.utils';
import { Enable2FADialogProps } from '../SignIn/Enable2FADialog.utils';
import Enable2FADialog from '../SignIn/Enable2FADialog';
import { CHANGE_PASSWORD_INVALID_ONE_TIME_PASSWORD } from '../../store/reducers/auth/reducer';

const useStyles = makeStyles(() => ({
    description: {
        fontSize: 16,
        color: Colors.Gray5,

        '& p': {
            marginTop: 0
        }
    }
}));

const multipleError = 'The password must contain at least one symbol from the following groups:'

const ChangePassword = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyles();
    usePageTitle();

    const [customMessage, setCustomMessage] = useState<string | React.ReactNode | undefined>(undefined);
    const [show2FAValidateDialog, setShow2FAValidateDialog] = useState<Validate2FADialogProps>({
        isOpen: false
    });
    const [show2FADialog, setShow2FADialog] = useState<Enable2FADialogProps>({
        isOpen: false
    });

    const {login, oldPassword, errors, inProgress, email, mfaConfig, waiting2FA, inProgressOfChangingPassword} = useSelector(
        (state: ReduxState) => state.auth,
    );

    useEffect(() => {
        if(login && !mfaConfig && !waiting2FA) {
        } else if (mfaConfig) {
            setShow2FADialog({
                isOpen: true,
                mfaConfig: mfaConfig
            });
        } else if (waiting2FA) {
            setShow2FAValidateDialog({
                isOpen: true,
                sessionConfig: waiting2FA,
            });
        }
    }, [login, mfaConfig, waiting2FA]);

    const dispatchChangePasswordAction = (
        payload: ApiChangePasswordPayload,
    ) => {
        dispatch(actions.changeExpiredPassword.request({
            ...payload,
            onSuccess: () =>
            {
                const sessionId = localStorage.getItem('session_id');
                if(sessionId) {
                    history.push(Routes.Default);
                } else {
                    history.push(Routes.SignIn);
                }
            }
        }));
    };

    useEffect(() => {
        if (errors) {
            //@ts-ignore
            const error = (errors as AxiosError)?.response?.data as APIErrorInterface
            if(error?.faultcode === PASSWORD_CHANGE_MISSING_OTP_ERROR ||
            errors?.faultcode === PASSWORD_CHANGE_MISSING_OTP_ERROR) {
                if(!show2FAValidateDialog.isOpen) {
                    setShow2FAValidateDialog({
                        isOpen: true,
                        customHandler: (one_time_password) => {
                            dispatchChangePasswordAction({
                                login: login || '',
                                password: oldPassword || '',
                                new_password: email || '',
                                one_time_password: one_time_password
                            });
                        }
                    });
                }
                return;
            }
            else if (errors?.faultcode === CHANGE_PASSWORD_INVALID_ONE_TIME_PASSWORD) {
                return;
            }
            else if (errors?.faultstring.includes(multipleError)) {
                const formatted = formatMsg(errors.faultstring, ':', '\n')
                setCustomMessage(<ExtendedMessage title={formatted.title} messages={formatted.errors}/>)
            } else {
                setCustomMessage(errors.faultstring)
            }
        }
    }, [errors, show2FAValidateDialog]);

    return (
        <AuthWrapper title={t('screens:signIn.passwordChangeTitle')} message={customMessage}>
            <div className={classes.description}>
                <p>{t('screens:signIn.changePasswordSubTitle')}</p>
            </div>
            <ChangePasswordExpirationForm
                onSubmitAction={dispatchChangePasswordAction}
                validateOnChange={false}
                login={login || ''}
                oldPassword={oldPassword || ''}
                apiErrorsExist={!!errors && errors?.faultcode !== PASSWORD_CHANGE_MISSING_OTP_ERROR}
                inProgress={inProgressOfChangingPassword || inProgress}
            />
            <Enable2FADialog {...show2FADialog} />
            <Validate2FADialog {...show2FAValidateDialog} />
        </AuthWrapper>
    );
};

export default ChangePassword;
