import {Grid} from "@material-ui/core";
import classNames from "classnames";
import SwitchWithLabel from "../../SwitchWithLabel/SwitchWithLabel";
import IconWithTooltip from "../../Tooltip/IconWithTooltip";
import React, {useCallback, useMemo} from "react";
import {RingGroupGeneralFormType} from "./generalFormUtils";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import {makeStyles} from "@material-ui/core/styles";
import CustomizedRadio from "../../Radio/Radio";
import {RingMembersWrapState} from "../../../store/types/RingGroup";
import MinSecRangePicker from "../../MinSecRangePicker/MinSecRangePicker";
import {formatTime} from "../../MinSecRangePicker/MinSecRangePicker.utils";
import CustomizedTooltip from "../../Tooltip/Tooltip";

const useStyles = makeStyles(() => ({
    itemsContainer: {
        margin: '22px 0px 0 0px',
        paddingBottom: 28,
        display: 'flex',
        padding: '0px 16px',
        flexDirection: 'column'
    },

    rowBox: {
        display: 'flex',
    },

    rowBox2: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 30
    },
    rowBoxContainer: {
        paddingLeft: 64,
        display: 'flex',
        flexDirection: "column"
    },

    callPickupContainer: {
        marginTop: 18,
        marginLeft: -3,
        paddingBottom: 0,
    },

    radioButton: {
        marginRight: 36,
        marginTop: 8,
        '& .MuiTypography-body1': {
            fontWeight: 400
        },
    },
}));

const WrapUpForm :React.VFC<{disabled?:boolean}>= ({disabled}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const {
        values,
        errors,
        initialValues,
        setFieldValue,
        setValues,
        setFieldError
    } = useFormikContext<RingGroupGeneralFormType>();

    const wrapUpState = useMemo(
        () =>
            Object.values(RingMembersWrapState).map((v) => ({
                name: t(`enums:RingMembersWrapState.${v}`),
                value: v,
            })),
        [],
    );

    const onChangeWrapUpState = useCallback(
        (v: { name: string; value: RingMembersWrapState }) => {
            setFieldValue('hunt_while_wrapping_up', v.value);
        },
        [setFieldValue],
    );

    const updateWrapTime = useCallback(
        (v: any) => {
            setFieldValue('call_wrap_up_time', v);
            if (errors.call_wrap_up_time_format || errors.call_wrap_up_time) {
                setFieldError('call_wrap_up_time_format', undefined);
                setFieldError('call_wrap_up_time', undefined);
            }
        },
        [values.call_wrap_up_time],
    );


    const updateExtTime = useCallback(
        (v: any) => {
            setFieldValue('wrap_up_extend_time', v)
            if (errors.wrap_up_extend_time || errors.wrap_up_extend_time_format) {
                setFieldError('wrap_up_extend_time', undefined);
                setFieldError('wrap_up_extend_time_format', undefined);
            }
        },
        [values.wrap_up_extend_time],
    );

    return (
        <Grid
            item
            className={classNames(
                classes.itemsContainer,
                classes.callPickupContainer,
            )}
        >
            <CustomizedTooltip
                title={t('tooltips:billing.disabledFeature')}
                copy={false}
                disableHoverListener={!values.activity_monitoring_lock}
            >
                <SwitchWithLabel
                    label={t('screens:ringGroups.callWrapUp')}
                    setValue={(field, value) => {
                        setValues({
                            ...values,
                            activity_monitoring: value,
                            call_wrap_up_time: value ? initialValues.call_wrap_up_time ? initialValues.call_wrap_up_time : 0 : undefined,
                            wrap_up_extend_time: value ? initialValues.wrap_up_extend_time ? initialValues.wrap_up_extend_time : 0 : undefined,
                            hunt_while_wrapping_up: initialValues.hunt_while_wrapping_up
                        })
                    }}
                    value={values.activity_monitoring || false}
                    field={'activity_monitoring'}
                    id={'activity_monitoring'}
                    icon={
                        <IconWithTooltip
                            dataQa="ring-group-activity-monitoring-tooltip"
                            tooltipText={t(
                                'tooltips:ringGroups.callWrapUp',
                            )}
                        />
                    }
                    disabled={values.activity_monitoring_lock}
                />
            </CustomizedTooltip>


            {values.activity_monitoring && (
                <div className={classes.rowBoxContainer}>
                    <div className={classes.rowBox}>
                        <MinSecRangePicker
                            label={t('screens:ringGroups.callWrapUpMinSec')}
                            defaultValue={formatTime(values.call_wrap_up_time || 0)}
                            required
                            onChange={updateWrapTime}
                            helperText={errors.call_wrap_up_time || errors.call_wrap_up_time_format}
                            setTextRepresentation={(value) => setFieldValue('call_wrap_up_time_format', value)}
                        />
                        <MinSecRangePicker
                            label={t('screens:ringGroups.callMaxExtWrapUpMinSec')}
                            required
                            defaultValue={formatTime(values.wrap_up_extend_time || 0)}
                            onChange={updateExtTime}
                            helperText={errors.wrap_up_extend_time || errors.wrap_up_extend_time_format}
                            setTextRepresentation={(value) => setFieldValue('wrap_up_extend_time_format', value)}

                        />
                    </div>
                    <div className={classes.rowBox2}>
                        <div>{t('screens:ringGroups.upState')}</div>
                        <div>
                            {wrapUpState.map((v) => (
                                <CustomizedRadio
                                    key={v.value}
                                    value={v.value}
                                    className={classes.radioButton}
                                    checked={values.hunt_while_wrapping_up === v.value || !values.hunt_while_wrapping_up && v.value == RingMembersWrapState.Always}
                                    label={v.name}
                                    dataQa={`wrapUpTypeRadio-${v.value}`}
                                    onChange={() => onChangeWrapUpState(v)}
                                />
                            ))}
                        </div>
                    </div>
                </div>)
            }
        </Grid>
    )
}

export default WrapUpForm;