import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { callBarringFormDefaultValues } from '../components/Forms/CallSettings/CallBarring/utils';
import { serviceCodesFormDefaultValues } from '../components/Forms/CallSettings/utils/serviceCodesUtils';
import { ReduxState } from '../store/types';
import { EditCallSettingsForm } from '../views/CallSettings/CallSettingsDetails';
import { musicRingingFormDefaultValues } from '../components/Forms/CallSettings/MusicRinging/utils';
import { CallBarringRule } from '../store/types/CallBarring';
import {
    ServiceFeature,
    ServiceFeatureName,
} from '../store/types/ServiceFeature';
import { MohInfo } from '../store/types/AccountMoh';
import { defaultCallerFormDefaultValues } from '../components/Forms/CallSettings/GeneralForm/DefaultCallerForm';
import { CustomerDIDNumberType } from '../store/types/CustomerDIDNumber';
import { CustomerInfoDetails } from '../store/types/CustomerInfo';
import { GetCallProcessingPolicyListEntity } from '../store/actions/extensions/payloads';
import { callScreeningFormDefaultValues } from '../components/Forms/CallSettings/CallScreening/PoliciesForm.utils';

const defaultValues = {
    ...serviceCodesFormDefaultValues,
    ...callBarringFormDefaultValues,
    ...musicRingingFormDefaultValues,
    ...defaultCallerFormDefaultValues,
    ...callScreeningFormDefaultValues
};

export const useEditCallSettingsFormData = () => {
    const onHoldMusicStatus = useSelector<ReduxState, boolean>(
        (state) => state.callSettings.onHoldMusicStatus || false,
    );
    const extToExtStatus = useSelector<ReduxState, boolean>(
        (state) => state.callSettings.extToExtStatus || false,
    );

    const { dialingRules, customerServiceFeatures, mohDetails } = useSelector(
        (state: ReduxState) => state.callSettings,
    );

    const callBarringStatus = useSelector<ReduxState, boolean>(
        (state) =>
            state.callSettings.callBarring?.flag_value === 'Y' &&
            state.callSettings.callBarring.effective_flag_value === 'Y',
    );
    const individualStatusValue = useSelector<ReduxState, boolean>(
        (state) =>
            state.callSettings.callBarring?.flag_value === '~' &&
            state.callSettings.callBarring.effective_flag_value === '',
    );

    const callBarringItems = useSelector<ReduxState, CallBarringRule[]>(
        (state) => state.callSettings.callBarringItems || [],
    );

    const customerInfo = useSelector<
        ReduxState,
        CustomerInfoDetails | undefined
    >((state) => state.callSettings.customerInfo || undefined);

    const numbers = useSelector<ReduxState, CustomerDIDNumberType[]>(
        (state) => state.didNumbers.numbers || [],
    );

    const policies = useSelector<ReduxState, GetCallProcessingPolicyListEntity[] | undefined>((state) => 
        state.extensions.callProcessingPolicyList);
    
    const initialValues = useMemo((): EditCallSettingsForm => {
        if (!dialingRules) {
            return defaultValues;
        }

        const callParkingFeature = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.CallParking,
        ) as ServiceFeature;

        const pagingFeature = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.Paging,
        ) as ServiceFeature;

        const groupPickingFeature = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.GroupPicking,
        ) as ServiceFeature;

        const callSupervisionFeature = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.CallSupervision,
        ) as ServiceFeature;

        const voiceDialingFeature = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.VoiceDialing,
        ) as ServiceFeature;

        const musicOnHold = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.MusicOnHold,
        ) as ServiceFeature;

        const selectedMohFile = mohDetails?.items.find(
            (e: MohInfo) =>
                e.i_moh?.toString() === musicOnHold?.attributes[0].values[0],
        );

        const defaultCompanyNumber =
            customerInfo?.service_features
                ?.find((service) => service.name == 'cli')
                ?.attributes.find((attr) => attr.name == 'centrex')
                ?.values[0] || '';

        const callParking = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.CallParking,
        ) as ServiceFeature;

        let automaticRetrievalRingbackTone = 
            callParking?.attributes?.find(e => e.name === 'ringback_tone')?.values?.[0];
            
        const automaticRetrievalTimeout = 
            callParking?.attributes?.find(e => e.name === 'retrieval_timeout')?.values?.[0];

        if(automaticRetrievalTimeout == 0) {
            automaticRetrievalRingbackTone = undefined;
        }

        return {
            selectedRuleId:
                voiceDialingFeature?.attributes.find(
                    (v) => v.name === 'i_dial_rule',
                )?.values[0] || 0,
            rules: dialingRules || [],
            callParkingStatus: callParkingFeature?.effective_flag_value === 'Y',
            pagingIntercomStatus: pagingFeature?.effective_flag_value === 'Y',
            groupPickupStatus:
                groupPickingFeature?.effective_flag_value === 'Y',
            callBarringStatus: callBarringStatus || individualStatusValue,
            callBarringItems: callBarringItems,
            callBarringLocked: false,
            individualRules: individualStatusValue,
            onHoldMusicSwitcher: onHoldMusicStatus,
            extToExtSwitcher: extToExtStatus,
            selectedMohFile: onHoldMusicStatus
                ? selectedMohFile?.name || ' '
                : '',
            callSupervisionStatus:
                callSupervisionFeature?.effective_flag_value === 'Y',
            defaultCompanyNumber: defaultCompanyNumber,
            policies: policies || [],
            automatic_retrieval: automaticRetrievalRingbackTone
                ? (
                    automaticRetrievalRingbackTone === '0'
                    ? '1'
                    : '2'
                )
                : '0',
            retrieve_after: automaticRetrievalRingbackTone
                ? parseInt(automaticRetrievalTimeout || '120')
                : 120
        };
    }, [
        onHoldMusicStatus,
        extToExtStatus,
        onHoldMusicStatus,
        extToExtStatus,
        dialingRules,
        customerServiceFeatures,
        callBarringItems,
        individualStatusValue,
        numbers,
        customerInfo,
    ]);

    return {
        initialValues,
        data: { dialingRules, numbers },
    };
};
