import {
    ConfigData,
    PasswordComplexityRules,
} from '../store/types/ConfigData';

export type PasswordConfig = {
    lowers?: boolean;
    uppers?: boolean;
    numbers?: boolean;
    symbols?: boolean;
    length: number;
};

export const getConfigFromPasswordRulesOrDefault = (configData?: ConfigData, useWebPasswordLength?: boolean) =>
    configData?.Web?.PasswordComplexityRules
        ? generatePasswordConfigBasedOnComplexityRules(
            configData?.Web?.PasswordComplexityRules,
            useWebPasswordLength
                ? configData?.Web?.PasswordMinLength
                : parseInt(configData.Accounts.CreditAccMinServicePasswdLength),
        )
        :
        getDefaultPasswordSetup();

export const generatePasswordConfigBasedOnComplexityRules = (
    rules: PasswordComplexityRules[],
    length: number,
) => {
    return {
        length,
        lowers: rules.some((r) =>
            [
                PasswordComplexityRules.LatinLettersCanBeUsed,
                PasswordComplexityRules.LatinLettersMustBeUsed,
                PasswordComplexityRules.LowercaseLettersCanBeUsed,
                PasswordComplexityRules.LowercaseLettersMustBeUsed,
            ].includes(r),
        ),
        uppers: rules.some((r) =>
            [
                PasswordComplexityRules.LatinLettersCanBeUsed,
                PasswordComplexityRules.LatinLettersMustBeUsed,
                PasswordComplexityRules.UppercaseLettersCanBeUsed,
                PasswordComplexityRules.UppercaseLettersMustBeUsed,
            ].includes(r),
        ),
        numbers: rules.some((r) =>
            [
                PasswordComplexityRules.DigitsCanBeUsed,
                PasswordComplexityRules.DigitsMustBeUsed,
            ].includes(r),
        ),
        symbols: rules.some((r) =>
            [
                PasswordComplexityRules.SpecialCharactersCanBeUsed,
                PasswordComplexityRules.SpecialCharactersMustBeUsed,
            ].includes(r),
        ),
    };
};

export const generatePasswordBasedOnComplexityRules = (
    rules: PasswordComplexityRules[],
    length: number,
) => {
    return generatePassword({
        length,
        lowers: rules.some((r) =>
            [
                PasswordComplexityRules.LatinLettersCanBeUsed,
                PasswordComplexityRules.LatinLettersMustBeUsed,
                PasswordComplexityRules.LowercaseLettersCanBeUsed,
                PasswordComplexityRules.LowercaseLettersMustBeUsed,
            ].includes(r),
        ),
        uppers: rules.some((r) =>
            [
                PasswordComplexityRules.LatinLettersCanBeUsed,
                PasswordComplexityRules.LatinLettersMustBeUsed,
                PasswordComplexityRules.UppercaseLettersCanBeUsed,
                PasswordComplexityRules.UppercaseLettersMustBeUsed,
            ].includes(r),
        ),
        numbers: rules.some((r) =>
            [
                PasswordComplexityRules.DigitsCanBeUsed,
                PasswordComplexityRules.DigitsMustBeUsed,
            ].includes(r),
        ),
        symbols: rules.some((r) =>
            [
                PasswordComplexityRules.SpecialCharactersCanBeUsed,
                PasswordComplexityRules.SpecialCharactersMustBeUsed,
            ].includes(r),
        ),
    });
};

export const getDefaultPasswordSetup = (passwordConfig?: any) => ({
    length: parseInt(passwordConfig || '6'),
    lowers: true,
    uppers: true,
    numbers: true,
});

export const generatePassword = (config: PasswordConfig) => {
    let generatedPassword = '';

    const randomFunc: { [key in string]: () => void } = {
        lowers: getRandomLower,
        uppers: getRandomUpper,
        numbers: getRandomNumber,
        symbols: getRandomSymbol,
    };

    const typesArr = Object.entries(config)
        .filter((v) => v[1] === true)
        .map((v) => v[0]);

    if (!typesArr.length) {
        return '';
    }

    for (let i = 0; i < config.length; i += typesArr.length) {
        typesArr.forEach((type) => {
            generatedPassword += randomFunc[type]();
        });
    }

    return [...generatedPassword.slice(0, config.length)]
        .sort(() => Math.random() - 0.5)
        .join('');
};

const getRandomLower = () => {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
};

const getRandomUpper = () => {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 65);
};

const getRandomNumber = () => {
    return +String.fromCharCode(Math.floor(Math.random() * 10) + 48);
};

const getRandomSymbol = () => {
    const symbols = '~!@#$%';
    return symbols[Math.floor(Math.random() * symbols.length)];
};
