import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth:1348
    },
    rightSection:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    }
}));

export interface SearchCallback {
    callback?: () => void
}