import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({

    minimalContainer :{
        display: 'flex',
        flexDirection: 'column',
        height:74,
        width:'fit-content'
    },

    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 61px)'
    },
    chartMainContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    chartContainer: {
        width: 110,
        position: 'relative',
    },
    minChartContainer: {
        width: 74,
        position: 'relative',
        height:'auto'
    },

    chart: {
        position: 'absolute',
        top: 0,
    },
    chartPlaceholder: {
        height: 70,
        borderRadius: '50%',
        border: `transparent solid 20px`,
    },
    minChartPlaceholder: {
        borderRadius: '50%',
        border: `transparent solid 5px`,
        height: 63,
        width: 63
    },
    chartEmptyBackground: {
        borderColor: Colors.BorderDark,
    },
    chartLoadingBackground: {
        borderColor: Colors.SmokeBackground,
    },
    sumValue: {
        width: 110,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 42,
    },

    smallSumValue:{
        width: '100%',
        height:'auto',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 20,
    },
    sumText: {
        fontSize: 22,
    },

    smallSumText: {
        fontSize: '20px',
        fontWeight:400
    },
    labelsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 8px 0 8px',
        height: '100%'
    },
    dotContainer: {
        height: 'calc(100% - 28px)',
        width: 16,
        position: 'relative',
        marginBottom: 28
    },
    dot: {
        width: 6,
        height: 6,
        borderRadius: 3,
        marginRight: 10,
        position: 'absolute',
        top: 'calc(50% - 3px)'
    },
    labelItemTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    labelTopTextContainer: {
        lineHeight: '16px',
        height: 'calc(100% - 28px)',
        display: 'flex',
        alignItems: 'center'
    },
    labelText: {
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
        lineHeight: '16px'
    },
    labelItemContainer: {
        display: 'flex',
        alignItems: 'end'
    },
    loadingLabel: {
        backgroundColor: Colors.White,
        width: 110,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 42,
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
    },
}));

export type DoughnutChartProps = {
    data: {
        label: string;
        color: string;
        value: number;
    }[];
    isLoading: boolean;
    accessDenied?:boolean;
    hideLegend?:boolean,
    cutout?:number,
    icon?:any,
    hideLoadingLabelOnLoading?: boolean;

    inLine?: boolean
};
