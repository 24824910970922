import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../../styles/Colors';
import {TFunction} from 'react-i18next';
import {CellProps, Column} from 'react-table';
import {AutoAttendantListItem} from '../../../store/types/AutoAttendant';
import history from '../../../history';
import {FetchMenuStatus} from '../../../store/reducers/autoAttendants/reducer';
import ExtensionDIDNumber from '../../../components/Extensions/ExtensionDIDNumber';
import EmptyRowContent from '../../../components/DataTable/EmptyRowContent';
import MenuCell from '../MenuCell';
import {ExtensionsListItem} from '../../../store/reducers/extensions/extensions/reducer';
import {ExtensionServices} from '../../../components/Extensions/ExtensionServices';
import {convertServiceFeaturesToIconsFeatures} from '../../../utils/extensions/convertServiceFeaturesToIconsFeatures';
import OverflowTooltip from '../../../components/OverflowTooltip/OverflowTooltip';
import {ReactComponent as CallHistoryIcon} from '../../../assets/call_history.svg';
import BarLoader from '../../../components/BarLoader/BarLoader';
import {getRoute, Routes} from '../../../routes/routes';
import {selectId} from '../../../utils/autoAttendants/selectId';
import CustomizedIconButton from '../../../components/IconButton/IconButton';
import DataItemActions from '../../../components/DataTable/DataItemActions';
import {Service} from '../../../components/Extensions/ServiceIcon';
import PermissionProvider from '../../../components/PermissionProvider/PermissionProvider';
import {Permission} from '../../../store/types/Permission';
import PermissionLinkCell from "../../../components/DataTable/PermissionLinkCell";

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        background: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: 'white',
        },
        flex: 1,

        '& .MuiDataGrid-cell': {
            alignItems: 'center',
            height: 64,
            color: Colors.Gray5,
        },
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '48px 96px',
        '& .MuiTableContainer-root': {
            width: 'unset !important'
        }
    },
    email: {
        fontSize: 14,
        opacity: 0.6,
    },
    actionsButtonsContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 160,
    },
    tableContainer: {
        maxWidth: 1040,
        overflowX: 'hidden',

        '& .MuiTableRow-head': {
            '& :nth-child(1)': {
                marginLeft: 16,
            },
            '& :last-child': {
                marginRight: 16,
            },
        },
    },
    table: {
        marginTop: 24,
        '& .MuiTableBody-root': {
            '& tr': {
                minHeight: 70,
                '& .MuiTableCell-root:first-child': {
                    marginLeft: 16,
                },
                '& .MuiTableCell-root:last-child': {
                    marginRight: 16,
                },
            },
        },
        '& .MuiTableHead-root': {
            height: 64,
            '& .MuiTableRow-root': {
                height: 64,
                '& th:last-child': {
                    display: 'flex',
                    justifyContent: 'center',
                },
            },
        },
        '& .MuiTableFooter-root': {
            '& .MuiTableRow-footer': {
                '& .MuiTableCell-footer': {
                    '& .MuiTablePagination-root': {
                        height: '64px !important',
                    },
                },
            },
        },
    },
    nameLink: {
        color: Colors.Secondary1,
        cursor: 'pointer',
    },
    deleteDialogButtons: {
        '& button': {
            minWidth: 90,
        },

        '& .MuiDialogContent-root': {
            paddingTop: 24,
            paddingBottom: 24,

            '& p': {
                marginBottom: 0,
            },
        },
        '& .MuiPaper-root': {
            width: 320,
            '& .MuiDialogContent-root': {
                minWidth: 'unset !important',
                background: Colors.White,
            },
        },
    },
    header: {
        marginBottom: 48,
    },
}));

export const generateColumns = (
    t: TFunction<string>,
    fetchMenu: (id_pin: string, i_account: number) => void,
    classes: ReturnType<typeof useStyles>,
    showCallHistory: (accountId: number, name?: string) => void,
    deleteExtension: (
        id: string,
        i_customer: number,
        i_c_ext?: number,
        name?: string,
    ) => void,
): Column<AutoAttendantListItem>[] => {
    return [
        {
            accessor: 'name',
            Header: t<string>('common:name'),
            width: 2.25,
            Cell: function Cell(params) {
                const id = selectId(params.row.original);
                const name =
                    params.row.original.name ??
                    t('screens:autoAttendants.unnamed');
                return (
                    <PermissionLinkCell
                        text={name || ''}
                        onClick={() => {
                            history.push(
                                getRoute(Routes.AutoAttendantsDetails, {
                                    id: id,
                                }),
                            );
                        }}
                        permissions={Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.value}
                    />
                );
            },
        },
        {
            accessor: 'numbers',
            width: 2.75,
            Header: t<string>('screens:didNumbers.didNumber'),
            Cell: function Cell(params) {
                const numbers = (
                    params.row.original.account_info?.alias_did_number_list ||
                    []
                ).map((v) => v.did_number);

                if (numbers.length > 0) {
                    return (
                        <ExtensionDIDNumber
                            showFooter={false}
                            extNumbers={numbers}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'menu',
            width: 2,
            Header: t<string>('screens:autoAttendants.menu'),
            Cell: function Cell(params) {
                const menuItems = params.row.original.menu?.items ?? [];

                if (
                    params.row.original.menu?.fetchMenu != FetchMenuStatus.Done
                ) {
                    if (params.row.original.menu?.fetchMenu == undefined) {
                        if (params.row.original.menu) {
                            params.row.original.menu.fetchMenu = FetchMenuStatus.InProgress;
                        } else {
                            params.row.original.menu = {
                                fetchMenu: FetchMenuStatus.InProgress,
                                items: []
                            };
                        }
                        fetchMenu(
                            params.row.original.id_pin,
                            params.row.original.i_account,
                        );
                    }

                    return (
                        <BarLoader
                            dataQa={
                                'fetch-did-numbers-loader-' +
                                params.row.original.id_pin
                            }
                        />
                    );
                }

                if (menuItems.length > 0) {
                    return (
                        <MenuCell
                            above={
                                params.state.pageSize >= 10
                                    ? params.row.index ===
                                    params.rows.length - 1 ||
                                    params.row.index ===
                                    params.rows.length - 2
                                    : false
                            }
                            menuItems={menuItems}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            Header: t<string>('screens:extensions.servicesAndEmail'),
            accessor: (row) => row?.account_info?.email,
            width: 2.5,
            id: '3',
            Cell: function Cell(
                params: React.PropsWithChildren<CellProps<ExtensionsListItem, string[] | undefined>>,
            ) {
                const email = params?.row.original.account_info?.email;

                return (
                    <div>
                        <ExtensionServices
                            services={convertServiceFeaturesToIconsFeatures(
                                params.row.original.serviceFeatures || [],
                            )}
                            forceTooltipDefinition={[
                                {
                                    service: Service.CallProcessing,
                                    text: t('screens:incoming.callScreening'),
                                },
                                {
                                    service:
                                    Service.CallProcessingPbxEnabledOther,
                                    text: 'Call processing',
                                },
                                {
                                    service: Service.CallProcessingPbxEnabled,
                                    text: 'Call processing',
                                },
                                {
                                    service: Service.CallProcessingOther,
                                    text: 'Call processing',
                                },
                            ]}
                        />
                        <OverflowTooltip
                            text={email}
                            tooltip={email}
                            classes={{text: classes.email}}
                        />
                    </div>
                );
            },
        },
        {
            Header: t<string>('common:actions'),
            accessor: 'i_account',
            width: 1,
            maxWidth: 160,
            minWidth: 160,
            Cell: function Cell(params) {
                const delButtonVisible = params.row.original.delete_enable;
                const id = selectId(params.row.original);

                return (
                    <div className={classes.actionsButtonsContainer}>
                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.AutoAttendants
                                    .ViewCallHistory.value
                            }
                            skipReadOnly
                        >
                            <CustomizedIconButton
                                onClick={() =>
                                    showCallHistory(
                                        params.row.original.i_account,
                                        params.row.original.name ??
                                        t('screens:autoAttendants.unnamed'),
                                    )
                                }
                                data-testid="view-call-history-list-item-button"
                                data-qa="view-call-history-list-item-button"
                                tooltipText={t(
                                    'tooltips:ringGroups.viewCallHistory',
                                )}
                            >
                                <CallHistoryIcon/>
                            </CustomizedIconButton>
                        </PermissionProvider>
                        <DataItemActions
                            onDelete={
                                delButtonVisible
                                    ? () =>
                                        deleteExtension(
                                            params.row.original
                                                ?.extension_id || '',
                                            params?.row.original.i_customer ||
                                            -1,
                                            params.row.original.i_c_ext,
                                            params.row.original.name?.toString() ??
                                            t(
                                                'screens:autoAttendants.unnamed',
                                            ),
                                        )
                                    : undefined
                            }
                            onEdit={() => {
                                history.push(
                                    getRoute(Routes.AutoAttendantsDetails, {
                                        id: id,
                                    }),
                                );
                            }}
                            editPermission={
                                Permission.CloudPBX.AutoAttendants
                                    .AutoAttendantDetails.value
                            }
                            deletePermission={
                                Permission.CloudPBX.AutoAttendants
                                    .DeleteAutoAttendant.value
                            }
                        />
                    </div>
                );
            },
        },
    ];
};
