import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomizedSwitch from '../Switch/Switch';
import {TwoFaSettingProps, useStyles} from './TwoFaSetting.utils';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../store';
import {ReduxState} from '../../store/types';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import CreationButton from '../CreationButton/CreationButton';
import {MfaStatus, SessionIdPayload} from '../../store/actions/payloads';
import {Disable2FADialogProps} from './Disable2FADialog.utils';
import Disable2FADialog from './Disable2FADialog';
import {Enable2FADialogProps} from '../../views/SignIn/Enable2FADialog.utils';
import Enable2FADialog from '../../views/SignIn/Enable2FADialog';
import Reset2FADialog from './Reset2FADialog';
import AlertDialog from '../AlertDialog/AlertDialog';
import {DialogButton} from '../AlertDialog/DialogContainer';
import {Reset2FADialogProps} from './Reset2FADialog.utils';
import toast from 'react-hot-toast';
import {usePermissionContext} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";

const TwoFaSetting: React.VFC<TwoFaSettingProps> = ({
    object,
    i_object,
    isMyOwn,
    login
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const permission = usePermissionContext();

    if (permission === PermissionType.Hidden) {
        return <div />;
    }

    const {session_id, access_token, mfaConfig, individualMfaConfigError, individualMfaConfig, 
            csrf_token, updateMfaConfigError, resetMfaConfigError, isVerifing} = useSelector((state: ReduxState) => state.auth);
    
    const [disable2faDialog, setDisable2faDialog] = useState<Disable2FADialogProps>({
        isOpen: false,
        object: object,
        i_object: i_object,
        mfaError: updateMfaConfigError
    });
    
    const [show2FADialog, setShow2FADialog] = useState<Enable2FADialogProps>({
        isOpen: isVerifing || false,
        mfaConfig: mfaConfig,
        showActionButtons: true,
    });

    const [reset2FADialog, setReset2FADialog] = useState<Reset2FADialogProps>({
        isOpen: false,
        object: object,
        i_object: i_object
    });
    
    const [disableAlert, setDisableAlert] = useState<{
        isOpen: boolean;
    }>({
        isOpen: false,
    });

    const [resetAlert, setResetAlert] = useState<{
        isOpen: boolean;
    }>({
        isOpen: false,
    });
    
    const [enableAlert, setEnableAlert] = useState<{
        isOpen: boolean;
    }>({
        isOpen: false,
    });

    useEffect(() => {
        if((!individualMfaConfig || individualMfaConfig.i_object !== i_object || individualMfaConfig.object !== object) 
            && i_object !== -1) {
            dispatch(actions.getMfaConfig.request({
                object,
                i_object
            }));
        }
    }, [object, i_object, individualMfaConfig]);

    useEffect(() => {
        if(mfaConfig) {
            setShow2FADialog({
                isOpen: true,
                mfaConfig: mfaConfig,
                showActionButtons: true,
                onClose: (success) => {
                    if(success) {
                        dispatch(actions.updateMfaUsed.request({
                            i_object: i_object,
                            object: object,
                            use_mfa: MfaStatus.Enabled,
                            onSuccess: () => {
                                setShow2FADialog({
                                    isOpen: false,
                                    mfaConfig: mfaConfig,
                                    showActionButtons: true,
                                });
                            }
                        }));
                    } else {
                        setShow2FADialog({
                            isOpen: false,
                            mfaConfig: mfaConfig,
                            showActionButtons: true,
                        });
                    }
                },
            });
        }
    }, [mfaConfig]);

    const onResetButtonClick = useCallback(() => {
        if(isMyOwn) {
            //5. Reset 2FA key for own user (Customer or Individual):
            setReset2FADialog({
                isOpen: true,
                object: object,
                i_object: i_object,
                mfaError: resetMfaConfigError,
                onClose: (success) => {
                    setReset2FADialog({
                        isOpen: false,
                        object: object,
                        i_object: i_object,
                    });
                    if(success) {
                        dispatch(actions.removeAuthDataAndReload({reload: true}));
                    }
                },
            });
        } else {
            //6. Reset 2FA key for another user (Individual):
            setResetAlert({
                isOpen: true
            });
        }
    }, [object, i_object, isMyOwn, resetMfaConfigError]);

    const featureEnabled = useMemo(() => {
        let mfaFlag = (individualMfaConfig?.mfa_used || MfaStatus.Disabled);
        if(mfaFlag === MfaStatus.Inherited) {
            mfaFlag = individualMfaConfig?.effective_mfa_used || individualMfaConfig?.mfa_used || MfaStatus.Disabled;
        }
        return mfaFlag === MfaStatus.Enabled || mfaFlag === MfaStatus.Inherited;
    }, [individualMfaConfig]);
    
    const featureConfigured = useMemo(() => {
        return (individualMfaConfig?.mfa_configured || 0) > 0;
    }, [individualMfaConfig]);
    
    const sessionInfo = useMemo(() => {
        return {
            session_id: session_id,
            access_token: access_token,
            csrf_token: csrf_token,
            mfa_enabled: -1,
            mfa_verified: 0
        } as SessionIdPayload;
    }, [session_id, access_token, csrf_token]);

    useEffect(() => {
        if(updateMfaConfigError) {
            setDisable2faDialog({
                isOpen: true,
                mfaError: updateMfaConfigError,
                object: object,
                i_object: i_object,
                onClose: () => {
                    setDisable2faDialog({
                        isOpen: false,
                        object: object,
                        i_object: i_object,
                        mfaError: undefined
                    });
                }
            })
        }
    }, [updateMfaConfigError, object, i_object]);

    
    useEffect(() => {
        if(resetMfaConfigError) {
            setReset2FADialog({
                isOpen: true,
                mfaError: resetMfaConfigError,
                object: object,
                i_object: i_object,
                onClose: (success) => {
                    setReset2FADialog({
                        isOpen: false,
                        object: object,
                        i_object: i_object,
                        mfaError: undefined
                    });
                    if(success && isMyOwn) {
                        dispatch(actions.removeAuthDataAndReload({reload: true}));
                    }
                }
            })
        }
    }, [resetMfaConfigError, object, i_object]);

    const enableWithoutConfigCall = useMemo(() => {
        return individualMfaConfig
            && individualMfaConfig.effective_mfa_used === MfaStatus.Disabled
            && individualMfaConfig.mfa_configured === 1
            && individualMfaConfig.mfa_used === MfaStatus.Inherited;
    }, [individualMfaConfig]);

    const onSwitchClick = useCallback(() => {
        const newStateBoolean = !featureEnabled;
        if(isMyOwn && !newStateBoolean) {
            //3. Disable 2FA for own user (Customer or Individual) with one_time_password: 
            setDisable2faDialog({
                isOpen: true,
                object: object,
                i_object: i_object,
                mfaError: updateMfaConfigError,
                onClose: () => {
                    setDisable2faDialog({
                        isOpen: false,
                        object: object,
                        i_object: i_object,
                        mfaError: undefined
                    });
                },
            });
        } else if(isMyOwn && newStateBoolean) {
            if(enableWithoutConfigCall) {
                // If "effective_mfa_used":"Disabled", "mfa_configured":1, "mfa_used":"Inherited", 
                //      and a user wants to enable 2FA, then the app should enable 2FA using AccessControl/set_mfa
                //      with "use_mfa":"Enabled" WITHOUT generating a new mfa config.
                // E.g., {"i_object":7721,"object":"customer","use_mfa":"Enabled"} (please mind object - customer or individual)
                dispatch(actions.updateMfaUsed.request({
                    i_object: i_object,
                    object: object,
                    use_mfa: MfaStatus.Enabled,
                    onSuccess: () => {
                        const message: string = t('screens:twoFa.successEnabledNotification');
                        toast(message);
                    }
                }));
            } else {
                //1. Enable 2FA for own user (Customer or Individual)
                dispatch(actions.generateMfaConfig.request(sessionInfo));
            }
        } else if(!isMyOwn && newStateBoolean) {
            //2. Enable 2FA for another user (Individual)
            setEnableAlert({
                isOpen: true
            });
        } else if(!isMyOwn && !newStateBoolean) {
            //4. Disable 2FA for another user (Individual) without OTP:
            setDisableAlert({
                isOpen: true
            });
        }
    }, [featureEnabled, isMyOwn, object, i_object, individualMfaConfig, updateMfaConfigError, enableWithoutConfigCall]);

    const handleDisableAction = useCallback(() => {
        dispatch(actions.updateMfaUsed.request({
            use_mfa: MfaStatus.Disabled,
            i_object: i_object,
            object: object,
            onSuccess: () => {
                setDisableAlert({
                    isOpen: false
                });
            }
        }));
    }, [i_object, object]);
    
    const handleResetAction = useCallback(() => {
        dispatch(actions.resetMfa.request({
            i_object: i_object,
            object: object,
            onSuccess: () => {
                setResetAlert({
                    isOpen: false
                });
            }
        }));
    }, [i_object, object]);

    const handleEnableAction = useCallback(() => {
        if(enableWithoutConfigCall) {
            // If "effective_mfa_used":"Disabled", "mfa_configured":1, "mfa_used":"Inherited", 
            //      and a user wants to enable 2FA, then the app should enable 2FA using AccessControl/set_mfa
            //      with "use_mfa":"Enabled" WITHOUT generating a new mfa config.
            // E.g., {"i_object":7721,"object":"customer","use_mfa":"Enabled"} (please mind object - customer or individual)
            dispatch(actions.updateMfaUsed.request({
                i_object: i_object,
                object: object,
                use_mfa: MfaStatus.Enabled,
                mfa_configured: 0,
                onSuccess: () => {
                    const message: string = t('screens:twoFa.successEnabledNotification');
                    toast(message);
                    
                    setEnableAlert({
                        isOpen: false
                    });
                }
            }));
        } else {
            //2. Enable 2FA for another user (Individual)
            dispatch(actions.updateMfaUsed.request({
                use_mfa: MfaStatus.Enabled,
                i_object: i_object,
                object: object,
                mfa_configured: 0,
                onSuccess: () => {
                    const message: string = t('screens:twoFa.successEnabledNotification');
                    toast(message);
                    
                    setEnableAlert({
                        isOpen: false
                    });
                }
            }));
        }
    }, [i_object, object, enableWithoutConfigCall]);

    if(individualMfaConfigError) {
        return <></>;
    }

    return (<div className={classes.mainContainer}>
        <div className={classes.containerLine}>
            <CustomizedSwitch
                checked={featureEnabled}
                onChange={onSwitchClick}
                skipPermission
                dataQa='twofa-switch'
                dataTestId='twofa-switch-id'
                disabled={permission == PermissionType.ReadOnly}
            />
            <div className={classes.switchLabel}>
                {t('screens:twoFa.twoFactorAuth2fa')}
            </div>
            <IconWithTooltip
                dataQa="2fa-tooltip"
                tooltipText={t(isMyOwn
                    ? 'screens:twoFa.addAnExtraLayer'
                    : 'screens:twoFa.addAnExtraLayerOtherUser',
                )}
                className={classes.tooltipIconContainer}
            />
        </div>
        {featureEnabled && (
            <div className={classes.containerLine}>
                <CreationButton
                    onClick={onResetButtonClick}
                    title={t('screens:twoFa.reset2FA')}
                    withPlus={false}
                    disabled={featureEnabled && !featureConfigured}
                    className={classes.resetButton}
                />
            </div>
        )}
        <Disable2FADialog {...disable2faDialog} />
        <Enable2FADialog {...show2FADialog} />
        <Reset2FADialog {...reset2FADialog} />
        <AlertDialog
            className={classes.disableAlert}
            contentClass={classes.contentDialog}
            isOpen={disableAlert.isOpen}
            dataQa={'disable-2fa-dialog'}
            hideHeader
            description={t('screens:twoFa.disable2FAForLogin', {
                login: login,
            })}
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={() => {
                        setDisableAlert({
                            isOpen: false
                        });
                    }}
                />,
                <DialogButton
                    key="delete"
                    label={t('screens:twoFa.disableButton')}
                        onClick={handleDisableAction}
                />,
            ]}
        />
        <AlertDialog
            className={classes.resetAlert}
            contentClass={classes.contentDialog}
            isOpen={resetAlert.isOpen}
            dataQa={'reset-2fa-dialog'}
            hideHeader
            description={t('screens:twoFa.reset2FAForAccount')}
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={() => {
                        setResetAlert({
                            isOpen: false
                        });
                    }}
                />,
                <DialogButton
                    key="delete"
                    label={t('screens:twoFa.resetButton')}
                        onClick={handleResetAction}
                />,
            ]}
        />
        <AlertDialog
            className={classes.enableAlert}
            contentClass={classes.contentDialog}
            isOpen={enableAlert.isOpen}
            dataQa={'enable-2fa-dialog'}
            hideHeader
            description={t('screens:twoFa.enable2FAForLogin', {
                login: login,
            })}
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={() => {
                        setEnableAlert({
                            isOpen: false
                        });
                    }}
                />,
                <DialogButton
                    key="delete"
                    label={t('screens:twoFa.enableButton')}
                        onClick={handleEnableAction}
                />,
            ]}
        />
    </div>);
};

export default TwoFaSetting;
