import classNames from 'classnames';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../../store';
import {SipCall, SipCallState, SipCallType} from '../../../store/actions/ringgroups/payloads';
import {ReduxState} from '../../../store/types';
import {
    ExtensionPresenceWidgetData,
    RefreshWidgetQueueItem,
    WidgetExtensionPresenseData,
    WidgetExtensionPresenseFilter,
    WidgetPresantationType
} from '../../../store/types/Wallboard';
import {Colors} from '../../../styles/Colors';
import {Doughnut} from 'react-chartjs-2';
import {useStyles} from './ExtensionPresenseWidget.utils';
import AnimatedNumberText from '../../../components/AnimatedNumber/AnimatedNumber';
import EmptyGridPlaceholder from '../../../components/DataGrid/EmptyGridPlaceholder';
import WidgetLegendItem, {ChartLegendItem} from './WidgetLegendItem';
import {ExtensionType} from '../../../store/types/Extension';
import {RingGroupType} from '../../../store/types/RingGroup';
import WidgetLoadingMask from '../WidgetLoadingMask';

export type ExtensionPresenseWidgetProps = {
    data: WidgetExtensionPresenseData,
    id: string,
    presentation: WidgetPresantationType,
    darkMode: boolean,
};

const ExtensionPresenseWidget: React.VFC<ExtensionPresenseWidgetProps> = ({
    data,
    id,
    presentation,
    darkMode
}) => {

    const classes = useStyles();
    const [mainContainerWidth, setMainContainerWidth] = useState(0);
    const [mainContainerHeight, setMainContainerHeight] = useState(0);

    const refreshWidgetsQueue: RefreshWidgetQueueItem[] = useSelector((state: ReduxState) => state.wallboard.refreshWidgetsQueue);

    const [extensionsList, setExtensionsList] = useState<ExtensionType[] | undefined>(undefined);
    const [ringGroupsList, setRingGroupsList] = useState<RingGroupType[] | undefined>(undefined);
    const [sipCallsList, setSipCallsList] = useState<SipCall[] | undefined>(undefined);

    const resizeObserver = new ResizeObserver(entries => {
        const w = entries && entries.length ? entries[0].target?.clientWidth ?? 0 : 0;
        const h = entries && entries.length ? entries[0].target?.clientHeight ?? 0 : 0;
        setMainContainerWidth(w);
        setMainContainerHeight(h);
    });
    
    const dispatch = useDispatch();
    const {t} = useTranslation();
    
    function refresh(data: ExtensionPresenceWidgetData) {
        setExtensionsList(data.extensionsList);
        setRingGroupsList(data.ringGroupsList);
        setSipCallsList(data.sipCallsList);

        dispatch(actions.refreshWidgetCompleted.request(id));
    }

    const isLoadingChart = useMemo(() => !!(refreshWidgetsQueue?.find(e => e.id === id)), 
        [refreshWidgetsQueue, id]);

    useEffect(() => {
        const itm = refreshWidgetsQueue?.find(e => e.id === id);
        if(itm && itm.dataHasLoaded) {
            refresh(itm.data as ExtensionPresenceWidgetData);
        }
    }, [refreshWidgetsQueue]);

    const filteredExtensions = useMemo(() => {
        if(data.show === WidgetExtensionPresenseFilter.selected_extensions) {
            return extensionsList?.filter(e => !!data.extensions?.find(k => k === e.extension_id)) ?? [];
        }
        else if(data.show === WidgetExtensionPresenseFilter.selected_ring_groups) {
            const groups = ringGroupsList?.filter(e => data.ringGroup === e.id) ?? [];
            const extIds: string[] = [];
            for(const r of groups) {
                for(const e of r.assigned_extensions ?? []) {
                    extIds.push(e.id || '');
                }
            }
            return extensionsList?.filter(e => !!extIds?.find(k => k === e.extension_id)) ?? [];
        } else {
            return extensionsList ?? [];
        }
    }, [data, extensionsList, ringGroupsList]);
    
    const filteredExtensionsInCall = useMemo(() => {
        const extIds: string[] = [];
        for(const e of sipCallsList ?? []) {
            if(e.callee?.extension_id && filteredExtensions.findIndex(c => c.extension_id === e.callee.extension_id) !== -1) {
                extIds.push(e.callee.extension_id);
            }
            if((e.caller?.extension_id && filteredExtensions.findIndex(c => c.extension_id === e.caller.extension_id) !== -1)) {
                extIds.push(e.caller.extension_id);
            }
        }
        return filteredExtensions?.filter(e => !!extIds?.find(k => k === e.extension_id)) ?? [];
    }, [filteredExtensions, sipCallsList]);
    
    const filteredExtensionsNotInCall = useMemo(() => {
        return filteredExtensions?.filter(e => !filteredExtensionsInCall?.find(k => k.extension_id === e.extension_id)) ?? [];
    }, [filteredExtensionsInCall, filteredExtensions]);

    /** Render statuses:
    - Unregistered: sip_status:0 - no extensions/ring groups there
    */
    const unregisteredExtensions = useMemo(() => {
        return filteredExtensionsNotInCall?.filter(e => e.sip_status === 0);
    }, [filteredExtensionsNotInCall]);

    const unregisteredCount = useMemo(() => {
        return unregisteredExtensions?.length || 0;
    }, [unregisteredExtensions]);
    
    //extensions in call
    const callsFilteredByExtensions = useMemo(() => {
        return sipCallsList
            ?.filter(e => 
                
                (e.callee?.extension_id && filteredExtensionsInCall.findIndex(c => c.extension_id === e.callee.extension_id) !== -1) || // && c.sip_status !== 0
                (e.caller?.extension_id && filteredExtensionsInCall.findIndex(c => c.extension_id === e.caller.extension_id) !== -1) // && c.sip_status !== 0
            )
            || [];
    }, [filteredExtensionsInCall, sipCallsList]);

    /**
     * state:connected
state:trying and type:outgoing
state:ringing and type:outgoing
state:queued and type:outgoing
state:dequeued and type:outgoing
state:dequeued and type:incoming
     */

    const onCallExtensions = useMemo(() => {
        const calls = callsFilteredByExtensions
            ?.filter(e => (e.state === SipCallState.Connected)
                || (e.state === SipCallState.Trying && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Ringing && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Queued && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Dequeued)
            ) ?? [];

        const extIds: string[] = [];
        for(const e of calls) {
            if(e.callee?.extension_id) {
                extIds.push(e.callee.extension_id);
            }
            if(e.caller?.extension_id) {
                extIds.push(e.caller.extension_id);
            }
        }
        return filteredExtensionsInCall.filter(e => !!extIds.find(d => d === e.extension_id)) ?? [];
    }, [callsFilteredByExtensions, filteredExtensionsInCall]);

    const onCall = useMemo(() => {
        return onCallExtensions?.length || 0;
    }, [onCallExtensions]);

    /**
        state:holding
        state:held
     */
    const onHold = useMemo(() => {
        const calls = callsFilteredByExtensions
            ?.filter(e => e.state === SipCallState.Holding || e.state === SipCallState.Held);
        const ids = calls
            ?.map(e => (e.tracking_id ?? e.callee?.account_id ?? e.callee?.account_id ?? ''))
            || [];
        return [...new Set(ids)].length;
    }, [callsFilteredByExtensions]);
    
    const onHoldExtensions = useMemo(() => {
        const calls = callsFilteredByExtensions
            ?.filter(e => e.state === SipCallState.Holding || e.state === SipCallState.Held);

        const extIds: string[] = [];
        for(const e of calls) {
            if(e.callee?.extension_id) {
                extIds.push(e.callee.extension_id);
            }
            if(e.caller?.extension_id) {
                extIds.push(e.caller.extension_id);
            }
        }

        return filteredExtensionsInCall
            ?.filter(e => !!extIds.find(d => d === e.extension_id))
            ?.filter(e => !(!!onCallExtensions.find(d => d === e)))
            ?? [];
    }, [callsFilteredByExtensions, filteredExtensionsInCall, onCallExtensions]);
    
    /** Ringing:
        state:ringing and type:incoming
        state:trying and type:incoming
     */
    const ringingExtensions = useMemo(() => {
        const calls = callsFilteredByExtensions
            ?.filter(e => (e.state === SipCallState.Ringing && e.type === SipCallType.Incoming)
                || (e.state === SipCallState.Trying && e.type === SipCallType.Incoming)
            );

        const extIds: string[] = [];
        for(const e of calls) {
            if(e.callee?.extension_id) {
                extIds.push(e.callee.extension_id);
            }
            if(e.caller?.extension_id) {
                extIds.push(e.caller.extension_id);
            }
        }

        return filteredExtensionsInCall
            ?.filter(e => !!extIds.find(d => d === e.extension_id))
            ?.filter(e => !(!!onCallExtensions.find(d => d === e)))
            ?.filter(e => !(!!onHoldExtensions.find(d => d === e)))
            ?? [];
    }, [callsFilteredByExtensions, filteredExtensionsInCall, onCallExtensions, onHoldExtensions]);
    
    const ringing = useMemo(() => {
        return ringingExtensions?.length || 0;
    }, [ringingExtensions]);

    const availableExtensions = useMemo(() => {
        if(data.show === WidgetExtensionPresenseFilter.selected_ring_groups) {
            const groups = ringGroupsList?.filter(e => data.ringGroup === e.id) ?? [];
            const extIds: string[] = [];
            for(const r of groups) {
                for(const e of r.assigned_extensions ?? []) {
                    if(e.hunt_active === "Y") {
                        extIds.push(e.id || '');
                    }
                }
            }
            const exts = filteredExtensionsNotInCall?.filter(e => !!extIds?.find(k => k === e.extension_id)) ?? [];
            return exts?.filter(e => e.sip_status === 1);
        }
        return [];
    }, [data, ringGroupsList, filteredExtensionsNotInCall]);

    const available = useMemo(() => {
        return availableExtensions?.length || 0;
    }, [availableExtensions]);

    const loggedOutExtensions = useMemo(() => {
        if(data.show === WidgetExtensionPresenseFilter.selected_ring_groups) {
            const groups = ringGroupsList?.filter(e => data.ringGroup === e.id) ?? [];
            const extIds: string[] = [];
            for(const r of groups) {
                for(const e of r.assigned_extensions ?? []) {
                    if(e.hunt_active === "N") {
                        extIds.push(e.id || '');
                    }
                }
            }
            const exts = filteredExtensionsNotInCall?.filter(e => !!extIds?.find(k => k === e.extension_id)) ?? [];
            return exts?.filter(e => e.sip_status === 1) || [];
        }
        return [];
    }, [data, ringGroupsList, filteredExtensionsNotInCall]);

    const loggedOut = useMemo(() => {
        return loggedOutExtensions?.length || 0;
    }, [loggedOutExtensions]);

    const idleExtensions = useMemo(() => {
        return filteredExtensionsNotInCall
            ?.filter(e => e.sip_status === 1)
            ?.filter(e => !(!!availableExtensions.find(d => d === e)))
            ?.filter(e => !(!!loggedOutExtensions.find(d => d === e)))
            ?? [];
    }, [filteredExtensionsNotInCall, availableExtensions, loggedOutExtensions]);

    const idleCount = useMemo(() => {
        return idleExtensions?.length || 0;
    }, [idleExtensions]);

    const chartData = useMemo(() => {
        const retArray = [
            {
                label: t('screens:wallboard.onCallCount'),
                value: onCall,
                color: Colors.Error,
                testId: 'on-call-label'
            } as ChartLegendItem,
            {
                label: t('screens:wallboard.onHoldCount'),
                value: onHold,
                color: Colors.Error,
                testId: 'on-hold-label'
            } as ChartLegendItem,
            {
                label: t('screens:wallboard.ringingCount'),
                value: ringing,
                color: Colors.Support,
                testId: 'ringing-label'
            } as ChartLegendItem
        ];

        if(data.show === WidgetExtensionPresenseFilter.selected_ring_groups) {
            retArray.push(  
            {
                label: t('screens:wallboard.loggedOutCounts'),
                value: loggedOut,
                color: Colors.Primary,
                testId: 'logged-out-label'
            } as ChartLegendItem);
        } else {
            retArray.push(  
            {
                label: t('screens:wallboard.idleCounts'),
                value: idleCount,
                color: Colors.Support,
                testId: 'idle-label'
            } as ChartLegendItem);
        }

        retArray.push(    
            {
                label: t('screens:wallboard.unregisteredCount'),
                value: unregisteredCount,
                color: Colors.Primary,
                testId: 'unregistered-label'
            } as ChartLegendItem
        );
        
        if(data.show === WidgetExtensionPresenseFilter.selected_ring_groups) {
            retArray.push(  
            {
                label: t('screens:wallboard.availableCount'),
                value: available,
                color: Colors.Support,
            } as ChartLegendItem);
        }

        return retArray;
    }, [onCall, onHold, ringing, idleCount, unregisteredCount, loggedOut, available, data]);
    
    const dataset = useMemo(() => {
        return {
            labels: chartData.map((v) => v.label),
            datasets: [
                {
                    data: chartData.map((v) => v.value),
                    backgroundColor: chartData.map((v) => v.color),
                    borderColor: chartData.map(() => darkMode ? '#201F2B' : Colors.White),
                },
            ],
        };
    }, [chartData, darkMode]);

    const minimalHeight = useMemo(() => {
        const w = 293;
        const h = 57;
        const c = filteredExtensions?.length ?? 0;
        if(mainContainerWidth > 0 && mainContainerHeight > 0 && c > 0) {
            let columns = Math.trunc(mainContainerWidth / w);
            if(columns < 1) columns = 1;

            let rows = Math.trunc(c / columns);
            if((c % columns) !== 0) {
                rows = rows + 1;
            }

            return rows * h;
        }
        return 0;
    }, [mainContainerWidth, mainContainerHeight, filteredExtensions]);

    const dataSum = dataset.datasets[0].data.reduce((v, w) => v + w, 0);

    const isEmptyData = useMemo(() => {
        return !isLoadingChart && filteredExtensions != undefined && filteredExtensions.length === 0;
    }, [filteredExtensions, isLoadingChart]);

    const calcExtNameFontSize = (name: string) => {
        if(name.length <= 6) return 20 - ((name.length - 1) * 2);
        if(name.length <= 10) return 9 - (name.length - 7);
        return '6px';
    };
    
    return (<>
        <div id={'extension_presense_widget_' + id} className={classNames(classes.widgetMainContainer, presentation === WidgetPresantationType.list && classes.overflowStyleForListWidgetType)}>
            {presentation === WidgetPresantationType.doughnut && (
                <div className={classes.mainContainer}>
                    <div className={classes.chartMainContainer}>
                        <div className={classNames(classes.chartContainer,
                                isEmptyData && classes.chartContainerEmptyData)}>
                            <div
                                className={classNames(
                                    classes.chartPlaceholder,
                                    isLoadingChart && classes.chartLoadingBackground,
                                    isEmptyData && classes.chartEmptyBackground,
                                )}
                            />
                            {!isLoadingChart && (
                                <Doughnut
                                    className={classes.chart}
                                    data={dataset}
                                    options={{
                                        responsive: true,
                                        //@ts-ignore
                                        cutout: '72%',
                                        //@ts-ignore
                                        borderWidth: 1,
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                            tooltip: {
                                                enabled: true,
                                                backgroundColor: 'rgba(51, 51, 51, 1)'
                                            }
                                        },
                                        maintainAspectRatio: false
                                    }}
                                />
                            )}

                            {!isLoadingChart && (
                                <div className={classes.sumValue}>
                                    <AnimatedNumberText
                                        className={classNames(classes.sumText, darkMode && classes.sumTextDarkMode)}
                                        hideThousands
                                        value={dataSum}
                                        dataTestId="doughnut-chart-sum-value"
                                        dataQa="doughnut-chart-sum-value"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.legendContainer}>
                        <div className={classes.legendRow}>
                            <div className={classes.legendSubRow}>
                                <div>
                                    <WidgetLegendItem itm={chartData[0]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                                </div>
                                <div>
                                    <WidgetLegendItem itm={chartData[1]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                                </div>
                                {chartData.length === 6 &&
                                    <div>
                                        <WidgetLegendItem itm={chartData[4]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                                    </div>
                                }
                            </div>
                            <div className={classNames(classes.legendSubRow, classes.secondColumn)}>
                                <div>
                                    <WidgetLegendItem itm={chartData[2]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                                </div>
                                <div>
                                    <WidgetLegendItem itm={chartData[3]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                                </div>
                                {chartData.length === 6 &&
                                    <div>
                                        <WidgetLegendItem itm={chartData[5]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                                    </div>
                                }
                            </div>
                        </div>
                        {chartData.length === 5 &&
                            <div className={classNames(classes.legendRow, classes.lastItem)}>
                                <WidgetLegendItem itm={chartData[4]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                            </div>
                        }
                    </div>
                </div>
            )}
            {presentation === WidgetPresantationType.list && (
                <div className={classNames(classes.listViewMainContainer, isEmptyData && classes.listViewMainContainerNoData)} ref={(el) => {
                    if(el) {
                        resizeObserver.observe(el);
                    }
                }} style={{
                    height: 'max(100%, ' + minimalHeight + 'px)'
                }}>
                    {
                        filteredExtensions.map(e => 
                        <div className={classes.listViewItem} key={"widget_" + id + "_extension_" + e.extension_id}>
                            <div className={classNames(classes.listViewIconContainer,
                                (unregisteredExtensions.findIndex(c => c === e) !== -1) && classes.listViewIconContainerUnregistered,
                                (unregisteredExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewIconContainerUnregisteredDarkMode,
                                (idleExtensions.findIndex(c => c === e) !== -1) && classes.listViewIconContaineridle,
                                (idleExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewIconContaineridleDarkMode,
                                (onCallExtensions.findIndex(c => c === e) !== -1) && classes.listViewIconContainerOnCall,
                                (onCallExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewIconContainerOnCallDarkMode,
                                (onHoldExtensions.findIndex(c => c === e) !== -1) && classes.listViewIconContainerOnHold,
                                (onHoldExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewIconContainerOnHoldDarkMode,
                                (ringingExtensions.findIndex(c => c === e) !== -1) && classes.listViewIconContainerRinging,
                                (ringingExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewIconContainerRingingDarkMode,
                                (availableExtensions.findIndex(c => c === e) !== -1) && classes.listViewIconContainerAvailable,
                                (availableExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewIconContainerAvailableDarkMode,
                                (loggedOutExtensions.findIndex(c => c === e) !== -1) && classes.listViewIconContainerLoggedOut,
                                (loggedOutExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewIconContainerLoggedOutDarkMode,
                                
                                )}>
                                <div className={classNames(classes.listViewIconText, darkMode && classes.listViewIconTextDarkMode)} style={{
                                    fontSize: calcExtNameFontSize(e.extension_id)
                                }}>
                                    {e.extension_id}
                                </div>
                            </div>
                            <div className={classes.listViewRighContainer}>
                                <div className={classNames(classes.listViewRighHeader,
                                    (unregisteredExtensions.findIndex(c => c === e) !== -1) && classes.listViewRighHeaderUnregistered,
                                    (unregisteredExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewRighHeaderUnregisteredDarkMode,
                                    (idleExtensions.findIndex(c => c === e) !== -1) && classes.listViewRighHeaderIdle,
                                    (idleExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewRighHeaderIdleDarkMode,
                                    (onCallExtensions.findIndex(c => c === e) !== -1) && classes.listViewRighHeaderOnCall, 
                                    (onCallExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewRighHeaderOnCallDarkMode,
                                    (onHoldExtensions.findIndex(c => c === e) !== -1) && classes.listViewRighHeaderOnHold,
                                    (onHoldExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewRighHeaderOnHoldDarkMode,
                                    (ringingExtensions.findIndex(c => c === e) !== -1) && classes.listViewRighHeaderRinging,
                                    (ringingExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewRighHeaderRingingDarkMode,
                                    (availableExtensions.findIndex(c => c === e) !== -1) && classes.listViewRighHeaderAvailable,
                                    (availableExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewRighHeaderAvailableDarkMode,
                                    (loggedOutExtensions.findIndex(c => c === e) !== -1) && classes.listViewRighHeaderLoggedOut,
                                    (loggedOutExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.listViewRighHeaderLoggedOutDarkMode
                                    )}>
                                    {e.extension_name}
                                </div>
                                <div className={classes.listViewRighDetails}>
                                    <div className={classNames(classes.statusDetails,
                                    (unregisteredExtensions.findIndex(c => c === e) !== -1) && classes.statusDetailsUnregistered,
                                    (unregisteredExtensions.findIndex(c => c === e) !== -1) && darkMode && classes.statusDetailsUnregisteredDarkMode,
                                    (idleExtensions.findIndex(c => c === e) !== -1) && classes.statusDetailsIdle,
                                    (onCallExtensions.findIndex(c => c === e) !== -1) && classes.statusDetailsOnCall,
                                    (onHoldExtensions.findIndex(c => c === e) !== -1) && classes.statusDetailsOnHold,
                                    (ringingExtensions.findIndex(c => c === e) !== -1) && classes.statusDetailsRinging,
                                    (availableExtensions.findIndex(c => c === e) !== -1) && classes.statusDetailsAvailable,
                                    (loggedOutExtensions.findIndex(c => c === e) !== -1) && classes.statusDetailsLoggedOut,
                                    )}>
                                        {(unregisteredExtensions.findIndex(c => c === e) !== -1) && t('screens:wallboard.unregisteredCount')}
                                        {(idleExtensions.findIndex(c => c === e) !== -1) && t('screens:wallboard.idleCounts')}
                                        {(onCallExtensions.findIndex(c => c === e) !== -1) && t('screens:wallboard.onCallCount')}
                                        {(onHoldExtensions.findIndex(c => c === e) !== -1) && t('screens:wallboard.onHoldCount')}
                                        {(ringingExtensions.findIndex(c => c === e) !== -1) && t('screens:wallboard.ringingCount')}
                                        {(availableExtensions.findIndex(c => c === e) !== -1) && t('screens:wallboard.availableCount')}
                                        {(loggedOutExtensions.findIndex(c => c === e) !== -1) && t('screens:wallboard.loggedOutCounts')}
                                    </div>
                                    <div className={classNames(classes.callDuration, darkMode && classes.callDurationDarkMode)}>
                                        {
                                            sipCallsList
                                                ?.find(d => (d.callee?.extension_id && d.callee.extension_id === e.extension_id)
                                                    || (d.caller?.extension_id && d.caller.extension_id === e.extension_id)
                                                )
                                                ?.durationFormatSec
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    }
                    {
                        <EmptyGridPlaceholder
                            showNoDataImg
                            message={t('common:noData')}
                            isVisible={isEmptyData}
                        />
                    }
                </div>
            )}
        </div>
        <WidgetLoadingMask isLoadingChart={isLoadingChart} darkMode={darkMode} />
    </>);
};

export default ExtensionPresenseWidget;