import React from "react";
import {CallsItem} from "../../store/reducers/extensions/extensions/reducer";
import {makeStyles, Tooltip} from '@material-ui/core';
import {Colors} from "../../styles/Colors";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

interface CallsCaounterCellInterface {
    calls: CallsItem[]
}

const useStyles = makeStyles(() => ({
    callsCell: {
        color: Colors.Gray,
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px'
    },
    badgeContainerGlobal: {
        background: Colors.Text,
        padding: 0,
        borderRadius: 4
    },
    badgeContainer: {
        padding: '18px 24px',
        background: Colors.Text,
        whiteSpace: 'nowrap',
        borderRadius: 4
    },
    badgeTitle: {
        fontWeight: 700,
        fontSize: 14,
        color: '#D8D8D8'
    },
    headerSeparator: {
        marginTop: 7,
        width: '100%',
        borderTop: '1px ' + Colors.WhiteWithOpacity + ' solid',
        height: '1px !important'
    },
    badgeRow: {
        width: '100%',
        height: 24,
        justifyContent: 'space-between',
        display: 'flex'
    },
    leftColumn: {
        fontWeight: 400,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'left',
        width: 'fit-content',
        display: "block",

        '&:firstLetter':{
            textTransform: "uppercase"
        }
    },
    rightColumn: {
        fontWeight: 400,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'right',
        width: 'fit-content'
    },
    firstTableRow: {
        paddingTop: 7,
        marginTop: 7
    }
}));

const CallsCounterCell: React.VFC<CallsCaounterCellInterface> = ({calls}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const data = calls.filter(item => item.value != null);
    const rowData = data.length > 2 ? data.slice(0, 2) : data;

    const itemsCount = rowData.length;

    const rowsData = rowData.map((item, index) => <span
        key={item.name}>{item.value} {item.translation} {index + 1 < itemsCount ? ' / ' : ''}</span>)

    const tooltipRows = data.map((item, index) => {
        const translation = t('screens:sipTrunks.'+item.name+'Tooltip');

        return (
            <div key={item.name} className={classNames(classes.badgeRow, index == 0 ? classes.firstTableRow : '')}>
                <div className={classes.leftColumn}>{translation}</div>
                <div className={classes.rightColumn}>{item.value}</div>
            </div>
        )
    })

    const tooltipContent = data.length > 2 ? (
        <div className={classes.badgeContainer}>
            <div className={classes.badgeRow}>
                <span className={classes.badgeTitle}>{t('screens:sipTrunks.maxNumberCalls')}</span>
            </div>
            <div className={classNames(classes.badgeRow, classes.headerSeparator)}/>
            {tooltipRows}
        </div>
    ) : '';

    return (
        <Tooltip
            title={tooltipContent}
            classes={{
                tooltip: classes.badgeContainerGlobal
            }}
        >
            <div className={classes.callsCell}>
                {rowsData}
            </div>
        </Tooltip>
    )
}

export default CallsCounterCell;