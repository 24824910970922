import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContainer, {
    DialogButton,
} from '../../AlertDialog/DialogContainer';
import PaymentStatusIcon from '../PaymentStatusIcon/PaymentStatusIcon';
import {
    PaymentStatusDialogProps,
    useStyles,
} from './PaymentStatusDialog.utils';
import { ReactComponent as ReceiptContainer } from '../../../assets/payments/receipt_container.svg';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import { GlobalCurrencyInfo } from '../../../store/types/CustomerInfo';
import { CloudDownload } from '@material-ui/icons';
import { PaymentStatus } from '../../../store/types/Payment';
import classNames from 'classnames';
import PaymentInfoBox from '../PaymentInfoBox/PaymentInfoBox';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import { delay } from 'lodash';

const PaymentStatusDialog: React.VFC<PaymentStatusDialogProps> = ({
    isOpen,
    toggleVisibility,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;


    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const { paymentDetails } = useSelector(
        (state: ReduxState) => state.billing,
    );

    const savePdf = useCallback(async () => {
        const receipt = document.getElementById('receipt-to-print');

        if (receipt) {

            const svgs = receipt.querySelectorAll('img');
            const tempPDF = new jsPDF("portrait", "px", 'a4');
            for (const el of svgs) {
                const src = el.getAttribute('src');
                if(src && src.length && src.toLocaleLowerCase().endsWith('.svg')) {
                    const imgJPG = await htmlToImage.toPng(el);
                    await delay(() => {;}, 1000);
                    tempPDF.addImage(imgJPG, 'png', 0, 0, 100, 100);
                }
            };
            tempPDF.close();

            const dataUrl = await htmlToImage.toPng(receipt);
            const pdf = new jsPDF("portrait", "px", 'a4');

            pdf.addImage(dataUrl, 'png', isMobile ? 25 : 100 , 0, isMobile ? 400 : 0, isMobile ? 600 : 0);

            delay(() => {
                ;
            }, 3000);

            pdf.save(
                t('screens:billing.receiptFileName', {
                    value: paymentDetails?.timestamp.replace(' ', '_'),
                }),
            );
        }
    }, [paymentDetails]);

    if (!paymentDetails) {
        return null;
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="processing-dialog"
            header={t(`enums:paymentStatus.${paymentDetails.status}`)}
            className={classes.modalContainer}
            disableEnforceFocus
            dialogActionsButtons={[
                <DialogButton
                    key="close"
                    label={t('common:close')}
                    onClick={toggleVisibility}
                />,
            ]}
            dialogLeftSideActionsButtons={
                paymentDetails.status === PaymentStatus.Completed
                    ? [
                          <DialogButton
                              key="download-receipt"
                              label={t('screens:billing.downloadReceipt')}
                              icon={<CloudDownload />}
                              className={classes.downloadReceiptButton}
                              secondaryAccent
                              onClick={savePdf}
                          />,
                      ]
                    : undefined
            }
        >
            <div className={classes.statusIcon}>
                <PaymentStatusIcon
                    status={paymentDetails.status}
                    type="small"
                />
            </div>

            <ReceiptContainer />

            <div className={classes.receiptDataContainer}>
                <span className={classes.totalHeader}>
                    {t('screens:billing.paymentTotal')}
                </span>
                <span className={classes.totalText}>
                    <span
                        className={classNames(
                            classes.totalBoldText,
                            paymentDetails.status === PaymentStatus.Failed &&
                                classes.failedPaymentTotal,
                        )}
                    >
                        {paymentDetails.amount}
                    </span>{' '}
                    {currencyInfo?.iso_4217}
                </span>

                <PaymentInfoBox />

                {paymentDetails.status === PaymentStatus.Failed && (
                    <>
                        <span className={classes.errorHeader}>
                            {t('screens:billing.failedToReceivePayment')}
                        </span>
                        <span className={classes.errorText}>
                            {t(
                                'screens:billing.failedToReceivePaymentDescription',
                            )}
                        </span>
                    </>
                )}
            </div>
        </DialogContainer>
    );
};

export default PaymentStatusDialog;
