import React, {useEffect, useMemo, useState} from 'react';
import DialogContainer, {DialogButton, SpinnerVariant} from '../AlertDialog/DialogContainer';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Colors} from '../../styles/Colors';
import AssignDevice from '../Extensions/AssignDevice';
import {AssignedPortInfo, UAInfo, UAPortConfiguration} from '../../store/types/Devices';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../store';
import {ReduxState} from '../../store/types';

interface AssignDeviceProps {
    isOpen: boolean;
    i_c_ext: number;
    onClose?: () => void;
    id?: string;
    selectedDevice?: AssignedPortInfo;
    callBackOnSave?: () => void;
    i_account: number;
}

const useStyles = makeStyles(() => ({
    header: {
        '& h2': {
            fontSize: 18,
            fontWeight: 700,
        },
    },
    itemsContainer: {
        backgroundColor: Colors.SmokeBackground,
        padding: '47px 84px 23px 84px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            width: '100%',
            marginBottom: 24,
        },
        '& .MuiGrid-root': {
            width: 472,
        },
    },
    root: {
        '& .MuiDialogContent-root': {
            padding: 0,
        },
        '& .MuiGrid-item': {
            marginBottom: 10,
        },
    },

    select: {
        display: 'flex',
    },

    disabledButton: {
        backgroundColor: Colors.Gray8,
        '& .MuiButton-label': {
            color: `${Colors.Gray10}`,
        },
    },
    primaryButton: {
        background: Colors.Primary,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: `${Colors.White}`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary,
        },
    },
    width: {
        width: 96,
    },
}));

const AssignDeviceDialog: React.VFC<AssignDeviceProps> = (
    {
        id,
        isOpen,
        onClose,
        selectedDevice,
        callBackOnSave,
        i_account
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [device, setDevice] = useState<UAInfo | null>(null);
    const [port, setPort] = useState<UAPortConfiguration | null>(
        null
    );
    const [showLoader, setShowLoader] = useState(false);

    const handleDeviceChange = (device: UAInfo | null) => {
        setDevice(device);
    };

    const handlePortChange = (port: UAPortConfiguration | null) => {
        setPort(port);
    };

    const items = useSelector((state: ReduxState) => state.devices?.items);

    useEffect(() => {
        if (selectedDevice && items) {
            const extDevice = items.find(e => e.i_ua === selectedDevice.i_ua && e.mac === selectedDevice.mac);
            if (extDevice) {
                const port = extDevice.ports_config?.find(e => e.port === selectedDevice.port);
                if (port) {
                    setDevice(extDevice);
                    setPort(port);
                }
            }
        } else if (!selectedDevice) {
            setDevice(null);
            setPort(null);
        }
    }, [selectedDevice, items]);

    const handleSubmit = () => {
        if (device && port) {
            setShowLoader(true);
            if (selectedDevice) {
                dispatch(
                    actions.releaseDevice.request({
                        i_ua_link: selectedDevice.i_ua_link,
                        onSuccess: () => {
                            dispatch(
                                actions.assignUA.request({
                                    port: port.port,
                                    i_ua: device.i_ua,
                                    i_account: i_account,
                                    callback: callBackOnSave,
                                }),
                            );
                        },
                    }),
                );
            } else {
                dispatch(
                    actions.assignUA.request({
                        port: port.port,
                        i_ua: device.i_ua,
                        i_account: i_account,
                        callback: callBackOnSave,
                    }),
                );
            }
        }
    };

    useEffect(() => {
        !isOpen && setShowLoader(false);
    }, [isOpen]);

    const isDirty = useMemo(() => {
        if (!device || !port)
            return true;
        if (!selectedDevice)
            return false;
        return !(selectedDevice.i_ua !== device.i_ua
            || selectedDevice.port !== port.port);
    }, [device, port, selectedDevice]);

    const actionButtons = [
        <DialogButton
            key="cancel"
            label={t('common:cancel')}
            onClick={() => {
                onClose?.();
                setTimeout(() => {
                    setDevice(null);
                    setPort(null);
                }, 10);
            }}
        />,
        <DialogButton
            key="save"
            label={t('common:save')}
            onClick={handleSubmit}
            disabled={isDirty || showLoader}
            className={classes.primaryButton}
        />,
    ];

    return (
        <DialogContainer
            isOpen={isOpen}
            header={t('screens:devices.assignExtension', {id: id})}
            headerClass={classes.header}
            dialogActionsButtons={actionButtons}
            className={classes.root}
            showLoader={showLoader}
            dialogVariant={SpinnerVariant.Circle}
        >
            <Grid container className={classes.itemsContainer}>
                <AssignDevice
                    refresh={isOpen}
                    onDeviceChange={handleDeviceChange}
                    onPortChange={handlePortChange}
                    device={device}
                    port={port}
                    callback={() => setShowLoader(prev => !prev)}
                    readOnly={showLoader}
                />
            </Grid>
        </DialogContainer>
    );
};
export default AssignDeviceDialog;
