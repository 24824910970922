import JSONFormData from "../../utils/JSONFormData";
import {select} from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import { api } from "../services/axios";

export function* pagedDataRequest<T>(requestURL: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    requestParams: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objFunc: (x: any) => T[]
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const pagingDefinedByRequest = (requestParams?.limit > 0 && requestParams?.limit !== 1000)
        || (requestParams?.offset > 0 && requestParams?.offset !== 0);
    const limit = requestParams?.limit ?? 500;
    let offset = requestParams?.offset ?? 0;

    const resultArray: T[] = [];

    do {
        body.setParams({
            ...requestParams,
            limit: limit,
            offset: offset,
            get_total: 1
        });
    
        const response: AxiosResponse<{
            total: number
        }> = yield api.post(
            requestURL,
            body,
        );

        const fetchedArray = objFunc(response.data) ?? [];
        for(const obj of fetchedArray) {
            resultArray.push(obj);
        }

        if(fetchedArray.length === 0 || response.data.total >= resultArray.length || pagingDefinedByRequest) {
            break;
        }

        offset += limit;
    } while(true);

    return resultArray;
}