import React, {useEffect, useMemo, useState} from 'react';
import {Grid, Box} from '@material-ui/core';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import TextField from '../../TextField/TextField';
import {CreateNewPortalUserFormData, portalUserSettingsFiledsName, useStyles} from '../utils';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';
import SelectField from '../../SelectField/SelectField';
import CustomKeyboardDateTimePicker from '../../KeyboardDateTimePicker/KeyboardDateTimePicker';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {ConfigData} from '../../../store/types/ConfigData';
import PasswordTextField from '../../PasswordTextField/PasswordTextField';
import {getConfigFromPasswordRulesOrDefault} from '../../../utils/passwordGenerator';
import classNames from 'classnames';
import {PortalUsersErrors} from '../../../services/apiErrors';
import {ErrorTabInteface} from "../../Forms/RingGroups/generalFormUtils";
import {actions} from "../../../store";
import {isAnyKEyFromSearchExistInSource} from "../../../utils/isAnyKEyFromSearchExistInSource";
import {usePermissionContext} from "../../../hooks/usePermissions";
import {PermissionType} from "../../../store/types/Permission";
import i18n from "../../../services/i18n";
import { convertCurrentDateToUserTimeZone } from '../../../views/PortalUsers/utils';

const PortalUserSettings: React.VFC<ErrorTabInteface> = ({tabIndex, tabName, callback}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const permissions = usePermissionContext();

    const {roleList, apiErrors} = useSelector(
        (state: ReduxState) => state.company,
    );

    const {timeZonesList, languagesList} = useSelector(
        (state: ReduxState) => state.generic,
    );
    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );
    const userDateFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_date_format ||
            '',
    );

    const iTimeZone = useSelector(
        (state: ReduxState) => state.myProfile?.customer?.iTimeZone,
    );

    const {
        values,
        handleChange,
        errors,
        setFieldValue,
        setFieldError,
        initialValues,
    } = useFormikContext<CreateNewPortalUserFormData>();

    const markTabAsHaveError = (setTabError: boolean) => {
        if (tabName && tabIndex !== undefined) {
            dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
        }
    }

    useEffect(() => {

        const errorsKeys = Object.keys(errors);
        if (isAnyKEyFromSearchExistInSource(errorsKeys, portalUserSettingsFiledsName)) {
            markTabAsHaveError(true)
        } else {
            markTabAsHaveError(false)
        }

    }, [errors, values]);

    const {login} = useSelector((state: ReduxState) => state.auth);
    const validateUserIsCurrent = function (userLogin: string) {
        return (login || '').toUpperCase() === (userLogin || '').toUpperCase();
    }

    const [isTheUserEqualsCurrent, setIsTheUserEqualsCurrent] = useState(validateUserIsCurrent(values?.login));

    useEffect(() => {
        setIsTheUserEqualsCurrent(validateUserIsCurrent(values?.login))
    }, [values.login]);

    useEffect(() => {
        if (
            apiErrors?.faultstring.includes(PortalUsersErrors.PortalUserExist)
        ) {
            setFieldError(
                'login',
                t('errors:portalUsers.loginExists', {value: values.login}),
            );
            markTabAsHaveError(true);
            callback && callback();
        }

        if (
            apiErrors?.faultstring.includes(PortalUsersErrors.PasswordError)
        ) {
            setFieldError(
                'password',
                i18n.t<string>('errors:extensions.portalPasswordUpdate'),
            );
            markTabAsHaveError(true);
            callback && callback();
        }

    }, [apiErrors]);

    const minForExpiration = useMemo(() => {
        const tzname = timeZonesList.find((e) => e.i_time_zone === iTimeZone)
            ?.time_zone_name;
        const tomorrow = convertCurrentDateToUserTimeZone(tzname); 
        tomorrow.setDate(tomorrow.getDate() + 1);
        if(!values.activationDate) {
            return tomorrow;
        }
        const minday = new Date(values.activationDate.toString()) || tomorrow;
        minday.setDate(minday.getDate() + 1);
        return minday;
    }, [values.activationDate, timeZonesList, iTimeZone]);

    const minDateForActivation = useMemo(() => {
        const tzname = timeZonesList.find((e) => e.i_time_zone === iTimeZone)
            ?.time_zone_name;
        return convertCurrentDateToUserTimeZone(tzname); 
    }, [timeZonesList, iTimeZone]);
    
    return (
        <>
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.marginBottom,
                )}
                data-testid={'edit-user-settings'}
            >
                <Box className={classes.headerBox} width={350}>
                    <span className={classes.rowBoxHeader}>
                        {t('screens:myCompany.general')}
                    </span>

                    <IconWithTooltip
                        dataQa="my-company-address"
                        tooltipText={t('tooltips:myCompany.general')}
                    />
                </Box>
                <Box className={classes.rowBox}>
                    <TextField
                        id="login"
                        label={t('common:login')}
                        onChange={handleChange}
                        value={values.login}
                        setFieldError={setFieldError}
                        dataQa="create-portal-user-login"
                        helperText={errors?.login}
                        required
                        maxLength={64}
                    />

                    <PasswordTextField
                        id="password"
                        label={t('common:password')}
                        value={values.password}
                        onChange={handleChange}
                        dataQa="create-extension-name-input"
                        setFieldError={setFieldError}
                        error={errors.password}
                        isRequired={true}
                        passwordRulesConfig={getConfigFromPasswordRulesOrDefault(
                            // @ts-ignore
                            config,
                            true
                        )}
                        setFieldValue={setFieldValue}
                        maxLength={32}
                        inputProps={{
                            inputProps: {
                                min:
                                    config?.Web.PasswordMinLength || 0,
                                maxLength: 32,
                            },
                        }}
                        passwordProtectionMode
                        initialValue={initialValues.password}
                    />
                </Box>
                <Box className={classes.rowBox}>
                    <TextField
                        id="email"
                        label={t('common:emailAddress')}
                        onChange={handleChange}
                        value={values.email}
                        setFieldError={setFieldError}
                        dataQa="create-portal-user-email"
                        helperText={errors?.email}
                        maxLength={128}
                    />

                    <SelectField
                        id="role"
                        onChange={(e, v) => setFieldValue('role', v)}
                        label={t('screens:portalUsers.role')}
                        items={
                            roleList
                                ?.filter((e) => e.name !== t('common:any', 'Any'))
                                ?.map((v) => v.name) || []
                        }
                        value={values.role}
                        dataQa="create-portal-user-role"
                        readOnly={isTheUserEqualsCurrent || permissions == PermissionType.ReadOnly}
                        className={classNames(isTheUserEqualsCurrent || permissions == PermissionType.ReadOnly ? classes.readOnlyRole : null)}
                        classes={{iconContainer: classNames(isTheUserEqualsCurrent || permissions == PermissionType.ReadOnly ? classes.readOnlyRoleIconContainer : null)}}
                        icon={
                            roleList?.find((e) => e.name === values.role)
                                ?.description ? (
                                <IconWithTooltip
                                    dataQa="my-company-address"
                                    tooltipText={
                                        roleList?.find(
                                            (e) => e.name === values.role,
                                        )?.description || ''
                                    }
                                />
                            ) : undefined
                        }
                        helperText={errors?.role}
                        required
                        setFieldError={setFieldError}
                    />
                </Box>
            </Grid>

            <Grid item className={classes.itemsContainer}>
                <Box className={classes.headerBox} width={350}>
                    <span className={classes.rowBoxHeader}>
                        {t('screens:portalUsers.changeStatus')}
                    </span>

                    <IconWithTooltip
                        dataQa="my-company-address"
                        tooltipText={t('tooltips:myCompany.changeStatus')}
                    />
                </Box>
                <Box
                    className={classNames(classes.rowBox, classes.rowBoxSecond)}
                >
                    <CustomKeyboardDateTimePicker
                        id="activationDate"
                        label={t('screens:portalUsers.activationDate')}
                        value={values.activationDate}
                        onChange={(v) => setFieldValue('activationDate', v)}
                        dataQa={'portal-user-activation-date'}
                        dataTestId={'portal-user-activation-date'}
                        minDate={minDateForActivation}
                        userDateTimeFormat={userDateFormat}
                        allowEmptyDate={true}
                        withHours={false}
                        setFieldError={setFieldError}
                        helperText={errors?.activationDate}
                    />

                    {/* Temporary fix */}
                    <div style={{height: 56}}/>

                    <CustomKeyboardDateTimePicker
                        id="expirationDate"
                        label={t('screens:portalUsers.expirationDate')}
                        value={values.expirationDate}
                        onChange={(v) => setFieldValue('expirationDate', v)}
                        dataQa={'portal-user-expieration-date'}
                        dataTestId={'portal-user-activexpierationation-date'}
                        userDateTimeFormat={userDateFormat}
                        allowEmptyDate={true}
                        withHours={false}
                        minDate={minForExpiration}
                        setFieldError={setFieldError}
                        helperText={errors?.expirationDate}
                    />
                </Box>
                <Box
                    className={classNames(
                        classes.rowBox,
                        classes.timeZoneRow,
                        classes.rowBoxSecond,
                    )}
                >
                    <SelectField
                        id="timezone"
                        onChange={(e, v) => setFieldValue('timezone', v)}
                        label={t('common:timezone')}
                        items={
                            timeZonesList?.map((v) => v.time_zone_name) || []
                        }
                        value={values.timezone}
                        dataQa="create-portal-user-timezone"
                    />

                    <SelectField
                        id="language"
                        onChange={(e, v) => setFieldValue('language', v)}
                        label={t('common:language')}
                        items={languagesList?.map((v) => v.name) || []}
                        value={values.language}
                        dataQa="create-portal-user-language"
                    />
                </Box>
            </Grid>
        </>
    );
};

export default PortalUserSettings;
