import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Colors} from '../../styles/Colors';
import {TFunction} from 'react-i18next';
import {Assignment, CloudDownload, Delete} from '@material-ui/icons';
import * as Yup from 'yup';
import CallerInfo from '../../components/Calls/CallerInfo';
import {Column} from 'react-table';
import DateTimeCell from '../../components/Calls/DateTimeCell';
import dayjs from '../../services/customDayJs';
import {CallHistory} from '../../store/types/CallHistory';
import {prepareCallRecordName} from '../../utils/calls/prepareCallRecordName';
import CustomizedIconButton from '../../components/IconButton/IconButton';
import i18n from '../../services/i18n';
import PermissionProvider from "../../components/PermissionProvider/PermissionProvider";
import {Permission, PermissionType} from "../../store/types/Permission";
import PlayFileButton from '../../components/PlayFileButton/PlayFileButton';
import { PlayingFile } from '../../store/reducers/calls/reducer';
import { usePermissions } from '../../hooks/usePermissions';
import classNames from 'classnames';

export type AudioFilePlayingType = {
    url: string,
};

export type CallRecordingsFormProps = {
    from: string;
    till: string;
    cli: string;
    cld: string;
};
export const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const dateTest = Yup.string()
    .required(i18n.t('errors:ringGroups.emptyInput'))
    .test('is-valid-date', i18n.t('errors:ringGroups.wrongFormat'), (v) =>
        dayjs(new Date(v?.replace(/-/g, '/') || '')).isValid(),
    );

export const callRecordingsFiltersValidationSchema = Yup.object().shape({
    from: dateTest,
    till: dateTest,
});

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        background: Colors.SmokeBackground,

        '& .MuiButton-label': {
            color: Colors.White,
        },
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '50px 90px 120px 90px',
    },

    outsideContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100vh',
        width: 824,
        overflowY: 'hidden',
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 14,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
        },
    },
    filtersContainer: {
        marginTop: 40,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
    },
    itemsContainer: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'flex-start',

        '& .MuiFormControl-root': {
            marginRight: 18,
        },
    },
    inputs: {
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },
    },
    button: {
        minWidth: 96,
        '& .MuiButton-label': {
            color: Colors.White,
        },
    },
    table: {
        maxWidth: 1040,

        '& tr td.MuiTableCell-root.MuiTableCell-body:nth-child(5) span': {
            marginLeft: -7,
        },

        '& thead th:nth-child(6)': {
            justifyContent: 'center',
            display: 'flex',
            paddingRight: 88,
        },
    },
    pagination: {
        color: Colors.Gray,
        marginTop: 30,
    },
    centeredChargeValue: {
        paddingLeft: '0!important',
        marginLeft: 30,
    },
    centeredRecordingValue: {
        paddingLeft: '0!important',
        marginLeft: 20,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 700,
        color: Colors.Gray7,
    },
    secondaryText: {
        opacity: 0.6,
        fontSize: 14,
    },
    iconMargin: {
        marginLeft: 16,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    actions: {
        display: 'flex',
        paddingLeft: 0,
        marginLeft: '-4rem',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    tooltipContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 8px',
    },
    tooltip: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    tooltipTitle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        fontSize: '16px',
        lineHeight: '28px',
        marginRight: '8px',
        whiteSpace: 'pre-wrap',
    },
    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
            background: `${Colors.White} !important`,
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },

    cloud: {
        display: 'flex',
        alignItems: 'center',
        color: Colors.LightGraySecondary3,
        marginLeft: 32,
        marginRight: 6,
        minWidth: 30,
    },
    deleteConfirmation: {
        whiteSpace: 'pre-wrap',
        marginBottom: 14,
    },
    containerDelete: {
        '& .MuiDialog-paperWidthSm': {
            height: 158,
            '& .MuiDialogContent-root:first-child': {
                padding: 24,
                paddingBottom: 14,
            },
        },
    },
    buttonsContainer: {
        maxWidth: 1042,
        width: 'calc(100% - 196px) !important',

        '& .MuiGrid-root': {
            padding: '12px 40px !important'
        }
    },
    cropLabelTextInFilter: {
        '& label': {
            maxWidth: 'calc(100% - 16px)',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        }
    },
    playPauseButtonLoading: {
        height: '24px !important',
        width: '24px !important',
        padding: '8px',
    },
    filesCounterCellContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',

        '& span': {
            marginTop: 12
        }
    },
    filesCounterCellContainerVisibleCounter: {
        paddingRight: 36,
    },
    filesCounter: {
        width: 'fit-content',
        height: 16,
        background: Colors.Border,
        color: Colors.Secondary,
        borderRadius: 16,
        padding: '4px 12px 5px 12px',
        marginLeft: 12,
        marginTop: 11,
        fontSize: 13,
        fontWeight: 400,

        '& span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            marginLeft: '0px !important'
        }
    },
    filesCounterActive: {
        background: Colors.Primary2,
        color: Colors.White,
    },
    audioBox: {
        maxWidth: 1010,
        width: 'calc(100% - 196px - 32px) !important',
    },
    audioBoxAboveRemoveBox: {
        top: 'calc(100vh - 145px) !important',
    },
    playingMusicRow: {
        background: '#FFF5DD !important',
        borderLeft: '4px solid ' + Colors.Primary2,

        '& [custom-attrib-file-counter="custom-attrib-file-counter"]': {
            background: Colors.Primary2,
            color: Colors.White,
        }
    }
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    downloadFile: (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => void,
    setCallRecordInformation: React.Dispatch<React.SetStateAction<CallHistory | undefined>>,
    removeItems: (items: CallHistory[]) => void,
    userDateTimeFormat?: string,
    isDownloadingGoingOn?: boolean[],
    isDialogOpened?: boolean,
    setCallRecordInformationInList?: (item: CallHistory) => void,
    filesQueue?: PlayingFile[][]
): Column<CallHistory>[] => {
    return [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.connectTime'),
            width: 1.2,
            Cell: function Cell(params) {
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        time={params.row.original.connect_time as string}
                        customDateFormat={userDateTimeFormat}
                    />
                );
            },
        },
        {
            accessor: 'CLI',
            Header: t<string>('screens:calls.caller'),
            width: 1,
            Cell: function Cell(params) {
                return <CallerInfo caller={params.row.original.CLI!}/>;
            },
        },
        {
            accessor: 'CLD',
            Header: t<string>('screens:calls.destination'),
            width: 1.5,
            Cell: function Cell(params) {
                return (
                    <CallerInfo
                        caller={params.row.original.CLD!}
                    />
                );
            },
        },
        {
            accessor: 'charged_quantity',
            Header: t<string>('screens:calls.duration'),
            width: 1.75,
            Cell: function Cell(params) {
                const downloadIds = params.row.original.cr_download_ids || [];
                const playRecordingVisible = usePermissions(...Permission.Calls.Recordings.PlayCallRecording.value) !== PermissionType.Hidden;
                return (
                    <div className={classNames(classes.filesCounterCellContainer, playRecordingVisible && classes.filesCounterCellContainerVisibleCounter)}>
                        <span>
                            {new Date(params.row.original.charged_quantity! * 1000)
                                .toISOString()
                                .substr(11, 8)}
                        </span>
                        {downloadIds.length > 1 && playRecordingVisible && (
                            <div className={classes.filesCounter} custom-attrib-file-counter="custom-attrib-file-counter"
                                data-testid={'multi-files-badge'}>
                                <span>{t<string>('screens:callSettings.filesCount', {
                                    count: downloadIds.length
                                })}</span>
                            </div>
                        )}
                        <div></div>
                    </div>
                );
            },
        },
        {
            accessor: 'i_xdr',
            Header: t<string>('common:actions'),
            maxWidth: 200,
            minWidth: 200,
            Cell: function Cell(params) {
                const downloadIds = params.row.original.cr_download_ids || [];
                const i_xdr = params.row.original.i_xdr;
                let fileSet: PlayingFile[] = [];
                if(downloadIds.length && filesQueue) {
                    for(const fq of filesQueue) {
                        if(fq.length && fq[0].call_recording_id === downloadIds[0]
                            && fq[0].i_xdr === i_xdr) {
                                fileSet = fq;
                        }
                    }
                }
                return (
                    <div className={classes.actions}>
                        {(downloadIds.length > 0) && (
                            <PermissionProvider permission={Permission.Calls.Recordings.PlayCallRecording.value} skipReadOnly>
                                <PlayFileButton
                                    files={fileSet}
                                    customClasses={{
                                        beforePlaying: classes.playPauseButtonLoading
                                    }}
                                    dataQa="play-file-icon"
                                    dataTestId="play-file-icon-id"
                                />
                            </PermissionProvider>
                        )}
                        
                        <PermissionProvider permission={Permission.Calls.Recordings.CallDetailRecord.value} skipReadOnly>
                            <CustomizedIconButton
                                tooltipText={t(
                                    'tooltips:callSettings.viewCallDetailRecord',
                                )}
                                dataQa="details-icon"
                                dataTestId="details-icon"
                                onClick={() =>
                                    setCallRecordInformation(params.row.original)
                                }
                                className={classes.iconMargin}
                            >
                                <Assignment/>
                            </CustomizedIconButton>
                        </PermissionProvider>

                        <PermissionProvider permission={Permission.Calls.Recordings.DownloadCallRecording.value} skipReadOnly>
                            <CustomizedIconButton
                                tooltipText={t(
                                    'tooltips:ringGroups.downloadRecording',
                                )}
                                dataQa="detail-download-icon"
                                dataTestId="download-icon"
                                onClick={() =>
                                    downloadFile(
                                        params.row.original.i_xdr as number,
                                        params.row.original
                                            .cr_download_ids?.[0] as string,
                                        prepareCallRecordName(
                                            params.row.original,
                                            timezoneOffset,
                                        ),
                                        params.row.index,
                                    )
                                }
                                className={classes.iconMargin}
                                disabled={
                                    !(
                                        params.row.original.cr_download_ids?.[0] &&
                                        !isDownloadingGoingOn?.[params.row.index]
                                    )
                                }
                            >
                                <CloudDownload/>
                            </CustomizedIconButton>
                        </PermissionProvider>

                        <PermissionProvider permission={Permission.Calls.Recordings.DeleteCallRecording.value}>
                            <CustomizedIconButton
                                tooltipText={t('common:delete')}
                                dataQa="delete-icon"
                                dataTestId="delete-icon"
                                onClick={() => {
                                    setCallRecordInformationInList?.(
                                        params.row.original,
                                    );
                                    removeItems([params.row.original]);
                                }}
                                className={classes.iconMargin}
                            >
                                <Delete/>
                            </CustomizedIconButton>
                        </PermissionProvider>

                    </div>
                );
            },
        },
    ];
};
