import classNames from 'classnames';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import { PaymentMethodType } from '../../../../store/types/Billing';
import { Country } from '../../../../store/types/Country';
import PaymentMethodIcon from '../../../Payments/PaymentMethodIcon';
import CustomizedRadio from '../../../Radio/Radio';
import SelectField from '../../../SelectField/SelectField';
import CustomizedTextField from '../../../TextField/TextField';
import {
    convertCardValidatorTypeToPaymentType,
    formatCardNumber,
    PaymentMethodFormProps,
    PaymentMethodFormType,
    useStyles,
    isEmailRequiredForSave,
    getCardType,
    paymentMethodTypeNameFunc,
    convertPaymentMethodToPaymentType,
} from './PaymentMethodForm.utils';
import TextFieldWithMask from '../../../TextFieldWithMAsk/TextFieldWithMask';
import ExpirationDateMask from '../../../TextFieldWithMAsk/Masks/ExpirationDateMask';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import { Subdivision } from '../../../../store/types/Subdivision';
import { actions } from '../../../../store';
import { OwnerPaymentMethod } from '../../../../store/types/Payment';
import { CustomerInfoDetails } from '../../../../store/types/CustomerInfo';
import { usePermissionContext } from '../../../../hooks/usePermissions';
import { PermissionType } from '../../../../store/types/Permission';
import { YesNo } from '../../../../store/types/CallScreening';

const PaymentMethodForm: React.VFC<PaymentMethodFormProps> = ({
    paymentMethodsTypes,
    withEmailWhenIsNecessary,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isEmailRequired, setIsEmailRequired] = useState(false);

    const countriesList = useSelector<ReduxState, Country[]>(
        (state) => state.generic.countriesList || [],
    );

    const subdivisionsList = useSelector<ReduxState, Subdivision[]>(
        (state) => state.generic.subdivisionsList,
    );

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const customerInfo = useSelector<
        ReduxState,
        CustomerInfoDetails | undefined
    >((state) => state.billing.customerInfo);
    
    const permission = usePermissionContext();

    const {
        values,
        setFieldError,
        setFieldValue,
        handleChange,
        errors,
    } = useFormikContext<PaymentMethodFormType>();

    const getSubdivisions = useCallback((iso_3166_1_a2?: string) => {
        if (iso_3166_1_a2) {
            dispatch(
                actions.getSubdivisionData.request({
                    iso_3166_1_a2,
                }),
            );
        }
    }, []);

    const validatorCardType = getCardType(values.cardNumber);
    const cardType = validatorCardType
        ? convertCardValidatorTypeToPaymentType(validatorCardType)
        : undefined;
    
    const cardLengthFunc = (v: string | undefined) => {
        const d = v?.replace(new RegExp(" ", 'g'), "");
        return d?.length || 0;
    }

    useEffect(() => {
        if (withEmailWhenIsNecessary) {
            setIsEmailRequired(
                isEmailRequiredForSave(
                    values,
                    ownerPaymentsMethods,
                    customerInfo,
                ),
            );
        }
    }, [values.cardNumber, cardType, customerInfo, ownerPaymentsMethods]);

    const getCurrentOwnerPaymentMethod = (
        type: PaymentMethodType,
        number: string,
    ) => {
        if (type === PaymentMethodType.CreditCard) {
            const validatorCardType = getCardType(number);
            if (validatorCardType) {
                const cardType = convertCardValidatorTypeToPaymentType(
                    validatorCardType
                );

                return ownerPaymentsMethods?.find(
                    (v) => v.payment_method === cardType,
                );
            }
        }
        else {
            return ownerPaymentsMethods?.find(
                (v) => convertPaymentMethodToPaymentType(v.payment_method) === type
            );
        }
        return undefined;
    };
    
    const isSelectedMethodRequiresExternalAuth = (
        type: PaymentMethodType,
        number: string
    ) => {
        const pm = getCurrentOwnerPaymentMethod(type, number);
        if(!pm) return false;

        return pm.ext_auth === YesNo.Yes;
    }

    return (
        <>
            {paymentMethodsTypes.length > 1 
                && paymentMethodsTypes.length <= 3
                && (
                <div className={classes.methodTypesContainer}>
                    {
                        paymentMethodsTypes.map((methodType) => (
                            <div key={'method_type_key' + (methodType as number)}
                                data-testid="credit-card-radio-container"
                                className={classNames(
                                    classes.methodTypeBox,
                                    values.paymentType === methodType &&
                                        classes.methodTypeBoxSelected,
                                )}
                            >
                                <CustomizedRadio
                                    label={paymentMethodTypeNameFunc(methodType, t)}
                                    checked={values.paymentType === methodType}
                                    onChange={() =>
                                        setFieldValue(
                                            'paymentType',
                                            methodType,
                                        )
                                    }
                                    dataQa="credit-card-radio"
                                />
                            </div>
                        ))
                    }
                </div>
            )}
            {paymentMethodsTypes.length > 3 && (
                <div className={classes.methodTypesContainer}>
                    <SelectField
                        label={t('screens:billing.paymentMethod')}
                        dataQa="select-payment-method-type"
                        dataTestId="select-payment-method-type"
                        items={paymentMethodsTypes}
                        getOptionLabel={(v) => paymentMethodTypeNameFunc(v, t)}
                        value={values.paymentType}
                        required
                        onChange={(_, value: PaymentMethodType) => {
                            setFieldValue(
                                'paymentType',
                                value,
                            );
                            if(value === PaymentMethodType.PayNearMe) {
                                setFieldValue(
                                    'email',
                                    customerInfo?.email ?? '',
                                );
                            }
                        }}
                        disableClearable
                        className={classes.paymentMethodsTypeDropDown}
                    />
                </div>)
            }

            {values.paymentType === PaymentMethodType.CreditCard && (
                <div className={classes.formItems}>
                    <span
                        className={classNames(
                            classes.sectionHeader,
                            paymentMethodsTypes.length < 2 &&
                                classes.sectionHeaderMargin,
                        )}
                    >
                        {t('screens:billing.cardDetails')}
                    </span>

                    <div className={classes.cardNumberContainer}>
                        <CustomizedTextField
                            id="cardholderName"
                            label={t('screens:billing.cardholderName')}
                            value={values.cardholderName}
                            helperText={errors.cardholderName}
                            setFieldError={setFieldError}
                            onChange={handleChange}
                            required
                            maxLength={41}
                        />

                        <CustomizedTextField
                            className={classes.creditCardInput}
                            id="cardNumber"
                            label={t('screens:billing.cardNumber')}
                            value={values.cardNumber}
                            helperText={errors.cardNumber}
                            setFieldError={setFieldError}
                            onChange={(e) => {
                                const validatorCardType = getCardType(e.target.value);

                                setFieldValue(
                                    'cardNumber',
                                    formatCardNumber(
                                        e.target.value,
                                        validatorCardType
                                            ? convertCardValidatorTypeToPaymentType(
                                                    validatorCardType,
                                                )
                                            : undefined,
                                    ),
                                );
                            }}
                            required
                            iconPosition="end"
                            icon={
                                cardType ? (
                                    <PaymentMethodIcon method={cardType} />
                                ) : undefined
                            }
                            maxLength={19}
                            customCounterLength={cardLengthFunc}
                            customCounterMaxLength={(v) => cardLengthFunc(v) > 16 ? cardLengthFunc(v) : 16}
                        />
                    </div>

                    <div className={classes.cardInfoContainer}>
                        <TextFieldWithMask
                            id="expirationDate"
                            label={t('screens:billing.expirationDate')}
                            value={values.expirationDate}
                            onChange={handleChange}
                            mask={ExpirationDateMask}
                            InputProps={{
                                required: true,
                                disabled: permission === PermissionType.ReadOnly,
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            required
                            setFieldError={setFieldError}
                            helperText={errors.expirationDate}
                            className={permission === PermissionType.ReadOnly ? classes.disabledMaskedLabel : undefined}
                        />

                        <CustomizedTextField
                            id="cvv"
                            label={t('screens:billing.cvv')}
                            value={values.cvv}
                            helperText={errors.cvv}
                            setFieldError={setFieldError}
                            onChange={handleChange}
                            required
                            iconPosition="end"
                            icon={
                                <IconWithTooltip
                                    dataQa="cvv-tooltip"
                                    tooltipText={t('tooltips:billing.cvv')}
                                />
                            }
                            maxLength={4}
                        />
                    </div>
                </div>
            )}

            {values.paymentType === PaymentMethodType.BankAccount && (
                <div className={classes.formItems}>
                    <span
                        className={classNames(
                            classes.sectionHeader,
                            paymentMethodsTypes.length < 2 &&
                                classes.sectionHeaderMargin,
                        )}
                    >
                        {t('screens:billing.bankAccountDetails')}
                    </span>

                    <CustomizedTextField
                        id="bankRoutingNumber"
                        label={t('screens:billing.bankRoutingNumber')}
                        value={values.bankRoutingNumber}
                        helperText={errors.bankRoutingNumber}
                        setFieldError={setFieldError}
                        onChange={handleChange}
                        required
                        maxLength={9}
                    />

                    <CustomizedTextField
                        id="accountNumber"
                        label={t('screens:billing.accountNumber')}
                        value={values.accountNumber}
                        helperText={errors.accountNumber}
                        setFieldError={setFieldError}
                        onChange={handleChange}
                        required
                        maxLength={41}
                    />

                    <CustomizedTextField
                        id="accountName"
                        label={t('screens:billing.accountName')}
                        value={values.accountName}
                        helperText={errors.accountName}
                        setFieldError={setFieldError}
                        onChange={handleChange}
                        required
                        maxLength={41}
                    />
                </div>
            )}

            {(values.paymentType === PaymentMethodType.DirectDebitNL) && (
                <div className={classes.formItems}>
                    <span
                        className={classNames(
                            classes.sectionHeader,
                            paymentMethodsTypes.length < 2 &&
                                classes.sectionHeaderMargin,
                        )}
                    >
                        {t('screens:billing.bankAccountDetails')}
                    </span>

                    <CustomizedTextField
                        id="accountNumber"
                        label={t('screens:billing.accountNumber')}
                        value={values.accountNumber}
                        helperText={errors.accountNumber}
                        setFieldError={setFieldError}
                        onChange={handleChange}
                        required
                        maxLength={41}
                    />

                    <CustomizedTextField
                        id="accountName"
                        label={t('screens:billing.accountName')}
                        value={values.accountName}
                        helperText={errors.accountName}
                        setFieldError={setFieldError}
                        onChange={handleChange}
                        required
                        maxLength={41}
                    />
                </div>
            )}

            {(values.paymentType === PaymentMethodType.CreditCard || 
                values.paymentType === PaymentMethodType.BankAccount || 
                values.paymentType === PaymentMethodType.DirectDebitNL) && (
                <div className={classes.formItems}>
                    <span
                        className={classNames(
                            classes.sectionHeader,
                            classes.billingAddressHeader,
                        )}
                    >
                        {t('screens:billing.billingAddress')}
                    </span>

                    <div className={classes.formRow}>
                        <CustomizedTextField
                            id="address"
                            label={t('screens:myCompany.address')}
                            value={values.address}
                            helperText={errors.address}
                            setFieldError={setFieldError}
                            onChange={handleChange}
                            required
                            maxLength={41}
                        />

                        <SelectField
                            label={t('screens:extensions.state')}
                            dataQa="extension-state-input"
                            items={subdivisionsList?.map((v) => v.name) || []}
                            value={
                                subdivisionsList?.find(
                                    (v) => v.i_country_subdivision === values.state,
                                )?.name || null
                            }
                            onChange={(_, value) => {
                                const state = subdivisionsList?.find(
                                    (v) => v.name === value,
                                )?.i_country_subdivision;
                                setFieldValue('state', state || null);
                            }}
                            disabled={!values.country}
                        />
                    </div>

                    <div className={classes.formRow}>
                        <div>
                            <SelectField
                                id="country"
                                label={t('screens:myCompany.country')}
                                items={countriesList.map((v) => v.name) || []}
                                value={
                                    countriesList?.find(
                                        (v) => v.iso_3166_1_a2 === values?.country,
                                    )?.name || null
                                }
                                onChange={(_, value) => {
                                    const country = countriesList?.find(
                                        (v) => v.name === value,
                                    )?.iso_3166_1_a2;

                                    setFieldValue('country', country || '', false);
                                    getSubdivisions(country);
                                    setFieldValue('state', null);
                                }}
                                dataQa="country"
                                disableClearable
                                setFieldError={setFieldError}
                                helperText={errors.country}
                                required
                            />
                        </div>
                        <CustomizedTextField
                            id="city"
                            label={t('screens:myCompany.city')}
                            value={values.city}
                            helperText={errors.city}
                            setFieldError={setFieldError}
                            onChange={handleChange}
                            required
                            maxLength={50}
                            className={classes.input}
                        />
                    </div>

                    <div className={classes.formRow}>
                        <CustomizedTextField
                            id="postalCode"
                            label={t('screens:myCompany.postalCode')}
                            value={values.postalCode}
                            helperText={errors.postalCode}
                            setFieldError={setFieldError}
                            onChange={handleChange}
                            required
                            maxLength={10}
                            className={classes.input}
                        />

                        <CustomizedTextField
                            id="billingPhone"
                            label={t('screens:billing.billingPhone')}
                            value={values.billingPhone}
                            helperText={errors.billingPhone}
                            setFieldError={setFieldError}
                            onChange={handleChange}
                            maxLength={16}
                            required
                            className={classes.input}
                        />
                    </div>

                    {isEmailRequired && (
                        <div className={classes.formRow}>
                            <CustomizedTextField
                                id="email"
                                label={t('screens:billing.companyEmail')}
                                onChange={handleChange}
                                value={values.email}
                                icon={
                                    <IconWithTooltip
                                        dataQa="extension-email-tooltip"
                                        tooltipText={t(
                                            'tooltips:billing.emailRequired',
                                        )}
                                    />
                                }
                                iconPosition="end"
                                helperText={errors.email}
                                setFieldError={setFieldError}
                                maxLength={128}
                                required
                            />
                        </div>
                    )}
                </div>
            )}
            
            {(values.paymentType === PaymentMethodType.PayNearMe) && (
                <div className={classes.formItems}>
                    <div className={classes.sectionHeader}></div>
                    <div className={classes.formRow}>
                        <CustomizedTextField
                            id="email"
                            label={t('screens:billing.accountEmail', {
                                payment_method: paymentMethodTypeNameFunc(values.paymentType, t)
                            })}
                            onChange={handleChange}
                            value={values.email}
                            icon={
                                <IconWithTooltip
                                    dataQa="extension-email-tooltip"
                                    tooltipText={t(
                                        'tooltips:billing.emailRequired',
                                    )}
                                />
                            }
                            iconPosition="end"
                            helperText={errors.email}
                            setFieldError={setFieldError}
                            maxLength={128}
                            required
                        />
                    </div>
                </div>
            )}
            
            {isSelectedMethodRequiresExternalAuth(
                values.paymentType,
                values.cardNumber) && (
                <div className={classes.formItems}>
                    <div className={classes.redirectionMessage}>
                        {t('screens:billing.youWillBeRedirectedToPaymentMethod', {
                            payment_method: paymentMethodTypeNameFunc(values.paymentType, t)
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default PaymentMethodForm;
