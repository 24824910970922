import dayjs from '../../services/customDayJs';
import React from 'react';
import { convertUtcToUserLocalTime } from '../../utils/dateWithTimezoneConversion';
import TwoLineCell from './TwoLineCell';

export type DateTimeCellProps = {
    timezoneOffset?: number;
    time: string;
    className?: string;
    customDateFormat?: string;
    hideDateWhenIsToday?: boolean;
};

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const DateTimeCell: React.VFC<DateTimeCellProps> = ({
    timezoneOffset,
    time,
    className,
    customDateFormat,
    hideDateWhenIsToday,
}) => {
    let todayDate = '';

    const date = convertUtcToUserLocalTime(
        time,
        timezoneOffset || 0,
        customDateFormat,
    ).split(' ');

    if (hideDateWhenIsToday) {
        todayDate = convertUtcToUserLocalTime(
            dayjs.utc().format(dateFormat),
            timezoneOffset || 0,
            customDateFormat,
        ).split(' ')[0];
    }

    return (
        <TwoLineCell
            headerText={date[1] + (date.length >= 3 ? ' ' + date[2] : '')}
            descriptionText={
                hideDateWhenIsToday && date[0] === todayDate
                    ? undefined
                    : date[0]
            }
            className={className}
        />
    );
};

export default DateTimeCell;
