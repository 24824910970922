import {TimeFiltersPageFilterType} from "./NewTimeFilterDialog.utils";
import {CPConditionInfo} from "../../../../store/types/CallScreening";
import {PeriodInfo} from "../../../../store/types/AccountFollowMeSettings";
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../../styles/Colors";
import {TFunction} from "react-i18next";
import {Permission, PermissionType} from "../../../../store/types/Permission";
import {Column} from "react-table";
import PermissionLinkCell from "../../../DataTable/PermissionLinkCell";
import CpNumbersCell from "../../../Calls/CpNumbersCell";
import OverflowTooltip from "../../../OverflowTooltip/OverflowTooltip";
import CustomizedIconButton from "../../../IconButton/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import React from "react";
import {stringifyInterval} from "./NewIntervalDialog.utils";

export interface TimeFiltersTabProps {
    pageFilterType: TimeFiltersPageFilterType;
    onDelete: (mode: CPConditionInfo) => void;
    onDeleteInterval?: (interval: PeriodInfo, cpCondition: CPConditionInfo) => void;
}

export const useStyles = makeStyles(() => ({
    pageContainer: {
        background: Colors.White,
        width: 'calc(100% - 40px)',
        paddingLeft: 24,
        paddingRight: 16,
        paddingBottom: 0
    },
    smallPadding: {
        paddingTop: 13,
    },
    addFilterButton: {
        marginTop:29,
        marginBottom: 16
    },
    timeIntervalCollapseContainer: {
        background: Colors.White
    },
    tableContainer: {
        background: Colors.White,
        width: 752,
        border: 'none !important',
        overflowX: 'hidden',

        '& table': {
            background: 'transparent',
            border: 'none !important',
            overflowX: 'hidden',
            marginLeft:8,
            '& thead': {
                background: 'transparent',
                border: 'none',
            },

            '& tbody': {
                background: 'transparent',
                border: 'none',

                '& tr': {
                    background: 'transparent',
                    border: 'none',
                    maxHeight:56
                }
            },

            '& .MuiTableCell-root': {
                paddingLeft: 8,
            },
        }
    },
    tableInsideRowExpander: {
        paddingLeft: 40,
        marginTop: 22,

        '& table': {
            '& .MuiTableCell-root': {
                paddingLeft: 0,
            },
        }
    },
    addIntervalButton: {
        marginTop: 8,
        marginLeft: 32
    },
    addNumberButton: {
        marginTop: 25,
        marginBottom: 15
    },
    numbersContainer: {
        maxHeight: 50,
        whiteSpace: 'normal',
        width: 'calc(100% - 24px)',

        '& > :first-child': {
            maxHeight: 50,
            whiteSpace: 'normal',
        },
    }
}));

export const generateColumnsTimeIntervals = (
    t: TFunction<string>,
    permission: PermissionType,
    onEdit?: (object: PeriodInfo, rowId: string) => void,
    onDelete?: (object: PeriodInfo, rowId: string) => void,
    ampm?: boolean
): Column<PeriodInfo>[] => {

    return [
        {
            Header: t<string>('screens:callSettings.timeInterval'),
            accessor: 'start_time',
            width: 4,
            Cell: function Cell(params) {
                const interval = stringifyInterval(t, params.row.original, ampm);
                return (
                    <PermissionLinkCell
                        text={interval}
                        onClick={() => {
                            (permission === PermissionType.Visible) && onEdit?.(params.row.original, params.row.id);
                        }}
                        permissions={Permission.Calls.Settings.CallScreening.value}
                        twoRowsText
                    />
                );
            },
        },
        {
            Header: '',
            accessor: 'end_time',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                return (<>
                    <CustomizedIconButton
                        onClick={() => {
                            onEdit?.(original, params.row.id);
                        }}
                        dataTestId="edit-time-interval-button"
                        dataQa="edit-time-interval-button"
                        tooltipText={t<string>('common:edit')}
                    >
                        <Edit htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                    <CustomizedIconButton
                        onClick={() => {
                            onDelete?.(original, params.row.id);
                        }}
                        dataTestId="remove-time-interval-button"
                        dataQa="remove-time-interval-button"
                        tooltipText={t<string>('common:delete')}
                    >
                        <Delete htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                </>);
            },
        }
    ];
};

export const generateColumnsFromTo = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    permission: PermissionType,
    isInUse: (filter: CPConditionInfo) => boolean,
    onEdit?: (object: CPConditionInfo) => void,
    onDelete?: (object: CPConditionInfo) => void,
): Column<CPConditionInfo>[] => {

    return [
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            width: 2,
            Cell: function Cell(params) {
                const name = params.row.original.name || '';
                return (
                    <PermissionLinkCell
                        text={name}
                        onClick={() => {
                            (permission === PermissionType.Visible) && onEdit?.(params.row.original);
                        }}
                        permissions={Permission.Calls.Settings.CallScreening.value}
                        twoRowsText
                    />
                );
            },
        },
        {
            Header: t<string>('screens:callSettings.numberOrExtension'),
            accessor: 'i_customer',
            width: 2,
            Cell: function Cell(params) {
                const numbersCell = (<CpNumbersCell cpNumbers={params.row.original}/>);
                return (
                    <div className={classes.numbersContainer}>
                        <OverflowTooltip
                            tooltip={numbersCell}
                            text={numbersCell}
                            copy={false}
                        />
                    </div>
                );
            },
        },
        {
            Header: '',
            accessor: 'i_cp_condition',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                return (<>
                    <CustomizedIconButton
                        onClick={() => {
                            onEdit?.(original);
                        }}
                        dataTestId="edit-number-button"
                        dataQa="edit-number-button"
                        tooltipText={t<string>('common:edit')}
                    >
                        <Edit htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                    {!isInUse(original) && (
                        <CustomizedIconButton
                            onClick={() => {
                                onDelete?.(original);
                            }}
                            dataTestId="remove-number-button"
                            dataQa="remove-number-button"
                            tooltipText={t<string>('common:delete')}
                        >
                            <Delete htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>
                    )}
                </>);
            },
        }
    ];
};