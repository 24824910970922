import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';
import {useLocation} from 'react-router';
import i18next from "i18next";
import React from "react";

export const IGNORE_TRANSLATION_KEY = 'serverSessionResetPasswordError';
export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.SignInRootBackground,
        height: '100vh'
    },

    wrapper: {
        height: 435,
        width: 515,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.White,
        border: `1px solid ${Colors.Border}`,
        borderRadius: 4,
        alignItems: 'center',
        '& .MuiInputBase-root': {
            marginBottom: 16,
        },
    },

    credentials: {
        width: 372,
        display: 'flex',
        flexDirection: 'column',
    },

    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
}));

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const getTranslationByFaultString = (text: string) => {

    if (text.indexOf(PasswordErrorsPartialText.NoUser) != -1) {
        return <div>{i18next.t<string>('errors:loginForm.noUser')}</div>
    }

    if (text.indexOf(PasswordErrorsPartialText.ToLongPassword) != -1) {
        const maxLength = text.match(/\(([^)]+)\)/);

        return <div>{i18next.t<string>('errors:loginForm.passwordTooLong', {
            value: maxLength?.length == 2 ? maxLength[1] : maxLength?.length ? maxLength[0] : 0
        })}</div>
    }

    if (text.indexOf(PasswordErrorsPartialText.RequestedNonce) != -1) {

        return <div>{i18next.t<string>('errors:loginForm.requestedNonce')}</div>
    }

    if (text.indexOf(PasswordErrorsPartialText.PasswordChangeFail) != -1) {
        const mainText = i18next.t<string>('errors:loginForm.commonErrorMsg')
        const errorTexts = [];

        if (text.indexOf(PasswordErrorsPartialText.MinimalLength) != -1) {

            const regex = /The password should be at least (\d+) symbols long/;
            const match = text.match(regex);

            if (match) {
                errorTexts.push(i18next.t<string>('errors:loginForm.tooShort', {value: parseInt(match[1])}))
            }

        }

        if (text.indexOf(PasswordErrorsPartialText.MissLatin) != -1) {
            errorTexts.push(i18next.t<string>('errors:loginForm.latin'))
        }

        if (text.indexOf(PasswordErrorsPartialText.MissLowercase) != -1) {
            errorTexts.push(i18next.t<string>('errors:loginForm.lowercase'))
        }

        if (text.indexOf(PasswordErrorsPartialText.MissDecimal) != -1) {
            errorTexts.push(i18next.t<string>('errors:loginForm.decimal'))
        }

        if (text.indexOf(PasswordErrorsPartialText.MissSpecial) != -1) {
            errorTexts.push(i18next.t<string>('errors:loginForm.special'))
        }

        if (text.indexOf(PasswordErrorsPartialText.MissUppercase) != -1) {
            errorTexts.push(i18next.t<string>('errors:loginForm.uppercase'))
        }

        if (text.indexOf(PasswordErrorsPartialText.ForbiddenCharacters) != -1) {
            errorTexts.push(i18next.t<string>('errors:loginForm.forbidden'))
        }

        return (<div style={{display: 'flex', flexDirection: "column"}}>
            <span>{mainText}</span>
            {errorTexts.map((val, index) => (<span key={index} style={{paddingLeft: 10}}> - {val}</span>))}
        </div>)
    }


    return text;
}

enum PasswordErrorsPartialText {
    NoUser = 'No such user',
    ToLongPassword = "allowed maximum",
    PasswordChangeFail = "Password has not been changed",
    MinimalLength = "The password should be at least",
    MissLatin = "latin letters",
    MissLowercase = "lowercase letters",
    MissDecimal = "decimal digits",
    MissSpecial = "special characters",
    MissUppercase = "uppercase letters",
    RequestedNonce = "nonce not found",
    ForbiddenCharacters = "forbidden characters"
}