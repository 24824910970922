import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import AnimatedNumberText from '../../../components/AnimatedNumber/AnimatedNumber';
import { CallVolumesWidgetData, WidgetCallHistory, WidgetCallVolumesData, WidgetCallVolumesTimeRange, WidgetExtensionPresenseFilter, WidgetPresantationType } from "../../../store/types/Wallboard";
import { useStyles } from './CallVolumesWidget.utils';
import { MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../styles/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../store';
import { ReduxState } from '../../../store/types';
import { DropDownDictionaryItem } from '../../CallHistory/ActiveCalls/ActiveCalls.utils';
import EmptyGridPlaceholder from '../../../components/DataGrid/EmptyGridPlaceholder';
import { ExtensionType } from '../../../store/types/Extension';
import { getDurationFromSec } from '../../../utils/transformers';
import WidgetLegendItem, { ChartLegendItem } from './WidgetLegendItem';
import CallVolumesWidgetListViewItem from './CallVolumesWidgetListViewItem';
import { RingGroupType } from '../../../store/types/RingGroup';
import WidgetLoadingMask from '../WidgetLoadingMask';

export type CallVolumesWidgetProps = {
    data: WidgetCallVolumesData,
    id: string,
    presentation: WidgetPresantationType,
    disableCustomRange: boolean,
    darkMode: boolean
};

const CallVolumesWidget: React.VFC<CallVolumesWidgetProps> = ({
    data,
    id,
    presentation,
    disableCustomRange,
    darkMode
}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    
    const [customRange, setCustomRange] = useState<WidgetCallVolumesTimeRange>(data.timeRange);
    
    const [extensionsList, setExtensionsList] = useState<ExtensionType[] | undefined>(undefined);
    const [ringGroupsList, setRingGroupsList] = useState<RingGroupType[] | undefined>(undefined);
    const [callHistoryItems, setCallHistoryItems] = useState<WidgetCallHistory[]>([]);
    
    function refresh(data: CallVolumesWidgetData) {
        setExtensionsList(data.extensionsList);
        setRingGroupsList(data.ringGroupsList);
        setCallHistoryItems(data.callHistoryItems);

        dispatch(actions.refreshWidgetCompleted.request(id));
    }

    const {refreshWidgetsQueue} = useSelector((state: ReduxState) => state.wallboard);
    const isLoadingChart = useMemo(() => !!(refreshWidgetsQueue?.find(e => e.id === id)), 
        [refreshWidgetsQueue, id]);

    const resizeObserver = new ResizeObserver(entries => {
        if(entries && entries.length && entries[0].target) {
            //@ts-ignore
            const offsetTop = entries[0].target?.offsetTop || 0;
            const el = document.getElementById("customTimeRange_doughnut_" + id)?.parentNode as Element;
            if(!el) return;

            let h = 0;
            if(offsetTop <= 40) {
                h = -8;
            } else if (offsetTop <= 48) {
                h = -4;
            }

            //@ts-ignore
            el.style.top = h + 'px';
        }
    });

    useEffect(() => {
        const itm = refreshWidgetsQueue?.find(e => e.id === id);
        if(itm && itm.dataHasLoaded) {
            refresh(itm.data as CallVolumesWidgetData);
        }
    }, [refreshWidgetsQueue]);

    useEffect(() => {
        const _id = 'native-select-container' + id;
        const el = document.getElementById(_id);
        const subItems = el?.getElementsByClassName('MuiSelect-root');
        const name = widgetCallVolumesTimeRangeList
            .find(e => e.value === customRange)?.name;
        if(subItems && subItems?.length > 0) {
            subItems[0].innerHTML = t('screens:wallboard.callsInLast', {
                value: name
            });
        }
    }, [id, customRange, data, presentation]);
    
    const widgetCallVolumesTimeRangeList = useMemo(
        () => {
            const values = [
                WidgetCallVolumesTimeRange.min60,
                WidgetCallVolumesTimeRange.min180,
                WidgetCallVolumesTimeRange.min300,
                WidgetCallVolumesTimeRange.min420,
                WidgetCallVolumesTimeRange.min1440,
                WidgetCallVolumesTimeRange.min4320,
                WidgetCallVolumesTimeRange.min7200,
                WidgetCallVolumesTimeRange.min10080
            ];
            if(!values.find(e => e === data.timeRange)) {
                values.push(data.timeRange);
                values.sort((a, b) => (a as number) - (b as number));
            }
            return values
                .map((v) => ({
                    name: t(`enums:widgetCallVolumesTimeRange.${v}`),
                    value: v as WidgetCallVolumesTimeRange,
                } as DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>));
            },
        [data],
    );

    const filteredExtensions = useMemo(() => {
        if(data.show === WidgetExtensionPresenseFilter.selected_extensions) {
            return extensionsList?.filter(e => !!data.extensions?.find(k => k === e.extension_id)) ?? [];
        }
        else if(data.show === WidgetExtensionPresenseFilter.selected_ring_groups) {
            const groups = ringGroupsList?.filter(e => data.ringGroup === e.id) ?? [];
            const extIds: string[] = [];
            for(const r of groups) {
                for(const e of r.assigned_extensions ?? []) {
                    extIds.push(e.id || '');
                }
            }
            return extensionsList?.filter(e => !!extIds?.find(k => k === e.extension_id)) ?? [];
        } else {
            return extensionsList ?? [];
        }
    }, [data, extensionsList, ringGroupsList]);
    
    const chartData = useMemo(() => {
        const retArray = [
            {
                label: t('screens:wallboard.incomingCount'),
                value: 0,
                color: Colors.Primary2,
                testId: 'incoming-label',
                durationInSec: 0,
                durationInSecString: ''
            } as ChartLegendItem,
            {
                label: t('screens:wallboard.outgoingCount'),
                value: 0,
                color: Colors.Support,
                testId: 'outgoing-label',
                durationInSec: 0,
                durationInSecString: ''
            } as ChartLegendItem,
            {
                label: t('screens:wallboard.forwardedCount'),
                value: 0,
                color: Colors.Secondary1,
                testId: 'forwarded-label',
                durationInSec: 0,
                durationInSecString: ''
            } as ChartLegendItem
        ];

        for(const call of callHistoryItems) {
            const ext = filteredExtensions.find(e => e.i_account === call.i_account);
            if(!ext) {
                continue;
            }
            
            const maxMinutesAgo = (customRange ?? data.timeRange) as number;
            if(call.startedMinutesAgo > maxMinutesAgo) continue;

            const requiredBits = (call.bit_flags || 0) & 12;
            if(requiredBits === 0 || requiredBits === 4) {
                //outgoing call
                retArray[1].value++;
                retArray[1].durationInSec += call.durationInSec;
            } else if(requiredBits === 8) {
                //incoming call
                retArray[0].value++;
                retArray[0].durationInSec += call.durationInSec;
            } else if(requiredBits === 12) {
                //forwarded call
                retArray[2].value++;
                retArray[2].durationInSec += call.durationInSec;
            }
        }

        for(const itm of retArray) {
            itm.durationInSecString = getDurationFromSec(itm.durationInSec).string;
        }

        if (retArray.every(el => el.value === 0)) {
            retArray.push({
                label: '',
                value: 1,
                color: darkMode ? '#494A55' : '#D9D9D9',
                testId: 'empty-chart-item',
                durationInSec: 0,
                durationInSecString: '-'
            });
        }

        return retArray;
    }, [filteredExtensions, callHistoryItems, data, customRange, darkMode]);
    
    const dataset = useMemo(() => {
        return {
            labels: chartData.map((v) => v.label),
            datasets: [
                {
                    data: chartData.map((v) => v.value),
                    backgroundColor: chartData.map((v) => v.color),
                    borderColor: chartData.map(() => darkMode ? '#201F2B' : Colors.White),
                },
            ],
        };
    }, [chartData]);

    const dataSum = useMemo(() => {
        return chartData
            .filter(e => e.durationInSecString !== '-')
            .map(e => e.value)
            .reduce((v, w) => v + w, 0);
    }, [chartData]);

    const isZeroDataSum = useMemo(() => {
        return dataSum === 0;
    }, [dataSum]);

    const calcCallsCount = useCallback((ext?: ExtensionType) => {
        let count = 0;
        for(const call of callHistoryItems) {
            if(ext && call.i_account !== ext.i_account) continue;
            
            const maxMinutesAgo = (customRange ?? data.timeRange) as number;
            if(call.startedMinutesAgo > maxMinutesAgo) continue;

            count ++;
        }
        
        if(count < 1) return '';
        return count + '';
    }, [callHistoryItems, customRange, data]);

    const isEmptyData = useMemo(() => {
        if(isLoadingChart) return false;
        if(filteredExtensions != undefined && filteredExtensions.length === 0)
            return true;
        let hasNoEmpty = false;
        for(const ext of filteredExtensions) {
            if(calcCallsCount(ext) !== '') {
                hasNoEmpty = true;
                break;
            }
        }
        return !hasNoEmpty;
    }, [filteredExtensions, isLoadingChart, callHistoryItems, customRange, data]);

    const calcSummaryDuration = useCallback((ext: ExtensionType) => {
        let totalDurationInSec = 0;
        for(const call of callHistoryItems) {
            if(call.i_account !== ext.i_account) continue;

            const maxMinutesAgo = (customRange ?? data.timeRange) as number;
            if(call.startedMinutesAgo > maxMinutesAgo) continue;

            totalDurationInSec += call.durationInSec;
        }
        
        if(totalDurationInSec < 1) return '';
        return getDurationFromSec(totalDurationInSec).string;
    }, [callHistoryItems, customRange, data]);

    return (<>
        <div id={'call_volumes_widget_' + id} className={classNames(classes.widgetMainContainer, presentation === WidgetPresantationType.list && classes.overflowStyleForListWidgetType)}>
            {presentation === WidgetPresantationType.doughnut && (
                <div className={classes.mainContainer}>
                    <div className={classes.nativeSelectContainer} id={'native-select-container' + id}>
                        <Select
                            id={"customTimeRange_doughnut_" + id}
                            value={customRange as number}
                            onChange={(e) => {
                                setCustomRange(e.target.value as WidgetCallVolumesTimeRange);
                            }}
                            disabled={disableCustomRange}
                            disableUnderline
                            variant='standard'
                            className={classNames(classes.nativeSelect, darkMode && classes.nativeSelectDarkMode)}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "right"
                                },
                                getContentAnchorEl: null
                            }}
                            data-testid={'custom-time-range'}
                        >
                            {
                                widgetCallVolumesTimeRangeList.map((r, i) => (
                                    <MenuItem 
                                        value={r.value as number} 
                                        key={'option_key_' + i} 
                                        className={classes.nativeSelectOption}
                                    >
                                        {r.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </div>
                    <div className={classes.chartMainContainer}>
                        <div className={classNames(classes.chartContainer,
                                isEmptyData && classes.chartContainerEmptyData)}>
                            <div
                                className={classNames(
                                    classes.chartPlaceholder,
                                    isLoadingChart && classes.chartLoadingBackground,
                                    isEmptyData && classes.chartEmptyBackground,
                                )}
                            />
                            {!isLoadingChart && (
                                <Doughnut
                                    className={classes.chart}
                                    id={'donut_char_' + id}
                                    data={dataset}
                                    options={{
                                        responsive: true,
                                        //@ts-ignore
                                        cutout: '72%',
                                        //@ts-ignore
                                        borderWidth: isZeroDataSum ? 0 : 1,
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                            tooltip: isZeroDataSum ? {
                                                enabled: false
                                            } : {
                                                enabled: true,
                                                backgroundColor: 'rgba(51, 51, 51, 1)',
                                                callbacks: {
                                                    label: function(tooltipItem) {
                                                        const dataObject = chartData[tooltipItem.dataIndex];
                                                        if(dataObject.durationInSecString === '-') {
                                                            return t('common:noData');
                                                        }
                                                        const labelValue = dataObject.label;
                                                        const totalDuration = dataObject.durationInSecString;
                                                        return labelValue + ': ' + totalDuration;
                                                    },
                                                }
                                            }
                                        },
                                        maintainAspectRatio: false,
                                    }}
                                />
                            )}

                            {!isLoadingChart && (
                                <div className={classes.sumValue}
                                    ref={(el) => {
                                        if(el) {
                                            resizeObserver.observe(el);
                                        }
                                    }}>
                                    <AnimatedNumberText
                                        className={classNames(classes.sumText, darkMode && classes.sumTextDarkMode)}
                                        hideThousands
                                        value={dataSum}
                                        dataTestId="doughnut-chart-sum-value"
                                        dataQa="doughnut-chart-sum-value"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.legendContainer}>
                        <div className={classes.legendRow}>
                            <div className={classes.legendSubRow}>
                                <div>
                                    <WidgetLegendItem itm={chartData[0]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                                </div>
                            </div>
                            <div className={classNames(classes.legendSubRow, classes.secondColumn)}>
                                <div>
                                    <WidgetLegendItem itm={chartData[1]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                                </div>
                            </div>
                        </div>
                        <div className={classNames(classes.legendRow, classes.lastItem)}>
                            <WidgetLegendItem itm={chartData[2]} isLoadingChart={isLoadingChart} darkMode={darkMode} />
                        </div>
                    </div>
                </div>
            )}
            {presentation === WidgetPresantationType.list && (
                <div className={classes.listViewMainContainer}>
                    <div className={classes.listViewHeaderContainer}>
                        <div className={classNames(classes.listViewHeaderItem, darkMode && classes.listViewHeaderItemInDarkMode)}>
                            {t('screens:wallboard.extension')}
                        </div>
                        <div id={'native-select-container' + id} className={classes.nativeSelectContainer}>
                            <Select
                                id={"customTimeRange_list_" + id}
                                value={customRange as number}
                                onChange={(e) => {
                                    setCustomRange(e.target.value as WidgetCallVolumesTimeRange);
                                }}
                                disableUnderline
                                disabled={disableCustomRange}
                                variant='standard'
                                className={classNames(classes.nativeSelect, classes.nativeSelectInList, darkMode && classes.nativeSelectDarkMode)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "right"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {
                                    widgetCallVolumesTimeRangeList.map((r, i) => (
                                        <MenuItem 
                                            value={r.value as number} 
                                            key={'option_key_' + i} 
                                            className={classes.nativeSelectOption}
                                        >
                                            {r.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                    </div>
                    <div className={classes.listViewItemsContainer}>
                        {
                            filteredExtensions.map(e => calcCallsCount(e) !== '' && (
                                <CallVolumesWidgetListViewItem extension={e}
                                    key={'list_view_extension_' + e.id + '_' + id}
                                    calcSummaryDuration={calcSummaryDuration}
                                    calcCallsCount={calcCallsCount}
                                    numberOfCalls={data.numberOfCalls}
                                    darkMode={darkMode}
                                />
                            ))
                        }
                        {
                            <EmptyGridPlaceholder
                                showNoDataImg
                                message={t('common:noData')}
                                isVisible={isEmptyData}
                                customClass={classes.noDataContainer}
                            />
                        }
                    </div>
                </div>
            )}
        </div>
        <WidgetLoadingMask isLoadingChart={isLoadingChart} darkMode={darkMode}
            customClasses={{
                container: classNames(presentation === WidgetPresantationType.doughnut && classes.loadingBackgroundDoughnut,
                    presentation === WidgetPresantationType.doughnut && classes.loadingBackgroundList),
                label: classNames(presentation === WidgetPresantationType.doughnut && classes.loadingLabelDoughnut,
                    presentation === WidgetPresantationType.doughnut && classes.loadingLabelList),
            }}
        />
    </>
);
};

export default CallVolumesWidget;