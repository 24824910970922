import { createAsyncAction } from 'typesafe-actions';
import { APIErrorInterface } from '../../types';
import { ExtensionType } from '../../types/Extension';
import { BaseWallboardWidget, RefreshWallboardTriggerPayload, RefreshWallboardTriggerResponse, RefreshWidgetQueueItem, UpdateWallboardsRequest, WidgetCallHistory } from '../../types/Wallboard';
import { RingGroupType } from '../../types/RingGroup';
import { SipCall } from '../ringgroups/payloads';

export const getWallboardData = createAsyncAction(
    'GET_WALLBOARDS',
    'GET_WALLBOARDS_SUCCESS',
    'GET_WALLBOARDS_FAILED',
)<undefined, BaseWallboardWidget[], APIErrorInterface>();

export const updateWallboards = createAsyncAction(
    'UPDATE_WALLBOARDS',
    'UPDATE_WALLBOARDS_SUCCESS',
    'UPDATE_WALLBOARDS_FAILED',
)<UpdateWallboardsRequest, BaseWallboardWidget[], APIErrorInterface>();

export const refreshWallboardTrigger = createAsyncAction(
    'REFRESH_TRIGGER_WALLBOARDS',
    'REFRESH_TRIGGER_WALLBOARDS_SUCCESS',
    'REFRESH_TRIGGER_WALLBOARDS_FAILED',
)<RefreshWallboardTriggerPayload, RefreshWallboardTriggerResponse, undefined>();

export const refreshWidgetCompleted = createAsyncAction(
    'REFRESH_WIDGET_COMPLETED',
    'REFRESH_WIDGET_COMPLETED_SUCCESS',
    'REFRESH_WIDGET_COMPLETED_FAILED',
)<string, RefreshWallboardTriggerResponse, undefined>();

export const getCallHistoryDataForWidget = createAsyncAction(
    'GET_WIDGET_CALL_HISTORY',
    'GET_WIDGET_CALL_HISTORY_SUCCESS',
    'GET_WIDGET_CALL_HISTORY_FAILED',
)<undefined, WidgetCallHistory[], APIErrorInterface>();

export const getExtensionsListForWidgets = createAsyncAction(
    'GET_EXTENSIONS_FOR_WALLBOARD',
    'GET_EXTENSIONS_FOR_WALLBOARD_SUCCESS',
    'GET_EXTENSIONS_FOR_WALLBOARD_FAILED',
)<{ skipService ?:boolean, limitAliasDidNumberList?:boolean}, ExtensionType[], APIErrorInterface>();

export const getRingGroupsListForWidgets = createAsyncAction(
    'GET_RINGGROUPS_FOR_WALLBOARD',
    'GET_RINGGROUPS_FOR_WALLBOARD_SUCCESS',
    'GET_RINGGROUPS_FOR_WALLBOARD_FAILED',
)<undefined, RingGroupType[], APIErrorInterface>();

export const getSipCallsListForWidgets = createAsyncAction(
    'GET_SIP_CALLS_LIST_FOR_WALLBOARD',
    'GET_SIP_CALLS_LIST_FOR_WALLBOARD_SUCCESS',
    'GET_SIP_CALLS_LIST_FOR_WALLBOARD_FAILED',
)<undefined, SipCall[], APIErrorInterface>();

export const validatesAndPostDataToQueue = createAsyncAction(
    'VALIDATE_WIDGET_DATA_LOADED',
    'VALIDATE_WIDGET_DATA_LOADED_SUCCESS',
    'VALIDATE_WIDGET_DATA_LOADED_FAILED',
)<undefined, RefreshWidgetQueueItem[], undefined>();