import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        maxWidth: 850,
    },
    header: {
        fontWeight: 500,
        color: Colors.Text,
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 40,
    },

    checkboxContainer2Rows: {
        display: 'flex',
        marginTop: 20,
        marginBottom: 40,
        flexDirection: 'column',
        alignItems: 'flex-start'
    },

    childContainer: {
        display: 'flex'
    },

    extraMargin:{
      marginLeft:72,
        marginTop:24,
        display:"flex",
        alignItems:'center'
    },

    insideText: {
        marginLeft: 16,
        marginRight: 26,
    },
    tooltipIcon: {
        marginLeft: 10,
    },
    textFieldMargin: {
        marginTop: -15,
        width: 200,
    },
    disabledText: {
        color: Colors.Gray9,
    },
    frozenWarning: {
        marginBottom: 35,
        maxWidth: 708,
    },
}));
