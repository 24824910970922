import {InputBaseComponentProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    hidePlaceHolder: {
        '& .MuiInputBase-root input::placeholder': {
            color: 'transparent',
        },
        '& .MuiInputBase-root input:focus::placeholder': {
            color: Colors.Gray3,
        }
    },

    textField: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        maxWidth: 350,
        minWidth: 350,

        '& .MuiInputBase-root': {
            minHeight: 56,
        },

        '& .MuiInput-underline::before': {
            borderColor: Colors.Border,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            backgroundColor: Colors.White,
        },
        '& .MuiInputBase-input': {
            color: Colors.Text,
            borderColor: Colors.Primary,
            padding: '25px 10px 6px 10px',
            fontSize: 16,
            '&::placeholder': {
                color: Colors.Gray3,
            },
        },
        '& .Mui-focused': {
            color: Colors.Primary,
            marginLeft: 0,
        },
        '& .MuiFormLabel-root': {
            marginLeft: 10,
        },

        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 13px) scale(0.75)',
        },

        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: Colors.Primary,
            },
        },
        '& .Mui-disabled:hover:before': {
            borderBottomColor: Colors.Border,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: Colors.Primary,
        },
        '& .Mui-disabled:before': {
            borderBottom: `1px solid ${Colors.Border}`,
        },
    },

    readOnly: {
        '& > input.MuiInputBase-input': {
            color: `${Colors.PlaceHolder} !important`,
        },
    },

    iconButton: {
        top: 6,
        right: 6,
        position: 'absolute',
    },
    popperContainer: {
        boxShadow: '0px 2px 4px rgba(0,0,0,0.25)',
        backgroundColor: Colors.White,
        padding: '20px 17px 8px 17px',
    },
    popperRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
    },
    selectContainer: {
        minWidth: 100 + 'px !important',
        marginLeft: 24,
        zIndex: 9999,
    },
    timeLabel: {
        width: 40,
        minWidth: 40,
    },
    popupIndicator: {
        '& .MuiAutocomplete-listbox': {
            '& :nth-child(n)': {
                visibility: 'hidden',
                height: 0,
                minHeight: 0,
                padding: 0,
            },
            '& :nth-child(5n+1)': {
                visibility: 'visible',
                height: 40,
                minHeight: 40,
                padding: '6px 16px',
            },
        },
    },
    helperText: {
        color: Colors.Error,
        fontSize: 12,
        margin: '0 15px',
        paddingTop: 3,
        lineHeight: 'unset',
        position: "absolute",
        top: 50
    },
    requiredSign: {
        color: Colors.Error,
    },
    error: {
        '& .MuiFormLabel-root': {
            color: 'inherit',
        },

        '& .MuiInput-underline::before': {
            borderBottomColor: `${Colors.Error}!important`,
            borderBottomWidth: 2,
        },
    },
    wholeDayCheckbox: {
        marginLeft: 52,
    },
    testValue: {
        visibility: 'hidden',
        height: 0,
        width: 0,
    },
    noWrapLabel: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }
}));

export type MinSecRangePickerProps = {
    mask?: React.ElementType<InputBaseComponentProps>;
    name?: string;
    label?: string;
    maskPlaceholder?: string;
    id?: string;
    value?: any;
    onChange?: (v: any) => void;
    className?: string;
    placeholder?: string;
    InputProps?: any;
    inputProps?: any;
    InputLabelProps?: any;
    defaultValue?: string;
    required?: boolean;
    helperText?: string;
    setFieldError?: (name: string, value: any) => void;
    setTextRepresentation?: (value: string) => void;
    customClasses?: {
        popperContainer?: string;
    };
};

export const defaultWrapUpInput = '00:00';


export function createTimeString(minutes: number, seconds: number) {
    // Make sure minutes and seconds are within valid ranges
    minutes = Math.max(0, minutes);
    seconds = Math.max(0, Math.min(59, seconds)); // seconds should be between 0 and 59

    // Format the values to have leading zeros if necessary
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    // Combine formatted values to create the time string
    return `${formattedMinutes}:${formattedSeconds}`;
}

export function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(remainingSeconds).padStart(2, '0');

    return `${minutesStr}:${secondsStr}`;
}


export const minMask = [
    /[0-5]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/,
];

export const minSecPlaceholder = '__ : __ ';





