import * as actions from '../../actions';
import { ActionType, createReducer } from 'typesafe-actions';
import { UAInfo, UATypeInfo } from '../../types/Devices';
import { ExtensionsListItem } from '../extensions/extensions/reducer';
import { APIErrorInterface } from '../../types/errors';

export interface DevicesStateType {
    items?: UAInfo[];
    total?: number;
    allTotal?: number;
    active?: number;
    isLoading?: boolean;
    isReleasingDevice?: boolean;
    extension?: ExtensionsListItem;
    isExtensionLoading?: boolean;
    isEditing?: boolean;
    isTypeListLoading?: boolean;
    isCreating?: boolean;
    ua_type_list?: UATypeInfo[];
    createdUAId?: number;
    createApiError?: APIErrorInterface;
    isUpdating?: boolean;
    updateApiError?: APIErrorInterface;
}

export type DevicesActionsType = ActionType<typeof actions>;

export const initialState: DevicesStateType = {};

const devicesReducer = createReducer<DevicesStateType, DevicesActionsType>(
    initialState,
)
    .handleAction(actions.devicesList.request, (state) => ({
        ...state,
        isLoading: true,
        isReleasingDevice: undefined,
        createdUAId: undefined,
        updateApiError: undefined,
    }))
    .handleAction(actions.devicesList.success, (state, action) => ({
        ...state,
        isLoading: false,
        ...action.payload,
        allTotal: action.payload.total !== undefined ?  action.payload.total : state.allTotal
    }))
    .handleAction(actions.devicesList.failure, (state) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.releaseDevice.request, (state) => ({
        ...state,
        isReleasingDevice: true,
    }))
    .handleAction(actions.releaseDevice.success, (state) => ({
        ...state,
        isReleasingDevice: false,
        items: undefined,
        total: undefined,
    }))
    .handleAction(actions.releaseDevice.failure, (state) => ({
        ...state,
        isReleasingDevice: false,
    }))
    .handleAction(actions.getExtensionById.request, (state) => ({
        ...state,
        isExtensionLoading: true,
        extension: undefined,
    }))
    .handleAction(actions.getExtensionById.success, (state, action) => ({
        ...state,
        isExtensionLoading: false,
        extension: action.payload.extension,
    }))
    .handleAction(actions.getExtensionById.failure, (state) => ({
        ...state,
        isExtensionLoading: false,
        extension: undefined,
    }))
    .handleAction(actions.assignDevice.request, (state) => ({
        ...state,
        isEditing: true,
    }))
    .handleAction(actions.assignDevice.success, (state) => ({
        ...state,
        isEditing: false,
        items: undefined,
        total: undefined,
    }))
    .handleAction(actions.assignDevice.failure, (state) => ({
        ...state,
        isEditing: false,
    }))
    .handleAction(actions.assignUA.request, (state) => ({
        ...state,
        isEditing: true,
    }))
    .handleAction(actions.assignUA.success, (state) => ({
        ...state,
        isEditing: false,
        items: undefined,
        total: undefined,
    }))
    .handleAction(actions.assignUA.failure, (state) => ({
        ...state,
        isEditing: false,
    }))
    .handleAction(actions.deviceTypeList.request, (state) => ({
        ...state,
        isTypeListLoading: true,
        isCreating: false,
        createdUAId: undefined,
        createApiError: undefined,
        updateApiError: undefined,
    }))
    .handleAction(actions.deviceTypeList.success, (state, action) => ({
        ...state,
        isTypeListLoading: false,
        ua_type_list: action.payload.ua_type_list,
    }))
    .handleAction(actions.deviceTypeList.failure, (state) => ({
        ...state,
        isTypeListLoading: false,
    }))
    .handleAction(actions.addDevice.request, (state) => ({
        ...state,
        items: undefined,
        isCreating: true,
        createApiError: undefined,
    }))
    .handleAction(actions.addDevice.success, (state, action) => ({
        ...state,
        isCreating: false,
        createdUAId: action.payload.i_ua,
        createApiError: undefined,
    }))
    .handleAction(actions.addDevice.failure, (state, action) => ({
        ...state,
        isCreating: false,
        createApiError: action.payload,
    }))
    .handleAction(actions.uploadDeviceListCsvFile.request, (state) => ({
        ...state
    }))
    .handleAction(actions.uploadDeviceListCsvFile.success, (state) => ({
        ...state
    }))
    .handleAction(actions.uploadDeviceListCsvFile.failure, (state) => ({
        ...state
    }))
    .handleAction(actions.getUploadUAApiErrorFile.request, (state) => ({
        ...state
    }))
    .handleAction(actions.getUploadUAApiErrorFile.success, (state) => ({
        ...state
    }))
    .handleAction(actions.getUploadUAApiErrorFile.failure, (state) => ({
        ...state
    }))
    .handleAction(actions.deleteUA.request, (state) => ({
        ...state
    }))
    .handleAction(actions.deleteUA.success, (state) => ({
        ...state
    }))
    .handleAction(actions.deleteUA.failure, (state) => ({
        ...state
    }))
    .handleAction(actions.updateUa.request, (state) => ({
        ...state,
        isUpdating: true,
        updateApiError: undefined,
    }))
    .handleAction(actions.updateUa.success, (state) => ({
        ...state,
        isUpdating: false,
        updateApiError: undefined,
    }))
    .handleAction(actions.updateUa.failure, (state, action) => ({
        ...state,
        isUpdating: false,
        updateApiError: action.payload,
    }));

export default devicesReducer;
