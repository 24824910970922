import {useSelector} from 'react-redux';
import {ReduxState} from '../store/types';
import {useMemo} from 'react';
import {ForwardModeFlag, ServiceFeatureName,} from '../store/types/ServiceFeature';
import {getServiceFeatureLockedStatus, getServiceFeatureValue,} from '../utils/extensions/getServiceValue';
import {
    callForwardingRuleEmptyRule,
    ExtensionCallForwardingFormType
} from '../components/Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import {
    convertArrayOfDaysStringToDaysSelectItems,
    convertArrayOfMonthsStringToMonthSelectItems,
    convertArrayOfNumbersToRangesString,
    convertArrayOfYearsStringToYearsSelectItems,
} from '../utils/extensions/RingScheduleSummary';
import {FollowMeSequence, TransportProtocol,} from '../store/types/AccountFollowMeSettings';
import {IntervalStatus} from "../components/IntervalSelect/IntervalSelect.utils";

export const useEditExtensionCallForwardingFormData = () => {
    const permittedSipProxies = useSelector<ReduxState, string[] | undefined>(
        (state) => state.extensions.permittedSipProxies,
    );

    const extension = useSelector(
        (state: ReduxState) => state.extensions.extensionDetails,
    );

    const {accountFollowMeSettings, serviceFeatures} = useSelector(
        (state: ReduxState) => state.extensions,
    );

    const initFormData: ExtensionCallForwardingFormType = useMemo(() => {
        const method = getServiceFeatureValue(
            ServiceFeatureName.ForwardMode,
            serviceFeatures,
        ) || ForwardModeFlag.NoForwarding;

        const rules = accountFollowMeSettings?.followme_numbers?.map(
            (v) =>
                ({
                    id: v.i_follow_me_number,
                    description: v.name,
                    number: v.redirect_number || '',
                    ringFor: v.timeout || '15',
                    enabled: v.active === 'Y',
                    weight: v.weight.toString(),
                    intervals: {
                        activity: !v.period_definition
                            ? IntervalStatus.Always
                            : IntervalStatus.OnlyFollowingTimeInterval,
                        intervals:
                            v.period_definition?.map((interval) => ({
                                wholeDay: !interval.start_time,
                                startTime: interval.start_time?.slice(
                                    0,
                                    5,
                                ),
                                endTime: interval.end_time?.slice(0, 5),
                                daysOfMonth: convertArrayOfNumbersToRangesString(
                                    interval.monthday_list,
                                ),
                                days: convertArrayOfDaysStringToDaysSelectItems(
                                    interval.weekday_list,
                                ),
                                months: convertArrayOfMonthsStringToMonthSelectItems(
                                    interval.month_list,
                                ),
                                years: convertArrayOfYearsStringToYearsSelectItems(
                                    interval.year_list,
                                ),
                            })) || []
                    },
                    callingPartyDisplay: v.keep_original_cli,
                    transportProtocol:
                        v.use_tcp === 'N'
                            ? TransportProtocol.UDP
                            : TransportProtocol.TCP,
                    keepOriginalCld: v.keep_original_cld === 'Y',
                    sipProxy: v.domain,
                }),
        ) || [];

        return {
            callForwardingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.ForwardMode,
                    serviceFeatures,
                ) !== ForwardModeFlag.NoForwarding,

            callForwardingLocked:
                getServiceFeatureLockedStatus(
                    ServiceFeatureName.ForwardMode,
                    serviceFeatures,
                ) || false,
            callForwardingMethod: method,
            callForwardingRuleOrder:
                accountFollowMeSettings?.followme_info.sequence ||
                FollowMeSequence.Simultaneous,
            setDefaultFollowMeSequence: !accountFollowMeSettings?.followme_info.sequence ? true : false,
            callForwardingRules:
                rules?.length == 0 && (method == ForwardModeFlag.SimpleForwarding || method == ForwardModeFlag.ForwardToSIPUri) ? [callForwardingRuleEmptyRule] : rules,
            callForwardingDeletedRules: []
        };
    }, [permittedSipProxies, serviceFeatures, accountFollowMeSettings]);

    return {
        initFormData: initFormData,
        data: {
            extension,
            permittedSipProxies,
        },
    };
};
