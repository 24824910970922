import { createAsyncAction, createAction } from 'typesafe-actions';
import { ConfirmMfaConfigPayload, ConfirmMfaConfigResponse, GetMfaConfigPayload, GetMfaConfigResponse, MailMePayload, MfaConfig, ResetMfaPayload, SessionIdPayload, SetMfaConfigPayload, SignInFromAdminPayload, Validate2FaCodePayload } from './payloads';
import {
    ApiChangePasswordPayload,
    ApiLoginPayload,
} from '../../services/auth-api';
import { APIError, APIErrorInterface } from '../../types';

export const passwordChange = createAsyncAction(
    'USER_PASSWORD_CHANGE',
    'USER_PASSWORD_CHANGE_SUCCESSES',
    'USER_PASSWORD_CHANGE_FAILED',
)<ApiChangePasswordPayload, { success: number }, APIError>();

export const changeExpiredPassword = createAsyncAction(
    'USER_EXPIRED_PASSWORD_CHANGE',
    'USER_EXPIRED_PASSWORD_CHANGE_SUCCESSES',
    'USER_EXPIRED_PASSWORD_CHANGE_FAILED',
)<ApiChangePasswordPayload, { success: number }, APIError>();

export const logIn = createAsyncAction(
    'USER_SIGN_IN',
    'USER_SIGN_IN_SUCCESSES',
    'USER_SIGN_IN_FAILED',
)<ApiLoginPayload, SessionIdPayload, APIError>();

export const passwordRecovery = createAsyncAction(
    'PASSWORD_RECOVERY',
    'PASSWORD_RECOVERY_SUCCESSES',
    'PASSWORD_RECOVERY_FAILED',
)<MailMePayload, { success: number }, APIError>();

export const sendRecoveryPasswordEmail = createAsyncAction(
    'SEND_RECOVERY_EMAIL',
    'SEND_RECOVERY_EMAIL_SUCCESS',
    'SEND_RECOVERY_EMAIL_FAILED',
)<MailMePayload, { success: number }, APIError>();

export const logInPasswordExpired = createAction('SIGN_IN_PASSWORD_EXPIRED')<{
    login: string;
    password: string;
}>();

export const loginClearErrors = createAction('SIGN_IN_CLEAR_ERRORS')();

export const setEnteredLogin = createAction('SIGN_IN_SET_ENTERED_LOGIN')<{
    login: string;
}>();

export const signInFromAdmin = createAsyncAction(
    'USER_SIGN_IN_FROM_ADMIN',
    'USER_SIGN_IN_FROM_ADMIN_SUCCESSES',
    'USER_SIGN_IN_FROM_ADMIN_FAILED',
)<SignInFromAdminPayload, SessionIdPayload, APIError>();

export const removeAuthDataAndReload = createAction('REMOVE_AUTH_DATA')<{
    reload?: boolean,
    waitTime?:number
}>();

export const clearStorageData = createAction('CLEAR_STORAGE_DATA')();

export const apiLogout = createAction('LOGOUT_FROM_API')<{
}>();

export const generateMfaConfig = createAsyncAction(
    'GENERATE_MFA_CONFIG',
    'GENERATE_MFA_CONFIG_SUCCESSES',
    'GENERATE_MFA_CONFIG_FAILED',
)<SessionIdPayload, MfaConfig, APIError>();

export const confirmMfaConfig = createAsyncAction(
    'CONFIRM_MFA_CONFIG',
    'CONFIRM_MFA_CONFIG_SUCCESSES',
    'CONFIRM_MFA_CONFIG_FAILED',
)<ConfirmMfaConfigPayload, ConfirmMfaConfigResponse, APIErrorInterface>();

export const twoFaValidationRequired = createAction('2FA_VALIDATION_REQUIRED')<SessionIdPayload | undefined>();

export const validate2FaCode = createAsyncAction(
    'VALIDATE_2FA_CODE',
    'VALIDATE_2FA_CODE_SUCCESSES',
    'VALIDATE_2FA_CODE_FAILED',
)<Validate2FaCodePayload, ConfirmMfaConfigResponse, APIErrorInterface>();

export const getMfaConfig = createAsyncAction(
    'GET_MFA_CONFIG',
    'GET_MFA_CONFIG_SUCCESSES',
    'GET_MFA_CONFIG_FAILED',
)<GetMfaConfigPayload, GetMfaConfigResponse, APIErrorInterface>();

export const updateMfaUsed = createAsyncAction(
    'UPDATE_MFA_USED',
    'UPDATE_MFA_USED_SUCCESSES',
    'UPDATE_MFA_USED_FAILED',
)<SetMfaConfigPayload, GetMfaConfigResponse, ConfirmMfaConfigResponse>();

export const resetMfa = createAsyncAction(
    'RESET_MFA_USED',
    'RESET_MFA_USED_SUCCESSES',
    'RESET_MFA_USED_FAILED',
)<ResetMfaPayload, GetMfaConfigResponse, ConfirmMfaConfigResponse>();

export const cleanupMfaConfig = createAction(
    'CLEANUP_CONFIG_MFA_CONFIG'
)<undefined>();