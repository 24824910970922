import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import {
    CPConditionInfo,
    CPConditionInfoType,
    CPRuleInfo,
    YesNo,
} from '../../../types/CallScreening';
import { GetCallProcessingPolicyListEntity } from '../../../actions/extensions/payloads';
import { APIErrorInterface } from '../../../types';

export type CallScreeningConditions = {
    items?: CPConditionInfo[];
    total?: number;
    filters?: {};
    FromNumber?: {
        items: CPConditionInfo[];
        negated?: YesNo;
        total?: number;
        filters?: {};
    };
    ToNumber?: {
        items: CPConditionInfo[];
        negated?: YesNo;
        total?: number;
        filters?: {};
    };
    TimeWindow?: {
        items: CPConditionInfo[];
        negated?: YesNo;
        total?: number;
        filters?: {};
    };
};

export type CallScreeningRules = {
    items?: CPRuleInfo[];
    total?: number;
    limit?: number;
    offset?: number;
};

export interface CallScreeningReducerState {
    callScreeningConditions?: CallScreeningConditions;
    callScreeningRules?: CallScreeningRules;
    callProcessingPolicyList?: GetCallProcessingPolicyListEntity[];
    isCreatingPolicy?: boolean;
    isEditingPolicy?: boolean;
    deletePolicyApiError?: APIErrorInterface;
    apiError?: APIErrorInterface;
    fetchApiError?: APIErrorInterface;
    callProcessingPolicyListApiError?: APIErrorInterface;
}

export const initialState: CallScreeningReducerState = {};

export type CallScreeningReducerActions = ActionType<typeof actions>;

export const callScreeningReducer = createReducer<
    CallScreeningReducerState,
    CallScreeningReducerActions
>(initialState)
    .handleAction(actions.getCallScreeningRules.request, (state, action) => {
        return {
            ...state,
            callScreeningRules: {
                ...state.callScreeningRules,
                limit: action.payload.limit,
                offset: action.payload.offset,
            },
            isCreatingPolicy: false,
            isEditingPolicy: false,
            deletePolicyApiError: undefined
        };
    })
    .handleAction(
        actions.getCallScreeningConditions.request,
        (state, action) => {

            const { offset, limit, name, type } = action.payload;

            const condType: CPConditionInfoType | string = action.payload?.type
                ? action.payload?.type
                : 'default';

            switch (condType) {
                case CPConditionInfoType.From:
                    return {
                        ...state,
                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            FromNumber: {
                                ...state.callScreeningConditions?.FromNumber,
                                items:
                                    state.callScreeningConditions?.FromNumber
                                        ?.items ?? [],
                                filters: { offset, limit, name, type },
                            },
                        },
                        fetchApiError: undefined
                    };
                case CPConditionInfoType.To:
                    return {
                        ...state,

                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            ToNumber: {
                                ...state.callScreeningConditions?.ToNumber,
                                items:
                                    state.callScreeningConditions?.ToNumber
                                        ?.items ?? [],
                                filters: { offset, limit, name, type },
                            },
                        },
                        fetchApiError: undefined
                    };
                case CPConditionInfoType.TimeWindow:
                    return {
                        ...state,

                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            TimeWindow: {
                                ...state.callScreeningConditions?.TimeWindow,
                                items:
                                    state.callScreeningConditions?.TimeWindow
                                        ?.items ?? [],
                                filters: { offset, limit, name, type },
                            },
                        },
                        fetchApiError: undefined
                    };
                default:
                    return {
                        ...state,
                        filters: { offset, limit, name, type },
                        fetchApiError: undefined
                    };
            }
        },
    )
    .handleAction(actions.getCallScreeningRules.success, (state, action) => {
        return {
            ...state,
            callScreeningRules: {
                ...state.callScreeningRules,
                items: action.payload.cp_rule_list,
                total: action.payload.total,
            },
        };
    })
    .handleAction(
        actions.getCallScreeningConditions.success,
        (state, action) => {
            const condType: CPConditionInfoType | string = action.payload?.type
                ? action.payload?.type
                : 'default';

            switch (condType) {
                case CPConditionInfoType.From:
                    return {
                        ...state,
                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            FromNumber: {
                                items: action.payload.cp_condition_list,
                                total: action.payload.total,
                            },
                        },
                        fetchApiError: undefined
                    };
                case CPConditionInfoType.To:
                    return {
                        ...state,
                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            ToNumber: {
                                items: action.payload.cp_condition_list,
                                total: action.payload.total,
                            },
                        },
                        fetchApiError: undefined
                    };
                case CPConditionInfoType.TimeWindow:
                    return {
                        ...state,
                        callScreeningConditions: {
                            ...state.callScreeningConditions,
                            TimeWindow: {
                                items: action.payload.cp_condition_list,
                                total: action.payload.total,
                            },
                        },
                        fetchApiError: undefined
                    };
                default:
                    const from =
                        action.payload.cp_condition_list?.filter(
                            (v) => v.type === CPConditionInfoType.From,
                        ) || [];

                    const to =
                        action.payload.cp_condition_list?.filter(
                            (v) => v.type === CPConditionInfoType.To,
                        ) || [];

                    const time =
                        action.payload.cp_condition_list?.filter(
                            (v) => v.type === CPConditionInfoType.TimeWindow,
                        ) || [];
                    return {
                        ...state,

                        callScreeningConditions: {
                            items: action.payload.cp_condition_list,
                            total: action.payload.total,
                            FromNumber: {
                                items: [...from],
                                total: from.length,
                            },
                            ToNumber: {
                                items: [...to],
                                total: to.length,
                            },
                            TimeWindow: {
                                items: [...time],
                                total: time.length,
                            },
                        },
                        fetchApiError: undefined
                    };
            }
        },
    )
    .handleAction(actions.getCallScreeningConditions.failure,(state, action) => ({
        ...state,
        fetchApiError: action.payload
    }))
    .handleAction(actions.getCpRuleList.request, (state) => ({
        ...state,
        fetchApiError: undefined
    }))
    .handleAction(actions.getCpRuleList.success, (state, action) => ({
        ...state,
        callScreeningRules: { items: action.payload },
        fetchApiError: undefined
    }))
    .handleAction(actions.getCpRuleList.failure, (state) => ({
        ...state,
        fetchApiError: undefined
    }))
    .handleAction(
        actions.getCallProcessingPolicyList.request,
        (state) => ({
            ...state,
            callProcessingPolicyListApiError: undefined,
        }),
    )
    .handleAction(
        actions.getCallProcessingPolicyList.success,
        (state, action) => ({
            ...state,
            callProcessingPolicyList: action.payload,
        }),
    )
    .handleAction(
        actions.getCallProcessingPolicyList.failure,
        (state, action) => ({
            ...state,
            callProcessingPolicyList: [],
            callProcessingPolicyListApiError: action.payload
        }),
    )
    .handleAction(actions.updateRuleStatus, (state, action) => ({
        ...state,
        callProcessingPolicyList: state.callProcessingPolicyList?.map((itm) => {
            return {
                ...itm,
                rule_list: itm.rule_list?.map((r) => {
                    return r === action.payload.object ?  {
                        ...r,
                        enabled: action.payload.newStatus
                    } : r;
                })
            };
        })
    }))
    .handleAction(actions.addNewPolicy.request, (state) => ({
        ...state,
        isCreatingPolicy: true
    }))
    .handleAction(actions.addNewPolicy.success, (state, action) => ({
        ...state,
        isCreatingPolicy: false,
        callProcessingPolicyList: action.payload
    }))
    .handleAction(actions.addNewPolicy.failure, (state) => ({
        ...state,
        isCreatingPolicy: false
    }))
    .handleAction(actions.deletePolicy.request, (state) => ({
        ...state,
        deletePolicyApiError: undefined
    }))
    .handleAction(actions.deletePolicy.success, (state, action) => ({
        ...state,
        isCreatingPolicy: false,
        callProcessingPolicyList: 
            state.callProcessingPolicyList?.filter(itm => itm.i_cp_policy !== action.payload.i_cp_policy),
        deletePolicyApiError: undefined
    }))
    .handleAction(actions.deletePolicy.failure, (state, action) => ({
        ...state,
        isCreatingPolicy: false,
        deletePolicyApiError: action.payload
    }))
    .handleAction(actions.editPolicy.request, (state) => ({
        ...state,
        isEditingPolicy: true
    }))
    .handleAction(actions.editPolicy.success, (state, action) => ({
        ...state,
        isEditingPolicy: false,
        callProcessingPolicyList: action.payload
    }))
    .handleAction(actions.editPolicy.failure, (state) => ({
        ...state,
        isEditingPolicy: false
    }))
    .handleAction(actions.deletePolicyApiErrorStateUpdate, (state, action) => ({
        ...state,
        deletePolicyApiError: action.payload
    }))
    .handleAction(actions.updateRulesOrder.success, (state, action) => ({
        ...state,
        callProcessingPolicyList: action.payload
    }));

export default callScreeningReducer;
