import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../styles/Colors";

export const useStyles = makeStyles(() => ({
    headerContainer: {
        paddingBottom: 0
    },
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflowY: 'hidden',
        backgroundColor: Colors.SmokeBackground,
        position: 'relative',

        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        '& .MuiBox-root': {
            overflow: 'visible',
        },
    },
    extensionHeader: {
        padding: '0 90px 0 90px',
        height: 140,
        backgroundColor: Colors.Gray2,
        display: 'flex',
        flexDirection: 'column',
    },

    extensionTop: {
        paddingTop: 54,
        display: 'flex',
        justifyContent: 'space-between',
    },

    extensionStatus: {
        display: 'flex',
        fontSize: 22,
        weight: 400,

        '& > div': {
            marginRight: 40,
        },
        '& > div:last-child': {
            marginRight: 0,
        },
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
    },

    extensionBottom: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'end',
    },

    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },

    actionsCustomClass: {

    },
    
    '@media (max-width: 1276px)': {
        actionsCustomClass: {
            left: 41,
            right: 57,
        },
    },

    '@media (min-width:1277px) and (max-width: 1440px)': {
        actionsCustomClass: {
            left: 108,
            right: 122,
        },
    },

    '@media (min-width: 1441px)': {
        actionsCustomClass: {
            left: 114,
            right: 130,
        },
    },
}));