import React, {useCallback, useEffect, useMemo, useState} from 'react';
import TextField from '../TextField/TextField';
import {IconButton} from '@material-ui/core';
import CustomizedTooltip from '../Tooltip/Tooltip';
import {Colors} from '../../styles/Colors';
import {makeStyles} from '@material-ui/core/styles';
import {
    generatePassword,
    PasswordConfig,
} from '../../utils/passwordGenerator';

import {ReactComponent as CustomRefresh} from '../../assets/refresh-icon-with-key.svg';
import {ReactComponent as ShowPassword} from '../../assets/show_password.svg';
import {ReactComponent as HidePassword} from '../../assets/hide_password.svg';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import i18n from 'i18next';
import {usePermissionContext} from '../../hooks/usePermissions';
import {PermissionType} from '../../store/types/Permission';
import classNames from 'classnames';

type PasswordTextFieldProps = {
    id?: string;
    label?: string;
    value?: string;
    error?: string;
    dataQa?: string;
    iconTestID?: string;
    iconDataQa?: string;
    isRequired?: boolean;
    setFieldError?: (field: string, message: string | undefined) => void;
    setFieldValue?: (fieldName: string, value: any) => void;
    onChange?: (e: React.ChangeEvent<any>) => void;
    refreshPasswordRules?: (value: string) => void;
    passwordRulesConfig?: PasswordConfig;
    tooltipText?: string;
    tooltipDataQa?: string;
    maxLength?: number;
    inputProps?: {
        inputProps?: { min?: number | string; maxLength?: number | string };
    };
    onMouseEnter?: (ev?: React.MouseEvent<any, any>) => void;
    onMouseLeave?: () => void;
    icon?: React.ReactNode;
    autoComplete?: string;
    passwordProtectionMode?: boolean;
    initialValue?: string;
};

const PasswordTextField: React.VFC<PasswordTextFieldProps> = (
    {
        id,
        label,
        value,
        error,
        dataQa,
        isRequired,
        iconTestID,
        iconDataQa,
        setFieldError,
        onChange,
        setFieldValue,
        passwordRulesConfig,
        tooltipText,
        tooltipDataQa,
        maxLength,
        inputProps,
        refreshPasswordRules,
        onMouseEnter,
        onMouseLeave,
        icon,
        autoComplete,
        passwordProtectionMode = false,
        initialValue = ''
    }) => {
    const permission = usePermissionContext();
    const enable = permission == PermissionType.Visible;

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [internalVal, setInternalVal] = useState('');

    useEffect(() => {
        const newVal = value ? value.replaceAll(' ', '') : '';
        setInternalVal(newVal);
    }, [value]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newVal = e.target.value ? e.target.value.replaceAll(' ', '') : '';
        setInternalVal(newVal);
        onChange?.(e);
        !!id && setFieldError?.(id, undefined);
    };

    const getTextField = (el?: HTMLElement | null) => {
        if(!el) return el;

        const getInput = (el?: HTMLElement | null) => {
            const inputs = el?.getElementsByTagName('input');
            if(inputs && inputs.length) 
                return inputs[0];
            return null;
        };

        let input = getInput(el);
        while(!input && el) {
            el = el?.parentElement;
            input = getInput(el);
        } 
        return input;
    }

    const generatePortalPassword = (ev?: React.ChangeEvent<any>) => {
        if (enable && passwordRulesConfig) {
            const password = generatePassword(passwordRulesConfig);
            setInternalVal(password);
            !!id && setFieldValue?.(id, password);
            !!id && setFieldError?.(id, undefined);
            refreshPasswordRules?.(password);
            
            if(!passwordVisible) {
                setPasswordVisible(!passwordVisible);
            }

            const _target = getTextField(ev?.target);
            if(_target) {
                setTimeout(() => {
                    _target.focus();
                }, 1);
            }
        }
    };

    const handleKeyPress = useCallback((ev?: React.ChangeEvent<any>) => {
        if(internalVal === initialValue && passwordProtectionMode) {
            ev?.target?.setSelectionRange(0, ev?.target?.value.length ?? 0);
        }
    }, [passwordProtectionMode, internalVal, initialValue]);

    const handleOnFocus = useCallback((ev?: React.FocusEvent<any>) => {
        ev?.target?.setSelectionRange(0, ev?.target?.value.length ?? 0);
    }, [passwordProtectionMode]);

    const handlePasswordVisibility = () => setPasswordVisible(!passwordVisible);
    
    return (
        <>
            <TextField
                id={id}
                label={label}
                onChange={handleChange}
                value={internalVal}
                dataQa={dataQa}
                helperText={error || ''}
                required={isRequired}
                type={passwordVisible ? 'text' : 'password'}
                setFieldError={setFieldError}
                autoComplete={autoComplete || 'new-password'}
                icon={
                    <>
                        {icon && icon}
                        {passwordRulesConfig &&
                            RegeneratePasswordIcon(
                                generatePortalPassword,
                                enable,
                            )}
                        {!!internalVal.length && 
                            (!passwordProtectionMode || (passwordProtectionMode && internalVal !== initialValue)) && (
                            <IconButton
                                size="small"
                                onClick={handlePasswordVisibility}
                                data-testid={
                                    iconTestID ?? 'password-visibility-button'
                                }
                                data-qa={
                                    iconDataQa ?? 'password-visibility-button'
                                }
                            >
                                {passwordVisible
                                    ? VisibilityOffIcon
                                    : VisibilityIcon}
                            </IconButton>
                        )}
                        {tooltipText && (
                            <IconWithTooltip
                                dataQa={tooltipDataQa}
                                tooltipText={tooltipText}
                            />
                        )}
                    </>
                }
                iconPosition="end"
                maxLength={maxLength}
                inputProps={inputProps}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                handleKeyPress={handleKeyPress}
                onFocus={handleOnFocus}
            />
        </>
    );
};

const RegeneratePasswordIcon = (onClick: (ev: React.MouseEvent<any>) => void, enabled = true) => {
    const classes = useStyles();

    return (
        <IconButton
            size="small"
            style={{marginRight: 7}}
            onClick={onClick}
            data-testid="regenerate-password-button"
            data-qa="regenerate-password-button"
            className={classNames(classes.icon, !enabled && classes.disabled)}
            disabled={!enabled}
        >
            <CustomRefresh/>
        </IconButton>
    );
};

const VisibilityIcon = (
    <CustomizedTooltip
        title={i18n.t('tooltips:extensions.showPassword')}
        copy={false}
    >
        <ShowPassword data-testid="visibility-icon"/>
    </CustomizedTooltip>
);

const VisibilityOffIcon = (
    <CustomizedTooltip
        title={i18n.t('tooltips:extensions.hidePassword')}
        copy={false}
    >
        <HidePassword data-testid="visibilityoff-icon"/>
    </CustomizedTooltip>
);

const useStyles = makeStyles(() => ({
    visibilityIcon: {
        marginRight: 6,
    },
    icon: {
        '& svg': {
            '& path': {
                fill: Colors.Gray5,
                fillOpacity: 1,
            },
        },
    },
    disabled: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

export default PasswordTextField;
