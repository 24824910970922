export const roundUp = (number: number, decimalPlace: number): number => {
    const pow = Math.pow(10, decimalPlace);
    return Math.ceil(number * pow) / pow;
};


export const truncate = (number:number, index = 2) => {
    return +number.toString().slice(0, (number.toString().indexOf(".")) + (index + 1));
}

export function financial(x:any, decimal = 2):string {
    return Number.parseFloat(x).toFixed(decimal);
}