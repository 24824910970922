import {call, delay, put, select, all} from 'redux-saga/effects';

//Actions
import * as actions from '../../../actions';

//Services
import {api} from '../../../services/axios';
import extensionsApi, {
    SERVICE_FEATURE_CALL_PROCESSING,
} from '../../../services/extensions';

//Types
import {AxiosError, AxiosResponse} from 'axios';
import {APIErrorInterface, ReduxState} from '../../../types';
import {
    ExtensionFilters,
    ExtensionsListItem,
    ExtensionsState,
} from '../../../reducers/extensions/extensions/reducer';
import {ActionType} from 'typesafe-actions';
import {
    CustomerDIDNumberType,
    CustomerNumberType,
} from '../../../types/CustomerDIDNumber';
import JSONFormData from '../../../../utils/JSONFormData';
import {
    CreateExtensionFaultCode,
    CustomerExtension,
    ExtensionType, PasswordFaultCode,
    UpdateExtensionFaultCode,
} from '../../../types/Extension';
import {ServiceFeature, ServiceFeatureName} from '../../../types/ServiceFeature';
import {CustomerInfo} from '../../../types/CustomerInfo';
import {compareObjectsAndReturnDifferencesInValues} from '../../../../utils/compareObjects';
import {TimeZone} from '../../../types/TimeZone';
import i18n from '../../../../services/i18n';
import {
    CallProcessingOperationModeListResponse,
    SwitchModeRequestPayload,
    UpdateAccountCallProcessingModeResponse,
    UpdateExtensionStatusPayload,
} from '../../../actions/extensions/payloads';
import {AccountListResponse} from '../../../types/Account';
import {showErrorToast} from '../../../../utils/showErrorToast';
import {assignDeviceToExtension} from '../../devices/saga';
import {
    Account,
    CodecConverter,
    Customer,
    DialingRule,
    DID,
} from '../../../../services/endpoints';
import {
    getDIDNumbersByAccountID,
    updateAccountDIDNumbers,
} from '../../didNumbers/saga';
import {
    getFaxEnabledValue,
    getServiceValue,
} from '../../../../utils/extensions/convertServiceFeaturesToIconsFeatures';
import {Service} from '../../../../components/Extensions/ServiceIcon';
import {FetchDataStatus} from '../../../reducers/autoAttendants/reducer';
import {EditExtensionForm} from '../../../../views/Extensions/ExtensionDetails/utils';
import {editExtensionPlan, getExtensionProducts} from '../plan/saga';
import {editExtensionRingGroups} from '../assignedRingGroups/saga';
import {ApiFileList} from '../../../types/ApiFile';
import qs from 'qs';
import {getAllServiceFeatures} from '../serviceFeatures/saga';
import {DialingRuleInfo} from '../../../types/DialingRule';
import {updateCallBarringRules} from '../callBarring/saga';
import {
    editCallSettingsServiceFeatures,
    editVoicemailSettings,
} from '../voicemail/saga';
import {
    getHuntGroupList,
    getResponseMessageList,
} from '../../ringgroups/saga';
import {getGlobalCustomerInfo} from '../../generic/saga';
import {getRoute, Routes} from '../../../../routes/routes';
import {editExtensionCallForwardingSettings} from '../callForwarding/saga';
import {editCallScreeningDetails} from '../callScreening/saga';
import {BillStatus} from "../../../types/SipTrunk";
import {recordingValidationMsg} from "../../../../utils/recordingValidationMsg";
import {YesNo} from '../../../types/CallScreening';
import fileDownload from "js-file-download";
import {ListFileFormat} from "../../../types/Devices";

export const EXTENSION_NOT_FOUND_BY_ID_API_ERROR = 'EXTENSION_NOT_FOUND_BY_ID_API_ERROR';
export const DID_NUMBERS_FETCH_API_ERROR = 'Server.DID.get_customer_numbers.access_denied';

export type StatusFilterType = {
    title: string;
    details: {
        sip_status: number | null;
        blocked?: string;
        bill_status?: BillStatus
    };
};

function* fetchAccountList(
    filters?: ExtensionFilters,
    params?: { limit: number; offset: number },
    strictExtensionPattern?: boolean,
) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        has_extension: 1,
        get_only_real_accounts: 1,
        get_not_closed_accounts: 1,
        get_status: 1,
        get_total: 1,
        ...params,
        extension_id:
            (filters?.extension &&
                (strictExtensionPattern
                    ? filters.extension
                    : `%${filters.extension}%`)) ||
            undefined,
        email: (filters?.email && `%${filters.email}%`) || undefined,
        extension_name: (filters?.name && `%${filters.name}%`) || undefined,
        did_number:
            (filters?.didNumber && `%${filters.didNumber}%`) || undefined,
        blocked:
            (filters?.status && filters.status.details.blocked) || undefined,
        sip_status:
            filters?.status && filters.status.details.sip_status !== undefined
                ? filters.status.details.sip_status
                : undefined,
        with_call_processing_mode: 1,
        get_service_features: [
            ServiceFeatureName.AutoAttendant,
            ServiceFeatureName.CallBarring,
            ServiceFeatureName.CallProcessing,
            ServiceFeatureName.CallRecording,
            ServiceFeatureName.ConfEnabled,
            ServiceFeatureName.UnifiedMessaging,
            ServiceFeatureName.LineSharing,
        ],
        limit_alias_did_number_list: 100,
    });

    const response: AxiosResponse<AccountListResponse> = yield api.post(
        Account.GetAccountList,
        body,
    );

    return response;
}

export function addMainService(o: ExtensionType) {
    let mainService: Service | undefined = undefined;
    if (o.service_features?.length) {
        const includeAutoAttendant = getServiceValue(
            ServiceFeatureName.AutoAttendant,
            o.service_features,
        );

        const fax = o.service_features
            .find((s) => s.name === ServiceFeatureName.UnifiedMessaging)
            ?.attributes.find(
                (a) =>
                    a.name == 'fax_only_mode' &&
                    a.effective_values.filter((v) => v == 'Y').length,
            );

        const includeFaxMailBox = !!fax && getFaxEnabledValue(fax) === 'Y';

        const includeSharedLine = o.service_features
            ?.find((s) => s.name === ServiceFeatureName.LineSharing)
            ?.effective_flag_value === 'Y';

        if (includeAutoAttendant) {
            mainService = Service.AutoAttendant;
        } else if (includeSharedLine) {
            mainService = Service.LineSharing;
        } else if (includeFaxMailBox) {
            mainService = Service.UnifiedMessagingFaxOnly;
        }
    }

    return mainService;
}

export function* getExtensionList(
    action: ActionType<typeof actions.getExtensionsList.request>,
) {
    try {
        const filters: ExtensionFilters = yield select(
            (state: ReduxState) => state.extensions.extensionList.filters,
        );

        const params: { limit: number; offset: number } & ExtensionFilters = {
            limit: action.payload.limit,
            offset: action.payload.offset,
        };

        const res: AxiosResponse<AccountListResponse> = yield fetchAccountList(
            filters,
            params,
        );

        const accountMapResult = res.data.account_list.map(
            (o: ExtensionType) => {
                const item: ExtensionsListItem = {
                    id: o.extension_id,
                    name: o.extension_name,
                    numbers: (o.did_number && [o.did_number]) || [],
                    i_account: o.i_account,
                    i_customer: o.i_customer,
                    // @ts-ignore
                    account_info: {
                        email: o.email,
                        sip_status: o.sip_status,
                        blocked: o.blocked,
                        sip_agent: o.sip_agent,
                        i_account: o.i_account,
                        country: o.country,
                        h323_password: o.h323_password,
                        alias_did_number_list: o.alias_did_number_list,
                    },
                    serviceFeatures: o.service_features,
                    mainService: addMainService(o),
                    fetchDIDNumbers: FetchDataStatus.NotStart,
                    fetchSipStatus: FetchDataStatus.NotStart,
                    callProcessingModeInfo: o.call_processing_mode_info,
                };

                return item;
            },
        );

        let registeredTotal = 0;
        if(filters.status === undefined || filters.status === null || Object.keys(filters.status.details).length === 0) {
            const extensionsWithRegisteredDevices: AxiosResponse<{
                total: number;
            }> = yield fetchAccountList(
                {
                    ...filters,
                    status: {
                        title: 'Registered',
                        details: {sip_status: 1},
                    },
                },
                {limit: 1, offset: 0},
            );
            registeredTotal = extensionsWithRegisteredDevices.data.total;
        } else if(filters.status?.details?.blocked === 'Y') {
            registeredTotal = 0;
        } else if(filters.status?.details?.sip_status === 1) {
            registeredTotal = res.data.total;
        } else if(filters.status?.details?.sip_status === 0) {
            registeredTotal = 0;
        }

        yield put(actions.resetExtensionBasicDetails());
        yield put(actions.resetExtensionRingGroups());
        yield put(
            actions.getExtensionsList.success({
                items: accountMapResult,
                total: res.data.total,
                filters: params,
                registeredTotal: registeredTotal
            }),
        );
    } catch (err: any) {
        if (err.response?.data) {
            yield put(actions.getExtensionsList.failure(err.response.data));
        }
    }
}

export function* fetchDIDNumbersExtension(
    action: ActionType<typeof actions.getDIDNumbersForExtension.request>,
) {
    try {
        const numberResponse: AxiosResponse<CustomerNumberType> = yield getDIDNumbersByAccountID(
            action.payload.i_account,
        );

        yield put(
            actions.getDIDNumbersForExtension.success({
                i_account: action.payload.i_account,
                numbers: numberResponse.data.number_list,
            }),
        );
    } catch (e) {
        yield put(
            actions.getDIDNumbersForExtension.failure({
                i_account: action.payload.i_account,
            }),
        );
    }
}

export function* fetchExtensionBasicData(
    extensionID: string,
    strictExtensionPattern?: boolean,
) {
    //fetch extension
    //@ts-ignore
    const extension = yield fetchExtensionListById(extensionID);

    if (!extension) {
        return undefined;
    }

    //fetch extension numbers
    const accountList: AxiosResponse<AccountListResponse> = yield fetchAccountList(
        {extension: extensionID},
        undefined,
        strictExtensionPattern,
    );
    let numbers: CustomerDIDNumberType[] = [];
    let apiError: APIErrorInterface | undefined = undefined;
    try {
        numbers = yield fetchDIDNumbers(
            extension.i_account,
        );
    } catch (err: any) {
        apiError = err?.response?.data;
    }
    //fetch account info
    const accountInfo: ExtensionType = yield fetchAccountInfo(
        extension.i_account,
    );

    const payload: ExtensionsListItem = {
        ...extension,
        callProcessingModeInfo: accountList.data.account_list.length
            ? accountList.data.account_list[0].call_processing_mode_info
            : undefined,
        account_info: {...accountInfo},
        numbers: numbers.map((n) => n.did_number),
        apiError: apiError
    };

    if (accountList.data.account_list && accountList.data.account_list.length) {
        const didNUmber: string | undefined =
            accountList.data.account_list[0].did_number;
        didNUmber && payload.numbers?.push(didNUmber);
    }

    return payload;
}

export function* getExtension(
    action: ActionType<typeof actions.getExtensionItemDetails.request>,
) {
    const payload: ExtensionsListItem = yield fetchExtensionBasicData(
        action.payload.extensionID,
    );

    //save in store
    yield put(actions.getExtensionItemDetails.success(payload));
}

export function* uploadExtensionListCsvFile(
    action: ActionType<typeof actions.uploadExtensionsListCsvFile.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {
        body.setParams({
            file_name: action.payload.name,
            response_format: 'file',
            file_format: action.payload.file_format
        });

        if (action.payload.file) {
            body.append('upload_file', action.payload.file);

            yield api.post(Customer.UploadExtensions, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

        }

        if (action.payload.callback) {
            action.payload.callback();
        }

        yield put(actions.uploadExtensionsListCsvFile.success());
    } catch (e) {

        // @ts-ignore
        if ((e as AxiosError)?.isAxiosError) {
            const err = (e as AxiosError)?.response?.data as APIErrorInterface;

            if (err && action.payload.errorCallback) {
                action.payload.errorCallback(err);
            }

            yield put(actions.uploadExtensionsListCsvFile.failure(err));
        } else {
            yield put(actions.uploadExtensionsListCsvFile.failure(undefined));
        }
    }
}

export function* fetchDIDNumbers(i_account: number) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({i_account: i_account});

    const numberResponse: AxiosResponse<CustomerNumberType> = yield api.post(
        DID.GetCustomerNumbers,
        body,
    );

    return numberResponse.data.number_list;
}

export function* getAccountInfo(i_account: number) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        i_account: i_account,
        get_account_info: 1,
        detailed_info: '1',
        get_service_features: [
            ServiceFeatureName.DefaultAction,
            ServiceFeatureName.ForwardMode,
            ServiceFeatureName.CallProcessing,
            ServiceFeatureName.Cli,
            ServiceFeatureName.CallBarring,
            ServiceFeatureName.CallWaiting,
            ServiceFeatureName.CallRecording,
            ServiceFeatureName.UnifiedMessaging,
        ],
    });

    //@ts-ignore
    return yield api.post(Account.GetAccountInfo, body);
}

export function* getCustomerInfoDetails(customerId: number) {
    const {session_id, csrf_token} = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        i_customer: customerId,
    });

    const res2: AxiosResponse<CustomerInfo> = yield api.post(
        Customer.GetCustomerInfo,
        body,
    );

    body.delete('params');

    const customerDIDNumbersDetails: AxiosResponse<CustomerNumberType> = yield api.post(
        DID.GetCustomerNumbers,
        body,
    );

    yield put(
        actions.getCustomerInfoDetails.success({
            ...res2.data,
            didNumbers: customerDIDNumbersDetails.data.number_list,
        }),
    );

}

export function* getCustomerExtensionDetails(
    action: ActionType<typeof actions.getCustomerExtensionDetails.request>,
) {
    try {

        const {session_id, csrf_token} = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);

        yield getCustomerInfoDetails(action.payload.customerId);

        body.setParams({
            i_c_ext: action.payload.extensionId,
            detailed_info: '1',
        });

        const res: AxiosResponse<{
            extension_info: CustomerExtension;
        }> = yield api.post(Customer.GetCustomerExtensionInfo, body);

        body.setParams({
            i_owner: res.data.extension_info.i_account,
            handler: 'dial_by_name',
        });

        const fileList: AxiosResponse<ApiFileList> = yield api.post(
            CodecConverter.GetFileList,
            body,
        );

        res.data.extension_info.prompt_name = fileList.data.file_list.find(
            (v) => v.id === res.data.extension_info.i_prompt,
        )?.name;

        yield put(
            actions.getCustomerExtensionDetails.success(
                res.data.extension_info,
            ),
        );
    } catch (err: any) {
        yield put(actions.getCustomerInfoDetails.failure(err));
    }
}

export function* getExtensionsListCsvFile(
    action: ActionType<typeof actions.getExtensionListCsvFile.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({
        get_main_office_extensions: 1,
        response_format: {
            file_format: action.payload.file_format,
            return_in_file: "Y"
        }
    });

    try {
        const res: AxiosResponse<string> = yield api.post(
            Customer.GetExtensionsList,
            body,
            {responseType: 'blob'},
        );

        fileDownload(res.data, `${action.payload.fileName}.${action.payload.file_format || ListFileFormat.CSV}`);

        if (action.payload.callback) {
            action.payload.callback();
        }

        yield put(actions.getExtensionListCsvFile.success());
    } catch (e) {
        // @ts-ignore
        showErrorToast(e);
        yield put(actions.getExtensionListCsvFile.failure());
    }
}


export function* setExtensionStatusCall(payload: UpdateExtensionStatusPayload) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(payload);

    yield api.post(Account.UpdateAccount, body);
}

export function* setExtensionStatus(
    action: ActionType<typeof actions.setExtensionStatus.request>,
) {
    try {

        yield setExtensionStatusCall(action.payload)

        yield put(actions.setExtensionStatus.success(action.payload));
        showErrorToast(
            i18n.t<string>(
                action.payload.account_info.blocked === 'Y'
                    ? 'screens:extensions.extensionDisabled'
                    : 'screens:extensions.extensionEnabled',
            ),
        );
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* updateExtensionStatusCall(
    payload: UpdateExtensionStatusPayload,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(payload);

    yield api.post(Account.UpdateAccount, body);
}

export function* fetchAccountInfo(i_account?: number, id?: string, serviceFeatures ?: ServiceFeatureName[]) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const accountList: AxiosResponse<{
        account_info: ExtensionType;
    }> = yield extensionsApi.getAccountInfo(session_id, csrf_token, {
        i_account: i_account,
        id: id,
        detailed_info: 1,
        limit_alias_did_number_list: 100,
        get_service_features: serviceFeatures ? serviceFeatures : [SERVICE_FEATURE_CALL_PROCESSING],
    });

    return accountList.data.account_info;
}

export function* basicExtensionDetailsData(
    action: ActionType<typeof actions.getExtensionBasicDetailsData.request>,
) {
    const {id} = action.payload;
    const payload: ExtensionsListItem = yield fetchExtensionBasicData(id, true);

    if (!payload) {
        yield put(actions.getExtensionBasicDetailsData.failure({
            faultcode: EXTENSION_NOT_FOUND_BY_ID_API_ERROR,
            faultstring: i18n.t<string>('tooltips:extensions.extensionNotFound', {
                value: id
            })
        } as APIErrorInterface));
        return;
    }

    yield getAllServiceFeatures(payload.i_account);
    yield put(actions.getExtensionBasicDetailsData.success(payload));
}

export function* fetchExtensionListById(id: string) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams({extension: id, get_main_office_extensions: 1});
    const extResponse: AxiosResponse<{
        extensions_list: ExtensionsListItem[];
        total: number;
    }> = yield api.post(Customer.GetExtensionsList, body);

    return extResponse.data.extensions_list[0];
}

export function* editExtension(
    action: ActionType<typeof actions.editExtension.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);
        const initialFeatureState: ServiceFeature[] | undefined = yield select((state) => state.extensions?.serviceFeatures);
        const callBarringFeatureInitial = initialFeatureState
            ?.find(e => e.name === ServiceFeatureName.CallBarring);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        yield editExtensionUserDetails(dataToSave, action);
        yield editExtensionDetails(dataToSave, action);
        yield editCallSettingsServiceFeatures(dataToSave, action);
        yield editCallScreeningDetails(dataToSave, action);
        yield updateCallBarringRules({
            callBarringItems: dataToSave.callBarringItems,
            callBarringStatus: action.payload.initialValues?.callBarringStatus !== action.payload.changedValues?.callBarringStatus
                ? action.payload.changedValues?.callBarringStatus
                : undefined,
            callBarringLocked: action.payload.changedValues?.callBarringLocked
        }, action, callBarringFeatureInitial);
        yield editVoicemailSettings(dataToSave, action);
        yield editExtensionPlan(dataToSave, action);
        yield editServicePassword(dataToSave, {
            ...action,
            payload: {
                ...action.payload,
                iAccount: action.payload.accountId,
            },
        });
        yield editExtensionCallForwardingSettings(dataToSave, action);

        showErrorToast(i18n.t<string>('screens:extensions.extensionEdited'));
        yield put(actions.editExtension.success());
        action.payload.onSuccess?.();

        yield delay(1000);

        const extentionState: ExtensionsState = yield select((state) => state.extensions);

        const extensionId =
            action.payload.changedValues.extensionNumber ||
            action.payload.initialValues.extensionNumber;

        if (!action.payload.blockRedirection) {
            location?.replace(
                `${extensionId}?${qs.stringify({
                    tab: action.payload.redirectTab
                })}`
            );
        } else if (action.payload.changedValues.extensionNumber &&
            action.payload.changedValues.extensionNumber !== action.payload.initialValues.extensionNumber) {
            const extension = extentionState?.extensionList?.items?.find(e => e.i_account === action.payload.initialValues.iAccount);
            if (extension) {
                extension.id = action.payload.changedValues.extensionNumber;
                yield put(
                    actions.getExtensionsList.success({
                        items: extentionState?.extensionList?.items || [],
                        total: extentionState?.extensionList?.total || 0,
                        filters: extentionState?.extensionList?.filters,
                        registeredTotal: extentionState?.extensionList?.registeredTotal || 0,
                    }),
                );
            }
        }
    } catch (err: any) {
        const errorMsg = recordingValidationMsg(err.response.data.faultcode, err.response?.data?.faultstring);

        showErrorToast(
            errorMsg,
            err.response?.data?.faultcode,
            [
                UpdateExtensionFaultCode.DuplicateId,
                UpdateExtensionFaultCode.DuplicateLogin,
                UpdateExtensionFaultCode.AccountUpdatedFailed,
                UpdateExtensionFaultCode.ExtHGNumberInUse,
                CreateExtensionFaultCode.ExtHGNumberInUse,
                PasswordFaultCode.InvalidChars
            ],
        );

        yield put(actions.editExtension.failure(err.response?.data));
    }
}

export function* editServicePassword(
    data: Partial<EditExtensionForm>,
    action: ActionType<typeof actions.editExtension.request>,
) {
    const {servicePassword} = data;
    const {iAccount} = action.payload;

    if (servicePassword !== undefined) {
        const {session_id, csrf_token} = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            account_info: {
                i_account: iAccount,
                h323_password: servicePassword,
            },
        });
        const response: AxiosResponse<{
            i_account: number;
        }> = yield api.post(Account.UpdateAccount, body);

        const {i_account} = response.data;
        yield put(
            actions.postServicePassword.success({
                i_account,
                account_inf: action.payload,
            }),
        );
    } else {
        yield put(
            actions.postServicePassword.success({
                i_account: iAccount || 0,
                account_inf: action.payload,
            }),
        );
    }
}

function* editExtensionDetails(
    data: Partial<EditExtensionForm>,
    action: ActionType<typeof actions.editExtension.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    const accountInfo: Partial<CustomerExtension> = {
        i_c_ext: action.payload.extensionId,
    };

    if (data.extensionName !== undefined) {
        accountInfo.name = data.extensionName;
    }

    if (data.extensionNumber !== undefined) {
        accountInfo.id = data.extensionNumber;
    }

    if (data.includeCorpDirectory !== undefined) {
        accountInfo.published = data.includeCorpDirectory ? 'Y' : 'N';
    }

    if (data.recordName && data.recordFile) {
        accountInfo.prompt_action = 'set';
        body.append('upload_file', data.recordFile);
    }

    if (Object.keys(accountInfo).length > 1) {
        body.setParams(accountInfo);
        yield api.post(Customer.UpdateCustomerExtension, body);

        body.delete('upload_file');
    }

    if (!!data.displayNumber) {
        const params = {
            i_account: action.payload.accountId,
            service_features: [
                {
                    name: ServiceFeatureName.Cli,
                    attributes: [
                        {
                            effective_values: [data.displayNumber],
                            name: 'centrex',
                            values: [data.displayNumber],
                        },
                    ],
                    effective_flag_value: 'Y',
                    flag_value: 'Y',
                },
            ],
        };

        body.setParams(params);

        yield api.post(Account.UpdateServiceFeatures, body);
    }

    if (data.displayNumber === null) {
        const params = {
            i_account: action.payload.accountId,
            service_features: [
                {
                    name: ServiceFeatureName.Cli,
                    attributes: [
                        {
                            name: 'centrex',
                        },
                    ],
                    flag_value: '^'
                },
            ],
        };

        body.setParams(params);

        yield api.post(Account.UpdateServiceFeatures, body);
    }

    if (data.sharedLine !== null && data.sharedLine !== undefined) {
        const params = {
            i_account: action.payload.accountId,
            service_features: [
                {
                    name: ServiceFeatureName.LineSharing,
                    attributes: [],
                    effective_flag_value: data.sharedLine ? YesNo.Yes : YesNo.No,
                    flag_value: data.sharedLine ? YesNo.Yes : YesNo.No
                },
            ],
        };

        body.setParams(params);

        yield api.post(Account.UpdateServiceFeatures, body);
    }

    if (data.didNumber) {
        const customerInfo: CustomerInfo & {
            didNumbers: CustomerDIDNumberType[];
        } = yield select(
            (state: ReduxState) => state.extensions.customerInfoDetails,
        );

        yield updateAccountDIDNumbers(
            action.payload.initialValues.didNumber,
            data.didNumber,
            customerInfo.didNumbers,
            action.payload.accountId,
        );
    }

    yield editExtensionRingGroups(data, action);
}

function* editExtensionUserDetails(
    data: Partial<EditExtensionForm>,
    action: ActionType<typeof actions.editExtension.request>,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    const account: Partial<ExtensionType> = {
        i_account: action.payload.accountId,
    };

    if (data.postalCode !== undefined) {
        account.zip = data.postalCode;
    }
    if (data.state !== undefined) {
        account.state = data.state;
    }
    if (data.city !== undefined) {
        account.city = data.city;
    }
    if (data.address !== undefined) {
        account.baddr1 = data.address;
    }
    if (data.country !== undefined) {
        account.country = data.country;
    }
    if (data.emailAddress !== undefined) {
        account.email = data.emailAddress;
    }
    if (data.portalPassword !== undefined) {
        account.password = data.portalPassword || null;
        account.login = action.payload.initialValues.portalLogin || null;
    }
    if (data.portalLogin !== undefined) {
        account.login = data.portalLogin || null;

        if (account.password === undefined) {
            account.password =
                action.payload.initialValues.portalPassword || null;
        }
    }
    if (data.timezone !== undefined) {
        const timezones: TimeZone[] = yield select(
            (state: ReduxState) => state.generic.timeZonesList,
        );

        const timezoneId = timezones.find(
            (v) => v.time_zone_name === data.timezone,
        )?.i_time_zone;

        if (timezoneId) {
            account.time_zone_name = data.timezone;
            account.i_time_zone = timezoneId;
        }
    }

    body.setParams({account_info: account});

    if (Object.keys(account).length > 1) {
        yield api.post(Account.UpdateAccount, body);
    }
}

export function* refreshExtensionStatus(
    action: ActionType<typeof actions.refreshExtensionStatus.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            i_account: action.payload.i_account,
            detailed_info: 1,
            limit_alias_did_number_list: 100,
        });

        const accountDetails: AxiosResponse<{
            account_info: ExtensionType;
        }> = yield api.post(Account.GetAccountInfo, body);

        yield put(
            actions.refreshExtensionStatus.success({
                i_account: action.payload.i_account,
                account_info: accountDetails.data.account_info,
            }),
        );
    } catch (e) {
        yield put(actions.refreshExtensionStatus.failure());
    }
}

export function* deleteExtensionFromExtensionListView(
    action: ActionType<typeof actions.deleteCustomerExtension.request>,
) {
    const {id, i_c_ext, i_customer} = action.payload;

    try {
        yield deleteExtension(id, i_customer, i_c_ext);
        showErrorToast(i18n.t<string>('screens:extensions.deletedExtension'));
        yield put(actions.deleteCustomerExtension.success({id}));
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.deleteCustomerExtension.failure());
    }
}

export function* deleteExtension(
    id: string,
    i_customer: number,
    i_c_ext?: any,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    let extension: ExtensionsListItem | undefined;
    if (!i_c_ext) {
        extension = yield fetchExtensionListById(id);
    }

    body.setParams({
        i_c_ext: extension?.i_c_ext ?? i_c_ext,
        i_customer: i_customer,
        dont_release_did_number_to_pool: 1,
    });

    yield api.post(Customer.DeleteCustomerExtension, body);
}

export function* deleteExtensionFromExtensionDetailsView(
    action: ActionType<
        typeof actions.deleteCustomerExtensionDetailsView.request
    >,
) {
    const {id, i_c_ext, i_customer} = action.payload;

    try {
        yield deleteExtension(id, i_customer, i_c_ext);
        showErrorToast(i18n.t<string>('screens:extensions.deletedExtension'));
        yield put(actions.deleteCustomerExtensionDetailsView.success());
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.deleteCustomerExtensionDetailsView.failure());
    }
}

export function* createExtensionDetailsData(
    action: ActionType<typeof actions.createExtensionDetailsData.request>,
) {
    try {
        yield getExtensionProducts(action.payload?.i_account);
        yield put(actions.createExtensionDetailsData.success());
    } catch (err) {
        yield put(actions.createExtensionDetailsData.failure());
    }
}

export function* createNewExtension(
    action: ActionType<typeof actions.createNewExtension.request>,
) {
    try {
        const {session_id, csrf_token} = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            i_product: action.payload.mainProductId,
            name: action.payload.extensionName,
            id: action.payload.extensionNumber,
        });

        const response: AxiosResponse<{ i_c_ext: number }> = yield api.post(
            Customer.AddCustomerExtension,
            body,
        );

        if (action.payload.device && action.payload.port) {
            yield call(
                assignDeviceToExtension,
                actions.assignDevice.request({
                    port: action.payload.port.port,
                    i_cpe: action.payload.device.i_ua,
                    i_c_ext: response.data.i_c_ext,
                }),
            );
        }

        showErrorToast(i18n.t<string>('screens:extensions.extensionCreated'));

        yield put(actions.createNewExtension.success());

        location?.replace(
            getRoute(Routes.ExtensionsDetails, {
                id: action.payload.extensionNumber,
            }),
        );
    } catch (err: any) {
        if (
            !err.response?.data?.faultcode?.includes(
                CreateExtensionFaultCode.MaxOfferedQuantity,
            ) &&
            err.response?.data?.faultcode !=
            CreateExtensionFaultCode.DuplicateId &&
            err.response?.data?.faultcode !=
            CreateExtensionFaultCode.ExtHGNumberInUse
        ) {
            action.payload.errorCallback?.();
        }
        yield put(actions.createNewExtension.failure(err.response?.data));
    }
}

export function* addCustomerExtension(
    name: string,
    number: string,
    iAccount?: number,
    productId?: number,
) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        i_product: productId,
        name: name,
        id: number,
        i_account: iAccount,
    });

    const response: AxiosResponse<{ i_c_ext: number }> = yield api.post(
        Customer.AddCustomerExtension,
        body,
    );

    return response.data.i_c_ext;
}

export function* getExtensionDialingRule(i_dial_rule: string) {
    const {session_id, csrf_token} = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {
        body.setParams({
            i_dialing_rule: i_dial_rule,
        });

        const rule: AxiosResponse<{
            dialing_rule_info: DialingRuleInfo;
        }> = yield api.post(DialingRule.GetDialingRuleInfo, body);

        yield put(
            actions.getExtensionDialingRules.success(rule.data.dialing_rule_info),
        );
    } catch (e: any) {
        const error = e?.response?.data;
        yield put(actions.getExtensionDialingRules.failure(error));
    }
}

export function* fetchSwitchModeData() {
    try {
        yield all([
            call(getHuntGroupList, actions.getHuntGroupList.request({})),
            call(
                getCallProcessingOperationModeList,
                actions.getCallProcessingOperationModeList.request({
                    check_usage: 1
                }),
            ),
            call(
                getResponseMessageList,
                actions.onResponseMessageList.request(),
            ),
        ]);

        yield getGlobalCustomerInfo();

        yield put(actions.fetchDataForSwitchMode.success());
    } catch (e) {
        //@ts-ignore
        showErrorToast(e.response?.data?.faultstring);
        yield put(actions.fetchDataForSwitchMode.failure());
    }
}

function* updateAccountCallProcessingMode(payload: SwitchModeRequestPayload) {
    const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        call_processing_mode_info: {
            short_code: payload.short_code,
            i_response_message: payload.i_response_message,
            effective_to: payload.effective_to,
        },
        i_c_group: payload.i_c_group,
        i_customer: payload.i_customer,
    });

    //@ts-ignore
    return yield api.post(Account.UpdateAccountsCallProcessingMode, body);
}

export function* saveSwitchModeForm(
    action: ActionType<typeof actions.updateAccountsCallProcessingMode.request>,
) {
    try {
        const res: AxiosResponse<UpdateAccountCallProcessingModeResponse> = yield updateAccountCallProcessingMode(
            action.payload,
        );

        const {total_mode_changed, total} = res.data;
        showErrorToast(
            i18n.t<string>('screens:switchModeDialog.saveComplete', {
                remain: total - total_mode_changed,
                updated: total_mode_changed,
            }),
        );

        yield put(actions.updateAccountsCallProcessingMode.success());
        action.payload.callback?.();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
        showErrorToast(e.response?.data?.faultstring);
        yield put(actions.updateAccountsCallProcessingMode.failure());
    }
}

export function* getCallProcessingOperationModeList(
    action: ActionType<
        typeof actions.getCallProcessingOperationModeList.request
    >,
) {
    try {
        const {session_id, csrf_token} = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams(action.payload);
        const res: AxiosResponse<CallProcessingOperationModeListResponse> = yield api.post(
            Customer.GetCallProcessingOperationModeList,
            body,
        );

        yield put(
            actions.getCallProcessingOperationModeList.success(
                res.data.operation_mode_list,
            ),
        );
    }
    catch(e) {
        const err = (e as AxiosError)?.response?.data as APIErrorInterface;
        yield put(
            actions.getCallProcessingOperationModeList.failure(err)
        );
    }
}
