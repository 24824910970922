import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IncomingCallIcon } from '../../assets/incomingCall.svg';
import classNames from 'classnames';
import { useStyles } from './RingGroupsIncomingCall.utils';

export interface RingGroupsCallsCellProps {
    queued: number;
    active: number;
    onHold: number;
};

export const RingGroupsIncomingCall: React.FC<RingGroupsCallsCellProps> = ({
    queued,
    active,
    onHold
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const bMarginShouldBeShown = ((queued > 0 ? 1 : 0) + (active > 0 ? 1 : 0) + (onHold > 0 ? 1 : 0)) === 2;
    const bDoubleMarginShouldBeShown = queued > 0 && active > 0 && onHold > 0;
    const bIconShown = queued > 0 || active > 0 || onHold > 0;
    
    return (
        <div className={classes.mainContainer}>
            {bIconShown && (
                <div className={classes.iconContainer}>
                    <div className={classNames(classes.iconSubContainer, 
                            bMarginShouldBeShown && classes.marginCallIcon, 
                            bDoubleMarginShouldBeShown && classes.marginDoubleCallIcon)}>
                        <IncomingCallIcon />
                    </div>
                </div>
            )}
            <div className={classes.labelsContainer} data-testid='queued-active-labels-container'>
                {queued > 0 && (
                    <div className={classes.queuedLabel} data-testid='queued-label'>
                        {queued} {t('screens:ringGroups.queued')}
                    </div>
                )}
                {active > 0 && (
                        <div className={classes.activeLabel} data-testid='active-label'>
                            {active} {t('screens:ringGroups.active')}
                        </div>
                    )
                }
                {onHold > 0 
                    ? (
                        <div className={classes.activeLabel} data-testid='on-hold-label'>
                            {onHold} {t('screens:ringGroups.onHoldInList')}
                        </div>
                    ) 
                    : (queued === 0) && (active === 0) && (
                        <div className={classes.emptyCallsContainer}>
                            &mdash;
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default RingGroupsIncomingCall;
