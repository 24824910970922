import { makeStyles } from '@material-ui/core/styles';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import { convertToUserFormat } from '../../../utils/dateWithTimezoneConversion';
import React from 'react';
import DateTimeCell from '../../Calls/DateTimeCell';

export const useStyles = makeStyles(() => ({
    header: {
        '& th:nth-child(5)': {
            display: ' flex',
            justifyContent: 'end',
        },
        '& th:nth-child(4)': {
            display: ' flex',
            justifyContent: 'end',
        },
    },

    tableContent: {
        '& td': {
            alignItems: 'center !important',
        },

        '& td:nth-child(5)': {
            display: 'flex',
            justifyContent: 'end',
        },
        '& td:nth-child(4)': {
            display: 'flex',
            justifyContent: 'end',
        },
    },
}));

export interface QuantityBasedTableRowInterface {
    id: string;
    accountId?: string;
    description?: string;
    date?: string;
    quantity?: number;
    chargedAmount?: number;
}

export const generateColumns = (
    t: TFunction<string>,
    currency = '',
    userDateTimeFormat?: string,
    unit = '',
): Column<QuantityBasedTableRowInterface>[] => {
    return [
        {
            Header: t<string>('screens:billing.accountID'),
            accessor: 'accountId',
            width: 1,
            Cell: function Cell(params) {
                const text = params.row.original.accountId;

                return (
                    <OverflowTooltip
                        tooltip={text}
                        text={text}
                        dataQa={'account-id-text'}
                        copy={false}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:calls.description'),
            accessor: 'description',
            width: 2,
            Cell: function Cell(params) {
                const text = params.row.original.description;

                return (
                    <OverflowTooltip
                        tooltip={text}
                        text={text}
                        dataQa={'description-text'}
                        copy={false}
                    />
                );
            },
        },
        {
            Header: t<string>('common:date'),
            width: 1,
            accessor: 'date',
            Cell: function Cell(params) {
                return (
                    <DateTimeCell
                        time={(params.row.original.date as string) || ''}
                        customDateFormat={userDateTimeFormat}
                    />
                );
            },
        },

        {
            Header: t<string>('screens:billing.QuantityWithUnit', {
                unit: unit,
            }),
            width: 1,
            accessor: 'quantity',
            Cell: function Cell(params) {
                return params.row.original?.quantity?.toFixed(2)?.replace(/\.0+$/, '');
            },
        },
        {
            Header: t<string>('screens:billing.chargedWithCurrency', {
                currency: currency,
            }),
            width: 0.7,
            accessor: 'chargedAmount',
            Cell: function Cell(params) {
                return params.row.original.chargedAmount
                    ? params.row.original.chargedAmount.toFixed(2)
                    : Number(0).toFixed(2);
            },
        },
    ];
};