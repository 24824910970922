import React, {useEffect} from "react";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../../store/types";
import {actions} from "../../store";

interface ErrorNotificationProps {
    tabName: string,
    isSubmitting: boolean,
}

const ErrorNotification: React.VFC<ErrorNotificationProps> = ({isSubmitting, tabName}) => {
    const {t} = useTranslation();
    const msg = t('errors:singleTabError');

    const dispatch = useDispatch();


    const errors = useSelector(
        (state: ReduxState) =>
            state.tabs?.errors || []
    );

    useEffect(() => {
        dispatch(actions.clearErrorInTabs())
    }, [])

    useEffect(() => {

        const errorsOnCurrentTab = errors.filter(item => item.name == tabName && item.markAsInvalid == true);
        const errorsOnOtherTabs = errors.filter(item => item.name !== tabName && item.markAsInvalid == true);

        if (!errorsOnCurrentTab.length && errorsOnOtherTabs.length && isSubmitting) {
            toast(msg, {duration: 1500});

            setTimeout(() => {
                dispatch(actions.saveForms({visible: false}))
            }, 100)
        }

    }, [isSubmitting]);

    return <></>
}

export default ErrorNotification;