import React from "react";
import {PermissionType} from "../../store/types/Permission";
import UnifiedAccessDenied from "../PermissionProvider/UnifiedAccessDenied";
import {useTranslation} from "react-i18next";

const ReadOnlyWarning: React.VFC<{permission:PermissionType}> = ({permission}) => {

    const {t} = useTranslation();

    if(permission !== PermissionType.ReadOnly)
    {
        return <></>
    }

    return (
        <UnifiedAccessDenied title={t('common:accessLimited')+'. '}> {t('common:limitedPermissionToPage')}</UnifiedAccessDenied>
    )
}

export default ReadOnlyWarning

