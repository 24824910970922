import {useSelector} from 'react-redux';
import {ReduxState} from '../store/types';
import {useMemo} from 'react';
import {EXTENSION_NOT_FOUND_BY_ID_API_ERROR} from "../store/sagas/extensions/extensions/saga";

export const useSipTrunkMain = () => {
    const { sipTrunk, apiErrors} = useSelector(
        (state: ReduxState) => state.sipTrunks?.sipTrunkDetails || {
            sipTrunk:undefined,
            apiErrors:undefined
        }
    );

    const {isSipTrunkDetailsLoaded}
        = useSelector((state: ReduxState) => state.sipTrunks);

    return useMemo(() => {
        return {
            notFound: apiErrors?.faultcode === EXTENSION_NOT_FOUND_BY_ID_API_ERROR,
            sipTrunk: sipTrunk,
            i_account: sipTrunk?.i_account,
            is_blocked: sipTrunk?.account_info?.blocked === 'Y',
            bill_status: sipTrunk?.account_info?.bill_status,
            didNumbers: sipTrunk?.numbers,
            sip_status: sipTrunk?.account_info?.sip_status,
            sip_agent: sipTrunk?.account_info?.sip_agent,
            i_c_ext: sipTrunk?.i_c_ext,
            extension_id: sipTrunk?.id,
            i_customer: sipTrunk?.i_customer,
            account_id: sipTrunk?.account_info?.id,
            um_domain: sipTrunk?.account_info?.um_domain,
            country: sipTrunk?.account_info?.country,
            name: sipTrunk?.name,
            calls: sipTrunk?.calls,
            isSipTrunkDetailsLoaded
        };
    }, [sipTrunk,isSipTrunkDetailsLoaded]);
};
