import classNames from 'classnames';
import React from 'react';
import { Fade, makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';

export type WidgetLoadingMaskProps = {
    darkMode: boolean;
    isLoadingChart: boolean;
    customClasses?: {
        container?: string,
        label?: string,
    }
};

export const useStyles = makeStyles(() => ({
    loadingBackground: {
        display: 'flex',
        position: 'relative',
        background: 'white',
        opacity: '0.83 !important',
        width: '100%',
        height: '100%',
        top: '-100%',
        left: 0,
    },
    loadingBackgroundDarkMode: {
        background: '#201F2B',
    },
    loadingLabel: {
        backgroundColor: 'transparent',
        width: '100%',
        height: 28,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 'calc(50% - 28px)',
        color: Colors.Gray5,
        fontSize: 14,
        fontWeight: 400,
        fontStyle: 'italic',
    },
    loadingLabelDarkMode: {
        color: '#F5F7F8'
    },
}));

const WidgetLoadingMask: React.VFC<WidgetLoadingMaskProps> = ({
    darkMode,
    isLoadingChart,
    customClasses
}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Fade in={isLoadingChart} timeout={1000} appear={isLoadingChart}>
            <div className={classNames(classes.loadingBackground, darkMode && classes.loadingBackgroundDarkMode, customClasses?.container)}>
                <div className={classNames(classes.loadingLabel, darkMode && classes.loadingLabelDarkMode, customClasses?.label)}>
                    {t('common:loading')}
                </div>
            </div>
        </Fade>
    );
};
export default WidgetLoadingMask;