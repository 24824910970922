import { AxiosResponse } from 'axios';
import { api, buildPayload, CONTENT_TYPE } from './axios';
import { MailMePayload } from '../actions/auth/payloads';

export interface ApiLoginPayload {
    login: string;
    password: string;
    enable_csrf_protection?: number;
}

export interface ApiChangePasswordPayload {
    login: string;
    password: string;
    new_password: string;
    one_time_password?: string;
    onSuccess?: () => void
}

export async function postLogin(
    payload: ApiLoginPayload,
): Promise<AxiosResponse> {
    const data = buildPayload(payload);
    const options = {
        headers: {
            'Content-Type': CONTENT_TYPE,
        },
        withCredentials: true
    };

    return api.post('/Session/login', data, options);
}

export async function postChangePassword(
    payload: ApiChangePasswordPayload,
): Promise<AxiosResponse> {
    const data = buildPayload(payload);
    const options = {
        headers: {
            'Content-Type': CONTENT_TYPE,
        },
    };

    return api.post('/Session/change_password', data, options);
}

export async function postPasswordRecovery(
    payload: MailMePayload,
): Promise<AxiosResponse> {
    const data = buildPayload(payload);
    const options = {
        headers: {
            'Content-Type': CONTENT_TYPE,
        },
    };

    return api.post('/Session/reset_password', data, options);
}

export async function postPing(session_id: string, csrf_token: string) {
    const data = buildPayload({ session_id: session_id, csrf_token: csrf_token });
    const options = {
        headers: {
            'Content-Type': CONTENT_TYPE,
        },
    };

    return api.post('/Session/ping', data, options);
}
