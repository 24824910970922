import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import TextField from '../../../TextField/TextField';
import SelectField from '../../../SelectField/SelectField';
import classNames from 'classnames';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import { Country } from '../../../../store/types/Country';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import { actions } from '../../../../store';
import { Subdivision } from '../../../../store/types/Subdivision';
import FormActions from '../../FormActions';
import { cloneDeep } from 'lodash';
import {
    CompanyInfoFormType,
    useStyles,
    companyInfoFormDefaultValues,
    CompanyInfoFormValidationSchema,
} from './utils';
import InputsParser from '../../../InputParser/InputsParser';
import Loader from '../../../../components/Loader/Loader';
import PreventLeavingPage from '../../../../components/AlertDialog/PreventLeavingPage';
import dayjs from '../../../../services/customDayJs';
import { convertFormatFromBackendToDayJs } from '../../../../utils/dateWithTimezoneConversion';
import {Permission} from "../../../../store/types/Permission";

type CompanyInfoFormProps = {
    companyInfoDetails: CompanyInfoFormType,
    hideCompanyName?: boolean,
    readOnly?: boolean,
    scrollPaddingForNotTabMode?: boolean,
    isDirtyOtherTabs?: boolean;
    isDirtyChanged?: (dirty: boolean) => void;
    hideFormsControlsIfReadOnly?: boolean;
} 

const CompanyInfoForm: React.FC<CompanyInfoFormProps> = ({
    companyInfoDetails,
    hideCompanyName = false,
    readOnly = false,
    scrollPaddingForNotTabMode = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children,
    isDirtyOtherTabs,
    isDirtyChanged,
    hideFormsControlsIfReadOnly = true
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const subdivisionsList = useSelector<ReduxState, Subdivision[]>((state) => [
        {
            i_country_subdivision: 0,
            iso_3166_1_a2: '',
            iso_3166_2: '',
            name: '',
        },
        ...state.generic?.subdivisionsList,
    ]);

    const countriesList = useSelector<ReduxState, Country[]>(
        (state) => state.generic.countriesList,
    );

    const isFormSaving = useSelector<ReduxState, boolean>(
        (state) => !!state.company.isFormSaving,
    );
    
    const isLoading = useSelector<ReduxState, boolean>(
        (state) => !!state.company.isLoading,
    );

    const countriesOptionsList = [
        {
            iso_3166_1_a2: '',
            name: '',
        },
        ...(countriesList || []),
    ];

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const initialValues: CompanyInfoFormType = useMemo(
        () =>
            companyInfoDetails
                ? {
                        ...cloneDeep(companyInfoDetails),
                        dateFormat: userDateTimeFormat,
                    }
                : companyInfoFormDefaultValues,
        [companyInfoDetails, userDateTimeFormat],
    );
    
    const updateCompanyInfoEventRaised = useSelector<ReduxState, boolean>(
        (state) => state.company.updateCompanyInfoEventRaised ?? false
    );

    const getCurrentTime = useCallback(
        (time = dayjs(), format = userDateTimeFormat) =>
            dayjs
                .utc(time, format)
                .utcOffset(timezoneOffset / 60)
                .format(convertFormatFromBackendToDayJs(format)),
        [],
    );

    const dateFormats = useMemo(() => {
        const time = dayjs();

        return [
            'YYYY-MM-DD HH24:MI:SS',
            'MM/DD/YYYY HH24:MI:SS',
            'MM/DD/YY HH12:MI:SS AM',
            'DD-MM-YYYY HH24:MI:SS',
            'DD-MM-YY HH24:MI:SS',
            'DD.MM.YYYY HH24:MI:SS',
            'DD.MM.YY HH24:MI:SS',
            'D-MON-YY HH24:MI:SS',
        ].map((v) => ({ name: getCurrentTime(time, v), value: v }));
    }, []);

    const {
        values,
        setFieldValue,
        handleChange,
        errors,
        setFieldError,
        dirty,
    } = useFormik<CompanyInfoFormType>({
        initialValues,
        onSubmit: (form) => {
            onSubmit(form);
        },
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: CompanyInfoFormValidationSchema,
    });

    useEffect(() => {
        isDirtyChanged?.(dirty);
    }, [dirty]);

    const onSubmit = (form: CompanyInfoFormType) => {
        dispatch(
            actions.updateCompanyInfoForm.request({
                initialValues,
                changedValues: form,
                date: form.dateFormat,
            }),
        );
    };
    
    useEffect(() => {
        if(updateCompanyInfoEventRaised && !readOnly) {
            onSubmit(values);
        }
    }, [updateCompanyInfoEventRaised]);

    const getSubdivisions = (iso_3166_1_a2?: string) => {
        if (iso_3166_1_a2) {
            dispatch(
                actions.getSubdivisionData.request({
                    iso_3166_1_a2,
                }),
            );
        }
    };

    const onSelectCountry = (event: React.ChangeEvent<{}>, value: string) => {
        const country = countriesOptionsList?.find((v) => v.name === value)
            ?.iso_3166_1_a2;

        setFieldValue('country', country || '', false);
        setFieldValue('state', '');
        getSubdivisions(country);
    };

    const onSelectProvinceState = (
        event: React.ChangeEvent<{}>,
        value: string,
    ) => {
        if(!isLoading) {
            setFieldValue('provinceState', value);
        }
    };

    useEffect(() => {
        if (values.country) {
            getSubdivisions(values.country);
        }
    }, [values.country]);

    return (
        <>
            <div className={classNames(classes.scrollable,
                scrollPaddingForNotTabMode ? classes.scrollPaddingForNotTabMode : null
                )}>
                    {children}
                    <form className={classes.formContainer}>
                        <div className={classes.inputs}>
                            {!hideCompanyName ? (<Grid
                                item
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.companyName,
                                )}
                            >
                                <TextField
                                    id={'companyName'}
                                    label={t('screens:myCompany.companyName')}
                                    onChange={handleChange}
                                    value={values?.companyName}
                                    dataQa="my-company-name"
                                    setFieldError={setFieldError}
                                    maxLength={100}
                                    readOnly={readOnly}
                                />
                            </Grid>) : (<div style={{display: 'none'}}></div>)}
                            <Grid
                                item
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.columnItemsContainer,
                                    classes.firstContainer,
                                )}
                            >
                                <Grid
                                    item
                                    className={classNames(
                                        classes.insideItemsContainer,
                                        classes.sectionSpace,
                                        classes.columnItemsContainer,
                                    )}
                                >
                                    <Box
                                        className={classes.headerBox}
                                        width={350}
                                    >
                                        <span className={classes.rowBoxHeader}>
                                            {t(
                                                'screens:myCompany.generalInformation',
                                            )}
                                        </span>

                                        <IconWithTooltip
                                            dataQa="my-company-contact-information-for-invoices-tooltip"
                                            tooltipText={t(
                                                'tooltips:myCompany.generalInformation',
                                            )}
                                        />
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <TextField
                                            id="salutation"
                                            label={t(
                                                'screens:myCompany.salutation',
                                            )}
                                            onChange={handleChange}
                                            value={values?.salutation}
                                            dataQa="my-company-salutation-input"
                                            helperText={errors.salutation || ''}
                                            setFieldError={setFieldError}
                                            maxLength={15}
                                            readOnly={readOnly}
                                        />

                                        <TextField
                                            id="firstName"
                                            label={t(
                                                'screens:myCompany.firstName',
                                            )}
                                            onChange={handleChange}
                                            value={values?.firstName}
                                            dataQa="my-company-firstName"
                                            helperText={errors.firstName || ''}
                                            setFieldError={setFieldError}
                                            maxLength={120}
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <TextField
                                            id="middleName"
                                            label={t(
                                                'screens:myCompany.middleName',
                                            )}
                                            onChange={handleChange}
                                            value={values?.middleName}
                                            dataQa="my-company-middle-name"
                                            helperText={errors.middleName || ''}
                                            setFieldError={setFieldError}
                                            maxLength={25}
                                            readOnly={readOnly}
                                        />

                                        <TextField
                                            id="lastName"
                                            label={t(
                                                'screens:myCompany.lastName',
                                            )}
                                            onChange={handleChange}
                                            value={values?.lastName}
                                            dataQa="my-company-last-name"
                                            helperText={errors.lastName || ''}
                                            setFieldError={setFieldError}
                                            maxLength={120}
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <TextField
                                            id="email"
                                            label={t('common:emailAddress')}
                                            onChange={handleChange}
                                            value={values?.email}
                                            type={'email'}
                                            dataQa="my-company-email"
                                            helperText={errors.email || ''}
                                            setFieldError={setFieldError}
                                            maxLength={128}
                                            readOnly={readOnly}
                                        />

                                        <TextField
                                            id="fax"
                                            label={t('screens:myCompany.fax')}
                                            onChange={handleChange}
                                            value={values?.fax}
                                            dataQa="my-company-fax"
                                            helperText={errors.fax || ''}
                                            setFieldError={setFieldError}
                                            maxLength={21}
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <TextField
                                            id="phone"
                                            label={t('screens:myCompany.phone')}
                                            onChange={handleChange}
                                            value={values?.phone || ''}
                                            dataQa="my-company-phone"
                                            helperText={errors.phone || ''}
                                            setFieldError={setFieldError}
                                            className={classes.shortField}
                                            maxLength={21}
                                            readOnly={readOnly}
                                        />
                                        <SelectField
                                            label={t(
                                                'screens:myCompany.dateTimeFormat',
                                            )}
                                            dataQa="my-company-date-and-time-format"
                                            items={dateFormats}
                                            getOptionLabel={(item) => item.name}
                                            value={
                                                dateFormats.find(
                                                    (v) =>
                                                        v.value ===
                                                        values.dateFormat,
                                                ) || null
                                            }
                                            onChange={(_, value) =>
                                                setFieldValue(
                                                    'dateFormat',
                                                    value.value,
                                                )
                                            }
                                            disableClearable
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.columnItemsContainer,
                                    classes.firstContainer,
                                )}
                            >
                                <Grid
                                    item
                                    className={classNames(
                                        classes.insideItemsContainer,
                                        classes.sectionSpace,
                                        classes.columnItemsContainer,
                                    )}
                                >
                                    <Box
                                        className={classes.headerBox}
                                        width={350}
                                    >
                                        <span className={classes.rowBoxHeader}>
                                            {t('screens:myCompany.address')}
                                        </span>

                                        <IconWithTooltip
                                            dataQa="my-company-address"
                                            tooltipText={t(
                                                'tooltips:myCompany.addressInformation',
                                            )}
                                        />
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <SelectField
                                            id="country"
                                            label={t(
                                                'screens:myCompany.country',
                                            )}
                                            items={
                                                countriesOptionsList?.map(
                                                    (v) => v.name,
                                                ) || []
                                            }
                                            value={
                                                countriesOptionsList?.find(
                                                    (v) =>
                                                        v.iso_3166_1_a2 ===
                                                        values?.country,
                                                )?.name || ''
                                            }
                                            onChange={onSelectCountry}
                                            dataQa="my-company-country"
                                            classes={{
                                                container: classes.container,
                                            }}
                                            readOnly={readOnly}
                                        />

                                        <TextField
                                            id="city"
                                            label={t('screens:myCompany.city')}
                                            onChange={handleChange}
                                            value={values?.city}
                                            dataQa="my-company-city"
                                            helperText={errors.city || ''}
                                            setFieldError={setFieldError}
                                            maxLength={31}
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <TextField
                                            id="addressLine1"
                                            label={t(
                                                'screens:myCompany.addressLine1',
                                            )}
                                            onChange={handleChange}
                                            value={values?.addressLine1}
                                            dataQa="my-company-address-line1"
                                            helperText={
                                                errors.addressLine1 || ''
                                            }
                                            setFieldError={setFieldError}
                                            maxLength={41}
                                            readOnly={readOnly}
                                        />

                                        <TextField
                                            id="addressLine2"
                                            label={t(
                                                'screens:myCompany.addressLine2',
                                            )}
                                            onChange={handleChange}
                                            value={values?.addressLine2}
                                            dataQa="my-company-address-line2"
                                            helperText={
                                                errors.addressLine2 || ''
                                            }
                                            setFieldError={setFieldError}
                                            maxLength={167}
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <SelectField
                                            label={t(
                                                'screens:myCompany.provinceState',
                                            )}
                                            dataQa="my-company-province-state"
                                            items={
                                                subdivisionsList?.map(
                                                    (v) => v.name,
                                                ) || []
                                            }
                                            value={values?.provinceState}
                                            onChange={onSelectProvinceState}
                                            disabled={!values?.country}
                                            classes={{
                                                container: classes.container,
                                            }}
                                            readOnly={readOnly}
                                        />

                                        <TextField
                                            id="postalCode"
                                            label={t(
                                                'screens:myCompany.postalCode',
                                            )}
                                            onChange={handleChange}
                                            value={values?.postalCode}
                                            dataQa="my-company-postal-code"
                                            helperText={errors.postalCode || ''}
                                            setFieldError={setFieldError}
                                            maxLength={10}
                                            inputProps={{
                                                pattern: '[0-9]*',
                                            }}
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.columnItemsContainer,
                                    classes.firstContainer,
                                )}
                            >
                                <Grid
                                    item
                                    className={classNames(
                                        classes.insideItemsContainer,
                                        classes.sectionSpace,
                                        classes.columnItemsContainer,
                                    )}
                                >
                                    <Box
                                        className={classes.headerBox}
                                        width={350}
                                    >
                                        <span className={classes.rowBoxHeader}>
                                            {t(
                                                'screens:myCompany.additionalContact',
                                            )}
                                        </span>

                                        <IconWithTooltip
                                            dataQa="my-company-additional-contact"
                                            tooltipText={t(
                                                'tooltips:myCompany.additionalContact',
                                            )}
                                        />
                                    </Box>
                                    <Box className={classes.rowBox}>
                                        <TextField
                                            id="contactPerson"
                                            label={t(
                                                'screens:myCompany.contactPerson',
                                            )}
                                            onChange={handleChange}
                                            value={values?.contactPerson}
                                            dataQa="my-company-contact-person"
                                            helperText={
                                                errors.contactPerson || ''
                                            }
                                            setFieldError={setFieldError}
                                            maxLength={120}
                                            readOnly={readOnly}
                                        />
                                        <TextField
                                            id="additionalPhone"
                                            label={t(
                                                'screens:myCompany.additionalPhone',
                                            )}
                                            onChange={handleChange}
                                            value={values?.additionalPhone}
                                            dataQa="my-company-additional-phone"
                                            helperText={
                                                errors.additionalPhone || ''
                                            }
                                            setFieldError={setFieldError}
                                            maxLength={21}
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            {values?.additionalDetails?.length > 0 && (
                                <Grid
                                    item
                                    className={classNames(
                                        classes.itemsContainer,
                                        classes.columnItemsContainer,
                                        classes.firstContainer,
                                    )}
                                >
                                    <Grid
                                        item
                                        className={classNames(
                                            classes.insideItemsContainer,
                                            classes.sectionSpace,
                                            classes.columnItemsContainer,
                                        )}
                                    >
                                        <Box
                                            className={classes.headerBox}
                                            width={350}
                                        >
                                            <span
                                                className={classes.rowBoxHeader}
                                            >
                                                {t(
                                                    'screens:myCompany.additionalInformation',
                                                )}
                                            </span>

                                            <IconWithTooltip
                                                dataQa="my-company-additional-information-tooltip"
                                                tooltipText={t(
                                                    'tooltips:myCompany.additionalInformation',
                                                )}
                                            />
                                        </Box>

                                        <Grid item container>
                                            <InputsParser
                                                inputValues={
                                                    values?.additionalDetails
                                                }
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                setFieldError={setFieldError}
                                                errors={
                                                    errors.additionalDetails
                                                }
                                                readOnly={readOnly}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {isFormSaving && (
                                <Loader
                                    dataQa="my-company-loader"
                                    absolutePosition
                                />
                            )}
                            <PreventLeavingPage
                                isOpen={dirty}
                                onSave={() => {
                                    if(!readOnly) {
                                        onSubmit(values);
                                    }
                                    dispatch(actions.updateCompanyInfoEventRaised);
                                }}
                            />
                        </div>
                </form>
            </div>
            { !hideFormsControlsIfReadOnly && (
                <FormActions
                    isSaveActive={dirty || (isDirtyOtherTabs ?? false)}
                    onSave={() => {
                        if(!readOnly) {
                            onSubmit(values);
                        }
                        dispatch(actions.updateCompanyInfoEventRaised());
                    }}
                    hideCancel={true}
                />
            )}
        </>
    );
};

export default CompanyInfoForm;
