import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import Radio from '../Radio/Radio';
import DragDropDataTable from '../DataTable/DragDropDataTable';
import {CallForwardingRule} from '../Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import CreationButton from '../CreationButton/CreationButton';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import AlertDialog from '../AlertDialog/AlertDialog';
import {DialogButton} from '../AlertDialog/DialogContainer';
import {generateColumns, IntervalStatus, IntervalType, useStyles} from "./IntervalSelect.utils";
import classNames from "classnames";

type IntervalSelectProps = {
    withoutYears?: boolean;
    defaultIntervalType?: IntervalType;
};

const defObj = {
    "startTime": "00:00",
    "endTime": "23:59",
    "daysOfMonth": "",
    "days": [],
    "months": [],
    "years": [],
    "wholeDay": false,
    "type": "new"
}

const IntervalSelect: React.VFC<IntervalSelectProps> = (
    {
        withoutYears
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [intervalToDeleteIndex, setIntervalToDeleteIndex] = useState<number | undefined>(undefined);
    const [tempIntervals, setTempIntervals] = useState<Interval[] | undefined>( undefined)

    const outTimeFormat = useSelector<ReduxState, string | undefined>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_time_format,
    );

    const confirmDelete = useCallback((indexToRemove?: number) => {
        if (indexToRemove !== undefined) {
            setIntervalToDeleteIndex(indexToRemove);
        }
    }, [setIntervalToDeleteIndex]);

    const addInterval = () => {

        const valuesToSet = [
            ...(values.intervals?.intervals || []),
            {
                startTime: '00:00',
                endTime: '23:59',
                daysOfMonth: '',
                days: [],
                months: [],
                years: [],
                wholeDay: false,
                type: 'new',
            },
        ]

        setFieldValue(
            'intervals.intervals',
            [
                ...valuesToSet
            ],
            false,
        );
    };

    const {
        values,
        setFieldValue,
        errors,
        handleChange,
        setFieldError,
    } = useFormikContext<CallForwardingRule>();

    useEffect(() => {
        if(values.intervals?.activity == IntervalStatus.OnlyFollowingTimeInterval){
            // @ts-ignore
            setTempIntervals(values.intervals?.intervals ? values.intervals?.intervals : undefined)
        }
    }, [values.intervals?.intervals]);

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                classes,
                setFieldValue,
                confirmDelete,
                errors,
                handleChange,
                setFieldError,
                withoutYears,
                outTimeFormat?.includes('AM')
            ),
        [
            errors,
            values.intervals?.intervals,
            withoutYears,
            outTimeFormat,
            values.intervals?.activity
        ],
    );

    const readonlyColumns = useMemo(
        () =>
            generateColumns(
                t,
                classes,
                setFieldValue,
                confirmDelete,
                errors,
                handleChange,
                setFieldError,
                withoutYears,
                outTimeFormat?.includes('AM'),
                true
            ),
        [
            values.intervals?.activity
        ],
    );

    const deleteInterval = useCallback(() => {
        if (intervalToDeleteIndex !== undefined && values.intervals?.intervals?.length) {
            const targetArray = values.intervals.intervals.filter((e, indx) => indx !== intervalToDeleteIndex);
            setFieldValue(
                'intervals.intervals',
                targetArray,
                false
            );
            setIntervalToDeleteIndex(undefined);
        }
    }, [values, intervalToDeleteIndex, setIntervalToDeleteIndex]);

    return (
        <div id={'abcd-abcd'}>
            <Grid item className={classes.itemsContainer}>
                <p className={classes.activeHeader}>
                    {t('screens:addToRingGroup.active')}
                </p>

                <Grid>
                    <Radio
                        dataQa="status-always"
                        checked={
                            values.intervals?.activity === IntervalStatus.Always
                        }
                        name="activity"
                        value={values.intervals?.activity}
                        onChange={() => {
                            setFieldValue('intervals', {
                                activity: IntervalStatus.Always,
                                intervals: []
                            })
                        }}
                        label={t('screens:ringSchedule.always')}
                    />

                    <Radio
                        dataQa="status-following-intervals"
                        checked={
                            values.intervals?.activity ===
                            IntervalStatus.OnlyFollowingTimeInterval
                        }
                        name="activity"
                        value={values.intervals?.activity}
                        onChange={() => {

                            setFieldValue('intervals', {
                                activity: IntervalStatus.OnlyFollowingTimeInterval,
                                intervals: tempIntervals?.length ? tempIntervals : [defObj]
                            })
                        }
                        }
                        label={t('screens:ringSchedule.onlyFollowingTime')}
                        className={classes.secondRadio}
                    />
                </Grid>


                {values.intervals?.activity ===
                    IntervalStatus.Always && (
                        <DragDropDataTable
                            className={
                                classNames(
                                    classes.table,
                                    values.intervals?.intervals?.length == 1 ? classes.intervals : ''
                                )
                            }
                            classes={{pagination: classes.pagination, rootTable: classes.rootTable}}
                            rows={[defObj]}
                            columns={readonlyColumns}
                            rowCount={Number.MAX_VALUE}
                            rowsPerPageOptions={[Number.MAX_VALUE]}
                            dragEnable={false}
                            hidePagination
                            getItemId={(itm, indx) => {
                                const targetArray = values.intervals?.intervals?.length ?? 1;
                                return 'unique_interval_item_' + targetArray + '_'  + indx + '_' + itm.id;
                            }}
                            initialPageSize={Number.MAX_VALUE}
                            paginationMode={'client'}
                            currentPage={0}
                            dataQa={'empty-interval-row'}
                        />
                    )}

                {values.intervals?.activity ===
                    IntervalStatus.OnlyFollowingTimeInterval && (
                        <DragDropDataTable
                            className={
                                classNames(
                                    classes.table,
                                    values.intervals?.intervals?.length == 1 ? classes.intervals : ''
                                )
                            }
                            classes={{pagination: classes.pagination, rootTable: classes.rootTable}}
                            rows={values.intervals?.intervals}
                            columns={columns}
                            rowCount={Number.MAX_VALUE}
                            rowsPerPageOptions={[Number.MAX_VALUE]}
                            dragEnable={false}
                            hidePagination
                            getItemId={(itm, indx) => {
                                const targetArray = values.intervals?.intervals?.length ?? 1;
                                return 'unique_interval_item_' + targetArray + '_'  + indx + '_' + itm.id;
                            }}
                            initialPageSize={Number.MAX_VALUE}
                            paginationMode={'client'}
                            currentPage={0}
                        />
                    )}
            </Grid>

            {values.intervals?.activity ===
                IntervalStatus.OnlyFollowingTimeInterval && (
                    <CreationButton
                        title={t('screens:ringSchedule.addInterval')}
                        onClick={addInterval}
                        className={classNames(classes.addInterval, values.intervals?.intervals?.length == 1 ? classes.noTopMargin : '')}
                    />
                )}

            <AlertDialog
                isOpen={intervalToDeleteIndex !== undefined}
                description={t('screens:extensions.deleteTimeInterval')}
                contentClass="alert-content"
                className={classes.removeDialogContainer}
                hideHeader={true}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => setIntervalToDeleteIndex(undefined)}
                    />,
                    <DialogButton
                        key="exlude"
                        label={t('common:delete')}
                        onClick={deleteInterval}
                    />,
                ]}
                dataQa="remove-interval-modal"
                dataTestId="remove-interval-modal"
            />
        </div>
    );
};

export default IntervalSelect;
