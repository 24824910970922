import {
    AddtoRingGroupFormProps,
    DispatchType,
} from '../../components/Extensions/RingGroupsInExtension/AddToRingGroupDialog.utils';
import {AssignedExtension, RingGroupType} from '../../store/types/RingGroup';


export const getDispatchType = (hunt_delay: string | number | undefined | null, hunt_expire: string | number | undefined | null) => {
    let dispatchType = DispatchType.Disabled;

    if (hunt_delay == undefined && hunt_expire == undefined ) {
        dispatchType = DispatchType.PermamentlyEnabled;
    } else if (hunt_delay != undefined && hunt_expire != undefined) {
        dispatchType = DispatchType.Custom;
    } else if (hunt_delay == undefined && hunt_expire != undefined) {
        dispatchType = DispatchType.Custom;
    } else if (hunt_delay != undefined && hunt_expire == undefined) {
        dispatchType = DispatchType.PermamentlyEnabled;
    }

    return dispatchType;
}

export const getCustomDelayExpireValue = (type: DispatchType, value: string | number | null | undefined, defVal: number | string = 15) => {

    let output = defVal;
    if (type == DispatchType.Custom) {
        output = value == '' || value == undefined || value == 0 ? 0 : value;
    }

    return output;
}

export const prepareInitialValues = (
    ringGroupToEdit: RingGroupType,
    assignedExtension?: AssignedExtension,
): AddtoRingGroupFormProps => {

    const hunt_delay = assignedExtension?.hunt_delay;
    const hunt_expire = assignedExtension?.hunt_expire;

    const dispatchType = getDispatchType(hunt_delay, hunt_expire)

    return {
        ignoreFollowMeVoiceMail: assignedExtension?.huntstop === 'Y',
        setAsPrimary: assignedExtension?.primary_group === 'Y',
        groupId: ringGroupToEdit.id,
        dispatchType:
            assignedExtension?.hunt_active === 'N'
                ? DispatchType.Disabled
                : dispatchType,
        customWaitsFor: getCustomDelayExpireValue(dispatchType, assignedExtension?.hunt_delay),
        customThenRingsFor: getCustomDelayExpireValue(dispatchType, assignedExtension?.hunt_expire, 30),
        supervisor: assignedExtension?.supervision_allowed === 'Y',
        allowGroupPaging: assignedExtension?.paging_allowed === 'Y',
        ringing: assignedExtension?.hunt_active !== 'N',
    };
};

export const prepareAssignedExtensionToChange: (
    form: AddtoRingGroupFormProps,
) => AssignedExtension = (form) => ({
    hunt_active: form.ringing ? 'Y' : 'N',
    hunt_delay:
        form.dispatchType === DispatchType.Custom ? form.customWaitsFor : undefined,
    hunt_expire:
        form.dispatchType === DispatchType.Custom
            ? form.customThenRingsFor
            : undefined,
    huntstop: form.ignoreFollowMeVoiceMail ? 'Y' : 'N',
    primary_group: form.setAsPrimary ? 'Y' : 'N',
    supervision_allowed: form.supervisor ? 'Y' : 'N',
    paging_allowed: form.allowGroupPaging ? 'Y' : 'N',
});
