import { Colors } from "../../styles/Colors";
import { makeStyles } from '@material-ui/core';
import i18n from "../../services/i18n";
import * as Yup from 'yup';
import { BaseWallboardWidget, BaseWallboardWidgetSizeProps, WidgetDataType, WidgetExtensionPresenseFilter } from "../../store/types/Wallboard";
import Config from '../../config.json';

export type AddNewWidgetDialogProps = {
    isOpen: boolean;
    onClose?: (success: boolean, object?: BaseWallboardWidget) => void;
    editObject?: BaseWallboardWidget;
    onDelete?: (object: BaseWallboardWidget) => void;
};

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            height: 417,
            width: 920,
            minWidth: 'unset !important',
            padding: '30px 80px 62px 80px !important',
            margin: 0,
            overflow: 'hidden'
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },
        
        '& .MuiGrid-root': {
            marginTop: 32,
        },

        '& .MuiPaper-root': {
            height: 655,
            width: 1080
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
        
        '& .MuiInputBase-root': {
            background: Colors.White
        },
    },

    smallerDialog: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            paddingBottom: '8px !important',
            height: 325,
            width: 400,
            minWidth: 'unset !important',
        },
    },

    header1: {
        fontWeight: 400,
        fontSize: 22,
        textAlign: 'center',
        color: Colors.Gray5,
        width: '100%'
    },

    header2: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'left',
        color: Colors.Gray5,
        width: '100%',
        letterSpacing: 0.15,
        lineHeight: '20px',
        maxWidth: 400
    },
    
    header3: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        color: Colors.Gray5,
        letterSpacing: 0.15,
        lineHeight: '16px'
    },

    itemsContainer: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        }
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: 0,
        margin: 0,
        overflow: 'hidden',
        height:440,
        paddingLeft:10,
        paddingRight:10
    },
    
    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',

        '& .Mui-error': {
            fontWeight: 400,
        },
        
        '& > :not(:first-child)': {
            marginLeft: 56
        }
    },

    disabledButton: {
        backgroundColor: Colors.Gray8,
        '& .MuiButton-label': {
            color: `${Colors.Gray10} !important`,
        },
    },

    primaryButton: {
        background: Colors.Primary,

        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: `${Colors.White} !important`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary + ' !important',
        },
    },

    halfScreenField: {
        width: '432px !important',

        '& .MuiFormControl-root': {
            width: '432px !important'
        }
    },

    quarterScreenField: {
        width: '192px !important',

        '& .MuiFormControl-root': {
            width: '192px !important'
        }
    },

    groupHeader: {
        height: 62,
        marginLeft: 16,
        color: Colors.LightGraySecondary,
        textTransform: 'uppercase'
    },

    widgetTypeSwitch: {

    },

    widgetTypeSwitchHeader: {
        marginBottom: 10,
        fontSize: 16,
        fontWeight: 500
    },

    switchBox: {
        display: 'flex',
        flexDirection: 'row'
    },

    switchBoxItem: {
        cursor: 'pointer',
        width: 20,
        height: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '18px 32px',
        background: Colors.White,
        border: '1px solid #C5D5E6',
    },

    switchBoxItemLeft: {
        borderRadius: '6px 0 0 6px',
    },
    
    switchBoxItemRight: {
        borderLeft: 'none',
        borderRadius: '0 6px 6px 0',
    },

    switchBoxItemSelected: {
        boxShadow: 'inset 0px 4px 6px rgba(0, 0, 0, 0.2)',
        background: Colors.Secondary4
    },
    
    marginTop30: {
        marginTop: 40,
        marginLeft: '70px !important'
    },

    switchBoxItemDisabled: {
        cursor: 'auto',
        background: '#FAFBFC',
        boxShadow: 'none',
        
        '& svg': {
            '& path': {
                fill: '#99A9B0',
            },
        },
    },

    dropDownChip: {
        '& .MuiChip-label.MuiChip-labelSmall': {
            maxWidth: 326,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    },
    
    dropDownMultiSelect: {
        minHeight: 56,

        '& .MuiAutocomplete-inputRoot': {
            height: 'fit-content !important',
            minHeight: 56,
        }
    },

    deleteWidgetButton: {
        marginLeft: 45
    },

    autoRefreshTextField: {
        '& .MuiInputLabel-formControl': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }
    }
}));

const getAllowedDomains = () => {
    // @ts-ignore
    const links = Config.ALLOWED_DOMAINS
        ?.split(';')
        // @ts-ignore
        ?.filter(e => e?.length > 0);
    return links?.length ? links : [];
};

const getCanonicalHost = (url: string) => {
    const firstTLDs = "ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|be|bf|bg|bh|bi|bj|bm|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|cl|cm|cn|co|cr|cu|cv|cw|cx|cz|de|dj|dk|dm|do|dz|ec|ee|eg|es|et|eu|fi|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jo|jp|kg|ki|km|kn|kp|kr|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|na|nc|ne|nf|ng|nl|no|nr|nu|nz|om|pa|pe|pf|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sx|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|yt".split('|');
    const secondTLDs = "com|edu|gov|net|mil|org|nom|sch|caa|res|off|gob|int|tur|ip6|uri|urn|asn|act|nsw|qld|tas|vic|pro|biz|adm|adv|agr|arq|art|ato|bio|bmd|cim|cng|cnt|ecn|eco|emp|eng|esp|etc|eti|far|fnd|fot|fst|g12|ggf|imb|ind|inf|jor|jus|leg|lel|mat|med|mus|not|ntr|odo|ppg|psc|psi|qsl|rec|slg|srv|teo|tmp|trd|vet|zlg|web|ltd|sld|pol|fin|k12|lib|pri|aip|fie|eun|sci|prd|cci|pvt|mod|idv|rel|sex|gen|nic|abr|bas|cal|cam|emr|fvg|laz|lig|lom|mar|mol|pmn|pug|sar|sic|taa|tos|umb|vao|vda|ven|mie|北海道|和歌山|神奈川|鹿児島|ass|rep|tra|per|ngo|soc|grp|plc|its|air|and|bus|can|ddr|jfk|mad|nrw|nyc|ski|spy|tcm|ulm|usa|war|fhs|vgs|dep|eid|fet|fla|flå|gol|hof|hol|sel|vik|cri|iwi|ing|abo|fam|gok|gon|gop|gos|aid|atm|gsm|sos|elk|waw|est|aca|bar|cpa|jur|law|sec|plo|www|bir|cbg|jar|khv|msk|nov|nsk|ptz|rnd|spb|stv|tom|tsk|udm|vrn|cmw|kms|nkz|snz|pub|fhv|red|ens|nat|rns|rnu|bbs|tel|bel|kep|nhs|dni|fed|isa|nsn|gub|e12|tec|орг|обр|упр|alt|nis|jpn|mex|ath|iki|nid|gda|inc".split('|');
    const knownSubdomains = "www|studio|mail|remote|blog|webmail|server|ns1|ns2|smtp|secure|vpn|m|shop|ftp|mail2|test|portal|ns|ww1|host|support|dev|web|bbs|ww42|squatter|mx|email|1|mail1|2|forum|owa|www2|gw|admin|store|mx1|cdn|api|exchange|app|gov|2tty|vps|govyty|hgfgdf|news|1rer|lkjkui";

    const knownSubdomainsRegExp = new RegExp(`^(${knownSubdomains})\.`, 'i');
    url = url?.replace(knownSubdomainsRegExp, '') || '';

    const parts = url.split('.');

    while (parts.length > 3) {
        parts.shift();
    }

    if (parts.length === 3 && ((parts[1].length > 2 && parts[2].length > 2) || (secondTLDs.indexOf(parts[1]) === -1) && firstTLDs.indexOf(parts[2]) === -1)) {
        parts.shift();
    }

    return parts.join('.');
}

export const domainIsNotAllowed = (url: string | undefined) => {
    if(!url) return true;
    const allowedDomains = getAllowedDomains();
    if(allowedDomains.length) {
        let domain = '';
        try {
            domain = (new URL(url)).hostname.toLowerCase();
        } catch{
        }
        if(domain?.length > 0) {
            const topLevelDomain = getCanonicalHost(domain).toLowerCase();
    
            for(const allowed of allowedDomains) {
                if(domain === allowed.toLowerCase() || topLevelDomain === allowed.toLowerCase()) {
                    return true;
                }
            }
    
            return false;
        }
    }
    return true;
};

const requiredError = i18n.t<string>('errors:common.emptyInput');
const formatError = i18n.t<string>('errors:common.wrongFormat');
const domainIsNotAllowedError = i18n.t<string>('errors:wallboard.domainIsNotAllowed');

export const externalIframeDataValidationSchema = Yup.object().shape({
    url: Yup.string()
        .required(requiredError)
        .url(formatError)
        .max(2048)
        .test('domainIsNotAllowed', domainIsNotAllowedError, domainIsNotAllowed),
});

export const extensionPresenseDataValidationSchema = Yup.object().shape({
    show: Yup.string()
        .required(requiredError),
    extensions: Yup.mixed()
        .when('show', {
            is: (value: WidgetExtensionPresenseFilter) =>
                value === WidgetExtensionPresenseFilter.selected_extensions,
            then: Yup.mixed().required()
                .test('extensions', requiredError, (v) => v?.length > 0),
            otherwise: Yup.mixed()
                .notRequired()
        }),
    ringGroup: Yup.mixed()
        .when('show', {
            is: (value: WidgetExtensionPresenseFilter) =>
                value === WidgetExtensionPresenseFilter.selected_ring_groups,
            then: Yup.string()
                .required(requiredError),
            otherwise: Yup.string()
                .notRequired()
        })
});

export const extensionCallsVolumesValidationSchema = Yup.object().shape({
    show: Yup.string()
        .required(requiredError),
    extensions: Yup.mixed()
        .when('show', {
            is: (value: WidgetExtensionPresenseFilter) =>
                value === WidgetExtensionPresenseFilter.selected_extensions,
            then: Yup.mixed()
                .test('extensions', requiredError, (v) => v?.length > 0),
            otherwise: Yup.mixed()
                .notRequired()
        }),
    ringGroup: Yup.mixed()
        .when('show', {
            is: (value: WidgetExtensionPresenseFilter) =>
                value === WidgetExtensionPresenseFilter.selected_ring_groups,
            then: Yup.string()
                .required(requiredError),
            otherwise: Yup.string()
                .notRequired()
        }),
    timeRange:Yup.string().required(requiredError)
});

export const validationSchema = Yup.object().shape({
    title: Yup.string()
        .required(requiredError)
        .max(32),
    dataType: Yup.string()
        .required(requiredError),
    autoRefreshTime: Yup.number()
        .required(requiredError),
    data: Yup.object()
        .when('dataType', {
            is: (value: WidgetDataType) =>
                value === WidgetDataType.externalIframe,
            then: externalIframeDataValidationSchema,
            otherwise: Yup.object().when('dataType', {
                is: (value: WidgetDataType) =>
                    value === WidgetDataType.extensionPresense,
                then: extensionPresenseDataValidationSchema,
                otherwise: Yup.object().when('dataType', {
                    is: (value: WidgetDataType) =>
                        value === WidgetDataType.callVolumes,
                    then: extensionCallsVolumesValidationSchema,
                    otherwise: Yup.object(),
                })
            })
        })
});

export const defaultIFrameWidgetSize: BaseWallboardWidgetSizeProps = {
    w: 4,
    h: 2
};

export const defaultWidgetExtensionPresenseDataSize: BaseWallboardWidgetSizeProps = {
    w: 3,
    h: 3
};

export const defaultWidgetCallVolumesDataSize: BaseWallboardWidgetSizeProps = {
    w: 3,
    h: 3
};