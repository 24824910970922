import {takeLatest, takeEvery} from 'redux-saga/effects';
import * as actions from '../../actions';
import {
    basicExtensionDetailsData,
    createExtensionDetailsData,
    createNewExtension,
    deleteExtensionFromExtensionDetailsView,
    deleteExtensionFromExtensionListView,
    editExtension,
    fetchDIDNumbersExtension,
    fetchSwitchModeData,
    getCallProcessingOperationModeList,
    getCustomerExtensionDetails,
    getExtension,
    getExtensionList, getExtensionsListCsvFile,
    refreshExtensionStatus,
    saveSwitchModeForm,
    setExtensionStatus, uploadExtensionListCsvFile,
} from './extensions/saga';
import {getExtensionMohDetails, uploadMohFile} from './mohs/saga';
import {validateAddonsProducts} from './plan/saga';
import {
    getCallScreeningTabData,
    getCallSettingsTabData,
    getDeviceTabData,
    getExtensionTabData,
    getPlanTabData,
    getCallForwardingTabData,
} from './extensionTabs/saga';
import {getCallProcessingPolicyList} from './callScreening/saga';

export const extensionSaga = [
    takeLatest(
        actions.updateAccountsCallProcessingMode.request,
        saveSwitchModeForm,
    ),
    takeLatest(
        actions.getCallProcessingOperationModeList.request,
        getCallProcessingOperationModeList,
    ),
    takeLatest(actions.fetchDataForSwitchMode.request, fetchSwitchModeData),
    takeLatest(actions.getExtensionsList.request, getExtensionList),
    takeEvery(
        actions.getDIDNumbersForExtension.request,
        fetchDIDNumbersExtension,
    ),
    takeLatest(actions.getExtensionMohDetails.request, getExtensionMohDetails),
    takeLatest(
        actions.getCustomerExtensionDetails.request,
        getCustomerExtensionDetails,
    ),
    takeLatest(actions.setExtensionStatus.request, setExtensionStatus),
    takeLatest(actions.uploadMohFile.request, uploadMohFile),
    takeLatest(actions.editExtension.request, editExtension),
    takeEvery(actions.refreshExtensionStatus.request, refreshExtensionStatus),
    takeLatest(actions.getExtensionItemDetails.request, getExtension),
    takeLatest(
        actions.deleteCustomerExtension.request,
        deleteExtensionFromExtensionListView,
    ),
    takeLatest(
        actions.deleteCustomerExtensionDetailsView.request,
        deleteExtensionFromExtensionDetailsView,
    ),
    takeLatest(actions.validateAddonsProducts.request, validateAddonsProducts),
    takeLatest(
        actions.createExtensionDetailsData.request,
        createExtensionDetailsData,
    ),
    takeLatest(actions.createNewExtension.request, createNewExtension),
    takeLatest(
        actions.getExtensionBasicDetailsData.request,
        basicExtensionDetailsData,
    ),
    takeLatest(actions.getExtensionTabData.request, getExtensionTabData),
    takeLatest(actions.getCallSettingsTabData.request, getCallSettingsTabData),
    takeLatest(actions.getDeviceTabData.request, getDeviceTabData),
    takeLatest(actions.getPlanTabData.request, getPlanTabData),
    takeLatest(
        actions.getCallForwardingTabData.request,
        getCallForwardingTabData,
    ),
    takeLatest(
        actions.getCallScreeningTabData.request,
        getCallScreeningTabData,
    ),
    takeLatest(
        actions.getCallProcessingPolicyList.request,
        getCallProcessingPolicyList,
    ),
    takeLatest(
        actions.uploadExtensionsListCsvFile.request,
        uploadExtensionListCsvFile
    ),
    takeLatest(
        actions.getExtensionListCsvFile.request,
        getExtensionsListCsvFile
    )

];
