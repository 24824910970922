import i18n from '../../../services/i18n';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    dateTimeFilter: {
        maxWidth: '176px',
        minWidth: 'unset',
    },
    dateTimeFilterCoveringFilter: {
        maxWidth: '220px',
        minWidth: 'unset',
    },
    statusFilter: {
        maxWidth: '220px',
        minWidth: '164px',
    },

    statusContainer: {
        minWidth: 'unset',
    },

    numberFilter: {
        minWidth: '172px',
        maxWidth: '172px',
    },

    option: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
}));

export interface InvoiceStatus {
    value: string | number;
    name: string;
}

export interface InvoiceFiltersType {
    invoiceNumber?: string;
    statusFilter: InvoiceStatus;
    afterFilter: string;
    beforeFilter?: string;
    coveringFilter?: string;
    showVoid: boolean;
}

export interface InvoiceFiltersProps {
    initData: InvoiceFiltersType;
    userDateTimeFormat?: string;
    userDateFormat?: string;
    onClick?: () => void;
    onChange?: (values: InvoiceFiltersType) => void;
}

export const statusOptions: InvoiceStatus[] = [
    { value: '', name: i18n.t<string>(`screens:billing.any`) },
    { value: 1, name: i18n.t<string>(`screens:billing.1`) },
    { value: 2, name: i18n.t<string>(`screens:billing.2`) },
    { value: 3, name: i18n.t<string>(`screens:billing.3`) },
    { value: 4, name: i18n.t<string>(`screens:billing.4`) },
    { value: 5, name: i18n.t<string>(`screens:billing.5`) },
    { value: 6, name: i18n.t<string>(`screens:billing.6`) },
    {
        value: 7,
        name: i18n.t<string>(`screens:billing.7`),
    },
];
