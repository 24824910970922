import classNames from "classnames";
import React from "react";
import { usePermissions } from "../../../../hooks/usePermissions";
import { Colors } from "../../../../styles/Colors";
import { makeStyles } from "@material-ui/core";
import { PermissionType } from "../../../../store/types/Permission";
import { useTranslation } from 'react-i18next';
import PermissionLinkCell from "../../../DataTable/PermissionLinkCell";

const useStyles = makeStyles(() => ({
    stickyIcon: {
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 10,
        color: Colors.Secondary1,
        border: `1px solid ${Colors.Secondary1}`,
        padding: '3px 8px',
        marginLeft: 16,
        height: 16
    },

    container: {
        width: '100%',
        display: 'inline-flex',
    },

    stickyContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}));

interface StickyCellProps {
    text: string,
    isSticky: boolean,
    stickyText?: string,
    customClasses?: {
        valueText?: string,
        stickyText?: string
    };
    permissions: string[];
    onClick?: () => void,
}

const StickyCell: React.VFC<StickyCellProps> = ({
    text,
    isSticky,
    stickyText,
    customClasses,
    permissions,
    onClick
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const permission = usePermissions(...permissions);
    
    return (
        <div className={classes.container}>
            <PermissionLinkCell
                text={text}
                onClick={() => {
                    (permission === PermissionType.Visible) && onClick?.();
                }}
                permissions={permissions}
                className={customClasses?.valueText}
                twoRowsText
            />
            {isSticky && (
                <div className={classes.stickyContainer}>
                    <div className={classNames(
                            customClasses?.stickyText,
                            classes.stickyIcon
                        )}
                    >
                        {stickyText || t<string>('screens:extensions.any')}
                    </div>
                </div>)
            }
        </div>
    );
}

export default StickyCell;