import React, {useMemo} from 'react';
import {Colors} from '../../styles/Colors';
import {useTranslation} from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import {AssignedExtension} from '../../store/types/RingGroup';
import {YesNo} from '../../store/types/CallScreening';
import classNames from 'classnames';
import {SipCall, SipCallState, SipCallType} from '../../store/actions/ringgroups/payloads';
import {APIErrorInterface} from '../../store/types';
import {useStyles} from './RingGroupsCallsCell.utils';
import PeopleIcon from '../../assets/PeopleIcon';
import {ExtensionType} from '../../store/types/Extension';

export interface RingGroupsCallsCellProps {
    assignedExtensions?: AssignedExtension[];
    sipCalls?: SipCall[];
    sipCallsApiError?: APIErrorInterface;
    extensionsList?: ExtensionType[];
};

export const RingGroupsCallsCell: React.FC<RingGroupsCallsCellProps> = ({
    assignedExtensions,
    sipCalls,
    sipCallsApiError,
    extensionsList
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    /*
    -Total of all type:account
    -Logged out of alltype:account and hunt_active:N
    -No Wrapup state for now
    -On call - this can be calculated using CallControl/get_sip_calls_list: 
        get the total of calls with unique tracking_id where extension_id matching id from assigned_extensions
        and state:connected or (state:trying or state:ringing and type:outgoing)
    -On hold - this can be calculated using CallControl/get_sip_calls_list: 
        get the total of calls with unique tracking_id where extension_id matches id from assigned_extensions
        and (state:holding or state:held)
    
    Add Idle = sip_status:1 (Registered)
        AND Logged in
        AND NOT On call 
        AND NOT On hold <- Count of accounts that are registered, logged-in and do not have any calls
    */

    const extensions = assignedExtensions?.filter(
        (v: AssignedExtension) => v.type === 'Account'
    );
    const total = extensions?.length || 0;

    const filteredExtensions = useMemo(() => {
        return extensionsList?.filter(e => assignedExtensions?.find(c => e.extension_id === c.id));
    }, [extensionsList, assignedExtensions]);
    
    const extIds = useMemo(() => {
        return assignedExtensions?.map(e => e.id || '') || [];
    }, [assignedExtensions]);

    const callsByExtensionIds = useMemo(() =>  {
        return sipCalls?.filter(e => 
            (e.callee?.extension_id && extIds.findIndex(c => c === e.callee.extension_id) !== -1) || 
            (e.caller?.extension_id && extIds.findIndex(c => c === e.caller.extension_id) !== -1)
        ) || [];
    }, [sipCalls, extIds]);

    const onCallExtensions = useMemo(() => {
        const calls = callsByExtensionIds
            ?.filter(e => (e.state === SipCallState.Connected)
                || (e.state === SipCallState.Trying && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Ringing && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Queued && e.type === SipCallType.Outgoing)
                || (e.state === SipCallState.Dequeued)
            ) ?? [];

        const extIds: string[] = [];
        for(const e of calls) {
            if(e.callee?.extension_id) {
                extIds.push(e.callee.extension_id);
            }
            if(e.caller?.extension_id) {
                extIds.push(e.caller.extension_id);
            }
        }
        return filteredExtensions?.filter(e => !!extIds.find(d => d === e.extension_id)) ?? [];
    }, [callsByExtensionIds, filteredExtensions]);

    const idle = useMemo(() => {
        const idleExtensions = filteredExtensions
            //sip_status:1 (Registered)
            ?.filter(e => e.sip_status === 1)
            //AND Logged in
            ?.filter(e => assignedExtensions?.find(c => e.extension_id === c.id)?.hunt_active === YesNo.Yes)
            //AND NOT On call
            ?.filter(e => !onCallExtensions?.find(c => e.extension_id === c.extension_id))
            //AND NOT On hold
            ?.filter(e => e.state !== SipCallState.Holding && e.state !== SipCallState.Held)
            ?? [];
        return idleExtensions.length;
    }, [filteredExtensions, assignedExtensions, onCallExtensions]);

    const loggedIn = useMemo(() => {
        return extensionsList
            ?.filter(e => e.sip_status === 1 && assignedExtensions
                ?.find(c => e.extension_id === c.id && c.hunt_active === YesNo.Yes))
            ?.length || 0;
    }, [extensions]);

    const onCallCount = useMemo(() => {
        return onCallExtensions?.length ?? 0
    }, [onCallExtensions]);
    
    const onHold = useMemo(() => {
        return callsByExtensionIds.filter(e => e.state === SipCallState.Holding || e.state === SipCallState.Held)
        ?.map(e => e.tracking_id);
    }, [callsByExtensionIds]);

    const onHoldCount = useMemo(() => {
        return [...new Set(onHold)].length;
    }, [onHold]);
    
    const idleColor = useMemo(() => {
        return idle == 0 ? Colors.Error : Colors.Support
    }, [idle]);
        
    const idleColorInTooltip = useMemo(() => {
        return idle == 0 ? Colors.Error : Colors.Support
    }, [idle]);

    const badgeTooltipContent = (
        <div className={classes.badgeContainer}>
            <div className={classes.badgeRow}>
                <span className={classes.badgeTitle}>{t('screens:ringGroups.includedExtensions')}</span>
            </div>
            <div className={classNames(classes.badgeRow, classes.headerSeparator)}>
            </div>
            <div className={classNames(classes.badgeRow, classes.firstTableRow)}>
                <div className={classes.leftColumn}>{t('screens:ringGroups.total')}</div>
                <div className={classes.rightColumn}>{total}</div>
            </div>
            <div className={classes.badgeRow}>
                <div className={classes.leftColumn} style={{color: idleColorInTooltip}}>{t('screens:ringGroups.idle')}</div>
                <div className={classes.rightColumn} style={{color: idleColorInTooltip}}>{idle}</div>
            </div>
            <div className={classes.badgeRow}>
                <div className={classes.leftColumn}>{t('screens:ringGroups.loggedIn')}</div>
                <div className={classes.rightColumn}>{loggedIn}</div>
            </div>
            <div className={classes.badgeRow}>
                <div className={classes.leftColumn}>{t('screens:ringGroups.onCall')}</div>
                <div className={classes.rightColumn}>{sipCallsApiError ? ' - ' : onCallCount}</div>
            </div>
            <div className={classes.badgeRow}>
                <div className={classes.leftColumn}>{t('screens:ringGroups.onHold')}</div>
                <div className={classes.rightColumn}>{sipCallsApiError ? ' - ' : onHoldCount}</div>
            </div>
        </div>
    );

    return (
        <Tooltip
            title={badgeTooltipContent}
            classes={{
                tooltip: classes.badgeContainerGlobal
            }}
        >
            <div className={classes.mainContainer}>
                <div className={classes.iconContainer}>
                    <div className={classes.iconSubContainer}>
                        <PeopleIcon />
                    </div>
                </div>
                <div className={classes.labelsContainer}>
                    <div className={classes.totalsLabel}>{total} {t('common:total')}</div>
                        <div className={classes.availableLabel} style={{color: idleColor}}>
                            {idle} {t('screens:ringGroups.idle')}
                        </div>
                </div>
            </div>
        </Tooltip>
    );
}

export default RingGroupsCallsCell;
