import React, {useCallback, useEffect} from 'react';
import {
    CallSettingsFormProps,
    CallSettingsFormType
} from './CallSettingsForm.utils';
import IncomingForm from '../Incoming/IncomingForm';
import CallBarringForm from '../../Extensions/CallBarring/CallBarringForm';
import CallRecordingForm from '../../Extensions/CallRecording/CallRecordingForm';
import {useFormikContext} from 'formik';
import DisaForm from '../DisaForm';
import Separator from '../../../Separator/Separator';
import OnHoldMusic from "../../../OnHoldMusic/OnHoldMusic";
import {actions} from "../../../../store";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
    mohContainer: {
        marginTop: 0,
    },
    selectedMohContainer: {
        marginBottom: 10,
        marginTop: 15,
        marginLeft: 30,
    },
    musicOnHoldSwitcher: {
        maxWidth:'fit-content',
        marginLeft:0
    },
}));

const CallSettingsForm: React.VFC<CallSettingsFormProps> = (
    {
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        callRecordingEmail,
        mohItems,
        i_account
    }) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyles();

    const {
        handleSubmit,
        isValid,
        dirty,
        setFieldValue,
        values
    } = useFormikContext<CallSettingsFormType>();

    useEffect(() => {
        handleDirtyChange?.('callSettings', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callSettings', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callSettings', () => isValid);
    }, [isValid]);

    const onSaveMohFile = useCallback(
        (file, fileName, callback) => {
            if (i_account) {
                dispatch(
                    actions.uploadMohFile.request({
                        accountId: i_account,
                        file: file,
                        name: fileName,
                        callback,
                    }),
                );
            }
        },
        [i_account],
    );

    return (
        <>
            <IncomingForm/>
            <Separator/>
            <CallBarringForm/>
            <Separator/>
            <CallRecordingForm callRecordingEmail={callRecordingEmail}/>
            <Separator/>

            <OnHoldMusic
                id="onHoldMusicStatus"
                fileSelectId="onHoldMusicName"
                setValue={setFieldValue}
                value={values.onHoldMusicStatus}
                withTooltip={false}
                mohItems={mohItems}
                selectedMohFile={values?.onHoldMusicName || ''}
                onSave={onSaveMohFile}
                customTooltip={t('tooltips:ringGroups.onHoldMusic')}
                classes={{
                    container: classes.mohContainer,
                    selectContainer: classes.selectedMohContainer,
                    switcher: classes.musicOnHoldSwitcher
                }}
            />

            <Separator/>
            <DisaForm/>
        </>
    );
};

export default CallSettingsForm;
