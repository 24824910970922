import { CallQueueFormType, CallQueuePromptActionType } from '../../components/Forms/RingGroups/callQueueFormUtils';
import { CallQueue, CallQueueActionsType } from '../../store/types/CallQueue';
import { YesNo } from '../../store/types/CallScreening';
import {callQueueFormDefaultValues} from "../../components/Forms/RingGroups/CallQueueForm";

export const preparePromptActionType = (
    data?: CallQueuePromptActionType,
): CallQueueActionsType  => ({
    //@ts-ignore
    i_callqueue_action: data?.action ?? undefined,
    //@ts-ignore
    i_prompt: data?.prompt ?? undefined,
    //@ts-ignore
    prompt_action: data?.promptFile && data?.promptName
        ? 'set' 
        : data && data.playPromptFile === false ? 'unset' : undefined,
    prompt_name: data?.promptName ?? undefined,
    redirect_to_i_entity: data?.redirectToEntity ?? undefined,
    wait_confirmation: data?.waitConfirmation ? YesNo.Yes : YesNo.No
});

export const prepareRingGroupCallQueueAddData = (
    data: Partial<CallQueueFormType>,
): Partial<CallQueue> => ({
    announce_estimated_wait_time: data.announceEstimatedWaitTime ? YesNo.Yes : YesNo.No,
    announce_number_of_callers: data.announceNumberOfCallersInQueue ? YesNo.Yes : YesNo.No,
    announcement_interval: data.intervalBetweenAnnouncements
        ? parseInt(data.intervalBetweenAnnouncements)
        : Number(callQueueFormDefaultValues?.intervalBetweenAnnouncements) || 5,
    incoming_capacity: data.maximumNumberOfQueuedCallers
        ? parseInt(data.maximumNumberOfQueuedCallers.toString())
        : 10,
    average_duration: data.averageHandleTime ?? 1,
    dtmf_to_confirm_exit: data.dtmfToConfirmQueueExit,
    dtmf_input_timeout: data.timeOutForCallerInput,
    dispatch_to_busy_agents: data.dispatchCallsToBusyExtensions ? YesNo.Yes : YesNo.No,
    maximum_waiting_time: data.maximumWaitingTime,
    maximum_ringing_time: data.maximumRingingTime,
    on_incoming_call_limit: preparePromptActionType(data.onIncomingCallLimit),
    on_max_ringing_time: preparePromptActionType(data.onMaxRingingTime),
    on_max_waiting_time: preparePromptActionType(data.onMaxWaitingTime),
});

export const prepareRingGroupCallQueueEditData = (
    changedData: Partial<CallQueueFormType>,
): Partial<CallQueue> => {
    const result: Partial<CallQueue> = {};

    if (changedData.announceEstimatedWaitTime !== undefined) {
        result.announce_estimated_wait_time = changedData.announceEstimatedWaitTime
        ? YesNo.Yes
        : YesNo.No;
    }

    if (changedData.announceNumberOfCallersInQueue !== undefined) {
        result.announce_number_of_callers = changedData.announceNumberOfCallersInQueue
            ? YesNo.Yes
            : YesNo.No;
    }

    if (changedData.intervalBetweenAnnouncements !== undefined) {
        result.announcement_interval =
            changedData.intervalBetweenAnnouncements !== undefined
                ? parseInt(changedData.intervalBetweenAnnouncements)
                : Number(callQueueFormDefaultValues?.intervalBetweenAnnouncements) || 5;
    }

    if (changedData.maximumNumberOfQueuedCallers !== undefined) {
        result.incoming_capacity =
            changedData.maximumNumberOfQueuedCallers !== undefined
                ? parseInt(changedData.maximumNumberOfQueuedCallers.toString())
                : 1;
    }

    if (changedData.averageHandleTime !== undefined) {
        result.average_duration =
            changedData.averageHandleTime !== undefined
                ? changedData.averageHandleTime
                : 1;
    }

    if (changedData.playOnHoldMusicStatus === false) {
        result.moh_action = 'unset';
    }

    if(changedData.dtmfToConfirmQueueExit !== undefined) {
        result.dtmf_to_confirm_exit = changedData.dtmfToConfirmQueueExit;
    }

    if(changedData.timeOutForCallerInput !== undefined) {
        result.dtmf_input_timeout = changedData.timeOutForCallerInput;
    }
    
    if(changedData.dispatchCallsToBusyExtensions !== undefined) {
        result.dispatch_to_busy_agents = changedData.dispatchCallsToBusyExtensions
            ? YesNo.Yes
            : YesNo.No;
    }
    
    if(changedData.maximumWaitingTime !== undefined) {
        result.maximum_waiting_time = changedData.maximumWaitingTime;
    }
    
    if(changedData.maximumRingingTime !== undefined) {
        result.maximum_ringing_time = changedData.maximumRingingTime;
    }

    if(changedData.onIncomingCallLimit !== undefined) {
        result.on_incoming_call_limit = preparePromptActionType(changedData.onIncomingCallLimit);
    }
    
    if(changedData.onMaxRingingTime !== undefined) {
        result.on_max_ringing_time = preparePromptActionType(changedData.onMaxRingingTime);
    }
    
    if(changedData.onMaxWaitingTime !== undefined) {
        result.on_max_waiting_time = preparePromptActionType(changedData.onMaxWaitingTime);
    }

    return result;
};
