import { AxiosError, AxiosResponse } from 'axios';
import { put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { actions } from '../../..';
import { EditExtensionForm } from '../../../../views/Extensions/ExtensionDetails/utils';
import {
    GetCallProcessingPolicyListResponse,
    GetCPConditionListResponsePayload,
    GetCPRuleListResponse,
} from '../../../actions/extensions/payloads';
import extensionsApi from '../../../services/extensions';
import {
    YesNo,
} from '../../../types/CallScreening';
import { api } from '../../../services/axios';
import { Account, Customer } from '../../../../services/endpoints';
import JSONFormData from '../../../../utils/JSONFormData';
import {
    ScreeningModeFlag,
    ServiceFeature,
    ServiceFeatureAtributes,
    ServiceFeatureAtributesName,
    ServiceFeatureName,
} from '../../../types/ServiceFeature';
import account from '../../../services/account';
import { APIErrorInterface } from '../../../types';

export function* editCallScreeningDetails(
    data: Partial<EditExtensionForm>,
    action:
        | ActionType<typeof actions.editExtension.request>
        | ActionType<typeof actions.editAutoAttendant.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const { rules, callScreening, pbxEnabled } = data;

    if (callScreening != undefined || pbxEnabled != undefined) {
        const { serviceFeatures } = yield select((state) => state.extensions);
        const feature = serviceFeatures.find(
            (v: ServiceFeature) => v.name == ServiceFeatureName.CallProcessing,
        );

        const updatedValue = {
            ...feature,
        };

        if (callScreening != undefined) {
            const flag = callScreening
                ? ScreeningModeFlag.FollowMe
                : ScreeningModeFlag.NoForwarding;

            updatedValue.effective_flag_value = flag;
            updatedValue.flag_value = flag;
        }

        if (pbxEnabled != undefined) {
            updatedValue.attributes = updatedValue.attributes.map(
                (att: ServiceFeatureAtributes) =>
                    att.name == ServiceFeatureAtributesName.PBX
                        ? {
                            ...att,
                            values: [pbxEnabled ? YesNo.Yes : YesNo.No],
                            effective_values: [
                                pbxEnabled ? YesNo.Yes : YesNo.No,
                            ],
                        }
                        : att,
            );
            updatedValue.flag_value = pbxEnabled ? YesNo.Yes : updatedValue.flag_value;
        }

        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            account_info: {
                i_account: action.payload.accountId,
                service_features: [updatedValue],
            },
        });

        yield api.post(Account.UpdateAccount, body);
    }
    if (rules?.length) {
        const toDelete =
            action.payload.initialValues.rules?.filter(
                (init: any) =>
                    init.i_cp_rule &&
                    !rules?.find(
                        (newValue: any) =>
                            newValue.i_cp_rule &&
                            init.i_cp_rule == newValue.i_cp_rule &&
                            newValue.i_cp_rule != undefined,
                    ),
            ) || [];
        if (toDelete && toDelete.length) {
            for (let i = 0; i < toDelete.length; i++) {
                yield account.postDeleteCpRule(session_id, csrf_token, toDelete[i]);
            }
        }

        for (let i = 0; i < rules.length; i++) {
            if (rules[i].i_cp_rule == -1) {
                rules[i].i_cp_rule = undefined;
                //@ts-ignore
                const newRuleId = yield account.postAddCpRule(session_id, csrf_token, {
                    i_account: action.payload.accountId,
                    ...rules[i],
                });
                rules[i].i_cp_rule = newRuleId.data.i_cp_rule;
            }
        }

        const toUpdate = rules?.filter(
            (o: any) => !toDelete.find((item) => item.i_cp_rule == o.i_cp_rule),
        );

        if (toUpdate && toUpdate.length) {
            for (let i = 0; i < toUpdate.length; i++) {
                yield account.postUpdateCpRule(session_id, csrf_token, toUpdate[i]);
            }
        }
    }
}
export function* getCallScreeningData() {
    //     const { session_id } = yield select((state) => state.auth);
    //     try {
    //         const rulesRes: AxiosResponse<GetCPRuleListResponse> = yield extensionsApi.getCallScreeningRules(
    //             session_id,
    //             action.payload,
    //         );
    //         let condRes: AxiosResponse<GetCPConditionListResponsePayload> = yield extensionsApi.getCallScreeningConditions(
    //             session_id,
    //             {
    //                 i_account: action.payload.i_account,
    //                 get_total: 1,
    //             },
    //         );
    //         const conTotal = condRes.data?.total || 0;
    //         const cond = condRes?.data?.cp_condition_list;
    //         if (cond && cond.length > conTotal) {
    //             condRes = yield extensionsApi.getCallScreeningConditions(
    //                 session_id,
    //                 {
    //                     i_account: action.payload.i_account,
    //                     get_total: 1,
    //                     limit: conTotal,
    //                 },
    //             );
    //         }
    //         yield put(actions.getCallScreeningRules.success(rulesRes.data));
    //         yield put(actions.getCallScreeningConditions.success(condRes.data));
    //     } catch (e: any) {
    //         yield put(actions.getCallScreeningRules.failure(e));
    //         toast(i18n.t<string>('screens:extensions.fetchCallScreeningFailed'));
    //     }
}

export function* getCallScreeningConditionData(
    action: ActionType<typeof actions.getCallScreeningConditions.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);

    try {
        const condRes: AxiosResponse<GetCPConditionListResponsePayload> = yield extensionsApi.getCallScreeningConditions(
            session_id,
            csrf_token,
            action.payload,
        );
        const type = action.payload.type ? action.payload.type : undefined;

        const resPayload = {
            ...condRes.data,
        };
        if (type) {
            resPayload.type = type;
        }
        yield put(actions.getCallScreeningConditions.success(resPayload));
    } catch (e: any) {
        //toast(i18n.t<string>('screens:extensions.fetchCallScreeningFailed'));
        const error = e?.response?.data;
        yield put(actions.getCallScreeningConditions.failure(error));
    }
}

export function* getCpRuleList(
    action: ActionType<typeof actions.getCpRuleList.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try{
        body.setParams(action.payload);

        const res: AxiosResponse<GetCPRuleListResponse> = yield api.post(
            Account.GetCpRuleList,
            body,
        );

        yield put(actions.getCpRuleList.success(res.data.cp_rule_list || []));
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getCpRuleList.failure(error));
    }
}

export function* getCallProcessingPolicyList(
    action: ActionType<typeof actions.getCallProcessingPolicyList.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);
    
        const res: AxiosResponse<GetCallProcessingPolicyListResponse> = yield api.post(
            Customer.GetCallProcessingPolicyList,
            body,
        );
    
        const data = res.data.cp_policy_list;
        if(data?.length) {
            for(const policy of data) {
                if(policy?.rule_list?.length) {
                    for(const indx in policy.rule_list) {
                        if(!policy.rule_list[indx]) continue;
                        policy.rule_list[indx].order_number = parseInt(indx) + 1;
                    }
                }
            }
        }
    
        yield put(
            actions.getCallProcessingPolicyList.success(data),
        );
    }
    catch(e) {
        const err = (e as AxiosError)?.response?.data as APIErrorInterface;
        yield put(
            actions.getCallProcessingPolicyList.failure(err)
        );
    }
}
