import i18next from "i18next";

export function getUploadFileErrorMsg(faultString: string) {

    const searchErrorText = 'The entity you are trying to upload doesn\'t have the'
    let output = faultString;

    if (faultString.includes(searchErrorText)) {
        const test = faultString.split("'");
        const colName = test.length ? test[test.length - 2].trim() : faultString;
        output = i18next.t<string>('screens:devices.errorMissingColumn', {column_name: colName})
    }

    return output;
}