import React from "react";
import {CallsItem} from "../../../store/reducers/extensions/extensions/reducer";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    separator: {
        marginLeft: 8,
        marginRight: 8
    },
    maxCallsItem: {
        fontWeight: 400,
    }
}));


interface CallsComponentInterface {
    filteredCalls: CallsItem[]
}

// @ts-ignore
const CallsComponent: React.VFC<CallsComponentInterface> = ({filteredCalls}) => {

    const classes = useStyles();

    return filteredCalls.map((item, index) => {
        return (
            <span
                className={classes.maxCallsItem}
                key={item.name}
            >
                {item.value} {item.translation}
                {index != filteredCalls.length - 1 ?
                    <span className={classes.separator}>/</span> : ''
                }
            </span>
        )
    })

}

export default CallsComponent