import React, {useEffect, useRef, useState} from "react";
import PoliciesForm from "./PoliciesForm";
import CallScreeningsTabs from "./CallScreeningsTabs";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import { useSelector } from "react-redux";
import { CP_CONDITIONS_FETCH_API_ERROR } from "../../../../store/reducers/callSettings/reducer";
import { APIErrorInterface, ReduxState } from "../../../../store/types";
import AccessDenied from "../../../PermissionProvider/AccessDenied";

const useStyles = makeStyles(() => ({
    select: {
        marginTop: 10,
        maxWidth: 392,
    },
    itemsContainer: {
        padding: '22px 16px 17px 16px',
        maxWidth: 970,
        display: 'flex',
        flexDirection: 'column',
    },
    policyFormContainer: {
        paddingLeft: 24,
        paddingBottom: 31
    }
}));

export type CallScreeningFormProps = {};

const CallScreeningForm: React.VFC<CallScreeningFormProps> = () => {
    const classes = useStyles();

    const apiErrors: APIErrorInterface | undefined = useSelector((state: ReduxState) => 
        state.callSettings?.getCpConditionsListApiError);

    if(apiErrors?.faultcode === CP_CONDITIONS_FETCH_API_ERROR) {
        return <AccessDenied subPageMode />;
    }
    
    const [height, setHeight] = useState(0)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if ( ref && ref.current && ref.current.clientHeight > height)
        {
            setHeight(ref.current.clientHeight)
        }
    });

    return (
        <div>
            <Grid item className={classes.itemsContainer}>
                <div className={classes.policyFormContainer}>
                    <PoliciesForm/>
                </div>
                <div style={{'minHeight': height}} ref={ref}>
                    <CallScreeningsTabs/>
                </div>
            </Grid>
        </div>
    )
};

export default CallScreeningForm;