import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import DialogContainer, {DialogButton} from '../AlertDialog/DialogContainer';
import {Colors} from '../../styles/Colors';
import {ExtensionType} from "../../store/types/Extension";
import {displayCallerText} from "../../views/CallHistory/ActiveCalls/ActiveCalls.utils";
import {SipCall} from "../../store/actions/ringgroups/payloads";

interface DisconnectDialogProps {
    handleClickOnConfirm: () => void;
    isOpen: boolean;
    toggleVisibility?: () => void;
    accounts: ExtensionType[]
    activeCallEntity: SipCall

}

const useStyles = makeStyles(() => ({
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },

    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 336,
            minWidth: 336,
            height: 76,
            minHeight: 76,
            backgroundColor: Colors.SmokeBackground,
            padding: '24px 24px 19px 24px'
        },
    },

    buttonsContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },

}));

const DisconnectDialog: React.VFC<DisconnectDialogProps> = (
    {
        isOpen,
        toggleVisibility,
        handleClickOnConfirm,
        accounts,
        activeCallEntity
    }) => {

    const classes = useStyles();
    const {t} = useTranslation();


    const transform = (input: string) => {
        const desc = input.substr(input.indexOf('-'));
        const split = input.split(' ');
        const text1 = split[0];
        const text2 = '(' + desc.substring(1, desc.length).trimLeft() + ')';

        return text1 + ' ' + text2;
    }

    const callerText = displayCallerText(accounts, activeCallEntity.caller);
    const calleeText = displayCallerText(accounts, activeCallEntity.callee);

    let from = callerText == 'call_supervision' || activeCallEntity.caller.id == 'call_supervision' ? t('screens:calls.conference') : callerText;
    let to = callerText == 'call_supervision' || activeCallEntity.callee.id == 'call_supervision' ? t('screens:calls.conference') : calleeText;

    from = from.length ? from : (activeCallEntity.caller?.id ?? activeCallEntity.caller?.access_number ?? '');
    to = to.length ? to : (activeCallEntity.callee?.id ?? activeCallEntity.callee?.access_number ?? '');

    if (from?.includes('-')) {
        from = transform(from);
    }

    if (to?.includes('-')) {
        to = transform(to);
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="call-record-dialog"
            className={classes.modalContainer}
            hideHeader
            dialogActionsButtons={[
                <DialogButton
                    className={classes.primaryActionButton}
                    key="close"
                    label={t('common:cancel')}
                    onClick={toggleVisibility}
                />,
                <DialogButton
                    className={classes.primaryActionButton}
                    key="save"
                    label={t('screens:calls.disconnect')}
                    onClick={handleClickOnConfirm}
                />,
            ]}
        >

            <Grid style={{
                height: '100%',
                display: 'flex',
                flex: 1,
                alignItems: 'center'
            }}>
                <span style={{fontWeight: 400, lineHeight: '24px', fontSize: 16}}>
                    {t('screens:calls.disconnectText', {from: from ?? '', to: to ?? ''})}
                </span>
            </Grid>

        </DialogContainer>
    );
};

export default DisconnectDialog;
