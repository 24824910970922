import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 8,
        marginLeft:20
    },
    inputs: {
        display: 'flex',
        paddingLeft: 16,
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },
    switchContainer: {
        marginBottom: 0,
    },
    formatSelect: {
        width: 320,
        maxWidth: 320,
        marginLeft: 12,
    },
}));

export type PreferedIvrLanguageFormProps = {
    toolTipKey: 'language_extensions' | 'language_sipTrunks' | 'language_autoAttendants',
    customClasses?: {
        root?: string
    }
};