import { AxiosResponse } from 'axios';
import { put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { Account } from '../../../../services/endpoints';
import JSONFormData from '../../../../utils/JSONFormData';
import { EditExtensionForm } from '../../../../views/Extensions/ExtensionDetails/utils';
import * as actions from '../../../actions';
import { api } from '../../../services/axios';
import { CallBarringRule } from '../../../types/CallBarring';
import { ServiceFeature, ServiceFeatureName } from '../../../types/ServiceFeature';

export function* getCallBarringRules(
    action: ActionType<typeof actions.getCallBarringRules.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            i_account: action.payload.i_account,
            get_effective_values: 1,
        });
        const response: AxiosResponse<{
            options: CallBarringRule[];
        }> = yield api.post(Account.GetCallBarringOptions, body);

        yield put(
            actions.getCallBarringRules.success({
                rules: response.data.options,
            }),
        );
    } catch (e: any) {
        yield put(actions.getCallBarringRules.failure(e));
    }
}

export function* updateCallBarringRules(
    data: Partial<EditExtensionForm>,
    action: ActionType<typeof actions.editExtension.request>,
    initialFeatureValue?: ServiceFeature
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    if (data.callBarringStatus !== undefined) {
        const status = data.callBarringStatus ? 'Y' : 'N';
        body.setParams({
            i_account: action.payload.accountId,
            service_features: [
                {
                    name: ServiceFeatureName.CallBarring,
                    flag_value: status,
                    locked: data.callBarringLocked ? 1 : 0,
                    attributes: [{
                        name: 'call_barring_rules',
                        values: data.callBarringItems ? data.callBarringItems
                            .map(c => c.i_cp_condition + '') : []
                    }]
                },
            ],
            detailed_response: 1
        });

        yield api.post(Account.UpdateServiceFeatures, body);
    }
    else if(data.callBarringItems !== undefined && 
        initialFeatureValue !== undefined && 
        initialFeatureValue.flag_value !== 'Y') {
            body.setParams({
                i_account: action.payload.accountId,
                service_features: [
                    {
                        name: ServiceFeatureName.CallBarring,
                        flag_value: 'Y',
                        locked: data.callBarringLocked ? 1 : 0,
                        attributes: [{
                            name: 'call_barring_rules',
                            values: data.callBarringItems ? data.callBarringItems
                                .map(c => c.i_cp_condition + '') : []
                        }]
                    },
                ],
                detailed_response: 1
            });
    
            yield api.post(Account.UpdateServiceFeatures, body);
    }

    if (data.callBarringItems !== undefined) {
        body.setParams({
            i_account: action.payload.accountId,
            options: data.callBarringItems,
        });

        yield api.post(Account.UpdateCallBarringOptions, body);
    }
}
