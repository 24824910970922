import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import history from '../../history';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../store/actions';
import {ReduxState} from '../../store/types';
import Snackbar from '../../components/Snackbar/Snackbar';
import ChangePasswordForm from '../../components/Forms/LoginForm/ChangePasswordForm';
import {MailMePayload} from '../../store/actions/auth/payloads';
import {getTranslationByFaultString, useQuery, useStyles,} from './ResetPassword.utils';
import {Routes} from '../../routes/routes';
import SignInHeaderComponent from '../../components/AuthWraper/SignInHeaderComponent';
import usePageTitle from '../../hooks/usePageTitle';


const ResetPassword = () => {

    const dispatch = useDispatch();
    const recoveryId = useQuery().get('nonce');
    usePageTitle();

    const {session_id, errors, passwordRecovered} = useSelector(
        (state: ReduxState) => state.auth,
    );

    const {t} = useTranslation();

    if (!recoveryId || passwordRecovered) {
        history.push(Routes.SignIn);
    }

    if (session_id) {
        history.push(Routes.Default);
    }

    const [showSnackbar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<React.ReactNode>(undefined);

    const dispatchEmailMeAction = (payload: MailMePayload) => {
        dispatch(actions.passwordRecovery.request(payload));
    };

    const handleOnCloseSnackbar = () => {
        setShowSnackBar(false);
        dispatch(actions.loginClearErrors());
    };

    useEffect(() => {
        if (errors) {
            setShowSnackBar(true);
            setSnackbarMessage(getTranslationByFaultString(errors.faultstring));
        }
    }, [errors]);

    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Snackbar
                isOpen={showSnackbar}
                snackbarMessage={snackbarMessage}
                onButtonClick={handleOnCloseSnackbar}
                handleClose={handleOnCloseSnackbar}
                actionLabel={t('common:closeSnackbar')}
                dataQa="login-errors-snackbar"
            />
            <div className={classes.wrapper}>
                <SignInHeaderComponent
                    welcomeText={t('screens:signIn.resetPassword')}
                />
                <Grid item className={classes.credentials}>
                    <ChangePasswordForm
                        changePasswordAction={dispatchEmailMeAction}
                        validateOnChange={false}
                        recoveryId={recoveryId ? recoveryId : ''}
                        dataQa={'recovery-password-form'}
                        apiErrors={errors ? errors : undefined}
                    />
                </Grid>
            </div>
        </Grid>
    );
};

export default ResetPassword;
