import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as BadgeTop } from '../../assets/BadgeTop.svg';
import { ReactComponent as BadgeBottom } from '../../assets/BadgeBottom.svg';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import CustomizedTooltip from '../Tooltip/Tooltip';
import AnimatedNumberText from "../AnimatedNumber/AnimatedNumber";

const useStyles = makeStyles(() => ({
    opacity: {
      opacity:.5,
        transition: 'opacity .5s'
    },
    container: {
        opacity:1,
        transition: 'opacity .5s'
    },
    header: {
        color: Colors.Gray5,
        fontSize: 16,
    },
    valueTextContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    valueText: {
        fontSize: 20,
        color: Colors.Text,
    },
    badge: {
        border: `1px solid ${Colors.Support}`,
        backgroundColor: Colors.White,
        color: Colors.Support,
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        height: 24,
        paddingLeft: 7,
        paddingRight: 8,
        borderRadius: 4,
        marginLeft: 8,
    },
    badgeText: {
        marginLeft: 7,
    },
    negativeBadge: {
        border: `1px solid ${Colors.Error}`,
        color: Colors.Error,
    },
    boldText: {
        fontWeight: 700,
    },
}));

export function numberWithSpaces(x: number | undefined) {
    return x?.toLocaleString()
        .replaceAll('.', ' ')
        .replaceAll(',', ' ')
        ?? '';
}



const CallsTodayInfo: React.VFC = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { todayCalls, yesterdayCalls,getCallHistoryBasicDataRefresh } = useSelector<
        ReduxState,
        { todayCalls: number; yesterdayCalls: number; getCallHistoryBasicDataRefresh: boolean }
    >((state) => state.calls);

    const difference = todayCalls - yesterdayCalls;

    const tooltipText = t('tooltips:calls.comparedToPrevousDay', {
        value: numberWithSpaces(yesterdayCalls),
    }).split('(');

    return (
        <div className={classNames(classes.container, getCallHistoryBasicDataRefresh && classes.opacity)}>
            <span className={classes.header}>
                {t('screens:calls.totalCallsToday')}
            </span>

            <div className={classes.valueTextContainer}>
                <span className={classes.valueText}>
                    <AnimatedNumberText value={Number(numberWithSpaces(todayCalls))} />
                </span>

                {difference !== 0 && (
                    <CustomizedTooltip
                        title={
                            <span>
                                {tooltipText[0]}
                                <span className={classes.boldText}>
                                    ({tooltipText[1]}
                                </span>
                            </span>
                        }
                        copy={false}
                    >
                        <div
                            className={classNames(
                                classes.badge,
                                difference < 0 && classes.negativeBadge,
                            )}
                        >
                            {difference < 0 ? (
                                <BadgeBottom data-testid="arrow-down" />
                            ) : (
                                <BadgeTop data-testid="arrow-up" />
                            )}
                            <span
                                className={classes.badgeText}
                                data-testid="calls-delta"
                            >
                                {numberWithSpaces(Math.abs(difference))}
                            </span>
                        </div>
                    </CustomizedTooltip>
                )}
            </div>
        </div>
    );
};

export default CallsTodayInfo;
