import i18n from "../services/i18n";

export const recordingValidationMsg = (
    faultCode?: string,
    defaultMsg?: string,
) => {

    if (faultCode == FaultCode.RecordingValidationCode) {
        return i18n.t<string>('common:callRecordingValidationFailed');
    }

    if (faultCode == FaultCode.ServiceNotEnabledCode) {
        return i18n.t<string>('common:serviceNotEnabled');
    }

    return defaultMsg;
};

enum FaultCode {
    RecordingValidationCode = 'Server.Account.sf.call_recording.validation_failed',
    ServiceNotEnabledCode = 'Server.service_not_enabled'
}