import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../styles/Colors";
import {CallsItem} from "../../../store/reducers/extensions/extensions/reducer";
import {useTranslation} from "react-i18next";
import CallsComponent from "./CallsComponent";

const useStyles = makeStyles(() => ({
    maxCallsDiv: {
        fontSize: 12,
        lineHeight: 'px',
        color: Colors.Black,
        marginLeft: 54
    },
    maxCallsText: {
        fontWeight: 700,
        marginRight: 8
    }
}));


type MaxCallsComponentProps = {
    calls?: CallsItem[]
}

export const MAX_CALLS_DATA_TEST_ID = 'max-calls-container'

const MaxCallsComponent: React.VFC<MaxCallsComponentProps> = ({calls}) => {

    if (!calls?.length) {
        return <></>
    }

    const classes = useStyles();
    const {t} = useTranslation();
    const filteredCalls = calls?.filter(call => call.value > 0);
    const callComponent = <CallsComponent filteredCalls={filteredCalls}/>

    return (
        <div className={classes.maxCallsDiv} data-testid={MAX_CALLS_DATA_TEST_ID}>
            <div>
                <span className={classes.maxCallsText}>{t('screens:sipTrunks.maxCallsTitle')}</span>
                {callComponent}
            </div>
        </div>
    )
}

export default MaxCallsComponent