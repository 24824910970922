import {Fade} from '@material-ui/core';
import classNames from 'classnames';
import React, {useMemo} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
import {Colors} from '../../styles/Colors';
import AnimatedNumberText from '../AnimatedNumber/AnimatedNumber';
import {DoughnutChartProps, useStyles} from './doughnutChartUtils';

const DoughnutChart: React.VFC<DoughnutChartProps> = (
    {
        data,
        isLoading,
        accessDenied,
        hideLegend = false,
        cutout = 35,
        icon,
        hideLoadingLabelOnLoading,
        inLine= true
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const dataset = useMemo(() => {
        return {
            labels: data.map((v) => v.label),
            datasets: [
                {
                    data: data.map((v) => v.value),
                    backgroundColor: data.map((v) => v.color),
                    borderColor: data.map(() => Colors.White),
                },
            ],
        };
    }, [data]);

    const dataSum = dataset.datasets[0].data.reduce((v, w) => v + w, 0);
    return (
        <div className={hideLegend ? classes.minimalContainer : classes.mainContainer}>
            <div className={classes.chartMainContainer}>
                <div className={ hideLegend ? classes.minChartContainer : classes.chartContainer}>
                    <div
                        className={classNames(
                            hideLegend ? classes.minChartPlaceholder : classes.chartPlaceholder,
                            isLoading && classes.chartLoadingBackground,
                            dataSum === 0 && classes.chartEmptyBackground,
                        )}
                    />
                    {!isLoading && (
                        <Doughnut
                            className={classes.chart}
                            data={dataset}
                            height={200}
                            width={200}
                            options={{
                                responsive: true,
                                //@ts-ignore
                                cutout: cutout,
                                //@ts-ignore
                                borderWidth: 1,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltip: {
                                        enabled: false,
                                    },
                                },
                            }}
                        />
                    )}


                    {!isLoading && (
                        <div className={hideLegend ? classes.smallSumValue:classes.sumValue}>
                            <AnimatedNumberText
                                className={hideLegend ? classes.smallSumText : classes.sumText}
                                hideThousands
                                value={dataSum}
                                dataTestId="doughnut-chart-sum-value"
                                dataQa="doughnut-chart-sum-value"
                                showLongDash={accessDenied}
                                icon={icon}
                                inLine={inLine}
                            />
                        </div>
                    )}
                    
                    {!(hideLoadingLabelOnLoading ?? false) && (
                        <Fade in={isLoading} timeout={1000} appear={isLoading}>
                            <div className={classes.loadingLabel}>
                                {t('common:loading')}
                            </div>
                        </Fade>
                    )}
                </div>
            </div>


            {!hideLegend && (
                <div className={classNames(classes.labelsContainer)}>
                    {data.map((v) => (
                        <div key={v.label} className={classes.labelItemContainer}>
                            <div className={classes.dotContainer}>
                                <div
                                    className={classes.dot}
                                    style={{backgroundColor: v.color}}
                                />
                            </div>
                            <div className={classes.labelItemTextContainer}>
                                <div className={classes.labelTopTextContainer}>
                                    <span className={classes.labelText}>{v.label}</span>
                                </div>
                                {!isLoading ? (
                                    <AnimatedNumberText
                                        value={v.value}
                                        dataTestId="doughnut-chart-legend-value"
                                        dataQa="doughnut-chart-legend-value"
                                        showLongDash={accessDenied}
                                        inLine={inLine}
                                    />
                                ) : (
                                    <span>—</span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}


        </div>
    );
};

export default DoughnutChart;
