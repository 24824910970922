import React, {useCallback, useEffect, useRef, useState} from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {Colors} from '../../styles/Colors';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import CustomizedIconButton from '../IconButton/IconButton';
import {Timer} from '@material-ui/icons';
import {ClickAwayListener, Popper} from '@material-ui/core';
import SelectField from '../SelectField/SelectField';
import {
    defaultWrapUpInput,
    minMask,
    useStyles,
    MinSecRangePickerProps, minSecPlaceholder, createTimeString,
} from './MinSecRangePicker.utils';
import {useTranslation} from 'react-i18next';
import {usePermissionContext} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";

const MinSecTimeMask = (props: any) => {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={minMask}
            placeholder={minSecPlaceholder}
            keepCharPositions
        />
    );
};

const MinSecRangePicker: React.VFC<MinSecRangePickerProps> = (
    {
        name,
        label,
        id = 'min-sec-time-range-picker',
        className,
        placeholder,
        InputProps,
        inputProps,
        InputLabelProps,
        defaultValue,
        onChange,
        required,
        helperText,
        customClasses,
        value,
        setTextRepresentation
    }) => {
    const classes = useStyles();
    const inputRef = useRef<any>();
    const {t} = useTranslation();
    const permission = usePermissionContext();

    const [anchorEl, setAnchorEl] = useState(null);
    const [inputValue, setInputValue] = useState(
        value || defaultValue,
    );

    const [minutes, setMinutes] = useState(
        // getMinInString(defaultValue || defaultWrapUpInput)
        new Date(value || defaultValue || defaultWrapUpInput).getMinutes().toString()
    );

    const [seconds, setSeconds] = useState(
        new Date(value || defaultValue || defaultWrapUpInput).getSeconds().toString()
    );

    const [hidePlaceHolder, setHidePlaceHolder] = useState(false);


    useEffect(() => {

        const values = inputValue.split(':');

        setTextRepresentation && setTextRepresentation(inputValue);

        const minutes = values[0]
        const seconds = values[1]

        setMinutes(isNaN(minutes) ? '0' : minutes);
        setSeconds(isNaN(seconds) ? '0' : seconds);

        if (defaultValue !== inputValue && inputValue !== '') {
            onChange?.(Number((isNaN(minutes) ? '0' : minutes) * 60) + Number(isNaN(seconds) ? '0' : seconds))
            setHidePlaceHolder(false);

        } else if (inputValue == '') {
            onChange?.(undefined)
            setHidePlaceHolder(true)
        }

    }, [inputValue, defaultValue]);

    const onChangeTime = useCallback(
        (newMinutes, newSeconds) => {
            setMinutes(newMinutes);
            setSeconds(newSeconds);

            const newInput = createTimeString(newMinutes, newSeconds)

            if (inputValue !== newInput) {
                setInputValue(newInput);
                onChange?.((60 * Number(newMinutes)) + Number(newSeconds))
            }
        },

        [inputValue],
    );

    const handleClick = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);

        onChangeTime(minutes, seconds);

    };

    const onKeyDown = (e: any) => {

        setInputValue(e.target.value);
    }


    const timeMask = useCallback(
        (props) => <MinSecTimeMask {...props} />,
        [],
    );

    const open = Boolean(anchorEl);
    const ids = open ? 'simple-popper' : undefined;


    const items = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map(v => v.toString());


    return (
        <div
            ref={inputRef}
            className={classNames(classes.textField, className)}
        >
            <FormControl
                className={classNames(!!helperText && classes.error, hidePlaceHolder && classes.hidePlaceHolder)}>
                {label && (
                    <InputLabel htmlFor={id} {...InputLabelProps} className={classes.noWrapLabel}>
                        {label}
                        {required && (
                            <span className={classes.requiredSign}> *</span>
                        )}
                    </InputLabel>
                )}

                <span className={classes.testValue} data-testid="input-value">
                    {inputValue}
                </span>

                <Input
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}

                    name={name}
                    id={id}
                    inputComponent={timeMask}
                    placeholder={placeholder}
                    {...InputProps}
                    inputProps={inputProps}
                    onKeyDown={onKeyDown}
                    required={required}
                    readOnly={permission !== PermissionType.Visible}
                    disabled={permission !== PermissionType.Visible}
                    classes={{disabled: permission !== PermissionType.Visible ? classes.readOnly : undefined}}
                />

                <CustomizedIconButton
                    className={classes.iconButton}
                    onClick={handleClick}
                    dataTestId="time-range-picker-popper"
                >
                    <Timer htmlColor={Colors.Gray5} aria-describedby={ids}/>
                </CustomizedIconButton>

                <Popper
                    open={open}
                    anchorEl={inputRef.current}
                    container={document.body}
                    style={{zIndex: 1000}}
                    popperOptions={{
                        modifiers: {
                            offset: {
                                enabled: true,
                                offset: '0,8px',
                            },
                        },
                        positionFixed: false,

                    }}
                    placement="bottom-start"
                >
                    {({TransitionProps}) => (
                        <ClickAwayListener onClickAway={handleClick}>
                            <div
                                className={classNames(classes.popperContainer, customClasses?.popperContainer)}
                                {...TransitionProps}
                            >
                                <div className={classes.popperRow}>
                                    <SelectField
                                        label={t('common:minutes')}
                                        dataTestId="min-select-filed"
                                        items={items}
                                        value={minutes || 0}
                                        disableClearable
                                        onChange={(_, value) => {
                                            onChangeTime(
                                                value, seconds
                                            );
                                        }}
                                        classes={{
                                            container: classes.selectContainer,
                                        }}
                                    />
                                    <SelectField
                                        label={t('common:seconds')}
                                        items={items}
                                        value={seconds || 0}
                                        disableClearable
                                        onChange={(_, value) => {
                                            onChangeTime(
                                                minutes, value
                                            );
                                        }}
                                        classes={{
                                            container: classes.selectContainer,
                                        }}
                                    />

                                </div>
                            </div>
                        </ClickAwayListener>
                    )}
                </Popper>
            </FormControl>

            {!!helperText && (
                <span className={classes.helperText}>{helperText}</span>
            )}
        </div>
    );
};

export default MinSecRangePicker;