import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';
import * as Yup from 'yup';
import { CallBarringRule } from '../../../../store/types/CallBarring';

export const useStyles = makeStyles(() => ({
    exPadding:{
        paddingLeft:60
    },
    itemsContainer: {
        padding: '22px 16px 17px 16px',

        maxWidth: 876,
        display: 'flex',
        flexDirection: 'column',
        '& > :first-child': {
            '& div': {
                marginBottom: 0,
            },
        },
    },

    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        alignItems: 'center',

        '& .makeStyles-inputs-41': {
            marginBottom: 0,
        },

        '& .MuiTypography-body1': {
            color: `${Colors.Gray7} !important`,
        },
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Gray7,
    },
    boldHeader: {
        fontWeight: 700,
    },
    checkbox: {
        '& .MuiTypography-body1': {
            color: Colors.Text,
            fontSize: 14,
        },
    },
    individualRules: {
        '& .MuiTypography-root': {
            fontWeight: 'normal',
            fontSize: '16px !important',
        },
    },
    itemContainer: {
        padding: '0 !important',
        display: 'flex',
    },
    itemsElementsContainer: {
        paddingTop: 18,
        maxWidth: 650,
        marginLeft:0
    },
    noData: {
        color: Colors.LightGraySecondary2,
        display: 'flex',
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 15,
        paddingLeft: 12,
    },
    headerClass: {
        display: 'flex',
        maxWidth: 986,
        paddingLeft: '0 !important',
        marginTop: 35,
        color: Colors.Text,
        fontSize: 16,

        '& span': {
            fontWeight: 500,
        },

        '& .MuiFormControlLabel-root': {
            '& span': {
                fontSize: 16,
            },
        },

        paddingBottom: '0px !important',
        lineHeight:'inherit'
    },
}));

export type CallBarringFormType = {
    callBarringStatus: boolean;
    callBarringItems: CallBarringRule[];
    callBarringLocked: boolean;
    individualRules: boolean;
};

export const callBarringFormValidationSchema = Yup.object().shape({
    callBarringStatus: Yup.boolean().required(),
    callBarringItems: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required(),
                status: Yup.boolean().notRequired(),
            }),
        )
        .notRequired(),
});

export const callBarringFormDefaultValues: CallBarringFormType = {
    callBarringStatus: false,
    callBarringItems: [],
    callBarringLocked: false,
    individualRules: false,
};

export type CallBarringFormProps = {};

export enum PreferedIvrLanguage {
    "ar" = "ar",
    "my" = "my",
    "yu" = "yu",
    "cm" = "cm",
    "en" = "en",
    "fr" = "fr",
    "fr-ch" = "fr-ch",
    "de" = "de",
    "el" = "el",
    "he" = "he",
    "it" = "it",
    "fa" = "fa",
    "pt-br" = "pt-br",
    "ru" = "ru",
    "es" = "es",
    "sv" = "sv",
    "tr" = "tr",
    "nl" = "nl",
    "ms" = "ms",
    "pl" = "pl"
};

export type PreferedIvrLanguageFormType = {
    preferedIvrLanguageStatus: boolean;
    preferedIvrLanguageBlocked: boolean;
    preferedIvrLanguageCode?: PreferedIvrLanguage;
}

export const preferedIvrLanguageFormValidationSchema = Yup.object().shape({
    preferedIvrLanguageStatus: Yup.boolean().notRequired(),
    preferedIvrLanguageBlocked: Yup.boolean().notRequired(),
    preferedIvrLanguageCode: Yup.string().when('preferedIvrLanguageStatus', {
        is: true,
        then: Yup.string()
            .nullable(true)
            .required(),
        otherwise: Yup.string().nullable(true).notRequired(),
    }),
});

export const preferedIvrLanguageFormDefaultValues: PreferedIvrLanguageFormType = {
    preferedIvrLanguageStatus: false,
    preferedIvrLanguageBlocked: false,
    preferedIvrLanguageCode: PreferedIvrLanguage.en,
};