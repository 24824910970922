import moment from "moment";
import 'moment-timezone';
import i18n from "../../../../services/i18n";
import dayjs from "../../../../services/customDayJs";

export type DefaultDatePreferences = {
    currentMonthStartDate: string;
    currentMonthEndDate: string;
    previousMonthStartDate: string;
    previousMonthEndDate: string;
};

export type TimeLinePositions = 'Empty' | 'Future' | 'Past' | 'Current';

export const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export function getDefaultDatePreferences(date: Date): DefaultDatePreferences {
    moment.locale(localStorage.getItem('user_language')?.toLowerCase() || 'en');

    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    const _currentMonthStartDate = moment(date).format(dateFormat);
    const dateCopy = new Date(date.valueOf());

    date.setMonth(date.getMonth()-1);
    const _previousMonthStartDate = moment(date).format(dateFormat);
    
    date.setMonth(date.getMonth()+2);
    date.setSeconds(-1);
    const _currentMonthEndDate = moment(date).format(dateFormat);
    
    dateCopy.setSeconds(-1);
    const _previousMonthEndDate = moment(dateCopy).format(dateFormat);//error

    return {
        currentMonthStartDate: _currentMonthStartDate,
        currentMonthEndDate: _currentMonthEndDate,
        previousMonthStartDate: _previousMonthStartDate,
        previousMonthEndDate: _previousMonthEndDate
    } as DefaultDatePreferences;
}

export function convertDateFromTimeZoneToTimeZone(date: string, timeZoneFrom: string, timeZoneTo: string): string {
    const dt = moment(date, dateFormat);
    const fromTz = moment.tz(timeZoneFrom);
    fromTz.set({
        year: dt.year(),
        month: dt.month(),
        date: dt.date(),
        hour: dt.hour(),
        minute: dt.minute(),
        second: dt.second(),
        millisecond: dt.millisecond()
    });
    return moment.tz(fromTz.valueOf(), timeZoneTo).format(dateFormat);
}

export function convertDate(date: Date | null | undefined, timeZoneFrom: string, timeZoneTo: string, userFormat?: string): string | undefined {
    if(!date) {
        return undefined;
    }

    if(!date.getFullYear) {
        date = new Date(date);
    }

    const dt = moment(date);
    const fromTz = moment.tz(timeZoneFrom);
    fromTz.set({
        year: dt.year(),
        month: dt.month(),
        date: dt.date(),
        hour: dt.hour(),
        minute: dt.minute(),
        second: dt.second(),
        millisecond: dt.millisecond()
    });

    const tzConverted = fromTz.format(dateFormat);
    const convertedDate = convertDateFromTimeZoneToTimeZone(tzConverted, timeZoneFrom, timeZoneTo);

    if(!userFormat) {
        return convertedDate;
    }

    return dayjs(convertedDate).format(userFormat);
}

export function isDatePastOrFuture(date: string | null | undefined, timeZoneFrom: string, timeZoneTo: string, nowDate: Date = new Date()): 
    TimeLinePositions {

    if(!date) {
        return 'Empty';
    }

    //assume nowDate as always in timeZoneTo
    const fromTz = moment.tz(timeZoneFrom);
    fromTz.set({
        year: nowDate.getFullYear(),
        month: nowDate.getMonth(),
        date: nowDate.getDate(),
        hour: nowDate.getHours(),
        minute: nowDate.getMinutes(),
        second: nowDate.getSeconds(),
        millisecond: 0
    });

    const nowDateConverted = fromTz.format(dateFormat);
    const convertedDate = convertDateFromTimeZoneToTimeZone(date, timeZoneFrom, timeZoneTo);
    
    if(nowDateConverted === convertedDate) {
        return 'Current';
    }

    return nowDateConverted < convertedDate ? 'Future' : 'Past';
}

export function statusBasedOnDates(activation_date: TimeLinePositions, expiration_date: TimeLinePositions): string {
    const inactive = i18n.t<string>('screens:portalUsers.inactive');
    const active = i18n.t<string>('screens:portalUsers.active');
    const expired = i18n.t<string>('screens:portalUsers.expired');

    if(expiration_date === 'Past')
        return expired;
    if(activation_date === 'Current' || activation_date === 'Future') 
        return inactive;

    return active;
}