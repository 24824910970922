import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    outsideContainer: {
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none',
        left: 0,
        margin: "auto",
        zIndex: 9999
    },
    mainContainer: {
        height: 580,
        width: "auto",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    receiptDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: 80,
        width: '100%',
        maxWidth: 400
    },
    logoContainer: {
        marginTop: 20,
        marginBottom: 45,
        height: 60,

        '& svg': {
            height: 60,
        },

        '& img': {
            height: 60,
        },
    },


    '@media (max-width: 720px)': {

        outsideContainer: {
            position: 'absolute',
            opacity: 0,
            pointerEvents: 'none',
            left: 0,
            margin: "auto",
            zIndex:9999,

        },

        logoContainer: {
            marginTop: 40,
            marginBottom: 45,
            height: 60,

            '& svg': {
                height: 60,
            },

            '& img': {
                height: 60,
            },
        },


        mainContainer: {
            height: 600,
            width: 412,
            maxWidth: 412,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

        receiptDataContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            top: 'auto'
        },
    },

}));

export type PaymentReceiptProps = {
    date: string;
    method: string;
    transactionNumber: string;
    total?: string;
    paymentTotal?: string;
    tax?: string;
};
