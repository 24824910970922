import classNames from 'classnames';
import React from 'react';
import OverflowTooltip from '../../../components/OverflowTooltip/OverflowTooltip';
import { ExtensionType } from '../../../store/types/Extension';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../styles/Colors';

export interface CallVolumesWidgetListViewItemProps {
    extension: ExtensionType;
    calcSummaryDuration: (ext: ExtensionType) => string;
    calcCallsCount: (ext?: ExtensionType) => string;
    numberOfCalls: boolean;
    darkMode: boolean;
}

const useStyles = makeStyles(() => ({
    listViewRow: {
        width: 'calc(100% - 16px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 44,
        alignItems: 'center',
    },
    listViewRowSeparator: {
        display: 'block',
        left: 0,
        bottom: 0,
        height: 1,
        width: 'calc(100% - 40px - 16px)',
        marginLeft: 40,
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    },
    listViewIconText: {
        height: 'fit-content',
        width: '100%',
        fontWeight: 700,
        fontSize: 14,
        color: Colors.Text,
        marginTop: 'calc(50% - 14px)',
    },
    listViewIconTextDarkMode: {
        color: '#F5F7F8'
    },
    listViewIconContainer: {
        marginRight: 14,
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 20,
        backgroundColor: Colors.Secondary11,
        marginTop: 2,
        minWidth: 40,
    },
    listViewIconContainerDarkMode: {
        backgroundColor: '#494A55'
    },
    listViewNameText: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '19px',
        color: Colors.Text2,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flex: 1,
        textAlign: 'left',
        minWidth: '30%',
    },
    listViewNameTextDarkMode: {
        color: '#F5F7F8'
    },
    noName: {
        opacity: 0.5
    },
    listViewDurationText: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Text,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    listViewDurationTextDarkMode: {
        color: '#DDE1E7',
        fontSize: 13
    },
    callsCounter: {
        marginLeft: 10,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '16px',
        color: Colors.Text,
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    callsCounterDarkMode: {
        color: '#F5F7F8'
    },
}));

const CallVolumesWidgetListViewItem: React.VFC<CallVolumesWidgetListViewItemProps> = ({
    extension,
    calcSummaryDuration,
    calcCallsCount,
    numberOfCalls,
    darkMode
}) => {

    const {t} = useTranslation();
    const classes = useStyles();
    
    const calcExtNameFontSize = (name: string) => {
        if(name.length <= 6) return 20 - ((name.length - 1) * 2);
        if(name.length <= 10) return 9 - (name.length - 7);
        return '6px';
    };

    return (
    <>
        <div className={classes.listViewRow}>
            <div className={classNames(classes.listViewIconContainer, darkMode && classes.listViewIconContainerDarkMode)}>
                <div className={classNames(classes.listViewIconText, darkMode && classes.listViewIconTextDarkMode)} style={{
                    fontSize: calcExtNameFontSize(extension.extension_id)
                }}>
                    {extension.extension_id}
                </div>
            </div>
            <div className={classNames(classes.listViewNameText, !extension.extension_name?.length && classes.noName, darkMode && classes.listViewNameTextDarkMode)}>
                <OverflowTooltip
                    tooltip={(extension.extension_name?.length ?? 0) > 0 ? extension.extension_name : t('common:unnamed')}
                    text={(extension.extension_name?.length ?? 0) > 0 ? extension.extension_name : t('common:unnamed')}
                    classes={{ text: classNames(classes.listViewNameText, 
                        !extension.extension_name?.length && classes.noName,
                        darkMode && classes.listViewNameTextDarkMode
                    )}}
                    dataQa={"tooltip-list-view-name"}
                    copy={false}
                />
            </div>
            <div className={classNames(classes.listViewDurationText, darkMode && classes.listViewDurationTextDarkMode)}>
                <OverflowTooltip
                    tooltip={calcSummaryDuration(extension)}
                    text={calcSummaryDuration(extension)}
                    classes={{ text: classNames(classes.listViewDurationText, darkMode && classes.listViewDurationTextDarkMode) }}
                    dataQa={"tooltip-list-view-duration"}
                    copy={false}
                />
            </div>
            {numberOfCalls && (
                <div className={classNames(classes.callsCounter, darkMode && classes.callsCounterDarkMode)}>
                    {calcCallsCount(extension)}
                </div>
            )}
        </div>
        <div className={classes.listViewRowSeparator}>
        </div>
    </>
    );
};

export default CallVolumesWidgetListViewItem;