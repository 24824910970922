import React, {useCallback, useEffect, useMemo, useState} from 'react';
import CustomButton from '../../components/Button/Button';
import {Box, Grid} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import {actions} from '../../store';
import classNames from 'classnames';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import {useTranslation} from 'react-i18next';
import DataGrid from '../../components/DataGrid/DataGrid';
import Header from '../../components/ListViewHeader/Header';
import {PaginationMode} from '../../components/DataGrid/types';
import CreateNewRingGroupDialog from './CreateNewRingGroupDialog';
import TextField from '../../components/TextField/TextField';
import {useStyles, generateColumns} from './utils';
import CallHistoryDetails from './CallHistoryDetails';
import {RingGroupType} from '../../store/types/RingGroup';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {Permission, PermissionType} from '../../store/types/Permission';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {CustomerOfficeType} from '../../store/types/CustomerInfo';

const INITIAL_PAGE_SIZE = 10;

export const RingGroupsList: React.VFC = () => {
    const [nameFilter, setNameFilter] = useState('');
    const [numberFilter, setNumberFilter] = useState('');
    const [extensionFilter, setExtensionFilter] = useState('');
    const [, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
    const [
        createNewRingGroupModalVisible,
        setCreateNewRingGroupModalVisible,
    ] = useState(false);

    const [callHistoryModalVisible, setCallHistoryModalVisible] = useState(
        false,
    );

    const [removeModal, setRemoveModal] = useState<{
        isOpen: boolean;
        name?: string;
        id?: number;
    }>();

    const [
        selectedCallHistoryRow,
        setSelectedRowHistoryRow,
    ] = useState<RingGroupType | null>(null);
    const classes = useStyles();
    const {t} = useTranslation();
    usePageTitle();

    const dispatch = useDispatch();
    const {ringGroupsList, isLoading, sipCallsList, sipCallsApiError} = useSelector(
        (state: ReduxState) => state.ringgroups,
    );

    const {extensionsList} = useSelector(
        (state: ReduxState) => state.wallboard,
    );

    const i_office_type: CustomerOfficeType = useSelector<ReduxState, CustomerOfficeType>(
        (state) => state.generic?.customerOfficeType || CustomerOfficeType.Hq
    );

    const i_customer: number = useSelector(
        (state: ReduxState) => state.generic.sessionData?.i_customer || 0,
    );

    const showRemoveRingGroupModal = useCallback((id: number, name: string) => {
        setRemoveModal({
            isOpen: true,
            id,
            name,
        });
    }, []);

    const removeRingGroup = useCallback(() => {
        if (removeModal?.id) {
            dispatch(
                actions.removeRingGroup.request({i_c_group: removeModal.id}),
            );
            setRemoveModal({...removeModal, isOpen: false});
        }
    }, [removeModal]);

    const toggleCallHistoryVisibility = () =>
        setCallHistoryModalVisible(!callHistoryModalVisible);

    const onCallHistoryClick = (param: RingGroupType) => {
        setSelectedRowHistoryRow(param);
        toggleCallHistoryVisibility();
    };
    const columns = useMemo(
        () =>
            generateColumns(
                t,
                showRemoveRingGroupModal,
                onCallHistoryClick,
                classes,
                i_office_type,
                i_customer,
                sipCallsList,
                sipCallsApiError,
                extensionsList
            ),
            [i_office_type, i_customer, sipCallsList, sipCallsApiError, extensionsList],
    );

    const handleNameFilterChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => setNameFilter(e.target.value);

    const handleNumberFilterChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => setNumberFilter(e.target.value);

    const handleExtensionFilterChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => setExtensionFilter(e.target.value);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (params?: { pageSize: number; page: number }) => {
        dispatch(
            actions.ringGroupsList.request({
                nameFilter: `%${nameFilter}%`,
                groupNumberFilter: `%${numberFilter}%`,
                extensionFilter,
                limit: params?.pageSize || pageSize || INITIAL_PAGE_SIZE,
                offset: params ? params?.page * params?.pageSize : 0,
            }),
        );
        dispatch(
            actions.getSipCallsList.request({}),
        );
        dispatch(
            actions.getExtensionsListForWidgets.request({
                    skipService: true,
                    limitAliasDidNumberList: true
                }
            )
        );
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        fetchData();
        setPage(0);
    };

    const handleStatusChange = () => setCreateNewRingGroupModalVisible(true);

    const toggleCreateNewVisibility = () =>
        setCreateNewRingGroupModalVisible(!createNewRingGroupModalVisible);

    return (
        <PermissionPlaceholder permission={Permission.CloudPBX.RingGroups.value}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <div className={classes.header}>
                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.RingGroups.AddNewRingGroup.value
                            }
                            customizePermission={(value) => {
                                if (value === PermissionType.Visible && i_office_type === CustomerOfficeType.Branch) {
                                    return PermissionType.ReadOnly;
                                }
                                return value;
                            }}
                        >
                            <Header
                                title={t('screens:ringGroups.ringGroups')}
                                totalNumber={ringGroupsList.total || 0}
                                showDetails={false}
                                dataQa={'ring-group-list-view-header'}
                                buttonText={t('common:addNewButton')}
                                ofText={t('screens:autoAttendants.disabled')}
                                buttonOnClick={handleStatusChange}
                                buttonDataQa={'add-new-ring-group-button'}
                                plusIcon={true}
                            />
                        </PermissionProvider>
                    </div>

                    <div className={classNames(classes.filters)}>
                        <form
                            className={classes.filtersContainer}
                            onSubmit={handleSubmit}
                        >
                            <Grid item className={classes.itemsContainer}>
                                <Box className={classes.rowBox}>
                                    <TextField
                                        id="number"
                                        label={t('common:number')}
                                        onChange={handleNumberFilterChange}
                                        value={numberFilter}
                                        className={classes.filterSeparation}
                                    />
                                    <TextField
                                        id="groupName"
                                        label={t('common:name')}
                                        onChange={handleNameFilterChange}
                                        value={nameFilter}
                                        className={classes.filterSeparation}
                                    />
                                    <TextField
                                        id="includedExtension"
                                        className={classes.filterSeparation}
                                        label={t(
                                            'screens:ringGroups.includedExtension',
                                        )}
                                        onChange={handleExtensionFilterChange}
                                        value={extensionFilter}
                                    />
                                    <CustomButton
                                        dataQa="search-button"
                                        primary
                                        accent
                                        type="submit"
                                        className={classes.searchButton}
                                    >
                                        {t('common:search')}
                                    </CustomButton>
                                </Box>
                            </Grid>
                        </form>
                    </div>

                    <DataGrid
                        columns={columns}
                        data={ringGroupsList.items}
                        rowCount={ringGroupsList.total}
                        onPageChange={(params) => {
                            fetchData({
                                pageSize: params.pageSize,
                                page: params.page,
                            });
                            setPage(params.page);
                        }}
                        onPageSizeChange={(params) => {
                            setPageSize(params.pageSize);
                            setPage(0);
                            fetchData({pageSize: params.pageSize, page: 0});
                        }}
                        loading={isLoading}
                        initialPageSize={10}
                        paginationMode={PaginationMode.Server}
                        classes={{tableContainer: classes.tableContainer}}
                        centeredRows
                        narrowRows
                    />

                    <AlertDialog
                        isOpen={!!removeModal?.isOpen}
                        header={t('screens:ringGroups.deleteRingGroup')}
                        description={t(
                            'screens:ringGroups.deleteRingGroupDescription',
                            {name: removeModal?.name},
                        )}
                        contentClass="medium-width-modal-delete"
                        dialogActionsButtons={[
                            <DialogButton
                                key="cancel"
                                label={t('common:cancel')}
                                onClick={() =>
                                    setRemoveModal({
                                        ...removeModal,
                                        isOpen: false,
                                    })
                                }
                            />,
                            <DialogButton
                                key="delete"
                                label={t('common:delete')}
                                className={classes.primaryModalButton}
                                onClick={removeRingGroup}
                            />,
                        ]}
                        dataQa="remove-ringgroup-modal"
                        hideHeader={true}
                    />

                    <CreateNewRingGroupDialog
                        isOpen={createNewRingGroupModalVisible}
                        toggleVisibility={toggleCreateNewVisibility}
                    />
                    <CallHistoryDetails
                        isOpen={callHistoryModalVisible}
                        toggleVisibility={toggleCallHistoryVisibility}
                        form={selectedCallHistoryRow}
                        downloadPermission={
                            Permission.CloudPBX.RingGroups.ViewCallHistory
                                .DownloadCallRecording.value
                        }
                    />
                </div>
            </div>
        </PermissionPlaceholder>

    );
};
