import React from 'react';
import { Service, ServiceIcon } from './ServiceIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        '& svg': {
            marginRight: 3,
        },
    },
}));

type ExtensionServicesProps = {
    services: (Service | undefined)[];
    mode?: string;
    forceTooltipDefinition?: { service: Service; text: string }[];
};

export const ExtensionServices: React.VFC<ExtensionServicesProps> = ({
    services,
    mode,
    forceTooltipDefinition,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {services.includes(Service.CallRecording) && (
                <ServiceIcon
                    type={Service.CallRecording}
                    dataQa="call-recording-service"
                    dataTestId="call-recording-service"
                    forceTooltip={
                        forceTooltipDefinition?.find(
                            (def) => def.service == Service.CallRecording,
                        )?.text
                    }
                />
            )}

            {services.includes(Service.CallProcessing) && (
                <ServiceIcon
                    type={Service.CallProcessing}
                    dataQa="call-processing-service"
                    dataTestId="call-processing-service"
                    forceTooltip={
                        forceTooltipDefinition?.find(
                            (def) => def.service == Service.CallProcessing,
                        )?.text
                    }
                />
            )}

            {services.includes(Service.CallProcessingOther) && (
                <ServiceIcon
                    type={Service.CallProcessingOther}
                    dataQa="call-processing-service-no-default"
                    dataTestId="call-processing-service-no-default"
                    mode={mode}
                    forceTooltip={
                        forceTooltipDefinition?.find(
                            (def) => def.service == Service.CallProcessingOther,
                        )?.text
                    }
                />
            )}

            {services.includes(Service.CallProcessingPbxEnabled) && (
                <ServiceIcon
                    type={Service.CallProcessingPbxEnabled}
                    dataQa="call-processing-service-pbx-enabled"
                    dataTestId="call-processing-service-pbx-enabled"
                    mode={mode}
                    forceTooltip={
                        forceTooltipDefinition?.find(
                            (def) =>
                                def.service == Service.CallProcessingPbxEnabled,
                        )?.text
                    }
                />
            )}

            {services.includes(Service.CallProcessingPbxEnabledOther) && (
                <ServiceIcon
                    type={Service.CallProcessingPbxEnabledOther}
                    dataQa="call-processing-service-pbx-enabled-other"
                    dataTestId="call-processing-service-pbx-enabled-other"
                    mode={mode}
                    forceTooltip={
                        forceTooltipDefinition?.find(
                            (def) =>
                                def.service ==
                                Service.CallProcessingPbxEnabledOther,
                        )?.text
                    }
                />
            )}

            {services.includes(Service.ConfEnabled) && (
                <ServiceIcon
                    type={Service.ConfEnabled}
                    dataQa="conf-enabled-service"
                    dataTestId="conf-enabled-service"
                    forceTooltip={
                        forceTooltipDefinition?.find(
                            (def) => def.service == Service.ConfEnabled,
                        )?.text
                    }
                />
            )}

            {services.includes(Service.CallBarring) && (
                <ServiceIcon
                    type={Service.CallBarring}
                    dataQa="call-barring-service"
                    dataTestId="call-barring-service"
                    forceTooltip={
                        forceTooltipDefinition?.find(
                            (def) => def.service == Service.CallBarring,
                        )?.text
                    }
                />
            )}
            {services.includes(Service.UnifiedMessagingFaxOnly) && (
                <ServiceIcon
                    type={Service.UnifiedMessagingFaxOnly}
                    dataQa="unified-messaging-fax-only-service"
                    dataTestId="unified-messaging-fax-only-service"
                    forceTooltip={
                        forceTooltipDefinition?.find(
                            (def) =>
                                def.service == Service.UnifiedMessagingFaxOnly,
                        )?.text
                    }
                />
            )}
            {services.includes(Service.UnifiedMessaging) && (
                <ServiceIcon
                    type={Service.UnifiedMessaging}
                    dataQa="unified-messaging-service"
                    dataTestId="unified-messaging-service"
                    forceTooltip={
                        forceTooltipDefinition?.find(
                            (def) => def.service == Service.UnifiedMessaging,
                        )?.text
                    }
                />
            )}
        </div>
    );
};
