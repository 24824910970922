import React, { useMemo } from 'react';
import { PreferedIvrLanguage, PreferedIvrLanguageFormType } from '../../CallSettings/CallBarring/utils';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import { PreferedIvrLanguageFormProps, useStyles } from './PreferedIvrLanguageForm.utils';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import { Grid } from '@material-ui/core';
import SelectField from '../../../SelectField/SelectField';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import classNames from 'classnames';

const PreferedIvrLanguageForm: React.VFC<PreferedIvrLanguageFormProps> = ({
    toolTipKey,
    customClasses
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const languageList = useMemo(() => {
        return Object.values(PreferedIvrLanguage)
            .map((v) => ({
                name: t(`enums:ivrLanguage.${v}`),
                value: v,
            }));
    }, []);

    const defaultValue = useMemo(() => {
        return languageList.find(e => e.value === PreferedIvrLanguage.en);
    }, [languageList]);

    const {
        values,
        setFieldValue,
        initialValues
    } = useFormikContext<PreferedIvrLanguageFormType>();

    return (
        <div className={classNames(classes.inputs, customClasses?.root)}>
            <SwitchWithLabel
                className={classes.switchContainer}
                id="preferedIvrLanguageStatus"
                field="preferedIvrLanguageStatus"
                label={t('screens:extensions.preferedIvrLanguage')}
                value={values.preferedIvrLanguageStatus}
                disabled={values.preferedIvrLanguageBlocked}
                setValue={(field, value) => {
                    if(!values.preferedIvrLanguageCode && value === true) {
                        setFieldValue('preferedIvrLanguageCode', defaultValue?.value);
                    } else if(initialValues.preferedIvrLanguageStatus === false && value === false) {
                        setFieldValue('preferedIvrLanguageCode', initialValues.preferedIvrLanguageCode);
                    }
                    setFieldValue(field, value);
                }}
                icon={
                    <IconWithTooltip
                        dataQa="language-tooltip"
                        tooltipText={t('tooltips:extensions.' + toolTipKey)}
                    />
                }
            />
            {values.preferedIvrLanguageStatus && (
                <Grid item className={classes.itemsContainer}>
                    <SelectField
                        label={t('screens:extensions.language')}
                        items={languageList}
                        value={languageList.find(
                            (v) => v.value === values.preferedIvrLanguageCode,
                        )}
                        onChange={(_, value) => {
                            setFieldValue(
                                'preferedIvrLanguageCode',
                                value.value,
                                false,
                            );
                        }}
                        disableClearable
                        dataQa="language-input"
                        getOptionLabel={(v: { name: string }) => v.name}
                        getOptionSelected={(v: {
                            value: PreferedIvrLanguage;
                        }) => v.value === values.preferedIvrLanguageCode}
                        classes={{ container: classes.formatSelect }}
                    />
                </Grid>
            )}
        </div>        
    );
};

export default PreferedIvrLanguageForm;