import React from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../styles/Colors";


export const useStyles = makeStyles(() => ({

    valueLabelBottomDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: 39,

        '& span': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        }
    },

    timeMarkerLabel: {
        marginRight: 7,
        marginLeft: 2,

        '& span': {
            fontWeight: 300,
            fontSize: 20,
            color: Colors.Gray5,
            marginBottom: -3
        }
    }
}));

interface TimeUnitsDisplayInterface {
    index: number,
    arrLength: number
}

export const TimeUnitsDisplay: React.VFC<TimeUnitsDisplayInterface> = (
    {
        index, arrLength
    }) => {

    const classes = useStyles();
    const {t} = useTranslation()

    return (
        <div className={classNames(classes.valueLabelBottomDiv, classes.timeMarkerLabel)}>
            {
                (index === arrLength - 1)
                    ? (<span>{t('units:time.seconds')}</span>)
                    : (index === arrLength - 2)
                        ? (<span>{t('units:time.minutes')}</span>)
                        : (<span>{t('units:time.hours')}</span>)
            }
        </div>
    )


}