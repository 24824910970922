import React from "react";
import {Colors} from "../../styles/Colors";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    "@keyframes changewidth": {
        'from': {
            width: '0px',
            marginLeft: '0px'
        },
        '50%': {
            marginLeft: '50%',
            width: '50%'
        },
        'to': {
            width: '100%',
            marginLeft: '100%'
        }
    },

    wrapper: {
        overflow: 'hidden',
        width: '100%'
    },
    mainStyle: {
        width: '100%',
        color: Colors.Primary,
        height: '4px',
        backgroundColor: Colors.Primary,
        position: 'relative',
        marginTop: '-1px',
        animation: `$changewidth 2000ms infinite`,
    },

    hidden: {
        height: '4px',
        backgroundColor: 'transparent',
        width: '100%',
        position: 'relative',
        marginTop: '-1px',
    }
}))

export const dataQaVisible = 'line-loader-visible'
export const dataQaHidden = 'line-loader-hidden'

const LineLoader: React.VFC<{ show?: boolean }> = (
    {show}) => {

    const classes = useStyles()
    return (
        <div className={classes.wrapper}>
            <div
                className={show ? classes.mainStyle : classes.hidden}
                data-qa={show ? dataQaVisible : dataQaHidden}
                data-testid={show ? dataQaVisible : dataQaHidden}
            />
        </div>
    )
}

export default LineLoader;