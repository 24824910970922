import { put, takeLatest, select } from 'redux-saga/effects';
import * as actions from '../../actions';
import { api } from '../../services/axios';
import { AxiosResponse } from 'axios';
import { ReduxState } from '../../types';
import JSONFormData from '../../../utils/JSONFormData';
import { ActionType } from 'typesafe-actions';
import {
    CallControl,
    Customer,
    Generic
} from '../../../services/endpoints';
import { UIConfigInfo } from '../../types/Generic';
import { BaseWallboardWidget, CallVolumesWidgetData, ExtensionPresenceWidgetData, RefreshWidgetQueueItem, RefreshWidgetTime, WidgetCallHistory, WidgetDataType } from '../../types/Wallboard';
import dayjs from '../../../services/customDayJs';
import { fetchAccountList } from '../generic/saga';
import { AccountListResponse } from '../../types/Account';
import { RingGroupType } from '../../types/RingGroup';
import { GetSipCallsListResponse, SipCall, SipCallState } from '../../actions/ringgroups/payloads';
import { getDurationFromSec } from '../../../utils/transformers';
import { ExtensionType } from '../../types/Extension';
import { convertUserLocalTimeToUtc } from '../../../utils/dateWithTimezoneConversion';
import { pagedDataRequest } from '../paged.data.saga';
import {showErrorToast} from "../../../utils/showErrorToast";

const UIConfigItemKey = 'wallboards';
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

export function* getWallboardData() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            section_name: UIConfigItemKey
        });

        const res: AxiosResponse<{
            ui_config_list: UIConfigInfo[];
        }> = yield api.post(Generic.GetUIConfigList, body);

        const itm = res.data?.ui_config_list?.[0]?.value;

        if(itm) {
            const objects: BaseWallboardWidget[] = JSON.parse(itm);
            yield put(actions.getWallboardData.success(objects));
        } else {
            yield put(actions.getWallboardData.success([]));
        }
        
        yield put(actions.refreshWallboardTrigger.request({
            refreshImmidiately: true,
            id: undefined
        }));

    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getWallboardData.failure(error));
    }
}

export function* getExtensionsListForWidgets(action: ActionType<typeof actions.getExtensionsListForWidgets.request>) {
    try {
        const resTotal: AxiosResponse<AccountListResponse> = yield fetchAccountList(undefined, {
            "has_extension": 1,
            "get_only_real_accounts": 1,
            "get_not_closed_accounts": 1
        },
            action.payload.skipService,
            action.payload.limitAliasDidNumberList
        );

        const data = resTotal.data?.account_list || [];
        yield put(actions.getExtensionsListForWidgets.success(data));
        
        yield put(
            actions.validatesAndPostDataToQueue.request()
        );
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getExtensionsListForWidgets.failure(error));
    }
}

export function* updateWallboards(
    action: ActionType<typeof actions.updateWallboards.request>) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            ui_config_list: [
                {
                    section_name: UIConfigItemKey,
                    value: JSON.stringify(action.payload.data)
                }
            ],
        });

        const res: AxiosResponse<{
            success: number;
        }> = yield api.post(Generic.UpdateUIConfigList, body);

        if(res.data.success) {
            action.payload.messageOnSuccess && showErrorToast(action.payload.messageOnSuccess);
            action.payload.onSuccess?.();
            yield put(actions.updateWallboards.success(action.payload.data));
        } else {
            yield put(actions.updateWallboards.failure({
                faultcode: 'unknown error',
                faultstring: 'unknown error'
            }));
        }
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.updateWallboards.failure(error));
    }
}

export function* getCallHistoryDataForWidget() {
    try {
        // @ts-ignore
        const timezoneOffset = yield select( (state: ReduxState) => state.generic.sessionData?.tz_offset) || 0;

        const date = dayjs.utc();
        const fromDate = date.utcOffset(timezoneOffset / 60).add(-7, 'day').format(dateFormat);
        const toDate = date.utcOffset(timezoneOffset / 60).format(dateFormat);

        const params = {
            show_unsuccessful: '0',
            i_service_type: '3',
            from_date: convertUserLocalTimeToUtc(fromDate, timezoneOffset),
            to_date: convertUserLocalTimeToUtc(toDate, timezoneOffset),
        };

        const callHistoryItems: WidgetCallHistory[] = yield pagedDataRequest<WidgetCallHistory>(Customer.GetCustomerXDRS, params, (data) => data.xdr_list);

        const formatTime = dateFormat.split(' ')[1];
        for(const call of callHistoryItems) {
            const startTime = dayjs.utc(call.connect_time ?? call.bill_time, dateFormat);
            const finishTime = dayjs.utc(call.disconnect_time ?? toDate, dateFormat);
            
            const diffDuration = dayjs.utc(finishTime.diff(startTime));
            call.callDuration = diffDuration.format(formatTime);

            const diffInSec = date.diff(startTime) / 1000;
            call.startedMinutesAgo = diffInSec / 60;
            call.durationInSec = finishTime.diff(startTime) / 1000;
        }

        yield put(
            actions.getCallHistoryDataForWidget.success(callHistoryItems)
        );

        yield put(
            actions.validatesAndPostDataToQueue.request()
        );
    } catch (err) {
        //@ts-ignore
        const error = err?.response?.data;
        yield put(actions.getCallHistoryDataForWidget.failure(error));
    }
}

export function* getRingGroupsListForWidgets() {
    try {
        const params = {
            get_main_office_huntgroups: 1
        };

        const data: RingGroupType[] = yield pagedDataRequest<RingGroupType>(Customer.GetHuntGroupList, params, (data) => data.huntgroup_list);

        yield put(actions.getRingGroupsListForWidgets.success(data));

        yield put(
            actions.validatesAndPostDataToQueue.request()
        );
    } catch (err) {
        //@ts-ignore
        yield put(actions.getRingGroupsListForWidgets.failure(err));
    }
}

export function* getSipCallsListForWidgetsData () {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const resSipCalls: AxiosResponse<GetSipCallsListResponse> = yield api.post(
        CallControl.GetSipCallsList,
        body,
    );

    if(resSipCalls?.data?.calls_list?.length) {
        const formatTime = dateFormat.split(' ')[1];
        for(const call of resSipCalls.data.calls_list) {
            if(call.state === SipCallState.Connected) {
                //For state connected, calculate the duration: 
                //      server_time - connect_time and transform to HH:MM:SS format
                if(resSipCalls.data.server_time && call.connect_time) {
                    const serverTime = dayjs(resSipCalls.data.server_time, dateFormat);
                    const connectTime = dayjs(call.connect_time, dateFormat);
                    const diff = dayjs.utc(serverTime.diff(connectTime));
                    call.duration = diff.format(formatTime);
                    const diff2 = serverTime.diff(connectTime) / 1000;
                    call.durationFormatSec = getDurationFromSec(diff2).string;
                }
            } else {
                //For others states calculate the duration: 
                //  server_time - update_time and transform to HH:MM:SS format
                if(resSipCalls.data.server_time && call.update_time) {
                    const serverTime = dayjs(resSipCalls.data.server_time, dateFormat);
                    const updateTime = dayjs(call.update_time, dateFormat);
                    const diff = dayjs.utc(serverTime.diff(updateTime));
                    call.duration = diff.format(formatTime);
                    const diff2 = serverTime.diff(updateTime) / 1000;
                    call.durationFormatSec = getDurationFromSec(diff2).string;
                }
            }
        }
    }

    const resultData = resSipCalls.data.calls_list;

    return resultData;
}

export function* getSipCallsListForWidgets() {
    try
    {
        const resultData: SipCall[] = yield getSipCallsListForWidgetsData();
        yield put(actions.getSipCallsListForWidgets.success(resultData));
        
        yield put(
            actions.validatesAndPostDataToQueue.request()
        );
    }
    catch(error)
    {
        //@ts-ignore
        const apiError = error?.response?.data;
        yield put(
            actions.getSipCallsListForWidgets.failure(apiError),
        );
    }
}

const actionsOfCallVolumes: Function[] = [
    getCallHistoryDataForWidget,
    getExtensionsListForWidgets,
    getRingGroupsListForWidgets,
];

const actionsOfExtensionPresence: Function[] = [
    getSipCallsListForWidgets,
    getExtensionsListForWidgets,
    getRingGroupsListForWidgets,
];

export function* validatesAndPostDataToQueue() {

    try{
        const widgets: BaseWallboardWidget[] | undefined = yield select((state: ReduxState) => state.wallboard?.widgets);
        const callHistoryItems: WidgetCallHistory[] = yield select((state: ReduxState) => state.wallboard.callHistoryItems);
        const isLoadingHistoryCalls: boolean = yield select((state: ReduxState) => state.wallboard.historyCallsLoading);

        const sipCallsList: SipCall[] = yield select((state: ReduxState) => state.wallboard.sipCallsList);
        const isLoadingSipCalls: boolean = yield select((state: ReduxState) => state.wallboard.isLoadingSipCalls);

        const extensionsList: ExtensionType[] = yield select((state: ReduxState) => state.wallboard.extensionsList);
        const isLoadingExtensions: boolean = yield select((state: ReduxState) => state.wallboard.isLoadingExtensions);

        const ringGroupsList: RingGroupType[] = yield select((state: ReduxState) => state.wallboard.ringGroupsList);
        const isLoadingRingGroups: boolean = yield select((state: ReduxState) => state.wallboard.isLoadingRingGroups);

        const refreshWidgetsQueue: RefreshWidgetQueueItem[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsQueue);
            
        let hasUpdates = false;
        for(const itm of refreshWidgetsQueue) {
            const w = widgets?.find(e => e.id === itm.id);
            if(!w) continue;
            if(w.dataType === WidgetDataType.externalIframe) continue;
            if(w.dataType === WidgetDataType.callVolumes) {
                if(isLoadingHistoryCalls) continue;
                if(isLoadingExtensions) continue;
                if(isLoadingRingGroups) continue;

                itm.data = {
                    callHistoryItems: callHistoryItems,
                    extensionsList: extensionsList,
                    ringGroupsList: ringGroupsList,
                } as CallVolumesWidgetData;
                itm.dataHasLoaded = true;
                hasUpdates = true;
            }
            else if(w.dataType === WidgetDataType.extensionPresense) {
                if(isLoadingSipCalls) continue;
                if(isLoadingExtensions) continue;
                if(isLoadingRingGroups) continue;

                itm.data = {
                    sipCallsList: sipCallsList,
                    extensionsList: extensionsList,
                    ringGroupsList: ringGroupsList,
                } as ExtensionPresenceWidgetData;
                itm.dataHasLoaded = true;
                hasUpdates = true;
            }
        }

        if(hasUpdates) {
            yield put(
                actions.validatesAndPostDataToQueue.success(refreshWidgetsQueue),
            );
        }
    }
    catch(error)
    {
        yield put(
            actions.validatesAndPostDataToQueue.failure(),
        );
    }
}

export function* refreshWallboardTrigger(
    action: ActionType<typeof actions.refreshWallboardTrigger.request>) {

    try{
        const widgets: BaseWallboardWidget[] | undefined = yield select((state: ReduxState) => state.wallboard?.widgets);
        const refreshTime: string = yield select((state: ReduxState) => state.wallboard?.refreshTime);
        const refreshWidgetsTime: RefreshWidgetTime[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsTime);
        const refreshWidgetsQueue: RefreshWidgetQueueItem[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsQueue);

        let widgetsToCheckForUpdates = (action.payload.id
            ? widgets?.filter(e => e.id === action.payload.id)
            : widgets) || [];

        if(!action.payload.refreshImmidiately)
        {
            widgetsToCheckForUpdates = widgetsToCheckForUpdates.filter(el => el.autoRefreshTime)
        }

        const nowTime = dayjs(new Date(), dateFormat);
        const nowTimeString = nowTime.format(dateFormat)
        const widgetsToBeUpdated: BaseWallboardWidget[] = [];
        for(const w of widgetsToCheckForUpdates) {
            const lastRefreshTimeObject = refreshWidgetsTime.find(e => e.id === w.id);
            const lastRefreshTime = lastRefreshTimeObject?.time || refreshTime;
            const diff = nowTime.diff(dayjs(lastRefreshTime, dateFormat), 'second');
            if(diff >= w.autoRefreshTime || action.payload.refreshImmidiately) {
                const isAlreadyExists = !!refreshWidgetsQueue.find(e => e.id === w.id);
                if(!isAlreadyExists) {
                    widgetsToBeUpdated.push(w);
                }
            }
        }

        if(widgetsToBeUpdated.length) {
            const methodsToBeExecuted: Function[] = [];
            for(const wtu of widgetsToBeUpdated) {
                refreshWidgetsQueue.push({
                    id: wtu.id,
                    dataHasLoaded: false,
                    data: {},
                    initLoadStart: nowTimeString
                });
                if(wtu.dataType === WidgetDataType.callVolumes) {
                    for(const a of actionsOfCallVolumes) {
                        if(!methodsToBeExecuted.find(e => e === a)) {
                            methodsToBeExecuted.push(a);
                        }
                    }
                }
                else if(wtu.dataType === WidgetDataType.extensionPresense) {
                    for(const a of actionsOfExtensionPresence) {
                        if(!methodsToBeExecuted.find(e => e === a)) {
                            methodsToBeExecuted.push(a);
                        }
                    }
                }
            }
            
            if(methodsToBeExecuted.length) {
                for(const method of methodsToBeExecuted) {
                    if(method === getCallHistoryDataForWidget) {
                        yield put(actions.getCallHistoryDataForWidget.request());
                    }
                    else if(method === getExtensionsListForWidgets) {
                        yield put(actions.getExtensionsListForWidgets.request({}));
                    }
                    else if(method === getRingGroupsListForWidgets) {
                        yield put(actions.getRingGroupsListForWidgets.request());
                    }
                    else if(method === getSipCallsListForWidgets) {
                        yield put(actions.getSipCallsListForWidgets.request());
                    }
                    else {
                        throw 'Not Implemented!';
                    }
                }
            }
            
            yield put(
                actions.refreshWallboardTrigger.success({
                    refreshWidgetsQueue: refreshWidgetsQueue,
                    refreshWidgetsTime: refreshWidgetsTime
                }),
            );
        }
    }
    catch(error)
    {
        yield put(
            actions.refreshWallboardTrigger.failure(),
        );
    }
}

export function* refreshWidgetCompleted(
    action: ActionType<typeof actions.refreshWidgetCompleted.request>) {
    
    const refreshWidgetsTime: RefreshWidgetTime[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsTime);
    const refreshWidgetsQueue: RefreshWidgetQueueItem[] = yield select((state: ReduxState) => state.wallboard?.refreshWidgetsQueue);

    const nowTime = dayjs(new Date(), dateFormat);

    let rmIndx = refreshWidgetsTime.findIndex(e => e.id === action.payload);
    while(rmIndx !== -1) {
        refreshWidgetsTime.splice(rmIndx, 1);
        rmIndx = refreshWidgetsTime.findIndex(e => e.id === action.payload);
    }

    let indx = refreshWidgetsQueue.findIndex(e => e.id === action.payload);
    let lastRemoveObject: RefreshWidgetQueueItem | undefined = undefined;
    while(indx !== -1) {
        lastRemoveObject = refreshWidgetsQueue[indx];
        refreshWidgetsQueue.splice(indx, 1);
        indx = refreshWidgetsQueue.findIndex(e => e.id === action.payload);
    }
    
    refreshWidgetsTime.push({
        id: action.payload,
        time: lastRemoveObject?.initLoadStart ? lastRemoveObject.initLoadStart : nowTime.format(dateFormat)
    });
    
    yield put(actions.refreshWidgetCompleted.success({
        refreshWidgetsQueue: refreshWidgetsQueue,
        refreshWidgetsTime: refreshWidgetsTime
    }));
}

export const wallboardSaga = [
    takeLatest(actions.getWallboardData.request, getWallboardData),
    takeLatest(actions.updateWallboards.request, updateWallboards),
    takeLatest(actions.refreshWallboardTrigger.request, refreshWallboardTrigger),
    takeLatest(actions.refreshWidgetCompleted.request, refreshWidgetCompleted),
    takeLatest(actions.getCallHistoryDataForWidget.request, getCallHistoryDataForWidget),
    takeLatest(actions.getExtensionsListForWidgets.request, getExtensionsListForWidgets),
    takeLatest(actions.getRingGroupsListForWidgets.request, getRingGroupsListForWidgets),
    takeLatest(actions.getSipCallsListForWidgets.request, getSipCallsListForWidgets),
    takeLatest(actions.validatesAndPostDataToQueue.request, validatesAndPostDataToQueue),
];
