import { makeStyles } from '@material-ui/core';
import { DialingRuleInfo } from '../../../../store/types/DialingRule';
import { Colors } from '../../../../styles/Colors';

export type ServiceCodesFormType = {
    selectedRuleId: number;
    rules: DialingRuleInfo[];
    callParkingStatus: boolean;
    pagingIntercomStatus: boolean;
    groupPickupStatus: boolean;
    callSupervisionStatus: boolean;
    automatic_retrieval: string;
    retrieve_after: number;
};

export const serviceCodesFormDefaultValues: ServiceCodesFormType = {
    selectedRuleId: 0,
    rules: [],
    callParkingStatus: false,
    pagingIntercomStatus: false,
    groupPickupStatus: false,
    callSupervisionStatus: false,
    automatic_retrieval: '0',
    retrieve_after: 120
};

export enum AutomaticRetrievals {
    Disabled = 0,
    EnabledWithTheDefaultCallPattern = 1,
    EnabledWithADistinctiveCallPattern = 2
}

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '22px 16px 17px 16px',
        maxWidth: 970,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        fontSize: 16,
        fontWeight: 500,
        color: Colors.Text,
    },
    switchContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '15px 0',

        '& .MuiSwitch-root': {
            marginLeft: -10,
        },
    },
    rulesContainer: {
        margin: '32px 35px 10px 35px',
    },
    rowRulesContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    rowRulesMargin: {
        marginLeft: 85,
    },
    dialingRulesHeader: {
        marginBottom: 10,
    },
    generalHeader: {
        marginTop: 40,
    },
    noData: {
        color: Colors.LightGraySecondary2,
        display: 'flex',
        fontSize: 14,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 15,
    },
    ruleSelect: {
        '& .MuiInputBase-input': {
            padding: '5px 10px !important',
        },
    },
    switch: {
        marginTop: 0,
        marginBottom: 0,
        height: 'unset',
    },
    headerWithMargin: {
        marginTop: 20,
    },
    rowInsideContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    space: {
        marginLeft: '85px !important',
    },
    authomaticRetrievals: {
        marginLeft: 24,
        width: 312,

        '& .MuiFormControl-root': {
            width: '312px !important',
        },
        
        '& .MuiAutocomplete-input': {
            textOverflow: 'initial',
            paddingRight: '0px !important',
            fontSize: 15.5,
        }
    },
    minText: {
        position: 'absolute',
        marginTop: 14,
        marginLeft: 60,
        top: 10.5
    },
    numberInput: {
        marginLeft: 24,
        //flex: 1,
        width: '125px !important',

        '& .MuiInputBase-input': {
            paddingTop: '11px !important'
        }
    },
}));
