import React from "react";
import BarLoader from "./BarLoader";

interface BarLoaderWrapperProps {
    isLoading: boolean
}

const BarLoaderWrapper: React.FC<BarLoaderWrapperProps> = ({isLoading, children}
) => {
    return isLoading ? <BarLoader/> : <>{children}</>
}

export default BarLoaderWrapper