import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import { GlobalCurrencyInfo } from '../../../../store/types/CustomerInfo';
import _ from 'lodash';
import { actions } from '../../../../store';
import { BillingStateType } from '../../../../store/reducers/billing/reducer';
import CustomizedTextField from "../../../TextField/TextField";
import {makeStyles} from "@material-ui/core";

interface SimplePaymentTaxesProps {
    taxesVisible:boolean,
    amount:string
}

const useStyles = makeStyles(() => ({
    taxContainer: {
        display:'flex',
        alignItems:'center'
    },
    amountInput: {
        maxWidth:200,
        marginRight:15,
        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
        },
        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
        },
    },
}))

const SimplePaymentTaxes:React.VFC<SimplePaymentTaxesProps> = ({taxesVisible,amount}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const { simpleEstimatedTaxesValue } = useSelector<ReduxState, BillingStateType>(
        (state) => state.billing,
    );

    const estimateTaxes = useCallback(
        _.debounce((amount: string) => {
            dispatch(
                actions.simpleEstimateTaxes.request({
                    amount: parseFloat(amount.replace(/,/g, '') || '0'),
                }),
            );
        }, 800),
        [],
    );

    useEffect(() => {
        if (taxesVisible) {
            estimateTaxes(amount);
        }
    }, [amount]);

    const formattedValue = Number(amount) == 0 ? '0.00' : (
        (simpleEstimatedTaxesValue || 0) +
        parseFloat(amount.replace(/,/g, '') || '0')
    ).toFixed(2)

    return (
        <div className={classes.taxContainer}>
            <CustomizedTextField
                id="amountWithTax"
                label={`${t('screens:billing.totalAmountToCharge')}, ${
                    currencyInfo?.iso_4217
                }`}
                value={formattedValue}
                className={classes.amountInput}
                readOnly={true}
            />
        </div>
    );
};

export default SimplePaymentTaxes;
