export enum Colors {
    Primary = '#f6851f',
    Primary1 = 'rgba(246, 131, 31, 0.2)',
    Primary2 = '#F5841F',
    Primary3 = 'rgba(246, 131, 31, 0.8)',
    Secondary = '#3e5c69',
    Secondary1 = '#4c86b7',
    Secondary2 = '#8aaaca',
    Secondary3 = '#c5d5e6',
    Secondary4 = '#e2ecf4',
    Secondary5 = '#e4ebf3',
    Secondary6 = 'rgba(138, 170, 202, 0.2)',
    Secondary7 = 'rgb(97, 148, 192)',
    Secondary8 = 'rgba(97, 148, 192, 0.2)',
    Secondary9 = '#DFEAF2',
    Secondary10 = '#F8FAFC',
    Secondary11 = '#EAF5FE',
    Secondary12 = '#4DAFF9',
    Secondary13 = '#51A6ED',
    Secondary14 = '#a5c8e5',
    Support = '#75b943',
    Background = '#fff',
    Text = 'rgba(0, 0, 0, 0.87)',
    Text3 = 'rgba(0, 0, 0, 0.6)',
    Text2 = '#1F2937',
    White = '#fff',
    Black = '#000',
    WhiteWithOpacity = 'rgba(255, 255, 255, 0.50)',
    WhiteWithTinyOpacity = 'rgba(255, 255, 255, 0.2)',
    Error = '#C40202',
    Gray = 'rgba(0, 0, 0, 0.38)',
    LightGray = '#F9F9F9',
    LightGraySecondary = '#9E9E9E',
    LightGraySecondary2 = 'rgb(117,117,117)',
    LightGraySecondary3 = '#C4C4C4',
    LightGraySecondary4 = '#EFEFEF',
    LightGraySecondary5 = '#757575',
    LightGraySecondary6 = 'rgba(221, 225, 231, 0.50)',
    Gray2 = 'rgba(0, 0, 0, 0.03)',
    Gray3 = '#B0B0B0',
    Gray4 = '#ECECEC',
    Gray5 = 'rgba(0 ,0, 0, 0.6)',
    Gray6 = 'rgba(0, 0, 0, 0.4)',
    Gray7 = '#464646',
    Gray8 = 'rgba(0, 0, 0, 0.12)',
    Gray9 = 'rgba(0, 0, 0, 0.38)',
    Gray11 = 'rgba(0, 0, 0, 0.06)',
    PlaceHolder = 'rgba(0, 0, 0, 0.54)',
    Gray10 = 'rgba(0, 0, 0, 0.26)',
    Gray12 = '#9CA3AF',
    Gray13 = '#92929D',
    Gray14 = 'rgba(0, 0, 0, 0.04)',
    Link = '#217BC7',
    Blue = '#E9EEF0',
    Blue2 = 'rgba(233,238,240, 0.5)',
    ListIconColor = '#666666',
    SmokeBackground = '#F5F7F8',
    BlackSurface = '#121212',
    TransparentGrey = 'rgba(255, 255, 255, 0.16)',
    AvatarBackground = '#E1EEFC',
    Border = '#DDE1E7',
    SignInRootBackground = '#F5F7F8',
    BorderDark = '#D4D8DE',
    Purple = '#AA50E2',
    TooltipTextColor = "#D8D8D8"
}

// DEPRECATED
// Old method of export / import colors
// Will be removed
export const primaryColor: Color = '#f6851f';
export const secondaryColor: Color = '#3e5c69';
export const secondaryColor1: Color = '#4c86b7';
export const secondaryColor2: Color = '#8aaaca';
export const secondaryColor3: Color = '#c5d5e6';
export const secondaryColor4: Color = '#e2ecf4';
export const secondaryColor5: Color = '#e4ebf3';
export const secondaryColor6: Color = 'rgba(138, 170, 202, 0.2)';
export const supportColor: Color = '#75b943';
export const backgroundColor: Color = '#fff';
export const textColor: Color = 'rgba(0, 0, 0, 0.87)';
export const white: Color = '#fff';
export const errorColor: Color = '#C40202';
export const greyColor: Color = 'rgba(0, 0, 0, 0.38)';
export const lightGray: Color = '#F9F9F9';
export const lightGraySecondary: Color = '#9E9E9E';
export const lightGraySecondary2: Color = 'rgb(117,117,117)';
export const greyColor2: Color = 'rgba(0, 0, 0, 0.03)';
export const greyColor3: Color = '#B0B0B0';
export const greyColor4: Color = '#ECECEC';
export const greyColor5: Color = 'rgba(0,0,0,0.6)';
export const greyColor6: Color = '#cccccd';
export const placeHolderColor: Color = 'rgba(0, 0, 0, 0.54)';
export const blackWithOpacity: Color = 'rgba(0,0,0,0.12)';
export type Color =
    | 'rgba(0, 0, 0, 0.54)'
    | 'rgba(0,0,0,0.12)'
    | '#ECECEC'
    | '#F9F9F9'
    | '#9E9E9E'
    | 'rgba(138, 170, 202, 0.2)'
    | '#B0B0B0'
    | '#f6851f'
    | '#3e5c69'
    | '#4c86b7'
    | '#8aaaca'
    | '#c5d5e6'
    | '#e2ecf4'
    | '#e4ebf3'
    | '#75b943'
    | '#fff'
    | 'rgba(0, 0, 0, 0.87)'
    | '#C40202'
    | 'rgba(0, 0, 0, 0.38)'
    | 'rgba(0, 0, 0, 0.03)'
    | 'rgba(0,0,0,0.6)'
    | 'rgb(117,117,117)'
    | '#cccccd';

export function getServiceColor(serviceId: number): string {
    switch(serviceId) {
        case 1: return '#C94D4C';
        case 2: return '#3E5C69';
        case 3: return '#F5841F';
        case 4: return '#75B943';
        case 5: return '#B66720';
        case 6: return '#86460C';
        case 7: return '#8AAACA';
        case 8: return '#8E5CFA';
        case 9: return '#C0C3C9';
        case 10: return '#FCAC93';
        case 11: return '#D6D2CE';
        case 12: return '#51A5ED';
        case 14: return '#1FF5A8';
        case 15: return '#F68BF2';
        case 16: return '#9BCF06';
        case 17: return '#5E5CC9';
        case 18: return '#F51F5F';
        case 19: return '#B1B1B1';
        default:
            const r = (255 - (Math.floor(Math.random() * (25 - 1) + 1)) * 10);
            const g = (255 - (Math.floor(Math.random() * (25 - 1) + 1)) * 10);
            const b = (255 - (Math.floor(Math.random() * (25 - 1) + 1)) * 10);
            return `rgb(${r},${g},${b})`;
    }
}