import { AxiosResponse } from 'axios';
import { select, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { actions } from '../../..';
import { Account, CodecConverter } from '../../../../services/endpoints';
import i18n from '../../../../services/i18n';
import JSONFormData from '../../../../utils/JSONFormData';
import { showErrorToast } from '../../../../utils/showErrorToast';
import { api } from '../../../services/axios';
import { AccountMohListInfo, MohInfo } from '../../../types/AccountMoh';
import {
    MusicOnHoldFlag,
    ServiceFeature,
    ServiceFeatureName,
} from '../../../types/ServiceFeature';

export function* getExtensionMohDetails(
    action: ActionType<typeof actions.getExtensionMohDetails.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({ i_account: action.payload.accountId });

        const res: AxiosResponse<{
            service_features: ServiceFeature[];
        }> = yield api.post(Account.GetServiceFeatures, body);

        const musicOnHoldFeature = res.data.service_features.find(
            (v) => v.name === ServiceFeatureName.MusicOnHold,
        );

        const musicOnHoldStatus =
            musicOnHoldFeature?.effective_flag_value ===
            MusicOnHoldFlag.Enabled;

        const mohListRes: AxiosResponse<{
            moh_list_info: AccountMohListInfo;
        }> = yield api.post(Account.GetMohListInfo, body);

        const mohList: MohInfo[] =
            mohListRes.data.moh_list_info.aggregated_moh_list;

        yield put(
            actions.getExtensionMohDetails.success({
                isEnabled: musicOnHoldStatus,
                items: mohList,
                selectedValueId: parseInt(
                    musicOnHoldFeature?.attributes.find(
                        (v) => v.name === 'i_moh',
                    )?.values[0],
                ),
            }),
        );
    } catch (err: any) {
        actions.getExtensionMohDetails.failure(err);
    }
}

export function* uploadMohFile(
    action: ActionType<typeof actions.uploadMohFile.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            handler: 'moh',
            file_name: action.payload.name,
            object: 'account',
            i_object: action.payload.accountId,
        });

        body.append('upload_file', action.payload.file);

        yield api.post(CodecConverter.AddFile, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        yield getExtensionMohDetails(
            actions.getExtensionMohDetails.request({
                accountId: action.payload.accountId,
            }),
        );

        action.payload.callback?.();

        showErrorToast(i18n.t<string>('common:fileUploaded'));
        yield put(actions.uploadMohFile.success());
    } catch (err: any) {
        showErrorToast();
        yield put(actions.uploadMohFile.failure(err));
    }
}
