import React, {useEffect, useMemo, useCallback, useState} from 'react';
import {
    CallStatisticsFormProps,
    calculateAvgCallsDuration,
    initialValues,
    useStyles,
    formatDuration,
    CallStatisticCsvItem
} from './CallStatistics.utils';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../../store';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import Button from '../../../components/Button/Button';
import {useFormik} from 'formik';
import {WidgetCallHistory, WidgetCallVolumesTimeRange} from '../../../store/types/Wallboard';
import {DropDownDictionaryItem} from '../ActiveCalls/ActiveCalls.utils';
import SelectField from '../../../components/SelectField/SelectField';
import classNames from 'classnames';
import {ReduxState} from '../../../store/types';
import {CloudDownload} from '@material-ui/icons';
import CustomizedTooltip from '../../../components/Tooltip/Tooltip';
import {Colors} from '../../../styles/Colors';
import {CallStatisticTile} from './CallStatisticTile';
import {mkConfig, generateCsv, download} from "export-to-csv";
import {convertFormatFromBackendToDayJs} from '../../../utils/dateWithTimezoneConversion';
import {SearchCallback} from "../CallHistory.utils";
import Loader from "../../../components/Loader/Loader";

export const CallStatistics: React.VFC<SearchCallback> = ({callback}) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const ringGroupsList = useSelector(
        (state: ReduxState) => state.ringgroups?.ringGroupsList ?? [],
    );

    const isLoading = useSelector<ReduxState, boolean>(
        (state) => state.calls.isStatisticLoading ?? false,
    );

    const calls = useSelector<ReduxState, WidgetCallHistory[] | undefined>(
        (state) => state.calls.callsStatistic,
    );

    const callsStatisticUpdateDateFrom = useSelector<ReduxState, string | undefined>(
        (state) => state.calls.callsStatisticUpdateDateFrom,
    );

    const callsStatisticUpdateDateTo = useSelector<ReduxState, string | undefined>(
        (state) => state.calls.callsStatisticUpdateDateTo,
    );

    const userDateFormat = useSelector<ReduxState, string>(
        (state) => state.generic.sessionData?.out_date_time_format || '',
    );

    const [isSearchClicked,setIsSearchClicked] = useState<boolean>(false)

    const widgetCallVolumesTimeRangeList = useMemo(
        () => {
            const allValues = Object.values(WidgetCallVolumesTimeRange);
            const half = allValues.length / 2;
            const allStrings = Object.values(WidgetCallVolumesTimeRange)
                .splice(half, half);
            return allStrings
                .map((v) => ({
                    name: t(`enums:widgetCallVolumesTimeRange.${v}`),
                    value: v as WidgetCallVolumesTimeRange,
                } as DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>))
        },
        [],
    );

    const ringGroupsSelectList = useMemo(
        () => {
            const init: DropDownDictionaryItem<string>[] = [];
            init.push({
                name: t('screens:extensions.any'),
                value: '',
            } as DropDownDictionaryItem<string>);
            ringGroupsList?.items?.map(e => {
                init.push({
                    name: e.name,
                    value: e.id
                } as DropDownDictionaryItem<string>)
            });
            return init;
        }, [ringGroupsList],
    );

    const {
        values,
        handleSubmit,
        setFieldValue,
        errors,
        setFieldError,
    } = useFormik<CallStatisticsFormProps>({
        initialValues,
        onSubmit: (form) => {
            setIsSearchClicked(true)
            getCallStatistic(form);
            // dispatch(actions.getExtensionsListForCallsDoughnut.request());
            // dispatch(actions.getCallHistoryDataForCallsDoughnut.request());
            // dispatch(actions.getActiveCalls.request({serviceType: 3}));
        },
        enableReinitialize: true,
        validateOnChange: false,
    });

    useEffect(() => {
        getCallStatistic(initialValues);
        dispatch(actions.ringGroupsList.request({}));
        // dispatch(actions.getExtensionsListForCallsDoughnut.request());
        // dispatch(actions.getCallHistoryDataForCallsDoughnut.request());
        // dispatch(actions.getActiveCalls.request({serviceType: 3}));
    }, []);

    useEffect(() => {
        !isLoading && setIsSearchClicked(false);
    }, [isLoading]);

    const selectedRingGroup = useMemo(() => {
        const found = ringGroupsSelectList.find(e => e.value === values.ringGroup);
        return !!found ? found : null;
    }, [ringGroupsSelectList, values]);

    const getCallStatistic = useCallback((
        formData: CallStatisticsFormProps
    ) => {
        let extensionIds: string[] | undefined = undefined;
        if (formData.ringGroup && formData.ringGroup.length > 0) {
            const ringGroup = ringGroupsList?.items?.find(e => e.id === formData.ringGroup);
            extensionIds = ringGroup?.assigned_extensions?.map(e => e.id || '');
        }
        dispatch(
            actions.getCallHistoryDataForStatistics.request({
                timeRange: formData.timeRange,
                extensionIds: extensionIds,
                userDateFormat: convertFormatFromBackendToDayJs(userDateFormat)
            })
        );
    }, [ringGroupsList, userDateFormat]);

    const incomingCalls = useMemo(() => {
        return calls?.filter(call => ((call.bit_flags || 0) & 12) === 8);
    }, [calls]);

    const incomingConnectedCalls = useMemo(() => {
        return incomingCalls?.filter(e => e.durationInSec > 0);
    }, [incomingCalls]);

    const outgoingCalls = useMemo(() => {
        return calls?.filter(call => ((call.bit_flags || 0) & 12) === 0
            || ((call.bit_flags || 0) & 12) === 4);
    }, [calls]);

    const outgoingConnectedCalls = useMemo(() => {
        return outgoingCalls?.filter(e => e.durationInSec > 0);
    }, [outgoingCalls]);

    const forwardedCalls = useMemo(() => {
        return calls?.filter(call => ((call.bit_flags || 0) & 12) === 12);
    }, [calls]);

    const forwardedConnectedCalls = useMemo(() => {
        return forwardedCalls?.filter(e => e.durationInSec > 0);
    }, [forwardedCalls]);

    const abandonedCalls = useMemo(() => {
        return incomingCalls?.filter(e => e.durationInSec === 0);
    }, [incomingCalls]);

    const callAbandonrate = useMemo(() => {
        if (!incomingCalls || !incomingCalls.length) {
            return '0.0%';
        }
        const callsOfRealDuration = incomingCalls?.filter(e => e.durationInSec > 0);
        return ((100.0 * (incomingCalls.length - callsOfRealDuration.length)) / (1.0 * incomingCalls.length)).toFixed(1) + '%';
    }, [incomingCalls]);

    const avgIncomingCalls = useMemo(() => {
        return calculateAvgCallsDuration(incomingCalls);
    }, [incomingCalls]);

    const avgOutgoingCalls = useMemo(() => {
        return calculateAvgCallsDuration(outgoingCalls);
    }, [outgoingCalls]);

    const totalDuration = useMemo(() => {
        if (!calls || !calls.length) {
            return '00:00';
        }
        const duration = calls?.map(e => e.durationInSec)
            ?.reduce((sum, current) => sum + current, 0) ?? 0;
        return formatDuration(duration);
    }, [calls]);

    const exportToCsv = useCallback(() => {
        const csvConfig = mkConfig({
            useKeysAsHeaders: false,
            showTitle: false,
            showColumnHeaders: false,
            columnHeaders: ['name', 'value', 'ringGroup'],
            filename: 'call_statistics_'
        });

        const data: CallStatisticCsvItem[] = [];
        data.push({
            name: t<string>('screens:calls.fromDateTime'),
            value: t<string>('screens:calls.toDateTime'),
            ringGroup: t<string>('screens:calls.ringGroup')
        });
        data.push({
            name: callsStatisticUpdateDateFrom || '',
            value: callsStatisticUpdateDateTo || '',
            ringGroup: selectedRingGroup?.name || ''
        });
        data.push({
            name: '',
            value: '',
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.totalCalls'),
            value: calls?.length ?? 0,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.incomingCallsInterscope'),
            value: incomingCalls?.length ?? 0,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.outgoingCalls'),
            value: outgoingCalls?.length ?? 0,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.forwardedCalls'),
            value: forwardedCalls?.length ?? 0,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.abandonedIncomingCalls'),
            value: abandonedCalls?.length ?? 0,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.callAbandonRate'),
            value: callAbandonrate.replace("%", ""),
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.connectedIncomingCalls'),
            value: incomingConnectedCalls?.length ?? 0,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.connectedOutgoingCalls'),
            value: outgoingConnectedCalls?.length ?? 0,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.connectedForwardedCalls'),
            value: forwardedConnectedCalls?.length ?? 0,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.avgIncomingCallDuration'),
            value: avgIncomingCalls,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.avgOutgoingCallDuration'),
            value: avgOutgoingCalls,
            ringGroup: ''
        });
        data.push({
            name: t<string>('screens:calls.totalDuration'),
            value: totalDuration,
            ringGroup: ''
        });

        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }, [selectedRingGroup, callsStatisticUpdateDateFrom, callsStatisticUpdateDateTo,
        calls, incomingCalls, outgoingCalls, forwardedCalls, abandonedCalls,
        callAbandonrate, avgIncomingCalls, avgOutgoingCalls, totalDuration]);

    return (<>
        <form
            onSubmit={handleSubmit}
            autoComplete="off"
            data-testid="call-statistics-form"
            className={classes.formContainer}
        >
            <Grid item className={classes.filtersContainer}>
                <Grid item className={classes.itemsContainer}>
                    <Grid item className={classes.inputsContainer}>
                        <SelectField
                            id="ringGroup"
                            label={t('screens:calls.ringGroup')}
                            getOptionLabel={(option: DropDownDictionaryItem<string>) => {
                                return option.name;
                            }}
                            setFieldError={setFieldError}
                            onChange={(_, option: DropDownDictionaryItem<string>) => {
                                setFieldValue('ringGroup', option.value);
                            }}
                            items={ringGroupsSelectList ?? []}
                            value={selectedRingGroup}
                            getOptionSelected={(
                                option: DropDownDictionaryItem<string>,
                                value: DropDownDictionaryItem<string>,
                            ) => value.value === option.value}
                            dataQa={'ring-group'}
                            skipPermission
                            disableClearable
                            classes={{container: classNames(classes.ringGroupField, classes.dropDownMultiSelect)}}
                            helperText={
                                errors?.ringGroup && errors?.ringGroup?.length > 0 ? errors?.ringGroup : ''
                            }
                        />

                        <SelectField
                            id='timeRange'
                            items={widgetCallVolumesTimeRangeList}
                            label={t('screens:wallboard.timeRange')}
                            value={widgetCallVolumesTimeRangeList.find((v) => v.value === values.timeRange)}
                            getOptionLabel={(v: DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>) => v?.name || ''}
                            classes={{container: classes.timeRangeField}}
                            setFieldError={setFieldError}
                            getOptionSelected={(
                                option: DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>,
                                value: DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>,
                            ) => value?.value === option?.value}
                            renderOption={(v: DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>) =>
                                <span>{v?.name}</span>
                            }
                            onChange={(_, v: DropDownDictionaryItem<WidgetCallVolumesTimeRange | undefined>) => {
                                setFieldValue('timeRange', v.value);
                            }}
                            helperText={
                                errors && errors.timeRange
                                    ? errors.timeRange
                                    : ''
                            }
                            disableAutoSettingValue
                            disableClearable
                            skipPermission
                        />
                    </Grid>
                    <Button
                        primary
                        accent
                        dataQa="call-statistics-search"
                        className={classes.button}
                        onClick={() => {
                            handleSubmit();
                            callback?.()
                        }}
                        skipPermission
                    >
                        {t('common:search')}
                    </Button>

                    <CustomizedTooltip
                        title={t('tooltips:calls.downloadCsv')}
                        copy={false}
                        disableHoverListener={false}
                        above
                    >
                        <Button
                            data-qa={'download-csv-button'}
                            data-testid={'download-csv-button'}
                            type="button"
                            onClick={exportToCsv}
                            className={classes.downloadButton}
                            skipPermission
                        >
                            <CloudDownload
                                htmlColor={Colors.Secondary2}
                                style={{height: 22, width: 22, marginRight: 6, marginLeft: -3}}
                            />
                            {t('tooltips:calls.downloadCsv')}
                        </Button>
                    </CustomizedTooltip>
                </Grid>

                <Grid className={classes.dataContainer}>
                    {isLoading && (
                        <div className={classes.spinnerContainer}>
                            <Loader dataQa={'call-statistic-loader'}/>
                        </div>)
                    }

                    <div className={isLoading ? classes.loadDataInProgress: ''}>
                        <div className={classNames(classes.dataContainerRow, classes.dataContainerRowUnderlined)}>
                            <CallStatisticTile
                                value={calls?.length?.toString() ?? ''}
                                valueClass={classes.supportColor}
                                textLine={t('screens:calls.totalCalls')}
                                isLoading={isLoading}
                                dataTestId={'call-statistic-total-calls'}
                                preventShowDash={isSearchClicked}
                            />
                            <CallStatisticTile
                                value={abandonedCalls?.length?.toString() ?? ''}
                                valueClass={classes.errorColor}
                                textLine={t('screens:calls.abandonedIncomingCalls')}
                                isLoading={isLoading}
                                preventShowDash={isSearchClicked}
                            />
                            <CallStatisticTile
                                value={callAbandonrate}
                                valueClass={classes.errorColor}
                                textLine={t('screens:calls.callAbandonRateNoPercent')}
                                isLoading={isLoading}
                                padValue={false}
                                preventShowDash={isSearchClicked}
                            />
                        </div>
                        <div className={classNames(classes.dataContainerRow, classes.dataContainerRowUnderlined)}>
                            <CallStatisticTile
                                value={incomingCalls?.length?.toString() ?? ''}
                                valueClass={classes.blackColor}
                                textLine={t('screens:calls.incomingCallsInterscope')}
                                isLoading={isLoading}
                                preventShowDash={isSearchClicked}
                            />
                            <CallStatisticTile
                                value={outgoingCalls?.length?.toString() ?? ''}
                                valueClass={classes.blackColor}
                                textLine={t('screens:calls.outgoingCalls')}
                                isLoading={isLoading}
                                preventShowDash={isSearchClicked}

                            />
                            <CallStatisticTile
                                value={forwardedCalls?.length?.toString() ?? ''}
                                valueClass={classes.blackColor}
                                textLine={t('screens:calls.forwardedCalls')}
                                isLoading={isLoading}
                                preventShowDash={isSearchClicked}
                            />
                        </div>
                        <div className={classNames(classes.dataContainerRow, classes.dataContainerRowUnderlined)}>
                            <CallStatisticTile
                                value={incomingConnectedCalls?.length?.toString() ?? ''}
                                valueClass={classes.blackColor}
                                textLine={t('screens:calls.connectedIncomingCalls')}
                                isLoading={isLoading}
                                preventShowDash={isSearchClicked}
                            />
                            <CallStatisticTile
                                value={outgoingConnectedCalls?.length?.toString() ?? ''}
                                valueClass={classes.blackColor}
                                textLine={t('screens:calls.connectedOutgoingCalls')}
                                isLoading={isLoading}
                                preventShowDash={isSearchClicked}
                            />
                            <CallStatisticTile
                                value={forwardedConnectedCalls?.length?.toString() ?? ''}
                                valueClass={classes.blackColor}
                                textLine={t('screens:calls.connectedForwardedCalls')}
                                isLoading={isLoading}
                                preventShowDash={isSearchClicked}
                            />
                        </div>
                        <div className={classes.dataContainerRow}>
                            <CallStatisticTile
                                value={avgIncomingCalls}
                                valueClass={classes.blackColor}
                                textLine={t('screens:calls.avgIncomingCallDurationNoTime')}
                                isLoading={isLoading}
                                padValue={false}
                                preventShowDash={isSearchClicked}
                            />
                            <CallStatisticTile
                                value={avgOutgoingCalls}
                                valueClass={classes.blackColor}
                                textLine={t('screens:calls.avgOutgoingCallDurationNoTime')}
                                isLoading={isLoading}
                                padValue={false}
                                preventShowDash={isSearchClicked}
                            />
                            <CallStatisticTile
                                value={totalDuration}
                                valueClass={classes.blackColor}
                                textLine={t('screens:calls.totalCallDuration')}
                                isLoading={isLoading}
                                padValue={false}
                                preventShowDash={isSearchClicked}
                            />
                        </div>
                    </div>

                </Grid>
            </Grid>
        </form>
    </>);
};