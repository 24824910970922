import {createAction, createAsyncAction} from 'typesafe-actions';
import {APIErrorInterface} from '../../types';
import {
    CreateNewSipTrunkFormDataPayload,
    SipTrunksListRequestPayload,
    SipTrunksListRequestResponse
} from "./payloads";
import * as payloads from "../extensions/payloads";
import * as payloads_sip from "../sipTrunks/payloads";
import {SipTrunksListItem} from "../../reducers/extensions/extensions/reducer";
import {DynamicListEntity} from "../../../components/Forms/SipTrunks/SipContact/SipContactForm.utils";

export const getSipTrunksList = createAsyncAction(
    'GET_SIP_TRUNKS_LIST_REQUEST',
    'GET_SIP_TRUNKS_LIST_SUCCESS',
    'GET_SIP_TRUNKS_LIST_FAILED',
)<SipTrunksListRequestPayload, SipTrunksListRequestResponse, APIErrorInterface>();

export const getSipTrunkTabData = createAsyncAction(
    'GET_SIP_TRUNK_TAB_DETAILS_DATA',
    'GET_SIP_TRUNK_TAB_DETAILS_DATA_SUCCESS',
    'GET_SIP_TRUNK_TAB_DETAILS_DATA_FAILED',
)<payloads_sip.GetSipTrunkTabDataPayload, undefined, undefined>();

export const getSipTrunkCallSettingsTabData = createAsyncAction(
    'GET_SIP_TRUNK_CALL_SETTINGS_TAB_DATA',
    'GET_SIP_TRUNK_CALL_SETTINGS_TAB_DATA_SUCCESS',
    'GET_SIP_TRUNK_CALL_SETTINGS_TAB_DATA_FAILED',
)<{ id: string; domain: string; i_account: number }, undefined, undefined>();

export const getSipTrunkCallForwardingTabData = createAsyncAction(
    'GET_SIP_TRUNK_CALL_FORWARDING_TAB_DATA',
    'GET_SIP_TRUNK_CALL_FORWARDING_TAB_DATA_SUCCESS',
    'GET_SIP_TRUNK_CALL_FORWARDING_TAB_DATA_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const getSipTrunkCallScreeningTabData = createAsyncAction(
    'GET_SIP_TRUNK_CALL_SCREENING_TAB_DETAILS_DATA',
    'GET_SIP_TRUNK_CALL_SCREENING_TAB_DETAILS_DATA_SUCCESS',
    'GET_SIP_TRUNK_CALL_SCREENING_TAB_DETAILS_DATA_FAILED',
)<
    payloads.GetCPRuleListRequest &
    payloads.GetCallProcessingPolicyListRequestPayload,
    undefined,
    undefined
>();

export const getSipTrunkDynamicAddresses = createAsyncAction(
    'GET_SIP_DYNAMIC_ADDRESSES',
    'GET_SIP_DYNAMIC_ADDRESSES_SUCCESS',
    'GET_SIP_DYNAMIC_ADDRESSES_FAILED',
)<
    {name?:string},
    {account_list: DynamicListEntity[]},
    undefined
>();

export const getSipTrunkPlanTabData = createAsyncAction(
    'GET_SIP_TRUNK_PLAN_TAB_DATA',
    'GET_SIP_TRUNK_PLAN_TAB_DATA_SUCCESS',
    'GET_SIP_TRUNK_PLAN_TAB_FAILED',
)<{ i_account: number }, undefined, undefined>();

export const editSipTrunk = createAsyncAction(
    'EDIT_SIP_TRUNK',
    'EDIT_SIP_TRUNK_SUCCEEDED',
    'EDIT_SIP_TRUNK_FAILED',
)<payloads_sip.EditSipTrunkRequestPayload, undefined, APIErrorInterface>();

export const getSipTrunkBasicDetailsData = createAsyncAction(
    'GET_SIP_TRUNK_BASIC_DETAILS_DATA',
    'GET_SIP_TRUNK_BASIC_DETAILS_DATA_SUCCESS',
    'GET_SIP_TRUNK_BASIC_DETAILS_DATA_FAILED',
)<
    payloads_sip.GetSipTrunkDetailsDataRequestPayload,
    SipTrunksListItem,
    APIErrorInterface | undefined
>();

export const setSipTrunkStatus = createAsyncAction(
    'SET_SIP_TRUNK_STATUS',
    'SET_SIP_TRUNK_STATUS_SUCCEEDED',
    'SET_SIP_TRUNK_STATUS_FAILED',
)<
    payloads.UpdateExtensionStatusPayload,
    payloads.UpdateExtensionStatusPayload,
    Error
>();


export const refreshSipTrunkStatus = createAsyncAction(
    'REFRESH_SIP_TRUNKS_STATUS_REQUEST',
    'REFRESH_SIP_TRUNKS_STATUS_SUCCESS',
    'REFRESH_SIP_TRUNKS_STATUS_FAILED',
)<payloads.RefreshExtensionStatusRequestPayload,
    payloads.RefreshExtensionStatusResponsePayload,
    undefined>();

export const clearErrorsCreateNewSipTrunkForm = createAction(
    'CLEAR_ERRORS_ADD_NEW_SIP_TRUNK_FORM',
)();

export const createSipTrunkDetailsData = createAsyncAction(
    'CREATE_SIP_TRUNK_DETAILS_DATA',
    'CREATE_SIP_TRUNK_DETAILS_DATA_SUCCESS',
    'CREATE_SIP_TRUNK_DETAILS_DATA_FAILED',
)<{ i_account?: number } | undefined, undefined, undefined>();

export const createNewSipTrunk = createAsyncAction(
    'CREATE_NEW_SIP_TRUNK',
    'CREATE_NEW_SIP_TRUNK_SUCCESS',
    'CREATE_NEW_SIP_TRUNK_FAILED',
)<CreateNewSipTrunkFormDataPayload, undefined, APIErrorInterface>();

