import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    AddPaymentMethodDialogProps,
    useStyles,
} from './AddPaymentMethodDialog.utils';
import {
    convertCardValidatorTypeToPaymentType,
    convertPaymentMethodToFormData,
    convertPaymentMethodToPaymentType,
    getCardType,
    isEmailRequiredForSave,
    paymentMethodFormDefaultValues,
    PaymentMethodFormType,
    paymentMethodValidationSchemaHelper,
} from '../PaymentMethodForm/PaymentMethodForm.utils';
import DialogContainer, {
    DialogButton,
} from '../../../AlertDialog/DialogContainer';
import PaymentMethodForm from '../PaymentMethodForm/PaymentMethodForm';
import { PaymentMethodType } from '../../../../store/types/Billing';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import {
    OwnerPaymentMethod
} from '../../../../store/types/Payment';
import { CustomerInfoDetails } from '../../../../store/types/CustomerInfo';
import { Subdivision } from '../../../../store/types/Subdivision';
import { actions } from '../../../../store';
import Loader from '../../../Loader/Loader';
import { YesNo } from '../../../../store/types/CallScreening';

const AddPaymentMethodDialog: React.VFC<AddPaymentMethodDialogProps> = ({
    isOpen,
    toggleVisibility,
    paymentMethodToEdit,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const customerInfo = useSelector<
        ReduxState,
        CustomerInfoDetails | undefined
    >((state) => state.billing.customerInfo);

    const isDefaultPaymentFormLoading = useSelector<
        ReduxState,
        boolean | undefined
    >((state) => state.billing.isDefaultPaymentFormLoading);

    const subdivisionsList = useSelector<ReduxState, Subdivision[]>(
        (state) => state.generic.subdivisionsList,
    );

    const paymentsMethods = useMemo(() => {
        const _paymentMethods: PaymentMethodType[] = [];
        if(!ownerPaymentsMethods) return _paymentMethods;

        for(const pm of ownerPaymentsMethods) {
            if(pm.ext_auth === YesNo.Yes) continue;
            const _type = convertPaymentMethodToPaymentType(pm.payment_method);
            if(_paymentMethods.findIndex(e => e === _type) === -1) {
                _paymentMethods.push(_type);
            }
        }

        _paymentMethods.sort((a, b) => (a as number) - (b as number));

        return _paymentMethods;
    }, [ownerPaymentsMethods]);

    const initialValues = useMemo(() => {
        return {
            ...paymentMethodFormDefaultValues,
            paymentType: paymentsMethods?.includes(PaymentMethodType.CreditCard)
                ? PaymentMethodType.CreditCard
                : PaymentMethodType.BankAccount,
            address: customerInfo?.baddr1 || '',
            state:
                subdivisionsList.find((v) => v.name === customerInfo?.state)
                    ?.i_country_subdivision || null,
            country: customerInfo?.country || '',
            city: customerInfo?.city || '',
            postalCode: customerInfo?.zip || '',
            billingPhone: customerInfo?.phone1 || '',
            ...(paymentMethodToEdit
                ? convertPaymentMethodToFormData(paymentMethodToEdit)
                : {}),
        };
    }, [paymentMethodToEdit, ownerPaymentsMethods, subdivisionsList]);

    const isPaymentMethodAvaiableToSave = (
        isDirty: boolean,
        values: PaymentMethodFormType,
    ) => {
        if (
            isEmailRequiredForSave(
                values,
                ownerPaymentsMethods,
                customerInfo,
            ) &&
            !values.email
        ) {
            return false;
        }

        if (values.paymentType === PaymentMethodType.CreditCard) {
            return (
                !!values.cardholderName &&
                !!values.cardNumber &&
                !!values.expirationDate &&
                !!values.cvv &&
                !!isDirty &&
                !!values.address &&
                !!values.country &&
                !!values.city &&
                !!values.billingPhone &&
                !!values.postalCode
            );
        } else if(values.paymentType === PaymentMethodType.BankAccount) {
            return (
                !!values.accountName &&
                !!values.accountNumber &&
                !!values.bankRoutingNumber &&
                !!isDirty &&
                !!values.address &&
                !!values.country &&
                !!values.city &&
                !!values.billingPhone &&
                !!values.postalCode
            );
        }
        else if(values.paymentType === PaymentMethodType.DirectDebitNL) {
            return (
                !!values.accountName &&
                !!values.accountNumber &&
                !!isDirty &&
                !!values.address &&
                !!values.country &&
                !!values.city &&
                !!values.billingPhone &&
                !!values.postalCode
            );
        }

        return true;
    };

    const getCurrentOwnerPaymentMethod = (
        type: PaymentMethodType,
        number: string,
    ) => {
        if (type === PaymentMethodType.CreditCard) {
            const validatorCardType = getCardType(number);
            if (validatorCardType) {
                const cardType = convertCardValidatorTypeToPaymentType(
                    validatorCardType
                );

                return ownerPaymentsMethods?.find(
                    (v) => v.payment_method === cardType,
                );
            }
        }
        else {
            return ownerPaymentsMethods?.find(
                (v) => convertPaymentMethodToPaymentType(v.payment_method) === type
            );
        }
        return undefined;
    };
    
    const onSubmit = (form: PaymentMethodFormType) => {
        console.log(form);
        
        const pm = getCurrentOwnerPaymentMethod(form.paymentType, form.cardNumber);
        const object = {
            ...form,
            payment_method: pm?.payment_method ?? ''
        };
        dispatch(actions.setDefaultPaymentMethod.request(object));
    };

    return (
        <Formik<PaymentMethodFormType>
            initialValues={initialValues}
            validationSchema={paymentMethodValidationSchemaHelper(
                ownerPaymentsMethods || [],
                customerInfo,
                true
            )}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange={false}
        >
            {({ submitForm, resetForm, dirty, values }) => (
                <DialogContainer
                    isOpen={isOpen}
                    dataQa="ring-schedule-group-dialog"
                    header={
                        paymentMethodToEdit
                            ? t('screens:billing.editPaymentMethod')
                            : t('screens:billing.addPaymentMethod')
                    }
                    className={classes.modalContainer}
                    disableEnforceFocus
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={() => {
                                toggleVisibility?.();
                                setTimeout(() => {
                                    resetForm();
                                }, 300);
                            }}
                        />,
                        <DialogButton
                            key="save"
                            label={t('common:save')}
                            onClick={submitForm}
                            disabled={
                                !isPaymentMethodAvaiableToSave(dirty, values)
                            }
                            primary
                        />,
                    ]}
                >
                    <PaymentMethodForm
                        paymentMethodsTypes={paymentsMethods}
                        withEmailWhenIsNecessary
                    />

                    {isDefaultPaymentFormLoading && (
                        <Loader
                            dataQa="edit-payment-method-loader"
                            absolutePosition
                        />
                    )}
                </DialogContainer>
            )}
        </Formik>
    );
};

export default AddPaymentMethodDialog;